import store from './store';
import routes from './router';
 
export default {
	name: "Settings Module",
	id: "settings-module",
    type:"setting",
	description: "Manages Settings",
	store: store,
	routes: routes
}