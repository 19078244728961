import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "page" }
const _hoisted_3 = { class: "body-content" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-content-center flex-wrap page-loading w-screen h-screen"
}
const _hoisted_5 = { class: "flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (!_ctx.self.hide_header)
        ? (_openBlock(), _createBlock(_component_AppHeader, {
            key: 0,
            onUserLoaded: _ctx.userLoaded
          }, null, 8, ["onUserLoaded"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.self.user_loaded)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["body-wrap p-0", { 'top-0': _ctx.self.hide_header }])
            }, [
              _createVNode(_component_router_view, { onRemoveHeader: _ctx.removeHeader }, null, 8, ["onRemoveHeader"])
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, _ctx.self.user_loaded]
    ]),
    (!_ctx.self.user_loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ProgressSpinner, {
              strokeWidth: "3",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}