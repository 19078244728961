import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "w-9" }
const _hoisted_6 = { class: "col-12 md:col-9" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "flex align-items-center justify-content-end mt-2" }

import { reactive, onMounted, capitalize, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import RoleEditService from "@/modules/settings/services/roles/roleEdit";

export default /*@__PURE__*/_defineComponent({
  __name: 'editRole',
  props: {
  view_role: {
    type: Boolean,
    default: false,
  },
  role: {
    type: Object,
    default: () => {
      return {};
    },
  },
},
  emits: ["updated-role"],
  setup(__props, { emit: __emit }) {

const { appContext }:any = getCurrentInstance();
const props = __props;
const editRole = ref();
const emit = __emit;
const self = reactive({
  role_edit_service: new RoleEditService(),
  store: useStore(),
  role: {} as any,
  permissions: {} as any,
  updating_role: false,
});
onMounted(() => {
  self.permissions = self.store.getters["setting/getRolesPermissionDetail"];
  getRoleAndResources();
});
function getRoleAndResources() {
  self.role = Object.assign({}, props.role);
  self.role.resources = props.role.resources.map((resource: any) => {
    return {
      id: resource.id,
      name: resource.name,
      slug: resource.slug,
      permission: {
        read: resource.permission.read == 1,
        write: resource.permission.write == 1,
        edit: resource.permission.edit == 1,
        delete: resource.permission.delete == 1,
        manage: resource.permission.manage == 1,
      },
    };
  });
}

function updateAll(index: number, key: any) {
  appContext.config.globalProperties.$allowACL(
    self.role.resources,
    index,
    key
  );
}

async function updateRole() {
  const validate = await editRole.value.validate();
  if (!validate.valid) return;
  self.updating_role = true; 
  self.role_edit_service.updateRole(
    self.role,
    (response: any, error: any) => {
      if (response) {
        self.updating_role = false;
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("updated-role", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Form = _resolveComponent("Form")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "editRole",
      ref: editRole,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            for: "name",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Name "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "name",
              name: "name",
              rules: "required",
              autocomplete: "off",
              modelValue: self.role.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.role.name) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.role.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.role.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "name"
            })
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(self.role.resources, (resource, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "field grid",
            key: index
          }, [
            _createElementVNode("label", {
              for: resource.slug,
              class: "col-12 mb-2 md:col-3 md:mb-0 justify-content-between"
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(resource.name), 1),
              _withDirectives(_createVNode(_component_Button, {
                icon: "fa-solid fa-question",
                class: "role-button ml-2",
                rounded: "",
                "aria-label": "Help"
              }, null, 512), [
                [
                  _directive_tooltip,
                  self.permissions[resource.slug],
                  void 0,
                  { bottom: true }
                ]
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "w-2" }, null, -1))
            ], 8, _hoisted_4),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Field, {
                class: "w-full flex flex-wrap justify-content-between",
                as: "div",
                type: "text",
                id: resource.slug,
                name: resource.name,
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(resource.permission, (permission, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      class: "flex align-items-center mr-2"
                    }, [
                      _createVNode(_component_Checkbox, {
                        modelValue: resource.permission[key],
                        "onUpdate:modelValue": ($event: any) => ((resource.permission[key]) = $event),
                        inputId: `${resource.slug}-${key}`,
                        name: capitalize(key.toString()),
                        binary: true,
                        onChange: ($event: any) => (updateAll(index, key))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "inputId", "name", "onChange"]),
                      _createElementVNode("label", {
                        for: `${resource.slug}-${key}`,
                        class: "ml-2"
                      }, _toDisplayString(capitalize(key.toString())), 9, _hoisted_7)
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["id", "name"]),
              _createVNode(_component_ErrorMessage, {
                as: "small",
                class: "p-error",
                name: resource.name
              }, null, 8, ["name"])
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Update Role",
            loading: self.updating_role,
            onClick: _withModifiers(updateRole, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})