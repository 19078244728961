<template>
  <div class="app-layout">
    <div v-show="self.agreement_loaded" class="page">
      <div class="body-content">
        <div class="flex flex-column">
          <Card
            class="agreement-content border-1 border-round w-6 mx-auto my-6"
          >
            <template #header>
              <div class="relative">
                <Button
                  icon="icofont icofont-close"
                  class="button-primary absolute"
                  @click="self.$keycloak.login()"
                  rounded
                  aria-label="Cancel"
                />
              </div>
            </template>
            <template #title> {{ self.agreement.title }} </template>
            <template #content>
              <div v-html="self.agreement.content"></div>
            </template>
          </Card>
        </div>
      </div>
    </div>
    <div
      v-if="!self.agreement_loaded"
      class="flex justify-content-center flex-wrap page-loading w-screen h-screen"
    >
      <div class="flex align-items-center justify-content-center">
        <ProgressSpinner
          strokeWidth="3"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted } from "vue";
export default defineComponent({
  name: "AgreementLayout",
  setup() {
    const { appContext }:any = getCurrentInstance();
    const self: any = reactive({
      ...appContext.config.globalProperties,
      agreement_loaded: false,
      agreement: {},
    });
    onMounted(() => {
      getAgreementContent();
    });

    function getAgreementContent() {
      self.agreement_loaded = false;
      self.public_service.getData(
        `user-agreements/${self.$route.params.slug}`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.agreement = response;
          }
          if (error) {
            self.$toastMessage(
              self.$getErrorResponse(error)
            );
          }
          self.agreement_loaded = true;
        }
      );
    }
    return {
      self,
      getAgreementContent,
    };
  },
});
</script>