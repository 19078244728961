<template>
  <div>
    <AppToolbar
      permission="tags"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      :suggestions="self.suggestions"
      :filter="self.filterOptions"
      search_placeholder="Search Tag Group"
      @reload-data="reloadAllData"
      @create-new="self.create_new = true"
      @submit-filter-data="searchTags"
      @get-suggestions="getFilterSuggestions"
    />
    <div class="the-content">
      <TableView
        permission_type="tags"
        :loading="self.loading"
        :items="self.tags"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getTagListing"
      />
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_tag"
        position="right"
      >
        <template #header>Edit Tag</template>
        <EditTag :tag="self.selected_tag" @updated-tag="reloadAllData" />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.create_new"
        position="right"
      >
        <template #header> Add Tag</template>
        <CreateTag @created-tag="reloadAllData" />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";

const EditTag = defineAsyncComponent(() => import("./components/editTag.vue"));
const CreateTag = defineAsyncComponent(() => import("./components/createTag.vue"));
export default defineComponent({
  name: "TagIndex",
  components: {
    EditTag,
    CreateTag,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      api_url: "tags",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-tags mr-1",
        label: "Tag Groups",
        to: { name: "tagGroup" },
      },
      store: useStore(),
      toolbarOptions: {
        filter: true,
        refresh: true,
        add: true,
      },
      filterOptions: {
        fields: true,
        date: true,
      },
      tags: [] as any,
      search_view: false,
      search_values: {},
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "name",
          label: "Tag Group",
          sortable: true,
          style: "width:90%",
        },
      ],
      selected_tag: {},
      create_new: false,
      edit_tag: false,
      action_buttons: [
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editTag(val);
          },
          permission: "edit",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteTag(val);
          },
          permission: "delete",
        },
      ],
      suggestions: {
        data: [] as any,
        loading: false,
        page: 1,
        pagination: {
          current_page: 1,
          items_per_page: 0,
          total_page: 0,
          total_records: 0,
        },
      },
    });

    onMounted(() => {
      getTagListing();
    });
    function reloadAllData(data: object = {}, created_new: boolean = false) {
      self.edit_tag = false;
      self.create_new = false;
      if (created_new) {
        self.tags.unshift(data);
      } else {
        getTagListing(true);
      }
    }

    function editTag(val: any) {
      self.selected_tag = val;
      self.edit_tag = true;
    }

    function deleteTag(val: any) {
      self.selected_tag = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to trash?",
        header: "Delete Tag",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_tag,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedTagListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedTagListing() {
      self.edit_tag = false;
      getTagListing(true);
    }

    function getTagListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.tags = [];
      }
      if (self.search_view) {
        searchTags(self.search_values, reset);
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    async function getFilterSuggestions(query: string, reset = false) {
      if (reset) {
        self.suggestions.page = 1;
        self.suggestions.data = [];
      }
      if (
        (self.suggestions.page <= self.suggestions.pagination.total_page &&
          !self.suggestions.loading) ||
        (self.suggestions.page == 1 && !self.suggestions.loading)
      ) {
        self.suggestions.loading = true;
        await appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.suggestions.page,
          {
            query: query,
          },
          (response: any, error: any) => {
            if (response) {
              if (response.metadata) {
                self.pagination = response.metadata;
              }
              self.suggestions.data.push(...response.data);
              self.suggestions.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.suggestions.loading = false;
          }
        );
      }
    }

    function searchTags(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.tags = [];
      }
      self.search_values = filter_value;
      self.search_view = true;
      if (
        (self.page <= self.pagination.total_page && !self.loading) || (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.tags.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }
    return {
      self,
      updatedTagListing,
      getTagListing,
      searchTags,
      reloadAllData,
      getFilterSuggestions,
    };
  },
});
</script>