import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: "",
    class: "download-dialog",
    visible: _ctx.self.view_dialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.view_dialog) = $event)),
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:view_modal', false))),
    style: { width: '70vw' },
    position: "top"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Downloads",
        icon: "fa fa-download",
        text: "",
        class: "primary-color p-0 font-bold opacity-100",
        disabled: ""
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1)
    ]),
    _: 1
  }, 8, ["visible"]))
}