import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_EditResource = _resolveComponent("EditResource")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "resources",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      onReloadData: _ctx.reloadAllData
    }, null, 8, ["breadcrumb_home", "options", "loading", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "resources",
        loading: _ctx.self.loading,
        items: _ctx.self.resources,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getResourceListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_resource,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.edit_resource) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Edit Resource")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_EditResource, {
            resource: _ctx.self.selected_resource,
            onUpdatedResource: _ctx.reloadAllData
          }, null, 8, ["resource", "onUpdatedResource"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}