<template>
  <Dialog
    modal
    class="owner-dialog"
    v-model:visible="self.view_modal"
    @hide="$emit('update:view_modal', false)"
    :style="{ width: '70vw' }"
    position="top"
  >
    <template #header>
      <Button
        label="Folder Templates"
        icon="fa fa-file-alt"
        class="primary-color p-0 font-bold opacity-100"
        disabled
        text
      />
    </template>
    <div class="modal-body">
      <TableView
        :loading="self.loading"
        :items="self.folder_templates"
         permission_type="folder_template"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        disable_select
        :pagination="self.pagination"
        :expandedRows="self.expandedRows"
        @load-more-data="getFolderTemplates"
      >
        <template #expanded-col="{ slotProps }">
          <div class="the-content relative h-auto" style="top: 0">
            <Tree id="notification-setting" class="template-setting" :value="slotProps.data.content">
              <template #default="sub_slot_props">
                <div class="flex justify-content-between flex-wrap">
                  <span
                    v-html="self.properties.$getIcon(sub_slot_props.node)"
                    class="mr-2 tree-icon"
                  >
                  </span>
                  <span class="pt-1">{{ sub_slot_props.node.name }}</span>
                </div>
              </template>
            </Tree>
          </div>
        </template>
      </TableView>
    </div>
  </Dialog>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  capitalize,
} from "vue";

export default defineComponent({
  name: "DocumentAnalytics",
  props: {
    view_modal: {
      type: Boolean,
      default: false,
    },
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:view_modal", "used-template"],
  setup(props:any, {emit}) {
    const appData: any = getCurrentInstance();
    const self = reactive({
      view_modal: false,
      api_url: "folder-templates/_public",
      properties: appData.appContext.config.globalProperties,
      folder_templates: [] as any,
      loading: false,
      page: 1,
      pagination: {
        current_page: 1,
        items_per_page: 30,
        total_page: 3,
        total_records: 73,
      },
      expandedRows: [] as any,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: false,
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
        },
      ], 
      action_buttons: [
        {
          type: "Use Current Template",
          icon: "icofont icofont-folder",
          command: (val: any) => {
            useTemplate(val);
          },
          permission:'read'
        },
        {
          type: "Template Detail",
          icon: "icofont icofont-eye-alt",
          command: (val: any) => {
            expandRow(val);
          },
          permission:'read'
        },
      ] as any,
    });

    onMounted(() => {
      self.view_modal = props.view_modal;
      getFolderTemplates();
    });

    watch(
      () => props.view_modal,
      (val: any) => {
        self.view_modal = val;
      }
    );

    function expandRow(val: any) {
      if (self.expandedRows.length) {
        self.expandedRows.forEach((row: any) => {
          if (row.id == val.id) {
            return (self.expandedRows = self.expandedRows.filter((detail: any) => detail.id != val.id));
          } else {
            getRowDetail(val);
          }
        });
      } else {
        getRowDetail(val);
      }
    }

    function getRowDetail(val: any) {
      self.expandedRows = self.folder_templates.filter((detail: any) => detail.id == val.id);
    }

    function getFolderTemplates(reset = false) {
      if (reset) {
        self.page = 1;
        self.folder_templates = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        self.properties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            if (response) {
              self.pagination = response.metadata;
              const template = response.data.map((data: any) => {
                return {
                  id: data.id,
                  name: data.name,
                  description: data.description,
                  content: data.content.map((content: any, sub_index: any) => {
                    return {
                      key: sub_index,
                      name: content.name,
                      doc_type: "folder",
                      type: "folder",
                      children: 
                        getNodeChildren(
                          content.childrens ? content.childrens : [],
                          sub_index
                        ),
                    };
                  }),
                };
              });
              self.folder_templates.push(...template);
              self.page += 1;
            }
            if (error) {
              self.properties.$toastMessage(
                self.properties.$getErrorResponse(error)
              );
            }
            self.loading = false;
          },
          true
        );
      }
    }

    function getNodeChildren(data: any, node_key: any) {
      if (data.length) {
        return data.map((child: any, index: any) => {
          return {
            key: node_key ? `${index}_${node_key}` : index,
            name: child.name,
            doc_type: "folder",
            type: "folder",
            children: child.childrens
              ? getNodeChildren(
                  child.childrens ? child.childrens : [],
                  node_key ? `${index}_${node_key}` : index
                )
              : [],
          };
        });
      } else {
        return [];
      }
    }

    function useTemplate(val: any) {
      self.properties.app_service.createData(
        `folder-templates/${val.id}/media/_create`,
        {
          parent_slug: props.doc.slug,
        },
        (response: any, error: any) => {
          if (response) {
            self.properties.$toastMessage(
              response
            );
            emit('update:view_modal', false)
            emit('used-template', true)
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    return {
      capitalize,
      self,
      appData,
      expandRow,
      getFolderTemplates,
      useTemplate,
    };
  },
});
</script>