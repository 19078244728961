import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = {
  key: 0,
  class: "public-shared mb-3"
}
const _hoisted_3 = { class: "p-inputgroup flex-1" }
const _hoisted_4 = { class: "p-inputgroup-addon w-30rem" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "p-input-icon-left w-full h-full" }
const _hoisted_7 = { class: "p-inputgroup-addon w-12rem" }
const _hoisted_8 = { class: "p-inputgroup-addon w-12rem" }
const _hoisted_9 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_10 = { class: "flex align-items-center justify-content-center" }
const _hoisted_11 = { class: "p-inputgroup flex-1 my-2" }
const _hoisted_12 = { class: "flex align-content-center flex-wrap" }
const _hoisted_13 = { class: "flex align-items-center justify-content-center" }
const _hoisted_14 = { class: "flex align-items-center justify-content-center" }
const _hoisted_15 = { class: "p-inputgroup-addon w-10rem" }
const _hoisted_16 = {
  key: 1,
  class: "text-red-500 my-2"
}
const _hoisted_17 = {
  key: 2,
  class: "mt-4 mb-2"
}
const _hoisted_18 = {
  key: 0,
  class: "flex align-content-center flex-wrap"
}
const _hoisted_19 = { class: "flex align-items-center justify-content-center" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "card flex flex-wrap justify-content-start gap-3 checkbox-wrap" }
const _hoisted_23 = { class: "ml-2" }
const _hoisted_24 = { class: "flex align-items-center" }
const _hoisted_25 = { class: "action-btn" }
const _hoisted_26 = {
  key: 0,
  class: "mt-3 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: "",
    class: "share-dialog",
    visible: _ctx.self.view_share,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.view_share) = $event)),
    onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:view_modal', false))),
    style: { width: '70vw' },
    position: "top"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Share Document",
        icon: "fa fa-share-alt",
        text: "",
        class: "primary-color p-0 font-bold opacity-100",
        disabled: ""
      })
    ]),
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        (_ctx.self.share.public.url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "grid" }, [
                _createElementVNode("div", { class: "col" }, [
                  _createElementVNode("h6", { class: "primary-color font-bold" }, "Public Share")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.self.share.public.permission,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.share.public.permission) = $event)),
                    options: _ctx.self.shared_permissions,
                    optionLabel: "label",
                    optionValue: "value",
                    class: "w-full h-full border-right-none border-noround-right"
                  }, {
                    value: _withCtx(() => [
                      _createTextVNode(" Anyone with this link can " + _toDisplayString(_ctx.self.shared_permissions[_ctx.self.share.public.permission - 1]
                    .label), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("a", {
                  class: "w-50rem cursor-pointer",
                  href: _ctx.self.share.public.url,
                  target: "_blank"
                }, [
                  _createElementVNode("span", _hoisted_6, [
                    _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fas fa-share-square" }, null, -1)),
                    _createVNode(_component_InputText, {
                      ref: "publicUrl",
                      modelValue: _ctx.self.share.public.url,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.share.public.url) = $event)),
                      class: "w-full cursor-pointer border-left-none border-noround-left border-noround-right",
                      readonly: ""
                    }, null, 8, ["modelValue"])
                  ])
                ], 8, _hoisted_5),
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_Button, {
                    onClick: _ctx.copyPublicUrl,
                    icon: "far fa-copy",
                    label: "Copy",
                    class: "button-primary w-full border-noround"
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_Button, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.revokeShare(true))),
                    icon: "fas fa-trash",
                    label: "Revoke",
                    severity: "danger",
                    class: "border-noround w-full"
                  })
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, [
            _createElementVNode("h6", { class: "primary-color font-bold" }, "Private Share")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            (!_ctx.self.share.public.url)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  onClick: _ctx.getPublicUrl,
                  label: "Get Public Link",
                  icon: "fas fa-link",
                  text: "",
                  iconPos: "right",
                  class: "primary-color bg-transparent p-0 font-bold opacity-100"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_AutoComplete, {
            modelValue: _ctx.self.share.private.emails,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.share.private.emails) = $event)),
            placeholder: "Enter Name/Email Address",
            multiple: "",
            suggestions: _ctx.self.users,
            optionLabel: "email",
            onComplete: _ctx.searchUsers
          }, {
            option: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, [
                  (slotProps.option.image)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 0,
                        image: _ctx.properties.$getFullPath(slotProps.option.image),
                        shape: "circle",
                        class: "mr-1"
                      }, null, 8, ["image"]))
                    : (slotProps.option.name)
                      ? (_openBlock(), _createBlock(_component_Avatar, {
                          key: 1,
                          label: slotProps.option.name.charAt(0),
                          shape: "circle",
                          class: _normalizeClass(["mr-1 text-white", `bg-${
                    _ctx.self.bg_colors[
                      slotProps.index > 8
                        ? Math.floor(Math.random() * 8)
                        : slotProps.index
                    ]
                  }-300`])
                        }, null, 8, ["label", "class"]))
                      : (_openBlock(), _createBlock(_component_Avatar, {
                          key: 2,
                          label: typeof slotProps.option.email == 'object'?slotProps.option.email.email.charAt(0):slotProps.option.email.charAt(0),
                          shape: "circle",
                          class: _normalizeClass(["mr-1 text-white", `bg-${
                    _ctx.self.bg_colors[
                      slotProps.index > 8
                        ? Math.floor(Math.random() * 8)
                        : slotProps.index
                    ]
                  }-300`])
                        }, null, 8, ["label", "class"]))
                ]),
                _createElementVNode("span", _hoisted_14, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.email), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "suggestions", "onComplete"]),
          _createElementVNode("span", _hoisted_15, [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.self.share.private.permission,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.share.private.permission) = $event)),
              options: _ctx.self.shared_permissions,
              optionLabel: "label",
              optionValue: "value",
              class: "dropdown-option-share w-full h-full"
            }, {
              value: _withCtx(() => [
                _createTextVNode(" Can " + _toDisplayString(_ctx.self.shared_permissions[_ctx.self.share.private.permission - 1].label), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options"])
          ])
        ]),
        (!_ctx.self.is_valid_email && _ctx.self.share.private.emails.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.self.share.private.emails.length > 1 ? "One Of the" : "") + " Shared address is not a valid email. ", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          onClick: _ctx.shareDocument,
          label: "Share",
          class: "button-primary",
          disabled: !_ctx.self.is_valid_email,
          icon: "fas fa-share-square"
        }, null, 8, ["onClick", "disabled"]),
        (_ctx.self.shared_list.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "grid" }, [
                _createElementVNode("div", { class: "col" }, [
                  _createElementVNode("h6", { class: "primary-color font-bold" }, "Shared With")
                ])
              ], -1)),
              _createVNode(_component_TableView, {
                id: "shared-table",
                loading: _ctx.self.loading_shared_items,
                permission_type: _ctx.permission,
                items: _ctx.self.shared_list,
                fields: _ctx.self.share_fields,
                actions: _ctx.self.action_fields,
                disable_select: true
              }, {
                "col-data": _withCtx(({ data }) => [
                  (data.key == 'detail')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, [
                          (data.data.image)
                            ? (_openBlock(), _createBlock(_component_Avatar, {
                                key: 0,
                                image: _ctx.properties.$getFullPath(data.data.image),
                                shape: "circle",
                                class: "mr-1"
                              }, null, 8, ["image"]))
                            : (data.data.name)
                              ? (_openBlock(), _createBlock(_component_Avatar, {
                                  key: 1,
                                  label: data.data.name.charAt(0),
                                  shape: "circle",
                                  class: _normalizeClass(["mr-1 text-white", `bg-${
                    _ctx.self.bg_colors[
                      data.index > 8
                        ? Math.floor(Math.random() * 8)
                        : data.index
                    ]
                  }-300`])
                                }, null, 8, ["label", "class"]))
                              : (_openBlock(), _createBlock(_component_Avatar, {
                                  key: 2,
                                  label: data.data.email.charAt(0),
                                  shape: "circle",
                                  class: _normalizeClass(["mr-1 text-white", `bg-${
                    _ctx.self.bg_colors[
                      data.index > 8
                        ? Math.floor(Math.random() * 8)
                        : data.index
                    ]
                  }-300`])
                                }, null, 8, ["label", "class"]))
                        ]),
                        _createElementVNode("span", {
                          class: "flex align-items-center justify-content-center",
                          innerHTML: data.data.detail
                        }, null, 8, _hoisted_20)
                      ]))
                    : (data.key == 'permission')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.data.permission, (permission, key) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "flex align-items-center",
                                key: key
                              }, [
                                _createVNode(_component_Checkbox, {
                                  modelValue: data.data.permission[key],
                                  "onUpdate:modelValue": ($event: any) => ((data.data.permission[key]) = $event),
                                  name: "key",
                                  binary: "",
                                  onChange: ($event: any) => (
                      key.toString() == 'manage'
                        ? _ctx.updateAll(key, data)
                        : _ctx.updateShare(data.data)
                    )
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]),
                                _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.capitalize(key.toString())), 1)
                              ]))
                            }), 128)),
                            _createElementVNode("div", _hoisted_24, [
                              _createElementVNode("ul", _hoisted_25, [
                                _withDirectives((_openBlock(), _createElementBlock("li", null, _cache[10] || (_cache[10] = [
                                  _createElementVNode("i", { class: "fas fa-question-circle" }, null, -1)
                                ]))), [
                                  [
                                    _directive_tooltip,
                                    _ctx.self.permission_message,
                                    void 0,
                                    { bottom: true }
                                  ]
                                ])
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["loading", "permission_type", "items", "fields", "actions"])
            ]))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, !_ctx.self.fetching_shared_items]
      ]),
      (_ctx.self.fetching_shared_items)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"30px","height":"30px"},
              strokeWidth: "5",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}