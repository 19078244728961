import dateFormatter from "./dateFormatter";
import documents from "./documents";
import AppService from "@/library/system/global";
import PublicService from "@/library/system/public";
import SignedAxios from "@/library/system/httpclients/signedAxios";

function setSessionStorage(key: string, value: string) {
  return sessionStorage.setItem(key, value);
}

function removeSessionStorage(key: string) {
  return sessionStorage.removeItem(key);
}

function clearSessionStorage() {
  return sessionStorage.clear();
}

function getSessionStorage(key: string) {
  return sessionStorage.getItem(key) ? sessionStorage.getItem(key) : null;
}

function toastMessage(response: any, life = 3000) {
  document.docvault.config.globalProperties.$toast.removeGroup("toastMessage");
  const toast_summary = {
    group: "toastMessage",
    severity:
      typeof response.status == "string"
        ? response.status
        : response.status >= 200 && response.status < 300
        ? "success"
        : "error",
    detail: response.data.message
      ? response.data.message
      : response.data.data
      ? response.data.data.message
      : response.data,
    life: life,
  };
  if (typeof response.status != "string") {
    Object.assign(toast_summary, {
      summary:
        response.status >= 200 && response.status < 300 ? "Success" : "Error",
    });
  }
  document.docvault.config.globalProperties.$toast.add(toast_summary);
}

function getErrorResponse(error: any) {
  return {
    status: error.status,
    data: {
      message: error.response
        ? error.response.data
          ? error.response.data.message
            ? getStringMessage(error.response.data.message)
            : error.response.status == 403
            ? getStringMessage(error.response.data)
            : error.response.data.detail
            ? error.response.data.detail
            : error.response.data
          : error.response
        : error.detail
        ? error.detail
        : error.status,
    },
  };
}

function getStringMessage(message: any) {
  if (typeof message === "object") {
    let message_array: any = [];
    if (message.update_data) {
      message_array = message.update_data.map((key: any, index: any) => {
        return `${index + 1})  ${Object.keys(key).map((sub_key: any) => {
          return `${getName(sub_key, "_")}: ${
            typeof key[sub_key] === "string" ? key[sub_key] : key[sub_key][0]
          }`;
        })}`;
      });
    } else {
      Object.keys(message).forEach((key, index) => {
        message_array.push(
          `${index + 1}) ${getName(key, "_")}: ${
            typeof message[key] === "string" ? message[key] : message[key][0]
          }`
        );
      });
    }
    return message_array.toString();
  } else {
    return message;
  }
}

function getName(value: any, split_type = "-") {
  const str = value.toLowerCase().split(split_type);
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

function viewRequired(rules: any) {
  return rules ? rules.includes("required") : false;
}

function getPlaceHolderData(value: any, array: any) {
  return value.depends_on
    ? array[value.depends_on].value == null
      ? `Please Select ${array[value.depends_on].label} to view it's ${
          value.label
        }`
      : `Please Select ${value.label}`
    : `Please Select ${value.label}`;
}

function isDependentValueNotSelected(value: any, array: any) {
  return array[value.depends_on]
    ? array[value.depends_on].value == null
    : false;
}

function createDebounce() {
  let timeout: any = null;
  return function (fnc: any, delayMs: any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

function getImage(img_name: string) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const image = require(`@/assets/img/${img_name}`);
  return image;
}

function getKeyName(value: any, type = "-") {
  if (!value) return "";
  const str = value.toLowerCase().split(type);
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

function textTOKey(str: any) {
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "_")
    .replace(/-+/g, "_");
  return str;
}

function allowACL(array: any, index: number, key: string) {
  if (key == "manage") {
    if (array[index].permission[key]) {
      Object.keys(array[index].permission).forEach((dataKey: any) => {
        array[index].permission[dataKey] = true;
      });
    }
  }
  return array[index];
}

function checkAll(obj: any, key: string) {
  if (key == "manage") {
    if (obj[key]) {
      Object.keys(obj).forEach((dataKey: any) => {
        obj[dataKey] = true;
      });
    }
  }
  return obj;
}

function downloadItem(file_url: any, full_url = true) {
  const iframe = document.createElement("iframe");
  const src = document.createAttribute("src");
  const iClass = document.createAttribute("class");
  src.value = full_url
    ? formatUrl(`${process.env.VUE_APP_URL}/${file_url}`)
    : file_url;
  iClass.value = "downloadable-iframe";
  iframe.setAttributeNode(src);
  iframe.setAttributeNode(iClass);
  const downloadable_iframe: any = document.getElementById(
    "downloadable-iframe"
  );
  downloadable_iframe.innerHTML = "";
  downloadable_iframe.append(iframe);
}

function formatUrl(value: any) {
  const regex = /((?:.*?\/\/.*?){1}.*?)\/\//m;
  const subst = `$1/`;
  const url = value.replace(regex, subst);
  return url;
}

function getFullPath(path: any) {
  const full_url = formatUrl(`${process.env.VUE_APP_URL}${path}`);
  return full_url;
}

// str must have / at the begining and end
function utoa(str: any) {
  return window.btoa(unescape(encodeURIComponent(str))).replace(/=/g, "b");
}
// base64 encoded ascii to ucs-2 string
function atou(str: any) {
  return decodeURIComponent(escape(window.atob(str.replace(/b/g, "="))));
}

function arrayToObject(array: any) {
  return array.reduce((result: any, item: any) => {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});
}

function chunkArrayData(array_data: any, chunk_size: any) {
  const results = [] as any;
  while (array_data.length) {
    results.push(array_data.splice(0, chunk_size));
  }
  return results;
}

function objectToQueryString(obj: any, only_include = [] as any) {
  const parts = [] as any;
  for (const i in obj) {
    if (only_include.length && only_include.includes(i)) {
      //if (obj.hasOwnProperty(i)) {
      parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
      //}
    }
    if (!only_include.length) {
      // if (obj.hasOwnProperty(i)) {
      parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
      //}
    }
  }
  return parts.join("&");
}

function appPermissions(type: null) {
  return type
    ? document.docvault.config.globalProperties.$store.getters[
        "system/getNavigationsPermission"
      ][type]
    : document.docvault.config.globalProperties.$store.getters[
        "system/getNavigationsPermission"
      ];
}

function scrollToDiv(el: any, smooth = true) {
  setTimeout(() => {
    const documentElement = document.querySelector(el) || null;
    if (documentElement) {
      documentElement.scrollIntoView({
        behavior: smooth ? "smooth" : "normal",
      });
      scrollBy(0, -90);
    }
  }, 200);
}

function isValidUrl(string: any) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

function removeDuplicateFromArray(
  array: any,
  oldArray: any,
  key: string = "id"
) {
  const joined_array: any = oldArray.concat(array);
  const unique = joined_array.reduce((res: any, itm: any) => {
    // Test if the item is already in the new array
    const result = res.find(
      (item: any) => JSON.stringify(item[key]) == JSON.stringify(itm[key])
    );
    // If not lets add it
    if (!result) return res.concat(itm);
    // If it is just return what we already have
    return res;
  }, []);
  return unique;
}

function getFormattedIP(ip: any) {
  return `${ip >>> 24}.${(ip >> 16) & 255}.${(ip >> 8) & 255}.${ip & 255}`;
}

function validateEmailFromArray(array: any) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const matches = new RegExp(emailRegex);
  let is_valid = true;
  array.forEach((arr: any) => {
    if (!matches.test(arr)) {
      is_valid = false;
    }
  });
  return is_valid;
}

function removeDragSelection() {
  const drag_selector: any = document.querySelectorAll(".ds-selector-area");
  if (drag_selector.length) {
    drag_selector[0].remove();
    removeDragSelection();
  }
}

export default {
  ...dateFormatter,
  ...documents,
  app_service: new AppService(),
  signed_url: new SignedAxios(),
  public_service: new PublicService(),
  $setSessionStorage: setSessionStorage,
  $getSessionStorage: getSessionStorage,
  $removeSessionStorage: removeSessionStorage,
  $clearSessionStorage: clearSessionStorage,
  $toastMessage: toastMessage,
  $viewRequired: viewRequired,
  $getPlaceHolderData: getPlaceHolderData,
  $isDependentValueNotSelected: isDependentValueNotSelected,
  $getImage: getImage,
  $getFullPath: getFullPath,
  $getKeyName: getKeyName,
  $textTOKey: textTOKey,
  $getErrorResponse: getErrorResponse,
  $allowACL: allowACL,
  $checkAll: checkAll,
  $getName: getName,
  $downloadItem: downloadItem,
  $formatUrl: formatUrl,
  $utoa: utoa,
  $atou: atou,
  $arrayToObject: arrayToObject,
  $chunkArrayData: chunkArrayData,
  $objectToQueryString: objectToQueryString,
  $appPermissions: appPermissions,
  $debounce: createDebounce,
  $scrollToDiv: scrollToDiv,
  $isValidUrl: isValidUrl,
  $removeDuplicateFromArray: removeDuplicateFromArray,
  $getFormattedIP: getFormattedIP,
  $validateEmailFromArray: validateEmailFromArray,
  $removeDragSelection: removeDragSelection,
};