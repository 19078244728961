import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-12" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = { class: "col-12 md:col-9" }
const _hoisted_8 = { class: "flex align-items-center justify-content-end mt-2" }

import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'editTemplate',
  props: {
  template: {
    type: Object,
    default: () => {
      return {};
    },
  },
  emailTemplateTypes: {
    type: Array,
  },
  emailTags: {
    type: Object,
  },
  toolbar: {
    type: Array,
  },
},
  emits: ["updated-template"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();
const props:any = __props;
const editTemplate = ref();
const emit = __emit;
const self = reactive({
  api_url: "email-templates",
  store: useStore(),
  template: getFormDetail(),
  updating_template: false,
});
function getFormDetail() {
  return {
    title: props.template.title,
    content: props.template.content,
    type: props.template.type,
  };
}

async function updateTemplate() {
  const validate = await editTemplate.value.validate();
  if (!validate.valid) return;
  self.updating_template = true;
  await appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    self.template,
    props.template.id,
    (response: any, error: any) => {
      self.updating_template = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("updated-template", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_app_editor = _resolveComponent("app-editor")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "editTemplate",
      ref: editTemplate,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "name",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Title "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "title",
              name: "title",
              rules: "required",
              autocomplete: "off",
              modelValue: self.template.title,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.template.title) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.template.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.template.title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "title"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "content",
            class: "col-12 mb-2 md:col-12 md:mb-2"
          }, [
            _createTextVNode("Content "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "description",
              name: "content",
              rules: "required",
              autocomplete: "off",
              modelValue: self.template.content,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((self.template.content) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_editor, {
                  value: self.template.content,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((self.template.content) = $event)),
                  tags: __props.emailTags,
                  toolbar: __props.toolbar
                }, null, 8, ["value", "tags", "toolbar"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "content"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "type",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Type "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "type",
              name: "type",
              rules: "required",
              autocomplete: "off",
              modelValue: self.template.type,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((self.template.type) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: self.template.type,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((self.template.type) = $event)),
                  options: __props.emailTemplateTypes,
                  optionLabel: "option",
                  optionValue: "value",
                  placeholder: "Select a Type",
                  class: "w-full"
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "type"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Update Template",
            loading: self.updating_template,
            onClick: _withModifiers(updateTemplate, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})