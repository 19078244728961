import store from './store';
import routes from './router';
 
export default {
	name: "Dashboard Module",
	id: "dashboard-module",
    type:"dashboard",
	description: "Manages Dashboard",
	store: store,
	routes: routes
}