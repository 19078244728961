<template>
  <div id="form-content" class="page-content">
    <Form ref="editUserPassword" class="form-wrap">
      <div class="field grid">
        <label for="current-password" class="col-12 mb-2 md:col-3 md:mb-0"
          >Current Password <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="current-password"
            name="Current Password"
            rules="required"
            autocomplete="off"
            v-model="self.user.current_password"
          >
          <Password class="w-full" :feedback="false" v-model="self.user.current_password" toggleMask />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Current Password" />
        </div>
      </div>
      <div class="field grid">
        <label for="password" class="col-12 mb-2 md:col-3 md:mb-0"
          >Password <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="password"
            name="password"
            rules="required|password_requirement"
            autocomplete="off"
            v-model="self.user.new_password"
          >
            <Password class="w-full" v-model="self.user.new_password" toggleMask>
              <template #header>
                <h6>Enter Password</h6>
              </template>
              <template #footer>
                <Divider />
                <p class="mt-2">Suggestions</p>
                <ul class="pl-2 ml-2 mt-0">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>Minimum 8 characters</li>
                </ul>
              </template>
            </Password>
          </Field>
          <ErrorMessage as="small" class="p-error" name="password" />
        </div>
      </div>
      <div class="field grid">
        <label for="confirm-password" class="col-12 mb-2 md:col-3 md:mb-0"
          >Re-type Password <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="confirm-password"
            name="confirm-password"
            rules="required|confirmed:password"
            autocomplete="off"
            v-model="self.user.re_password"
          >
            <Password class="w-full" v-model="self.user.re_password" toggleMask/>
          </Field>
          <ErrorMessage as="small" class="p-error" name="confirm-password" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2"> 
        <Button
          type="submit"
          label="Update Password"
          :loading="self.updating_user"
          @click.prevent="updateUserPassword"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script lang="ts">
import { reactive, ref, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "UserPasswordForm",
  emits: ["cancel-update", "updated-profile"],
  setup(props, { emit }) {
    const appData: any = getCurrentInstance();
    const editUserPassword = ref();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
      user: getUserDetailForm(),
      updating_user: false,
    });

    function getUserDetailForm() {
      return {
        current_password: null,
        new_password: null,
        re_password: null,
      } as any;
    }

    async function updateUserPassword() {
      const validate = await editUserPassword.value.validate();
      if (!validate.valid) return;
      self.updating_user = true;
      await self.properties.app_service.updateData(
        "change-password",
        self.user,
        null,
        (response: any, error: any) => {
          self.updating_user = false;
          if (response) {
            self.properties.$toastMessage(response);
            emit("updated-profile", response.data.data);
            self.user = getUserDetailForm();
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        },null,true
      );
    }

    return {
      appData,
      self,
      editUserPassword,
      getUserDetailForm,
      updateUserPassword,
    };
  },
});
</script>