import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = { class: "json-data" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "application_activity",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      queue: {
        view: true,
        text: `Total Queue: ${_ctx.self.pagination.total_records} | Pending Queue Items: ${_ctx.self.pagination.pending_queue} | Processing Queue Items: ${_ctx.self.pagination.processing_queue}`,
      },
      onReloadData: _ctx.reloadAllData
    }, null, 8, ["breadcrumb_home", "options", "loading", "queue", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        id: "full-text-table",
        loading: _ctx.self.loading,
        items: _ctx.self.queue,
        disable_select: true,
        fields: _ctx.self.table_fields,
        pagination: _ctx.self.pagination,
        expandedRows: _ctx.self.expandedRows,
        actions: _ctx.self.action_buttons,
        onLoadMoreData: _ctx.getQueueListing
      }, {
        "expanded-col": _withCtx(({ slotProps }) => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("pre", null, _toDisplayString(_ctx.getFormattedJSON(slotProps.data.reference)), 1)
          ])
        ]),
        "action-buttons": _withCtx((slotProps) => [
          _createElementVNode("ul", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            class: "action-btn"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.action_buttons, (action) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                key: action.type,
                onClick: _withModifiers(($event: any) => (action.command(slotProps.data)), ["stop"])
              }, [
                (typeof action.icon == 'string')
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(action.icon)
                    }, null, 2))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      innerHTML: action.icon(slotProps)
                    }, null, 8, _hoisted_4))
              ], 8, _hoisted_3)), [
                [
                  _directive_tooltip,
                  
                typeof action.type == 'string'
                  ? action.type
                  : action.type(slotProps)
              ,
                  void 0,
                  { bottom: true }
                ]
              ])
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["loading", "items", "fields", "pagination", "expandedRows", "actions", "onLoadMoreData"])
    ])
  ]))
}