import appConfig from "@/configs/app";
import SystemService from "./service";
class AppService extends SystemService {
  instance: any;
  constructor() {
    super();
    this.setBaseUrl(appConfig.PublicApiBaseUrl);
    this.setHttpClient();
  } 
}
export default AppService;