<template>
  <div>
    <AppToolbar
      permission="email_template"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :filter="self.filterOptions"
      :loading="self.loading"
      :suggestions="self.suggestions"
      search_placeholder="Search Email Templates"
      @reload-data="reloadAllData"
      @create-new="self.create_new = true"
      @submit-filter-data="searchTemplates"
      @get-suggestions="getFilterSuggestions"
    />
    <div class="the-content">
      <TableView
        permission_type="email_template"
        :loading="self.loading"
        :items="self.templates"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getTemplateListing"
      />

      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_template"
        position="right"
        class="w-7"
      >
        <template #header> Edit Template</template>
        <EditTemplate
          :template="self.selected_template"
          :emailTemplateTypes="self.emailTemplateTypes"
          :emailTags="self.emailTags"
          :toolbar="self.toolbar"
          @updated-template="reloadAllData"
        />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.create_new"
        position="right"
        class="w-7"
      >
        <template #header> Add Template</template>
        <CreateTemplate
          :emailTemplateTypes="self.emailTemplateTypes"
          :emailTags="self.emailTags"
          :toolbar="self.toolbar"
          @created-template="reloadAllData"
        />
      </Sidebar>
      <Dialog v-model:visible="self.view_send" modal :style="{ width: '50vw' }">
        <template #header> 
          <Button
            label="Test Email"
            icon="icofont icofont-paper-plane"
            text
            class="primary-color p-0 font-bold opacity-100"
            disabled
          />
        </template>
        <EmailTest
          :template="self.selected_template"
          @sent-mail="self.view_send = false"
        />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
  capitalize,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";

const EditTemplate = defineAsyncComponent(
  () => import("./components/editTemplate.vue")
);
const CreateTemplate = defineAsyncComponent(
  () => import("./components/createTemplate.vue")
);
const EmailTest = defineAsyncComponent(
  () => import("./components/sendMail.vue")
);
export default defineComponent({
  name: "FolderTemplateIndex",
  components: {
    EditTemplate,
    CreateTemplate,
    EmailTest,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      api_url: "email-templates",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-envelope mr-1",
        label: "Email Templates",
        to: { name: "emailTemplates" },
      },
      store: useStore(),
      emailTemplateTypes: [
        { option: "Registration", value: "registration" },
        { option: "User Creation", value: "user_creation" },
        { option: "Forgot Password", value: "forgot_password" },
        { option: "Share", value: "share" },
        { option: "Uploaded Via Email", value: "uploaded_via_email" },
        {
          option: "Account Login Verification",
          value: "login_verification",
        },
        { option: "Test Email", value: "test_email" },
        { option: "Reports", value: "reports" },
        { option: "Upload Notification", value: "upload_notification" },
        { option: "Downloads", value: "downloads" },
      ],
      emailTags: [
        { value: "full_name", text: "Full Name" },
        { value: "activation_code", text: "Activation Code" },
        { value: "password", text: "Password" },
        { value: "password_reset_token", text: "Password Reset Token" },
        {
          value: "password_reset_code_expiry_time",
          text: "Reset Password Code Expiry Time",
        },
        { value: "twofa_code_expiry_time", text: "2FA Code Expiry Time" },
        { value: "owner_name", text: "Owner Name" },
        { value: "shared_content_name", text: "Shared Name" },
        { value: "file_name", text: "File Name" },
        { value: "file_count", text: "File Count" },
        { value: "year", text: "Year" },
        { value: "application_name", text: "Application Name" },
        { value: "web_url", text: "Web Url" },
      ],
      toolbar: [
        "bold",
        "italic",
        "underline",
        "textStyle",
        "fontColor",
        "hiliteColor",
        "font",
        "fontSize",
        "formatBlock",
        "blockquote",
        "link",
        "codeView",
      ],
      toolbarOptions: {
        filter: true,
        refresh: true,
        add: true,
      },
      filterOptions: {
        fields: true,
        status: true,
        date: true,
      },
      templates: [] as any,
      search_view: false,
      search_values: {},
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "title",
          label: "Name",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          format: (val: any) => {
            return capitalize(val);
          },
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$getName(val, "_");
          },
        },
      ],
      selected_template: {},
      create_new: false,
      edit_template: false,
      view_send: false,
      action_buttons: [
        {
          type: "Send Test",
          icon: "icofont icofont-paper-plane",
          command: (val: any) => {
            viewEmailModal(val);
          },
          permission: "read",
        },
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editTemplate(val);
          },
          permission: "edit",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteTemplate(val);
          },
          permission: "delete",
        },
      ],
      suggestions: {
        data: [] as any,
        loading: false,
        page: 1,
        pagination: {
          current_page: 1,
          items_per_page: 0,
          total_page: 0,
          total_records: 0,
        },
      },
    });

    onMounted(() => {
      getTemplateListing();
    });
    function reloadAllData(data:object = {}, created_new:boolean = false) {
      self.edit_template = false;
      self.create_new = false;
      if(created_new){
        self.templates.unshift(data)
      } else {
        getTemplateListing(true);
      }
    
    }

    function editTemplate(val: any) {
      self.selected_template = val;
      self.edit_template = true;
    }

    function viewEmailModal(val: any) {
      self.selected_template = val;
      self.view_send = true;
    }

    function sendEmailTest() {
      self.view_send = false;
    }

    function deleteTemplate(val: any) {
      self.selected_template = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to trash?",
        header: "Delete Email Template",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_template,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedTemplateListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedTemplateListing() {
      self.edit_template = false;
      getTemplateListing(true);
    }

    function getTemplateListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.templates = [];
      }
      if (self.search_view) {
        searchTemplates(self.search_values, reset);
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    async function getFilterSuggestions(query: string, reset = false) {
      if (reset) {
        self.suggestions.page = 1;
        self.suggestions.data = [];
      }
      if (
        (self.suggestions.page <= self.suggestions.pagination.total_page &&
          !self.suggestions.loading) ||
        (self.suggestions.page == 1 && !self.suggestions.loading)
      ) {
        self.suggestions.loading = true;
        await appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.suggestions.page,
          {
            query: query,
          },
          (response: any, error: any) => {
            if (response) {
              if (response.metadata) {
                self.pagination = response.metadata;
              }
              const data = response.data.map((res:any)=>{
                return {
                  name:res.title
                }
              })
              self.suggestions.data.push(...data);
              self.suggestions.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.suggestions.loading = false;
          }
        );
      }
    }

    function searchTemplates(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.templates = [];
      }
      self.search_values = filter_value;
      self.search_view = true;
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.templates.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }
    return {
      self,
      updatedTemplateListing,
      getTemplateListing,
      searchTemplates,
      viewEmailModal,
      sendEmailTest,
      reloadAllData,
      getFilterSuggestions,
    };
  },
});
</script>