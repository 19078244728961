import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_menu = _resolveComponent("sidebar-menu")!

  return (!_ctx.self.loading_items)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "sidebar",
        class: _normalizeClass({
      'menu-collapsed': _ctx.self.collapse_sidebar,
    })
      }, [
        (!_ctx.self.collapse_sidebar && _ctx.self.windowWidth > 767)
          ? (_openBlock(), _createBlock(_component_sidebar_menu, {
              key: 0,
              menu: _ctx.self.menu_items,
              theme: "white-theme",
              width: "220px",
              widthCollapsed: "65px",
              collapsed: false,
              showOneChild: true,
              "onUpdate:collapsed": _ctx.onToggleCollapse,
              onItemClick: _ctx.onItemClick
            }, {
              "toggle-icon": _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", null, [
                  _createElementVNode("i", { class: "fa-solid fa-angles-right" }),
                  _createElementVNode("span", { class: "toggle-text" }, "Collapse Sidebar")
                ], -1)
              ])),
              _: 1
            }, 8, ["menu", "onUpdate:collapsed", "onItemClick"]))
          : _createCommentVNode("", true),
        (_ctx.self.collapse_sidebar && _ctx.self.windowWidth > 767)
          ? (_openBlock(), _createBlock(_component_sidebar_menu, {
              key: 1,
              menu: _ctx.self.menu_items,
              theme: "white-theme",
              width: "220px",
              widthCollapsed: "65px",
              collapsed: true,
              showOneChild: true,
              "onUpdate:collapsed": _ctx.onToggleCollapse,
              onItemClick: _ctx.onItemClick
            }, {
              "toggle-icon": _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", null, [
                  _createElementVNode("i", { class: "fa-solid fa-angles-left" })
                ], -1)
              ])),
              _: 1
            }, 8, ["menu", "onUpdate:collapsed", "onItemClick"]))
          : _createCommentVNode("", true),
        (_ctx.self.windowWidth < 767)
          ? (_openBlock(), _createBlock(_component_sidebar_menu, {
              key: 2,
              menu: _ctx.self.menu_items,
              theme: "white-theme",
              width: "65px",
              widthCollapsed: "65px",
              collapsed: true,
              showOneChild: true,
              "onUpdate:collapsed": _ctx.onToggleCollapse,
              onItemClick: _ctx.onItemClick
            }, {
              "toggle-icon": _withCtx(() => _cache[2] || (_cache[2] = [])),
              _: 1
            }, 8, ["menu", "onUpdate:collapsed", "onItemClick"]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}