import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = {
  key: 0,
  class: "vault-docs"
}
const _hoisted_4 = {
  key: 2,
  class: "text-center my-4"
}
const _hoisted_5 = { class: "flex justify-content-center align-items-center flex-wrap text-center" }
const _hoisted_6 = { class: "dialog-image" }
const _hoisted_7 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_DocTableView = _resolveComponent("DocTableView")!
  const _component_DocGrid = _resolveComponent("DocGrid")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDFViewer = _resolveComponent("PDFViewer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      current_view: _ctx.self.current_view,
      "onUpdate:current_view": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.current_view) = $event)),
      selected_items: _ctx.self.selectedDocuments,
      "onUpdate:selected_items": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
      search_placeholder: "Search Shared",
      breadcrumb_home: _ctx.self.breadcrumbHome,
      breadcrumb_items: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      is_public: true,
      onReloadData: _ctx.reloadAllData,
      onDownloadItem: _ctx.downloadSelectedDocuments
    }, null, 8, ["current_view", "selected_items", "breadcrumb_home", "breadcrumb_items", "options", "loading", "onReloadData", "onDownloadItem"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "p-0 relative top-0 col-12 width-transaction",
          onContextmenu: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
        }, [
          (_ctx.self.documents.length && !_ctx.self.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _withDirectives(_createVNode(_component_DocTableView, {
                  ref: "docTable",
                  is_public: "",
                  selected_documents: _ctx.self.selectedDocuments,
                  "onUpdate:selected_documents": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
                  selected_document: _ctx.self.selected_document,
                  "onUpdate:selected_document": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.selected_document) = $event)),
                  context_items: _ctx.self.menu_items,
                  loading: _ctx.self.loading,
                  items: _ctx.self.documents,
                  fields: _ctx.self.table_fields,
                  pagination: _ctx.self.pagination,
                  clickable: true,
                  drag_selector: false,
                  onLoadMoreDocs: _ctx.getDocument,
                  onLoadDocDetail: _ctx.getDocumentDetail,
                  onUpdateMenu: _ctx.getMenuItems
                }, null, 8, ["selected_documents", "selected_document", "context_items", "loading", "items", "fields", "pagination", "onLoadMoreDocs", "onLoadDocDetail", "onUpdateMenu"]), [
                  [_vShow, _ctx.self.current_view == 'list' && !_ctx.self.loading_docs]
                ]),
                _withDirectives(_createVNode(_component_DocGrid, {
                  ref: "docGrid",
                  drag_selector: false,
                  selected_documents: _ctx.self.selectedDocuments,
                  "onUpdate:selected_documents": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
                  selected_document: _ctx.self.selected_document,
                  "onUpdate:selected_document": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.selected_document) = $event)),
                  create: _ctx.self.create,
                  context_items: _ctx.self.menu_items,
                  loading: _ctx.self.loading,
                  items: _ctx.self.documents,
                  onLoadMoreDocs: _ctx.getDocument,
                  onLoadDocDetail: _ctx.getDocumentDetail,
                  onUpdateMenu: _ctx.getMenuItems
                }, null, 8, ["selected_documents", "selected_document", "create", "context_items", "loading", "items", "onLoadMoreDocs", "onLoadDocDetail", "onUpdateMenu"]), [
                  [_vShow, _ctx.self.current_view == 'grid' && !_ctx.self.loading_docs]
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.self.documents.length && !_ctx.self.loading)
            ? (_openBlock(), _createBlock(_component_AppErrorMessage, {
                key: 1,
                message: _ctx.self.emptyMessage,
                page_icon: "fa fa-share",
                hide_button: "",
                hide_page_type: ""
              }, null, 8, ["message"]))
            : _createCommentVNode("", true),
          (_ctx.self.loading_docs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ProgressSpinner, {
                  style: {"width":"20px","height":"20px"},
                  strokeWidth: "5",
                  animationDuration: ".5s",
                  "aria-label": "Loading"
                })
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ]),
      _createVNode(_component_Dialog, {
        visible: _ctx.self.preview_img,
        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.self.preview_img) = $event)),
        modal: "",
        style: { width: '90em', height: '90em' },
        maximizable: ""
      }, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.self.selected_document.name), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Image, {
                src: _ctx.self.properties.$getFullPath(_ctx.self.selected_document.path),
                alt: _ctx.self.selected_document.name
              }, null, 8, ["src", "alt"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Dialog, {
        visible: _ctx.self.view_progress,
        "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.self.view_progress) = $event)),
        id: "doc-upload-progress",
        position: "bottomright",
        onHide: _cache[9] || (_cache[9] = ($event: any) => (_ctx.self.view_progress = false)),
        style: { width: '30rem' },
        closeOnEscape: false,
        draggable: false
      }, {
        header: _withCtx(() => [
          _createVNode(_component_Button, {
            label: "Progressing Download",
            icon: "fa-solid fa-spinner",
            text: "",
            class: "primary-color p-0 font-bold opacity-100",
            disabled: ""
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.self.download.message), 1)
        ]),
        _: 1
      }, 8, ["visible"]),
      (_ctx.self.preview_pdf)
        ? (_openBlock(), _createBlock(_component_PDFViewer, {
            key: 0,
            preview: _ctx.self.preview_pdf,
            pdf_page: _ctx.self.pdf_page,
            is_public: true,
            onClosePdf: _cache[10] || (_cache[10] = ($event: any) => {
          _ctx.self.preview_pdf = false;
          _ctx.self.pdf_page.view = false;
        }),
            pdf_url: 
          _ctx.self.selected_document.proxy_path
            ? _ctx.self.selected_document.proxy_path
            : _ctx.self.selected_document.path
        ,
            doc: _ctx.self.selected_document,
            user_session: _ctx.self.user_session
          }, null, 8, ["preview", "pdf_page", "pdf_url", "doc", "user_session"]))
        : _createCommentVNode("", true)
    ])
  ]))
}