import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex align-content-center justify-content-between flex-wrap" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "pt-1" }
const _hoisted_7 = { class: "flex align-content-center justify-content-between flex-wrap" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "fas fa-bell"
}
const _hoisted_10 = {
  key: 1,
  class: "fa-solid fa-bell-slash"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Tree = _resolveComponent("Tree")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_InfiniteLoader = _resolveComponent("InfiniteLoader")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: {},
      loading: _ctx.self.loading
    }, null, 8, ["breadcrumb_home", "loading"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.self.treeList.length)
          ? (_openBlock(), _createBlock(_component_Tree, {
              key: 0,
              expandedKeys: _ctx.self.expandedKeys,
              "onUpdate:expandedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.expandedKeys) = $event)),
              loading: _ctx.self.loading,
              id: "notification-setting",
              value: _ctx.self.treeList
            }, {
              default: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", {
                    class: "flex align-content-center justify-content-between flex-wrap",
                    onClick: ($event: any) => (_ctx.getNotificationDetail(slotProps))
                  }, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.appContext.config.globalProperties.$getIcon(slotProps.node),
                      class: "mr-2 tree-icon"
                    }, null, 8, _hoisted_5),
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(slotProps.node.name), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        slotProps.node.name,
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  ], 8, _hoisted_4),
                  _createElementVNode("span", _hoisted_7, [
                    _withDirectives((_openBlock(), _createBlock(_component_Button, {
                      class: "ml-1",
                      severity: `${slotProps.node.notification.is_mixed ? 'secondary' : slotProps.node.notification.enabled ? 'success' : 'danger'}`,
                      rounded: "",
                      "aria-label": `${slotProps.node.notification.enabled ? 'Enabled' : 'Disabled' } Notification Setting`,
                      text: "",
                      onClick: ($event: any) => (_ctx.updateSetting(slotProps.node))
                    }, {
                      default: _withCtx(() => [
                        (!slotProps.node.hide_icon)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                              (slotProps.node.notification.enabled || slotProps.node.notification.is_mixed)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                                : (_openBlock(), _createElementBlock("i", _hoisted_10))
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[1] || (_cache[1] = [
                              _createElementVNode("i", { class: "fas fa-bell" }, null, -1)
                            ])))
                      ]),
                      _: 2
                    }, 1032, ["severity", "aria-label", "onClick"])), [
                      [
                        _directive_tooltip,
                        `All Notification ${slotProps.node.notification.enabled ? 'Enabled' : 'Disabled'}`,
                        void 0,
                        { right: true }
                      ]
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["expandedKeys", "loading", "value"]))
          : _createCommentVNode("", true),
        (!_ctx.self.treeList.length && !_ctx.self.loading)
          ? (_openBlock(), _createBlock(_component_AppErrorMessage, {
              key: 1,
              message: "There are no Projects/Folder (Shared with or Created by) you to view it's Notification Settings",
              page_icon: "fa-solid fa-bell",
              hide_button: "",
              hide_page_type: ""
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_InfiniteLoader, { onLoadMore: _ctx.getTreeList }, null, 8, ["onLoadMore"])
      ])
    ])
  ]))
}