import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }

import { reactive, onMounted, } from "vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'viewRole',
  props: {
  role: {
    type: Object,
    default: () => {
      return {};
    },
  },
},
  setup(__props) {

const props = __props;
const self = reactive({
  store: useStore(),
  role: {} as any,
  table_fields: [
    {
      key: "name",
      label: "Resource",
      sortable: true,
      style: "width:190px",
    },
    {
      key: "read",
      label: "Read",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "write",
      label: "Write",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "edit",
      label: "Edit",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "delete",
      label: "Delete",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "manage",
      label: "Manage",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
  ],
});
onMounted(() => {
  getRoleAndResources();
});
function getRoleAndResources() {
  self.role = Object.assign({}, props.role);
  self.role.resources = props.role.resources.map((resource: any) => {
    return {
      id: resource.id,
      name: resource.name,
      slug: resource.slug,
      read: resource.permission.read == 1,
      write: resource.permission.write == 1,
      edit: resource.permission.edit == 1,
      delete: resource.permission.delete == 1,
      manage: resource.permission.manage == 1,
    };
  });
}

return (_ctx: any,_cache: any) => {
  const _component_TableView = _resolveComponent("TableView")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref: "viewRole",
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("label", {
            for: "name",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Name ", -1)),
          _createElementVNode("div", _hoisted_3, _toDisplayString(self.role.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_TableView, {
            permission_type: "roles",
            disable_select: true,
            items: self.role.resources,
            fields: self.table_fields
          }, null, 8, ["items", "fields"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})