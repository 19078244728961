<template>
  <div id="form-content" class="page-content">
    <Form ref="sendTestMail" class="form-wrap">
      <div class="field grid">
        <label for="email" class="col-12 mb-2 md:col-12 md:mb-0">
          <h4 class="primary-color font-bold"> Receiver email address </h4>
        </label>
        <div class="col-12 md:col-12">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="email"
            name="email"
            rules="required|email"
            autocomplete="off"
            v-model="self.email_address"
          >
            <InputText type="text" v-model="self.email_address" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="email" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Send"
          icon="icofont icofont-paper-plane"
          :loading="self.sending_email"
          @click.prevent="sendEmail"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, getCurrentInstance } from "vue";
const sendTestMail = ref();
const { appContext }: any = getCurrentInstance();
const emit = defineEmits(["sent-mail"]);
const props = defineProps(["template"]);
const self = reactive({
  email_address: null,
  sending_email: false,
});

async function sendEmail() {
  const validate = await sendTestMail.value.validate();
  if (!validate.valid) return;
  self.sending_email = true;
  const data = {
    email: self.email_address,
  };
  await appContext.config.globalProperties.app_service.createData(
    `email-templates/${props.template.id}/test`,
    data,
    (response: any, error: any) => {
      self.sending_email = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("sent-mail", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>