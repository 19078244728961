import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "flex align-items-center justify-content-end mt-2" }

import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'createTag',
  emits: ["created-tag"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();
const createTag = ref();
const emit = __emit;
const self = reactive({
  store: useStore(),
  tag: {
    name: null,
  },
  creating_tag: false,
});

async function createFolderTag() {
  const validate = await createTag.value.validate();
  if (!validate.valid) return;
  self.creating_tag = true;
  await appContext.config.globalProperties.app_service.createData(
    "tags",
    self.tag,
    (response: any, error: any) => {
      self.creating_tag = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-tag", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "createTag",
      ref: createTag,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            for: "type",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Type "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "type",
              name: "type",
              rules: "required",
              autocomplete: "off",
              modelValue: self.tag.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.tag.name) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.tag.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.tag.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "type"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Create Tag",
            loading: self.creating_tag,
            onClick: _withModifiers(createFolderTag, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})