import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref } from "vue"

const _hoisted_1 = { class: "widget-data" }
const _hoisted_2 = {
  key: 0,
  class: "dropzone flex align-content-center justify-content-center flex-wrap"
}
const _hoisted_3 = { class: "p-2 pt-4 flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_5 = ["innerHTML"]

import { getCurrentInstance, defineAsyncComponent, ref, reactive } from "vue";
import type { Ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'widgetDisplay',
  props: ["widgets", "summary"],
  emits: ["update:updateWidgets", "update-list"],
  setup(__props) {

const props: any = __props;

const draggable = defineAsyncComponent(() => import("vuedraggable"));
const { appContext }: any = getCurrentInstance();
const dragging: Ref<boolean> = ref(false);
const widget_data = reactive({
  folder: {
    color: "info",
    icon: "fa fa-folder-open",
  },
  document: {
    color: "warning",
    icon: "fa fa-file",
  },
  project: {
    color: process.env.VUE_APP_THEME == 'red' ? 'danger' : "primary",
    icon: "fa-solid fa-layer-group",
  },
}) as any;

function getWidgetCount(el: any) {
  return el.slug == "document"
    ? `${props.summary.file_count
    } <small>(${appContext.config.globalProperties.$getFileSize(
      props.summary.file_size
    )})</small>`
    : el.slug == "folder"
      ? props.summary.folder_count
      : el.slug == "project"
        ? props.summary.project_count
        : props.summary.file_count;
}
function getWidgetContent(el: any) {
  return `${el.slug == "document"
      ? props.summary.today.file_count
      : el.slug == "folder"
        ? props.summary.today.folder_count
        : el.slug == "project"
          ? props.summary.today.project_count
          : props.summary.today.file_count
    } ${el.slug} today ${el.slug == "document"
      ? `(${appContext.config.globalProperties.$getFileSize(
        props.summary.today.file_size
      )})`
      : ""
    }`;
} 

return (_ctx: any,_cache: any) => {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.widgets.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "text-700 text-center" }, [
            _createElementVNode("h3", { class: "mb-1" }, [
              _createElementVNode("b", null, "Drop widget here from the widget list")
            ]),
            _createElementVNode("p", { class: "text-muted m-0" }, " Click the widget button to see the available widgets ")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_unref(draggable), {
      list: __props.widgets,
      disabled: false,
      "item-key": "name",
      class: "grid-group flex flex-wrap",
      group: "widget",
      onStart: _cache[0] || (_cache[0] = ($event: any) => (dragging.value = true)),
      onEnd: _cache[1] || (_cache[1] = ($event: any) => (dragging.value = false)),
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update-list', { type: 'dragged', event: $event })))
    }, {
      item: _withCtx((data) => [
        _createVNode(_component_Card, {
          style: {"width":"32.32%","height":"190px"},
          class: _normalizeClass(["widget-box mr-2", `${data.element.slug}`])
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Tag, {
                severity: widget_data.project.color,
                value: data.element.title,
                rounded: ""
              }, null, 8, ["severity", "value"]),
              _createVNode(_component_Button, {
                onClick: ($event: any) => (_ctx.$emit('update:updateWidgets', data.element)),
                icon: "icofont icofont-close",
                severity: "secondary",
                rounded: "",
                class: "close-button",
                "aria-label": "Close"
              }, null, 8, ["onClick"])
            ])
          ]),
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", {
                innerHTML: getWidgetCount(data.element)
              }, null, 8, _hoisted_5),
              _createVNode(_component_Button, {
                icon: widget_data[data.element.slug].icon,
                severity: widget_data.project.color,
                rounded: "",
                "aria-label": "Link"
              }, null, 8, ["icon", "severity"])
            ])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "mt-2 content-button",
              icon: "fas fa-chart-line",
              text: "",
              rounded: "",
              label: getWidgetContent(data.element)
            }, null, 8, ["label"])
          ]),
          _: 2
        }, 1032, ["class"])
      ]),
      _: 1
    }, 8, ["list"])
  ]))
}
}

})