<template>
  <div id="form-content" class="page-content">
    <Form ref="viewRole" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0">Name </label>
        <div class="col-12 md:col-9">
          {{ self.role.name }}
        </div>
      </div> 
      <div class="field grid">
        <TableView
          permission_type="roles"
          :disable_select="true"
          :items="self.role.resources"
          :fields="self.table_fields"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, onMounted, } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  role: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const self = reactive({
  store: useStore(),
  role: {} as any,
  table_fields: [
    {
      key: "name",
      label: "Resource",
      sortable: true,
      style: "width:190px",
    },
    {
      key: "read",
      label: "Read",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "write",
      label: "Write",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "edit",
      label: "Edit",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "delete",
      label: "Delete",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "manage",
      label: "Manage",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
  ],
});
onMounted(() => {
  getRoleAndResources();
});
function getRoleAndResources() {
  self.role = Object.assign({}, props.role);
  self.role.resources = props.role.resources.map((resource: any) => {
    return {
      id: resource.id,
      name: resource.name,
      slug: resource.slug,
      read: resource.permission.read == 1,
      write: resource.permission.write == 1,
      edit: resource.permission.edit == 1,
      delete: resource.permission.delete == 1,
      manage: resource.permission.manage == 1,
    };
  });
}
</script>