<template>
  <div>
    <Card> 
      <template #content>
        <input
          type="file"
          ref="fileInputImg"
          @change="onImageSelected"
          style="display: none"
        />
        <div class="flex grid justify-content-between flex-wrap">
          <div class="col-10 flex justify-content-start align-items-center">
            <div class="ext-large w-12rem h-12rem">
              <Avatar
                v-if="user_detail.image"
                :image="self.properties.$getFullPath(user_detail.image)"
                shape="circle"
                class="mr-2"
                size="xlarge"
              />
              <Avatar
                v-else
                :label="user_detail.name.charAt(0)"
                shape="circle"
                class="mr-2 text-white"
                size="xlarge"
              />
            </div>
            <div class="profile-info w-9 ml-3">
              <h3 class="mb-2">
                <b>{{ user_detail.name }}</b>
              </h3>
              <div class="flex justify-content-between flex-wrap w-20rem">
                <router-link
                class="text-primary"
                  :to="{ name: 'myVault' }"
                  v-tooltip.bottom="'Projects'"
                  ><i class="fa fa-layer-group mr-2"></i
                  >{{ summary_detail.project_count }}
                </router-link>
                <router-link
                class="text-primary"
                  :to="{ name: 'myVault' }"
                  v-tooltip.bottom="'Folders'"
                  ><i class="fa fa-folder mr-2"></i>
                  {{ summary_detail.folder_count }}
                </router-link>
                <router-link
                class="text-primary"
                  :to="{ name: 'myVault' }"
                  v-tooltip.bottom="'Files'"
                  ><i class="fa fa-file mr-2"></i>
                  {{ summary_detail.file_count }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-2 flex justify-content-start align-items-center">
            <Button
              icon="icofont icofont-pencil-alt-2"
              class="button-primary"
              label="Update Image"
              @click.prevent="fileInputImg.click()"
              raised
            />
          </div>
        </div>
      </template>
    </Card> 
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, ref } from "vue";
export default defineComponent({
  name: "UserInfo",
  props: {
    user_detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    summary_detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["image-uploaded"],
  setup(props, { emit }) {
    const appData: any = getCurrentInstance();
    const fileInputImg = ref();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
    });
    function onImageSelected(e: any) {
      emit("image-uploaded", e);
    }
    return {
      appData,
      self,
      fileInputImg,
      onImageSelected,
    };
  },
});
</script>