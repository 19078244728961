import appLayout from "@/configs/app";
const routes = [
  {
    path: "/dashboard",
    name: "settingDashboard",
    component: appLayout.layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "profile",
        name: "myProfile",
        component: appLayout.setting.profile,
        meta: { title: "Profile", requiresAuth: true },
      },
      {
        path: "user",
        name: "userSettings",
        component: appLayout.setting.user,
        meta: {
          title: "Users",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "users_settings",
          },
        },
      },
      {
        path: "user/groups",
        name: "userGroups",
        component: appLayout.setting.user_group,
        meta: {
          title: "User Groups",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "group_settings",
          },
        },
      },
      {
        path: "user/roles",
        name: "roleSettings",
        component: appLayout.setting.role,
        meta: {
          title: "Roles",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "roles_settings",
          },
        },
      },
      {
        path: "user/resources",
        name: "resourceSettings",
        component: appLayout.setting.resources,
        meta: { title: "Resources", requiresAuth: true },
      },
      {
        path: "folder-template",
        name: "folderTemplate",
        component: appLayout.setting.folder_template,
        meta: {
          title: "Folder Templates",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "folder_templates_settings",
          },
        },
      },
      {
        path: "email-templates",
        name: "emailTemplates",
        component: appLayout.setting.email_template,
        meta: {
          title: "Email Templates",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "email_templates_settings",
          },
        },
      },
      {
        path: "docs/tags",
        name: "tagGroup",
        component: appLayout.setting.tag_group,
        meta: {
          title: "Tag Groups",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "tag_group_settings",
          },
        },
      },
      {
        path: "user-agreement",
        name: "userAgreements",
        component: appLayout.setting.user_agreement,
        meta: {
          title: "User Agreements",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "user_agreement_settings",
          },
        },
      },
      {
        path: "settings",
        name: "systemSettings",
        component: appLayout.setting.index,
        meta: {
          title: "System Settings",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "user_agreement_settings",
          },
        },
      },
    ],
  },
];
export default routes;