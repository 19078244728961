import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "dialog-header flex justify-content-between flex-wrap p-3" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-angle-up"
}
const _hoisted_3 = {
  key: 1,
  class: "pi pi-angle-down"
}
const _hoisted_4 = {
  key: 0,
  class: "p-3"
}
const _hoisted_5 = {
  key: 0,
  class: "folder_upload_status mb-4"
}
const _hoisted_6 = {
  key: 1,
  class: "file_upload_status mb-4"
}
const _hoisted_7 = { class: "w-11" }
const _hoisted_8 = {
  key: 0,
  class: "main-app"
}
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex-initial flex align-items-top justify-content-start min-w-full grid pl-2" }
const _hoisted_11 = { class: "block font-medium text-900 mb-2" }
const _hoisted_12 = { class: "text-red-500" }
const _hoisted_13 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.self.upload.process.view,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.upload.process.view) = $event)),
    id: "doc-upload-progress",
    position: "bottomright",
    style: { width: '30rem' },
    closeOnEscape: false,
    draggable: false
  }, {
    container: _withCtx(({ closeCallback }) => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Uploading Documents", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_Button, {
            text: "",
            onClick: _ctx.toggleUploadMinimize,
            rounded: "",
            "aria-label": "Minimize"
          }, {
            default: _withCtx(() => [
              (_ctx.self.is_minimized)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : (_openBlock(), _createElementBlock("i", _hoisted_3))
            ]),
            _: 1
          }, 8, ["onClick"]),
          (!_ctx.self.upload.progressing)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: closeCallback,
                text: "",
                rounded: "",
                "aria-label": "Close"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "pi pi-times" }, null, -1)
                ])),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_ctx.self.is_minimized)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.self.upload.process.total_folders.uploading > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createTextVNode(" Folder uploaded : " + _toDisplayString(_ctx.self.upload.process.total_folders.uploaded) + " / " + _toDisplayString(_ctx.self.upload.process.total_folders.uploading) + " ", 1),
                  _createVNode(_component_ProgressBar, {
                    value: _ctx.total_folders_uploaded,
                    style: {"height":"10px"}
                  }, null, 8, ["value"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.self.upload.process.total_files.uploading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_Accordion, {
                    activeIndex: _ctx.active,
                    "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AccordionTab, null, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createTextVNode(" File uploaded : " + _toDisplayString(_ctx.self.upload.process.total_files.uploaded) + " / " + _toDisplayString(_ctx.self.upload.process.total_files.uploading) + " ", 1),
                            _createVNode(_component_ProgressBar, {
                              value: _ctx.total_files_uploaded,
                              style: {"height":"10px"}
                            }, null, 8, ["value"])
                          ])
                        ]),
                        default: _withCtx(() => [
                          (_ctx.self.upload.activity.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_ScrollPanel, {
                                  style: _normalizeStyle(`width: 100%; height: ${
                    _ctx.self.upload.activity.length > 0
                      ? _ctx.self.upload.activity.length < 2
                        ? '150'
                        : '300'
                      : '50'
                  }px`),
                                  pt: {
                    wrapper: {
                      style: {
                        'border-right': '10px solid var(--surface-ground)',
                      },
                    },
                    bary: 'hover:bg-primary bg-primary opacity-100',
                  }
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.upload.activity, (item, index) => {
                                      return (_openBlock(), _createBlock(_component_Card, {
                                        key: index,
                                        class: "p-0 border-bottom-1 cursor-pointer border-noround"
                                      }, {
                                        content: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_9, [
                                            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-initial flex align-items-top justify-content-center w-1" }, [
                                              _createElementVNode("span", { class: "bg-primary border-circle pt-1 h-2rem w-2rem text-center" }, [
                                                _createElementVNode("i", { class: "fa fa-upload" })
                                              ])
                                            ], -1)),
                                            _createElementVNode("div", _hoisted_10, [
                                              _createElementVNode("span", {
                                                class: _normalizeClass(["col-11", [
                              item.uploadErr || !_ctx.userOnline
                                ? 'col-10'
                                : 'col-11',
                            ]])
                                              }, [
                                                _createElementVNode("span", _hoisted_11, " Uploading " + _toDisplayString(item.file_name), 1),
                                                _createVNode(_component_ProgressBar, {
                                                  value: 
                                item.progress
                                  ? Number(item.progress.toFixed(0))
                                  : item.progress
                              ,
                                                  style: {"height":"10px"}
                                                }, null, 8, ["value"]),
                                                _createElementVNode("span", _hoisted_12, _toDisplayString(item.message ? item.message : ""), 1),
                                                (!_ctx.userOnline)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, " Please check your internet connection. "))
                                                  : _createCommentVNode("", true)
                                              ], 2),
                                              (Number(item.progress.toFixed(0)) < 100)
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _createElementVNode("div", {
                                                      onClick: ($event: any) => (_ctx.activityCanceled(item)),
                                                      class: "col-1 cursor-pointer text-right flex align-items-center"
                                                    }, _cache[4] || (_cache[4] = [
                                                      _createElementVNode("i", { class: "fa fa-times" }, null, -1)
                                                    ]), 8, _hoisted_14),
                                                    (item.uploadErr || !_ctx.userOnline)
                                                      ? (_openBlock(), _createElementBlock("div", {
                                                          key: 0,
                                                          onClick: ($event: any) => {
                                item.done = true;
                                _ctx.$emit('reupload-file', item);
                              },
                                                          class: "col-1 cursor-pointer text-right flex align-items-center"
                                                        }, _cache[5] || (_cache[5] = [
                                                          _createElementVNode("i", { class: "fas fa-redo" }, null, -1)
                                                        ]), 8, _hoisted_15))
                                                      : _createCommentVNode("", true)
                                                  ], 64))
                                                : _createCommentVNode("", true)
                                            ])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["style"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["activeIndex"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}