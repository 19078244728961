<template>
  <div id="main-header">
    <Menubar>
      <template #start>
        <Image class="logo" :src="self.main_logo" /> 
      </template>

      <template #item="{ label, item, props, root, hasSubmenu }">
        <router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span
            :class="[
              hasSubmenu &&
                (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
            ]"
            v-bind="props.submenuicon"
          />
        </a>
      </template>

      <template #end>
        <div class="navbar-message text-white" v-if="self.view_error_msg">
          <p> This browser is not supported. Use the latest version of Chrome to get the best experience.  </p>
        </div>
        <span> 
        </span>
        <span>
          <Button  type="button"> 
            <Avatar 
              label="G"
              shape="circle"
              class="mr-2 text-white"
            />
             Guest 
          </Button> 
          <Button label="Login" @click="appContext.config.globalProperties.$keycloak.login()" class="button-primary" /> 
        </span>
      </template>
    </Menubar>
  </div>
</template>
<script setup lang="ts">
import { detect } from "detect-browser";
import { useStore } from "vuex";
import { getCurrentInstance, onMounted, reactive } from "vue";
const emit = defineEmits(['user-loaded']);
const { appContext }: any = getCurrentInstance();  
const self = reactive({
  main_logo: appContext.config.globalProperties.$getImage(process.env.VUE_APP_LOGO),
  view_error_msg: false, 
  browser: detect() as any,
  user: {} as any,
  store: useStore() as any,
});

onMounted(() => {
  detectBrowser(); 
  emit('user-loaded',true)
});

function detectBrowser() {
  if (self.browser) {
    if (self.browser.name == 'Chrome' || self.browser.name == 'chrome') {
      self.view_error_msg = false;
    } else {
      self.view_error_msg = true;
    }
  }
}
</script>