import appLayout from "@/configs/app";
const routes = [
  {
    path: "/",
    name: "main",
    redirect: { name: "home" },
  }, 
  {
    path: "/guest",
    name: "publicGuest",
    component: appLayout.publicLayout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "share.html",
        name: "publicShare",
        component: appLayout.system.pubic.shared.index,
        meta: {
          title: "Public Share",
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/share",
    name: "publicGuestProjectShare",
    component: appLayout.projectShareLayout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "project.html",
        name: "projectShare",
        component: appLayout.system.pubic.shared.public,
        meta: {
          title: "Project Share",
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/user-agreement/:slug",
    name: "userAgreementData",
    component: appLayout.agreemetLayout,
    meta: {
      title: "User Agreement",
      requiresAuth: false,
    },
  },
  {
    path: "/:type",
    name: "onMaintanance",
    component: appLayout.maintananceLayout,
    meta: {
      title: "Maintanance",
      requiresAuth: false,
    },
  },
  {
    path: "/health",
    name: "healthlayout",
    component: appLayout.healthLayout,
    meta: {
      title: "Health",
      requiresAuth: false,
    },
  },
];

export default routes;