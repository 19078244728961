import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative block h-full w-full" }
const _hoisted_2 = { class: "activities-tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Detail = _resolveComponent("Detail")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_Shares = _resolveComponent("Shares")!
  const _component_Activities = _resolveComponent("Activities")!
  const _component_Tags = _resolveComponent("Tags")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TabView, {
        activeIndex: _ctx.self.active,
        "onUpdate:activeIndex": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.active) = $event))
      }, {
        default: _withCtx(() => [
          (_ctx.tabs.includes('details'))
            ? (_openBlock(), _createBlock(_component_TabPanel, {
                key: 0,
                header: "Details"
              }, {
                default: _withCtx(() => [
                  (_ctx.self.active == 0)
                    ? (_openBlock(), _createBlock(_component_Detail, {
                        key: 0,
                        doc: _ctx.doc,
                        view_parent: _ctx.view_parent
                      }, null, 8, ["doc", "view_parent"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.tabs.includes('share'))
            ? (_openBlock(), _createBlock(_component_TabPanel, {
                key: 1,
                header: "Share"
              }, {
                default: _withCtx(() => [
                  (_ctx.self.active == 1)
                    ? (_openBlock(), _createBlock(_component_Shares, {
                        key: 0,
                        doc: _ctx.doc,
                        onViewShareModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open-share-modal')))
                      }, null, 8, ["doc"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.tabs.includes('activities'))
            ? (_openBlock(), _createBlock(_component_TabPanel, {
                key: 2,
                header: "Activities"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Activities, {
                    doc: _ctx.doc,
                    activities: _ctx.activities,
                    pending_activities: _ctx.pending_activities,
                    loading: _ctx.loading,
                    onCancelUpload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel-doc-upload', $event))),
                    onRemoveCanceledUpload: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('remove-canceled-upload', $event))),
                    onLoadMoreData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('load-more-activities'))),
                    onViewActivitiesDetail: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('view-activity-detail', $event)))
                  }, null, 8, ["doc", "activities", "pending_activities", "loading"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.tabs.includes('tags'))
            ? (_openBlock(), _createBlock(_component_TabPanel, {
                key: 3,
                header: "Tags"
              }, {
                default: _withCtx(() => [
                  (_ctx.self.active == 3)
                    ? (_openBlock(), _createBlock(_component_Tags, {
                        key: 0,
                        doc: _ctx.doc
                      }, null, 8, ["doc"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["activeIndex"])
    ])
  ]))
}