import axios from "axios";
class SignedUrl {
  private axios: any;
  constructor() {
    setTimeout(() => {
      const token = document.docvault.config.globalProperties.$store.getters["setting/getUserToken"];
      const headers = {"Content-Type": "application/x-www-form-urlencoded"};
      if (token) {
        Object.assign(headers, { Authorization: token });
      }
      this.axios = axios.create({ headers: headers });
      this.axios.CancelToken = axios.CancelToken;
      this.axios.isCancel = axios.isCancel;
    }, 200);
  }
}

export default SignedUrl;
