import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!

  return (_ctx.message.view)
    ? (_openBlock(), _createBlock(_component_Message, {
        key: 0,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        severity: _ctx.message.type,
        closable: _ctx.closeable
      }, {
        default: _withCtx(() => [
          (typeof _ctx.message.text === 'string')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.message.text), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.text, (msg, key) => {
                  return (_openBlock(), _createElementBlock("div", { key: key }, _toDisplayString(_ctx.capitalize( key.toString() )) + " : " + _toDisplayString(typeof _ctx.message.text[key] == 'object' ? _ctx.message.text[key][0] : _ctx.message.text[key]), 1))
                }), 128))
              ]))
        ]),
        _: 1
      }, 8, ["severity", "closable"]))
    : _createCommentVNode("", true)
}