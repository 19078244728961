import Service from "@/library/system/service";

export default class SidebarService extends Service {
  constructor() {
    super();
  }

  loadMenu(cb: any) {
    const menu_item = [
      {
        key: "0",
        title: "Dashboard",
        icon: "fas fa-home",
        to: { name: "dashboard" },
        value: "dashboard",
      }, 
      {
        key: "1",
        title: "Projects",
        icon: "fas fa-project-diagram",
        value: "project",
        to: { name: "projectIndex" },
      },
      {
        key: "2",
        title: "My Vault",
        icon: "fas fa-folder-open",
        value: "media",
        to: { name: "myVault" },
        child: [
          {
            key: "2_1",
            title: "Trash",
            icon: "fa fa-trash",
            value: "trash",
            to: { name: "trashDocs" },
          },
          {
            key: "2_2",
            title: "Starred",
            icon: "fas fa-star",
            value: "media",
            to:{ name:"starredDocs" }
          },
          {
            key: "2_3",
            title: "Notification Setting",
            icon: "fa fa-bell",
            value: "media",
            to: { name: "notificationSettings" },
          },
        ],
      },
      {
        key: "3",
        title: "Shared Vault",
        icon: "fa fa-share-square",
        value: "share",
        child: [
          {
            key: "3_1",
            title: "Shared With Me",
            icon: "fa fa-share",
            value: "share",
            to:{ name:'sharedWithMe' }
          },
          {
            key: "3_2",
            title: "Shared By Me",
            icon: "fas fa-share-alt",
            value: "share",
            to:{ name:'sharedByMe' }
          },
        ],
      },
      {
        key: "4",
        title: "Settings",
        icon: "fas fa-cog",
        value: "settings",
        child: [
          {
            key: "4_1",
            title: "Users",
            icon: "fas fa-users",
            value: "user",
            to: { name: "userSettings" },
          },
          {
            key: "4_2",
            title: "User Groups",
            icon: "fas fa-users-cog",
            value: "user_groups",
            to: { name: "userGroups" },
          },
          {
            key: "4_3",
            title: "Roles",
            icon: "fas fa-user-tag",
            value: "roles",
            to: { name: "roleSettings" },
          },
          {
            key: "4_4",
            title: "Resources",
            icon: "fas fa-user-cog",
            value: "resources",
            to: { name: "resourceSettings" },
          },
          {
            key: "4_5",
            title: "Folder Templates",
            icon: "fas fa-folder",
            value: "folder_template",
            to: { name: "folderTemplate" },
          },
          {
            key: "4_6",
            title: "Email Templates",
            icon: "fas fa-envelope",
            value: "email_template",
            to: { name: "emailTemplates" },
          },
          {
            key: "4_7",
            title: "Tag Groups",
            icon: "fas fa-tags",
            value: "tags",
            to: { name: "tagGroup" },
          },
          {
            key: "4_8",
            title: "User Agreements",
            icon: "fas fa-cogs",
            value: "user_agreement",
            to: { name: "userAgreements" },
          },
          {
            key: "4_9",
            title: "System Settings",
            icon: "fas fa-wrench",
            value: "application_settings",
            to: { name: "systemSettings" },
          },
        ],
      },
      {
        key: "5",
        title: "Reports",
        icon: "fas fa-file-alt",
        value: "reports",
        to: { name: "reportsIndex" },
      },
      {
        key: "6",
        title: "Queue",
        icon: "fa-solid fa-book",
        to: { name: "queueMonitior" },
        value: "application_activity",
      },
      {
        key: "7",
        title: "Active User",
        icon: "fa-solid fa-person-circle-check",
        to: { name: "activeUsers" },
        value: "application_activity",
      },
    ]; 
    cb(menu_item);
  }
}