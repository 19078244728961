<template>
  <Dialog
    modal
    class="share-dialog"
    v-model:visible="self.view_share"
    @hide="$emit('update:view_modal', false)"
    :style="{ width: '70vw' }"
    position="top"
  >
    <template #header>
      <Button
        label="Share Document"
        icon="fa fa-share-alt"
        text
        class="primary-color p-0 font-bold opacity-100"
        disabled
      />
    </template>
    <div v-show="!self.fetching_shared_items" class="modal-body">
      <div class="public-shared mb-3" v-if="self.share.public.url">
        <div class="grid">
          <div class="col">
            <h6 class="primary-color font-bold">Public Share</h6>
          </div>
        </div>
        <div class="p-inputgroup flex-1">
          <span class="p-inputgroup-addon w-30rem">
            <Dropdown
              v-model="self.share.public.permission"
              :options="self.shared_permissions"
              optionLabel="label"
              optionValue="value"
              class="w-full h-full border-right-none border-noround-right"
            >
              <template #value>
                Anyone with this link can
                {{
                  self.shared_permissions[self.share.public.permission - 1]
                    .label
                }}
              </template>
            </Dropdown>
          </span>
          <a
            class="w-50rem cursor-pointer"
            :href="self.share.public.url"
            target="_blank"
          >
            <span class="p-input-icon-left w-full h-full">
              <i class="fas fa-share-square" />
              <InputText
                ref="publicUrl"
                v-model="self.share.public.url"
                class="w-full cursor-pointer border-left-none border-noround-left border-noround-right"
                readonly
              />
            </span>
          </a>
          <span class="p-inputgroup-addon w-12rem">
            <Button
              @click="copyPublicUrl"
              icon="far fa-copy"
              label="Copy"
              class="button-primary w-full border-noround"
            />
          </span>
          <span class="p-inputgroup-addon w-12rem">
            <Button
              @click="revokeShare(true)"
              icon="fas fa-trash"
              label="Revoke"
              severity="danger"
              class="border-noround w-full"
          /></span>
        </div>
      </div>
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center justify-content-center">
          <h6 class="primary-color font-bold">Private Share</h6>
        </div>
        <div class="flex align-items-center justify-content-center">
          <Button
            v-if="!self.share.public.url"
            @click="getPublicUrl"
            label="Get Public Link"
            icon="fas fa-link"
            text
            iconPos="right"
            class="primary-color bg-transparent p-0 font-bold opacity-100"
          />
        </div>
      </div>
      <div class="p-inputgroup flex-1 my-2">
        <AutoComplete
          v-model="self.share.private.emails"
          placeholder="Enter Name/Email Address"
          multiple
          :suggestions="self.users"
          optionLabel="email"
          @complete="searchUsers"
        >
          <template #option="slotProps">
            <div class="flex align-content-center flex-wrap">
              <span class="flex align-items-center justify-content-center">
                <Avatar
                  v-if="slotProps.option.image"
                  :image="properties.$getFullPath(slotProps.option.image)"
                  shape="circle"
                  class="mr-1"
                />
                <Avatar
                  v-else-if="slotProps.option.name"
                  :label="slotProps.option.name.charAt(0)"
                  shape="circle"
                  class="mr-1 text-white"
                  :class="`bg-${
                    self.bg_colors[
                      slotProps.index > 8
                        ? Math.floor(Math.random() * 8)
                        : slotProps.index
                    ]
                  }-300`"
                /> 
                <Avatar
                  v-else
                  :label="typeof slotProps.option.email == 'object'?slotProps.option.email.email.charAt(0):slotProps.option.email.charAt(0)"
                  shape="circle"
                  class="mr-1 text-white"
                  :class="`bg-${
                    self.bg_colors[
                      slotProps.index > 8
                        ? Math.floor(Math.random() * 8)
                        : slotProps.index
                    ]
                  }-300`"
                />
              </span>
              <span class="flex align-items-center justify-content-center">
                {{ slotProps.option.name }} {{ slotProps.option.email }}
              </span>
            </div>
          </template>
        </AutoComplete>
        <span class="p-inputgroup-addon w-10rem">
          <Dropdown
            v-model="self.share.private.permission"
            :options="self.shared_permissions"
            optionLabel="label"
            optionValue="value"
            class="dropdown-option-share w-full h-full"
          >
            <template #value>
              Can
              {{
                self.shared_permissions[self.share.private.permission - 1].label
              }}
            </template>
          </Dropdown>
        </span>
      </div>
      <div
        v-if="!self.is_valid_email && self.share.private.emails.length"
        class="text-red-500 my-2"
      >
        {{ self.share.private.emails.length > 1 ? "One Of the" : "" }} Shared
        address is not a valid email.
      </div>
      <Button
        @click="shareDocument"
        label="Share"
        class="button-primary"
        :disabled="!self.is_valid_email"
        icon="fas fa-share-square"
      />
      <div class="mt-4 mb-2" v-if="self.shared_list.length">
        <div class="grid">
          <div class="col">
            <h6 class="primary-color font-bold">Shared With</h6>
          </div>
        </div>
        <TableView
          id="shared-table"
          :loading="self.loading_shared_items"
          :permission_type="permission"
          :items="self.shared_list"
          :fields="self.share_fields"
          :actions="self.action_fields"
          :disable_select="true"
        >
          <template #col-data="{ data }">
            <div
              class="flex align-content-center flex-wrap"
              v-if="data.key == 'detail'"
            >
              <span class="flex align-items-center justify-content-center">
                <Avatar
                  v-if="data.data.image"
                  :image="properties.$getFullPath(data.data.image)"
                  shape="circle"
                  class="mr-1"
                />
                <Avatar
                  v-else-if="data.data.name"
                  :label="data.data.name.charAt(0)"
                  shape="circle"
                  class="mr-1 text-white"
                  :class="`bg-${
                    self.bg_colors[
                      data.index > 8
                        ? Math.floor(Math.random() * 8)
                        : data.index
                    ]
                  }-300`"
                />
                <Avatar
                  v-else
                  :label="data.data.email.charAt(0)"
                  shape="circle"
                  class="mr-1 text-white"
                  :class="`bg-${
                    self.bg_colors[
                      data.index > 8
                        ? Math.floor(Math.random() * 8)
                        : data.index
                    ]
                  }-300`"
                />
              </span>
              <span
                class="flex align-items-center justify-content-center"
                v-html="data.data.detail"
              ></span>
            </div>
            <div v-else-if="data.key == 'permission'">
              <div
                class="card flex flex-wrap justify-content-start gap-3 checkbox-wrap"
              >
                <div
                  v-for="(permission, key) in data.data.permission"
                  class="flex align-items-center"
                  :key="key"
                >
                  <Checkbox
                    v-model="data.data.permission[key]"
                    name="key"
                    binary
                    @change="
                      key.toString() == 'manage'
                        ? updateAll(key, data)
                        : updateShare(data.data)
                    "
                  />
                  <label class="ml-2"> {{ capitalize(key.toString()) }} </label>
                </div>
                <div class="flex align-items-center">
                  <ul class="action-btn">
                    <li v-tooltip.bottom="self.permission_message">
                      <i class="fas fa-question-circle"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </TableView>
      </div>
    </div>
    <div v-if="self.fetching_shared_items" class="mt-3 text-center">
      <ProgressSpinner
        style="width: 30px; height: 30px"
        strokeWidth="5"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
  </Dialog>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  capitalize,
  ref,
} from "vue";

export default defineComponent({
  name: "ShareModal",
  props: {
    view_modal: {
      type: Boolean,
      default: false,
    },
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permission: {
      type: String,
    },
  },
  emits: ["update:view_modal", "shared-document", "share-revoked"],
  setup(props: any, { emit }) {
    const publicUrl = ref();
    const self = reactive({
      view_share: false,
      api_url: "share",
      public_api_url: "public-share",
      instance: getCurrentInstance() as any,
      shared_list: [] as any,
      loading_shared_items: true,
      users: [] as any,
      bg_colors: [
        "blue",
        "green",
        "yellow",
        "cyan",
        "indigo",
        "teal",
        "orange",
        "red",
        "primary",
      ],
      permission_message:
        "This permission allows user to re-share, revoke share, update permission of other user in this document.",
      share_fields: [
        {
          key: "detail",
          label: "Email",
          sortable: false,
          style: "width:30%",
        },
        {
          key: "permission",
          label: "Permission",
          sortable: false,
        },
      ],
      action_fields: [
        {
          type: "Revoke",
          icon: "fas fa-trash",
          command: (val: any) => {
            revokeShare(false, val);
          },
        },
      ],
      share: {
        public: {
          url: null,
          permission: 1,
        } as any,
        private: {
          emails: [] as any,
          permission: 2,
        } as any,
      } as any,
      emptyFilterMessage: "Enter a text to view Users",
      shared_permissions: [
        { label: "View Only", value: 1 },
        { label: "Add, Edit", value: 2 },
      ],
      fetching_shared_items: true,
      is_valid_email: true,
      guest_share_url: `${process.env.VUE_APP_URL}/guest/share.html?token=`,
    });
    const properties = self.instance.appContext.config.globalProperties;
    const debounce = properties.$debounce();

    onMounted(() => {
      self.view_share = props.view_modal;
      getSharedItems();
    });

    watch(
      () => props.view_modal,
      (val: any) => {
        self.view_share = val;
      }
    );

    watch(
      () => props.doc,
      () => {
        getSharedItems();
      }
    );

    watch(
      () => self.share.private.emails,
      (val: any) => {
        if (val.length) {
          const values = val.map((v: any) => {
            return v.email;
          });
          self.is_valid_email = properties.$validateEmailFromArray(values);
        }
      }
    );

    function searchUsers(e: any) {
      debounce(() => {
        // const selected_users = self.share.private.emails
        //   ? self.share.private.emails.map((email: any) => {
        //       return {
        //         email: email,
        //       };
        //     })
        //   : [];
        if (!e.query) {
          return (self.emptyFilterMessage = "Enter a text to view Users");
        }
        properties.app_service.searchData(
          "users",
          null,
          {
            query: e.query,
            exclude_me: true,
          },
          (response: any, error: any) => {
            if (response) {
              self.users = response.data;
              // if (!self.users.length) {
              //   self.users = [
              //     {
              //       email: e.query ? e.query.trim().toLowerCase() : e.query,
              //     },
              //   ];
              // }
              // updateUserListing(selected_users);
            }
            if (error) {
              properties.$toastMessage(properties.$getErrorResponse(error));
            }
          }
        );
      });
    }

    // function updateUserListing(old_data: any) {
    //   self.users = properties.$removeDuplicateFromArray(
    //     self.users,
    //     old_data,
    //     "email"
    //   );
    // }

    function getSharedItems(is_share_revoked: boolean = false) {
      self.fetching_shared_items = true;
      properties.app_service.getData(
        `${props.doc.type == "project" ? "projects" : "media"}/${
          props.doc.id
        }/${self.api_url}`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.shared_list = response.data.map((res: any) => {
              return {
                id: res.id,
                email: res.email,
                name: res.name,
                detail: res.name
                  ? `${res.name} <br/> (${res.email})`
                  : `(${res.email})`,
                image: res.image,
                permission: res.permission
                  ? {
                      read: res.permission.read == 1,
                      write: res.permission.write == 1,
                      edit: res.permission.edit == 1,
                      delete: res.permission.delete == 1,
                      manage: res.permission.manage == 1,
                    }
                  : {},
              };
            });
            self.loading_shared_items = false;
            getPublicSharedToken();
            if (is_share_revoked) {
              emit("share-revoked", self.shared_list.length == 0);
            }
          }
          if (error) {
            properties.$toastMessage(properties.$getErrorResponse(error));
          }
        },
        false
      );
    }

    function getPublicSharedToken() {
      properties.app_service.getData(
        `${props.doc.type == "project" ? "projects" : "media"}/${
          props.doc.id
        }/${self.public_api_url}`,
        null,
        (response: any, error: any) => {
          if (response) {
            if (response.data) {
              self.share.public.url = response.data.token
                ? `${self.guest_share_url}${response.data.token}`
                : null;
            }
          }
          if (error) {
            properties.$toastMessage(properties.$getErrorResponse(error));
          }
          self.fetching_shared_items = false;
        },
        false
      );
    }

    function getPublicUrl() {
      properties.app_service.createData(
        `${props.doc.type == "project" ? "projects" : "media"}/${
          props.doc.id
        }/${self.public_api_url}`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.share.public.url = `${self.guest_share_url}${response.data.data.token}`;
          }
          if (error) {
            properties.$toastMessage(properties.$getErrorResponse(error));
          }
        }
      );
    }

    function shareDocument() {
      if (!self.share.private.emails.length) {
        return properties.$toastMessage({
          status: 503,
          data: "At least one user's email address is required to share the document",
        });
      }
      const data = {
        email: self.share.private.emails.map((email: any) => {
          return email.email;
        }),
      };

      Object.assign(data, {
        permission: {
          read: 1,
          write: self.share.private.permission == 2 ? 1 : 0,
          edit: self.share.private.permission == 2 ? 1 : 0,
          delete: 0,
          manage: 0,
        },
      });
      properties.app_service.createData(
        `${props.doc.type == "project" ? "projects" : "media"}/${
          props.doc.id
        }/${self.api_url}`,
        data,
        (response: any, error: any) => {
          if (response) {
            properties.$toastMessage(response);
            emit("shared-document", true);
          }
          if (error) {
            properties.$toastMessage(properties.$getErrorResponse(error));
          }
        }
      );
    }

    function copyPublicUrl() {
      publicUrl.value.$el.select();
      publicUrl.value.$el.setSelectionRange(0, 99999);
      document.execCommand("copy");
      properties.$toastMessage({
        status: 200,
        data: "Link Copied to clipboard",
      });
    }

    function revokeShare(is_public = false, val = {} as any) {
      properties.app_service.deleteData(
        `${props.doc.type == "project" ? "projects" : "media"}/${
          props.doc.id
        }/${is_public ? self.public_api_url : self.api_url}${
          !is_public && Object.keys(val).length ? "/" + val.email : ""
        }`,
        null,
        (response: any, error: any) => {
          if (response) {
            properties.$toastMessage(response);
            if (is_public) {
              self.share.public.url = null;
            }
            getSharedItems(true);
          }
          if (error) {
            properties.$toastMessage(properties.$getErrorResponse(error));
          }
        }
      );
    }

    function updateAll(key: any, data: any) {
      properties.$allowACL(self.shared_list, data.index - 1, key);
      updateShare(data.data);
    }

    function updateShare(val: any) {
      debounce(() => {
        const data = {
          email: val.email ? val.email : null,
        };
        Object.assign(data, {
          permission: {
            read: val.permission.read ? 1 : 0,
            write: val.permission.write ? 1 : 0,
            edit: val.permission.edit ? 1 : 0,
            delete: val.permission.delete ? 1 : 0,
            manage: val.permission.manage ? 1 : 0,
          },
        });
        properties.app_service.updateData(
          `${props.doc.type == "project" ? "projects" : "media"}/${
            props.doc.id
          }/${self.api_url}`,
          data,
          null,
          (response: any, error: any) => {
            if (response) {
              properties.$toastMessage(response);
            }
            if (error) {
              properties.$toastMessage(properties.$getErrorResponse(error));
            }
          },
          null,
          true
        );
      }, 1000);
    }

    return {
      publicUrl,
      self,
      properties,
      debounce,
      capitalize,
      searchUsers,
      getSharedItems,
      getPublicSharedToken,
      getPublicUrl,
      shareDocument,
      copyPublicUrl,
      updateAll,
      updateShare,
      revokeShare,
    };
  },
});
</script>
