import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "col" }

import { reactive, onMounted, getCurrentInstance } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'detailIndex',
  props: {
  detail: {
    type: Object,
    default: () => {
      return {};
    },
  },
  exclude_keys: {
    type: Array,
    default: () => [""],
  },
},
  setup(__props) {

const props = __props;
props;
const self = getCurrentInstance() as any;
const exclude_items: any = reactive(["id", "created_at", "status"]);
const filteredDetail: any = reactive([]);
onMounted(() => {
  fetchData();
});

function fetchData() {
  exclude_items.push(...props.exclude_keys);
  for (const key in props.detail) {
    const description =
      typeof props.detail[key] === "object" && props.detail[key] != null
        ? props.detail[key].name
        : props.detail[key]
        ? props.detail[key]
        : "";
    if (!exclude_items.includes(key) && description) {
      filteredDetail.push({
        name: self.appContext.config.globalProperties.$getName(key, "_"),
        description:
          description == true
            ? "Yes"
            : description == false
            ? "No"
            : description,
      });
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredDetail, (detail, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "field grid",
        key: index
      }, [
        _createElementVNode("label", {
          for: detail.name,
          class: "col-fixed"
        }, _toDisplayString(detail.name), 9, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "description", { data: detail }, () => [
            _createTextVNode(_toDisplayString(detail.description), 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})