import store from './store';
import routes from './router';
 
export default {
	name: "System Module",
	id: "system-module",
    type:"system",
	description: "Manages Individual System",
	store: store,
	routes: routes,
}