<template>
  <div>
    <TableView
      permission_type="user"
      v-model:selected_items="self.selected_projects"
      v-model:selected_item="self.selected_project"
      disable_select
      :loading="self.loading"
      :items="self.projects"
      :fields="self.table_fields"
      :pagination="self.pagination"
      :actions="self.action_buttons"
      :expandedRows="self.expandedRows"
      @load-more-data="getUserProjects"
    >
      <template #expanded-col>
        <div class="pl-4">
          <TableView
            :loading="self.shared_detail.loading"
            :items="self.shared_detail.data"
            permission_type="user"
            :fields="self.shared_detail.table_fields"
            disable_select
            :pagination="self.shared_detail.pagination"
            expander
            expanded_info
          >
            <template #footer>
              <Paginator
                v-if="
                  self.shared_detail.pagination.total_page > 1 &&
                  !self.shared_detail.loading
                "
                :rows="self.shared_detail.pagination.items_per_page"
                :totalRecords="self.shared_detail.pagination.total_records"
                @page="changeSharedDataPage($event)"
              />
            </template>
          </TableView>
        </div>
      </template>
    </TableView>
  </div>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, reactive, onMounted } from "vue";
export default defineComponent({
  name: "UserProjects",
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props: any) {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_url: `users/${props.user.id}/projects`,
      selected_projects: [] as any,
      selected_project: [] as any,
      loading: false,
      projects: [] as any,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:40%",
        },
        {
          key: "created_at",
          label: "Created At",
          format: (val: any) => {
            return appContext.config.globalProperties.$dayMonthDateYear(val);
          },
          sortable: true,
        },
      ],
      action_buttons: [
        {
          type: "View Project",
          icon: "icofont icofont-eye-alt",
          permission: "read",
          command: function (val: { id: string }) {
            appContext.config.globalProperties.$router.push({
              name: "myVaultDetail",
              params: {
                doc_type: "project",
                doc_id: val.id,
              },
            });
          },
        },
        {
          type: (row: any) => {
            const type: any = self.expandedRows.length
              ? self.expandedRows[0].id == row.data.id
                ? "Hide"
                : "View"
              : "View";
            return `${type} Shared Detail`;
          },
          icon: (row: any) => {
            const icon: any = self.expandedRows.length
              ? self.expandedRows[0].id == row.data.id
                ? '<i class="fa-solid fa-ban"></i>'
                : '<i class="fa fa-share-square"></i>'
              : '<i class="fa fa-share-square"></i>';
            return icon;
          },
          permission: "read",
          command: function (val: object) {
            viewProjectSharedDetail(val);
          },
          view_on: "shared",
        },
      ],
      page: 1,
      pagination: {
        items_per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      expandedRows: [] as any,
      shared_detail: {
        selected_project: {} as any,
        data: [],
        page: 1,
        pagination: {
          items_per_page: 15,
          total_records: 30,
          current_page: 1,
          total_page: 1,
        },
        loading: false,
        table_fields: [
          {
            key: "name",
            label: "User Name",
            sortable: true,
            style: "width:20%",
          },
          {
            key: "email",
            label: "Email",
            sortable: true,
            style: "width:20%",
          },
          {
            key: "permission",
            label: "Read",
            sortable: false,
            style: "width:90px",
            format: (val: any) => {
              return val.read
                ? "<i class='fas fa-check success'></i>"
                : "<i class='fas fa-times danger'></i>";
            },
          },
          {
            key: "permission",
            label: "Write",
            sortable: false,
            style: "width:90px",
            format: (val: any) => {
              return val.write
                ? "<i class='fas fa-check success'></i>"
                : "<i class='fas fa-times danger'></i>";
            },
          },
          {
            key: "permission",
            label: "Edit",
            sortable: false,
            style: "width:90px",
            format: (val: any) => {
              return val.edit
                ? "<i class='fas fa-check success'></i>"
                : "<i class='fas fa-times danger'></i>";
            },
          },
          {
            key: "permission",
            label: "Delete",
            sortable: false,
            style: "width:90px",
            format: (val: any) => {
              return val.delete
                ? "<i class='fas fa-check success'></i>"
                : "<i class='fas fa-times danger'></i>";
            },
          },
          {
            key: "permission",
            label: "Manage",
            sortable: false,
            style: "width:90px",
            format: (val: any) => {
              return val.manage
                ? "<i class='fas fa-check success'></i>"
                : "<i class='fas fa-times danger'></i>";
            },
          },
        ],
      },
    });
    onMounted(() => {
      getUserProjects();
    });
    function getUserProjects() {
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        self.properties.app_service.getData(
          self.api_url,
          self.page,
          (res: any, err: any) => {
            if (res) {
              if (res.metadata) {
                self.pagination = res.metadata;
              }
              self.projects.push(...res.data);
              self.page += 1;
            }
            if (err) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(err)
              );
            }
            self.loading = false;
          }
        );
      }
    }
    function viewProjectSharedDetail(project: any) {
      if (self.expandedRows.length) {
        if (project.id == self.expandedRows[0].id) {
          self.expandedRows = [];
          return;
        }
      }
      self.expandedRows = self.projects.filter((detail: any) => detail.id == project.id);
      self.shared_detail.selected_project = project;
      self.shared_detail.page = 1;
      getProjectSharedList(project);
    }

    function changeSharedDataPage(e: any) {
      self.shared_detail.page = e.page + 1;
      getProjectSharedList(self.shared_detail.selected_project);
    }

    function getProjectSharedList(project: any) {
      self.shared_detail.loading = true;
      self.properties.app_service.getData(
        `projects/${project.id}/share`,
        self.shared_detail.page,
        (res: any, err: any) => {
          if (res) {
            if (res.metadata) {
              self.shared_detail.pagination = res.metadata;
            }
            self.shared_detail.data = res.data;
          }
          if (err) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(err)
            );
          }
          self.shared_detail.loading = false;
        }
      );
    }
    return {
      appContext,
      self,
      getUserProjects,
      viewProjectSharedDetail,
      changeSharedDataPage,
    };
  },
});
</script>