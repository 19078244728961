<template>
  <div>
    <AppToolbar
      permission="folder_template"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      @reload-data="reloadAllData"
      @create-new="self.create_new = true"
    />
    <div class="the-content">
      <TableView
        permission_type="folder_template"
        :loading="self.loading"
        :items="self.templates"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getTemplateListing"
      />

      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_template"
        position="right"
      >
        <template #header> Edit Template</template>
        <EditTemplate
          :template="self.selected_template"
          @updated-template="reloadAllData"
        />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.create_new"
        position="right"
      >
        <template #header> Add Template</template>
        <CreateTemplate @created-template="reloadAllData" />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";

const EditTemplate = defineAsyncComponent(
  () => import("./components/editTemplate.vue")
);
const CreateTemplate = defineAsyncComponent(
  () => import("./components/createTemplate.vue")
);
export default defineComponent({
  name: "FolderTemplateIndex",
  components: {
    EditTemplate,
    CreateTemplate,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      api_url: "folder-templates",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-folder mr-1",
        label: "Folder Templates",
        to: { name: "folderTemplate" },
      },
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        refresh: true,
        add: true,
      },
      templates: [] as any,
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: false,
          format: (val: any) => {
            return val ? val.name : val;
          },
        },
      ],
      selected_template: {},
      create_new: false,
      edit_template: false,
      action_buttons: [
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editTemplate(val);
          },
          permission: "edit",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteTemplate(val);
          },
          permission: "delete",
        },
      ],
    });

    onMounted(() => {
      getTemplateListing();
    });
    
    function reloadAllData(data: object = {}, created_new: boolean = false) {
      self.edit_template = false;
      self.create_new = false;
      if (created_new) {
        self.templates.unshift(data);
      } else {
        getTemplateListing(true);
      }
    }

    function editTemplate(val: any) {
      self.selected_template = val;
      self.edit_template = true;
    }

    function deleteTemplate(val: any) {
      self.selected_template = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to trash?",
        header: "Delete Folder Template",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_template,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedTemplateListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedTemplateListing() {
      self.edit_template = false;
      getTemplateListing(true);
    }

    function getTemplateListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.templates = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.templates.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }
    return {
      self,
      updatedTemplateListing,
      getTemplateListing,
      reloadAllData,
    };
  },
});
</script>