<template>
  <div id="form-content" class="page-content">
    <Form ref="suspendReason" class="form-wrap">
      <Field
        class="w-full"
        as="div"
        type="text"
        id="suspend-reason"
        name="Suspend Reason"
        rules="required"
        autocomplete="off"
        v-model="self.user.reason"
      >
        <Textarea v-model="self.user.reason" rows="5" cols="30" />
      </Field>
      <ErrorMessage as="small" class="p-error" name="Suspend Reason" />
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Suspend User"
          :loading="self.suspending_user"
          @click.prevent="suspendUser"
          severity="danger"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { getCurrentInstance, reactive, ref } from "vue";
const { appContext }: any = getCurrentInstance();
const props: any = defineProps({
  user: {
    type: Object,
  },
});
const emit = defineEmits(["user-suspended"]);
const suspendReason = ref();
const self = reactive({
  api_url: "users",
  user: {
    reason: "",
  },
  suspending_user: false,
});

async function suspendUser() {
  const validate = await suspendReason.value.validate();
  if (!validate.valid) {
    return;
  }
  self.suspending_user = true;
  await appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    self.user,
    props.user.id,
    (response: any) => {
      self.suspending_user = false;
      appContext.config.globalProperties.$toastMessage(response);
      if (response.status >= 200 && response.status < 300) {
        emit("user-suspended", null);
      }
    },
    "suspend",
    true
  );
}
</script>