<template>
  <div>
    <AppToolbar
      permission="resources"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      @reload-data="reloadAllData"
    />
    <div class="the-content">
      <TableView
        permission_type="resources"
        :loading="self.loading"
        :items="self.resources"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getResourceListing"
      />
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_resource"
        position="right"
      >
        <template #header> Edit Resource</template>
        <EditResource
          :resource="self.selected_resource"
          @updated-resource="reloadAllData"
        />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
const EditResource = defineAsyncComponent(
  () => import("./components/editResource.vue")
);
export default defineComponent({
  name: "ResourceIndexData",
  components: {
    EditResource,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-user-cog mr-1",
        label: "Resources",
        to: { name: "resourceSettings" },
      },
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        filter: false,
        refresh: true,
        add: false,
      },
      resources: [] as any,
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "name",
          label: "Resource",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
        },
      ],
      selected_resource: {},
      edit_resource: false,
      action_buttons: [
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editResource(val);
          },
          permission: "edit",
        },
      ],
    });

    onMounted(() => {
      getResourceListing();
    });
    function reloadAllData() {
      self.edit_resource = false;
      getResourceListing(true);
    }

    function editResource(val: any) {
      self.selected_resource = val;
      self.edit_resource = true;
    }

    function updatedResourceListing() {
      self.edit_resource = false;
      getResourceListing(true);
    }

    function getResourceListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.resources = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          "resources",
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.resources.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }
    return {
      self,
      updatedResourceListing,
      getResourceListing,
      reloadAllData,
    };
  },
});
</script>