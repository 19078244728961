import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "analytics-tab" }
const _hoisted_2 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_TimeSpent = _resolveComponent("TimeSpent")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.self.view_modal,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.view_modal) = $event)),
    onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:view_modal', false))),
    style: { width: '70vw' },
    position: "top",
    modal: "",
    class: "owner-dialog"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TabView, {
          activeIndex: _ctx.self.active,
          "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.active) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.analytics_types, (tab, index) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                key: index,
                header: tab.name
              }, null, 8, ["header"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeIndex"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TabView, {
          activeIndex: _ctx.self.active,
          "onUpdate:activeIndex": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.active) = $event)),
          class: "hidden-header"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.analytics_types, (tab, index) => {
              return (_openBlock(), _createBlock(_component_TabPanel, { key: index }, {
                default: _withCtx(() => [
                  _createVNode(_component_TableView, {
                    loading: _ctx.self.loading,
                    items: _ctx.self.analytic_detail,
                    fields: _ctx.self.table_fields,
                    permission_type: "media",
                    actions: _ctx.self.action_buttons,
                    action_title: 'Analytic Detail',
                    disable_select: "",
                    pagination: _ctx.self.pagination,
                    expandedRows: _ctx.self.expandedRows,
                    expander: "",
                    is_public: _ctx.self.active == 1
                  }, {
                    "expanded-col": _withCtx(({ slotProps }) => [
                      _createVNode(_component_TableView, {
                        loading: 
                  _ctx.self.analytic_detail[slotProps.index].detail_info.loading
                ,
                        items: _ctx.self.analytic_detail[slotProps.index].detail_info.data,
                        permission_type: "media",
                        fields: 
                  _ctx.self.analytic_detail[slotProps.index].detail_info.table_fields
                ,
                        actions: 
                  _ctx.self.analytic_detail[slotProps.index].detail_info
                    .action_buttons
                ,
                        action_title: 'Time Spent',
                        disable_select: "",
                        pagination: 
                  _ctx.self.analytic_detail[slotProps.index].detail_info.pagination
                ,
                        expandedRows: 
                  _ctx.self.analytic_detail[slotProps.index].detail_info
                    .expanded_rows
                ,
                        expander: "",
                        expanded_info: ""
                      }, {
                        "expanded-col": _withCtx(({ subSlotProps }) => [
                          _createVNode(_component_TimeSpent, {
                            data: 
                      _ctx.self.analytic_detail[slotProps.index].detail_info.data[
                        subSlotProps.index
                      ].data
                    ,
                            doc: _ctx.doc,
                            onPreviewDoc: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('preview-document', $event)))
                          }, null, 8, ["data", "doc"])
                        ]),
                        footer: _withCtx(() => [
                          (
                      _ctx.self.analytic_detail[slotProps.index].detail_info
                        .pagination.total_page > 1
                    )
                            ? (_openBlock(), _createBlock(_component_Paginator, {
                                key: 0,
                                rows: 
                      _ctx.self.analytic_detail[slotProps.index].detail_info
                        .pagination.items_per_page
                    ,
                                totalRecords: 
                      _ctx.self.analytic_detail[slotProps.index].detail_info
                        .pagination.total_records
                    ,
                                onPage: ($event: any) => (_ctx.onPageChage($event, slotProps))
                              }, null, 8, ["rows", "totalRecords", "onPage"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["loading", "items", "fields", "actions", "pagination", "expandedRows"])
                    ]),
                    footer: _withCtx(() => [
                      (_ctx.self.pagination.total_page > 1)
                        ? (_openBlock(), _createBlock(_component_Paginator, {
                            key: 0,
                            rows: _ctx.self.pagination.items_per_page,
                            totalRecords: _ctx.self.pagination.total_records,
                            onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onAnalyticsPageChage($event)))
                          }, null, 8, ["rows", "totalRecords"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["loading", "items", "fields", "actions", "pagination", "expandedRows", "is_public"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeIndex"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}