<template>
  <div>
    <AppToolbar
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
    />
    <div class="the-content"> 
        <Card id="profile-data" v-if="!self.loading">
          <template #content>
            <Toolbar>
              <template #start>
                Enable two factor authentication to make your account more
                secure.
              </template>

              <template #end>
                <InputSwitch
                  @change="updateTwoFa"
                  class="custom-switch"
                  v-model="self.user_detail.two_factor_auth"
                />
              </template>
            </Toolbar>
          </template>
          <template #footer>
            <ProfileDetail
              :user_detail="self.user_detail"
              :summary_detail="self.summary_detail"
              @image-uploaded="onImageUpload"
              @updated-profile="reloadProfile"
            />
          </template>
        </Card>
    </div>
  </div>
</template>
<script lang="ts">
import { getUserDetail } from "@/configs/auth";
import { defineComponent, onMounted, reactive, getCurrentInstance } from "vue";
import ProfileDetail from "./components/userDetailInfo.vue";
export default defineComponent({
  name: "MYProfile",
  components: {
    ProfileDetail,
  },
  setup() {
    const appData:any = getCurrentInstance();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
      breadcrumbItems: {
        icon: "fas fa-user mr-1",
        label: "Profile",
        to: { name: "myProfile" },
      },
      toolbarOptions: {},
      user_detail: {} as any,
      summary_detail: {},
      loading: true,
    });
    onMounted(() => {
      getUserData();
    });

    function getUserData() {
      self.loading = true;
      self.user_detail =
        self.properties.$store.getters["setting/getUserDetail"];
      if (!Object.keys(self.user_detail).length) {
        reloadProfile();
      } else {
        self.loading = false;
      }
      getSummaryDetail();
    }

    function updateTwoFa() {
      self.properties.app_service.updateData(
        `2fa`,
        {
          enable: self.user_detail.two_factor_auth,
        },
        null,
        (response: any, error: any) => {
          if (response) {
            self.properties.$toastMessage(response);
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        },
        null,
        true
      );
    }

    function getSummaryDetail() {
      self.properties.app_service.getData(
        `summary`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.summary_detail = response;
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        },
        null,
        true
      );
    }

    function onImageUpload(e: any) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      self.properties.app_service.updateData(
        "profile-image",
        formData,
        null,
        (response: any, error: any) => {
          if (response) {
            self.properties.$toastMessage(response);
            reloadProfile();
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        },
        null,
        true
      );
    }

    function reloadProfile() {
      self.loading = true;
      getUserDetail(self.properties, (response: any) => {
        self.properties.$store.commit("setting/setUserDetail", response);
        self.user_detail = response;
        self.loading = false;
      });
    }

    return {
      appData,
      self,
      getUserData,
      updateTwoFa,
      getSummaryDetail,
      onImageUpload,
      reloadProfile,
    };
  },
});
</script>