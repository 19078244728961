import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, unref as _unref } from "vue"

const _hoisted_1 = { id: "main-header" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href", "target"]
const _hoisted_4 = {
  key: 0,
  class: "navbar-message"
}
const _hoisted_5 = { class: "faq-list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex align-items-center" }
const _hoisted_8 = { class: "pl-2" }
const _hoisted_9 = {
  key: 0,
  class: "w-full h-50",
  autoplay: "",
  controls: ""
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "pi pi-bell p-overlay-badge"
}
const _hoisted_13 = {
  key: 1,
  class: "pi pi-bell"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href", "target"]
const _hoisted_17 = ["src"]

import { detect } from "detect-browser";
import { useStore } from "vuex";
import { getCurrentInstance, ref, onMounted, reactive, watch } from "vue";
import Notification from "./applicationNotification.vue";
import { getUserDetail } from "@/configs/auth";

export default /*@__PURE__*/_defineComponent({
  __name: 'applicationHeader',
  emits: ["user-loaded"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { appContext }: any = getCurrentInstance();
const notificationPanel = ref();
const profileMenu = ref();
const faqMenu = ref();
const notification_audio = ref();
const self = reactive({
  main_logo: appContext.config.globalProperties.$getImage(
    process.env.VUE_APP_LOGO
  ),
  notification_sound: require("@/assets/sound/notification_sound.mp3"),
  properties: appContext.config.globalProperties,
  view_error_msg: false,
  user_loaded: false,
  browser: detect() as any,
  user: {} as any,
  store: useStore() as any,
  profile_menu: [
    {
      label: "My Profile",
      icon: "pi pi-fw pi-user",
      to: { name: "myProfile" },
    },
    {
      label: "Log Out",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        logOutUser();
      },
    },
  ],
  notification: {
    loading: false,
    lists: [] as any,
    page: 1,
    pagination: {
      current_page: 1,
      total_page: 2,
      total_records: 0,
      total_notification: 0,
      total_unread_notification: 0,
    },
  },
  faqs: [] as any,
  faq: {
    view: false,
    from: null,
    data: [] as any,
    modal: {
      view: false,
      selected: {} as any,
    },
  },
  permissions: {} as any,
});

onMounted(() => {
  detectBrowser();
  getFaqLists();
  updateFaqView(self.properties.$route);
  setTimeout(() => {
    getCurrentProfile();
    getNotifications();
    openSocketConnection();
    checkUserOnline();
  }, 1500);
});
watch(
  () => self.properties.$route,
  (val: any) => {
    updateFaqView(val);
  }
);
function openSocketConnection() {
  self.properties.app_service.getSocketConnection(
    self.properties,
    "notifications",
    (response: any) => {
      if (response.data) {
        self.notification.lists.unshift(response.data);
        self.notification.pagination.total_unread_notification += 1;
        notification_audio.value.play();
        setTimeout(() => {
          if (response.reload) {
            return openSocketConnection();
          }
        }, 300);
      }
    }
  );
}

function checkUserOnline() {
  self.properties.app_service.getSocketConnection(
    self.properties,
    "_online",
    () => {
      return;
    }
  );
}

function updateFaqView(val: any) {
  if (val.meta.faq) {
    self.faq.view = val.meta.faq.view;
    self.faq.from = val.meta.faq.slug;
    updateFaqList(self.faq.from);
  } else {
    self.faq.view = false;
  }
}
function updateFaqList(val: any) {
  const faq_lists = self.properties.$store.getters["setting/getFaqs"];
  if (faq_lists[val]) {
    self.faq.data = faq_lists[val].map((faq: any) => {
      return {
        label: faq.question,
        icon: "fa-solid fa-video",
        command: () => {
          viewVideo(faq);
        },
      };
    });
  }
}
function viewVideo(faq: any) {
  self.faq.modal = {
    view: true,
    selected: faq,
  };
  faqMenu.value.hide();
}
function refreshFaqParams() {
  self.faq = {
    view: true,
    from: self.faq.from,
    data: self.faq.data,
    modal: {
      view: false,
      selected: {} as any,
    },
  };
}

function detectBrowser() {
  if (self.browser) {
    if (self.browser.name == "Chrome" || self.browser.name == "chrome") {
      self.view_error_msg = false;
    } else {
      self.view_error_msg = true;
    }
  }
}
function toogleNotification(e: any) {
  notificationPanel.value.toggle(e);
}
function getNotifications(reset = false) {
  if (reset) {
    self.notification.lists = [];
    self.notification.page = 1;
  }
  if (
    (self.notification.pagination.current_page <
      self.notification.pagination.total_page &&
      !self.notification.loading) ||
    (self.notification.page == 1 && !self.notification.loading)
  ) {
    self.notification.loading = true;
    appContext.config.globalProperties.app_service.getData(
      "notifications",
      self.notification.page,
      (response: any) => {
        if (response) {
          self.notification.lists.push(...response.data);
          if (response.metadata) {
            self.notification.pagination = response.metadata;
          }
          self.notification.loading = false;
          self.notification.page += 1;
        }
      }
    );
  }
}

async function getFaqLists() {
  self.faqs = self.store.getters["setting/getFaqs"];
}

function getCurrentProfile() {
  self.user_loaded = false;
  const userData = self.store.getters["setting/getUserDetail"];
  self.permissions =
    appContext.config.globalProperties.$store.getters[
      "system/getNavigationsPermission"
    ];
  if (!userData) {
    getUserDetail(appContext.config.globalProperties, (response: any) => {
      self.user = response;
      self.store.commit("setting/setUserDetail", self.user);
      self.user_loaded = true;
      emit("user-loaded");
      getAllDetails();
    });
  } else {
    getAllDetails();
    self.user = userData;
  }
}

function logOutUser() {
  appContext.config.globalProperties.$keycloak.logout();
}

function toggleProfileMenu(e: any) {
  profileMenu.value.toggle(e);
}

function togglefaqMenu(e: any) {
  faqMenu.value.toggle(e);
}

async function getAllDetails() {
  await appContext.config.globalProperties.app_service.getData(
    "preferences",
    null,
    (response: any) => {
      if (response) {
        self.store.commit("system/setUserPreference", response);
        self.user_loaded = true;
        emit("user-loaded");
      }
    },
    false
  );
}

return (_ctx: any,_cache: any) => {
  const _component_Image = _resolveComponent("Image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Menubar = _resolveComponent("Menubar")!
  const _directive_badge = _resolveDirective("badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menubar, null, {
      start: _withCtx(() => [
        _createVNode(_component_router_link, { to: { name: 'dashboard' } }, {
          default: _withCtx(() => [
            _createVNode(_component_Image, {
              class: "logo",
              src: self.main_logo
            }, null, 8, ["src"])
          ]),
          _: 1
        })
      ]),
      item: _withCtx(({ label, item, props, root, hasSubmenu }) => [
        (item.to)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.to,
              custom: ""
            }, {
              default: _withCtx((routerProps) => [
                _createElementVNode("a", _mergeProps({
                  href: routerProps.href
                }, props.action), [
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                ], 16, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("a", _mergeProps({
              key: 1,
              href: item.url,
              target: item.target
            }, props.action), [
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17),
              _createElementVNode("span", _mergeProps({
                class: [
              hasSubmenu &&
                (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
            ]
              }, props.submenuicon), null, 16)
            ], 16, _hoisted_3))
      ]),
      end: _withCtx(() => [
        (self.view_error_msg)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
              _createElementVNode("p", null, " This browser is not supported. Use the latest version of Chrome to get the best experience. ", -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, [
          _withDirectives(_createVNode(_component_Button, {
            onClick: togglefaqMenu,
            type: "button"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "text-xl fa-regular fa-circle-question" }, null, -1)
            ])),
            _: 1
          }, 512), [
            [_vShow, self.faq.view]
          ]),
          _createVNode(_component_OverlayPanel, {
            ref_key: "faqMenu",
            ref: faqMenu,
            class: "faq-menu",
            appendTo: "body"
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(self.faq.data, (faq, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    onClick: faq.command
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("i", {
                        class: _normalizeClass(faq.icon)
                      }, null, 2),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(faq.label), 1)
                    ])
                  ], 8, _hoisted_6))
                }), 128))
              ])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_Dialog, {
            visible: self.faq.modal.view,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((self.faq.modal.view) = $event)),
            modal: "",
            header: self.faq.modal.selected.question,
            style: { width: '50vw' },
            onHide: refreshFaqParams
          }, {
            default: _withCtx(() => [
              (self.faq.modal.selected.video_link)
                ? (_openBlock(), _createElementBlock("video", _hoisted_9, [
                    _createElementVNode("source", {
                      src: self.faq.modal.selected.video_link,
                      type: "video/mp4"
                    }, null, 8, _hoisted_10),
                    _cache[3] || (_cache[3] = _createTextVNode(" Your browser does not support the video tag. "))
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_11, "No Video Available"))
            ]),
            _: 1
          }, 8, ["visible", "header"]),
          _createVNode(_component_Button, {
            onClick: toogleNotification,
            rounded: "",
            "aria-label": "Notification"
          }, {
            default: _withCtx(() => [
              (self.notification.pagination.total_unread_notification >= 1)
                ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_12, null, 512)), [
                    [
                      _directive_badge,
                      
                self.notification.pagination.total_unread_notification > 9
                  ? '9+'
                  : self.notification.pagination.total_unread_notification
              ,
                      void 0,
                      { danger: true }
                    ]
                  ])
                : (_openBlock(), _createElementBlock("i", _hoisted_13))
            ]),
            _: 1
          }),
          _createVNode(_component_OverlayPanel, {
            ref_key: "notificationPanel",
            ref: notificationPanel,
            class: "notification",
            appendTo: "body"
          }, {
            default: _withCtx(() => [
              _createVNode(Notification, {
                notifications: self.notification.lists,
                pagination: self.notification.pagination,
                loading: self.notification.loading,
                onLoadMoreNotification: getNotifications,
                onMarkedAll: getNotifications,
                onUpdateNotificationCount: getNotifications
              }, null, 8, ["notifications", "pagination", "loading"])
            ]),
            _: 1
          }, 512)
        ]),
        (self.user_loaded && self.user)
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
              _createVNode(_component_Button, {
                onClick: toggleProfileMenu,
                type: "button"
              }, {
                default: _withCtx(() => [
                  (self.user.image)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 0,
                        image: 
                _unref(appContext).config.globalProperties.$getFullPath(self.user.image)
              ,
                        shape: "circle",
                        class: "mr-2"
                      }, null, 8, ["image"]))
                    : (_openBlock(), _createBlock(_component_Avatar, {
                        key: 1,
                        label: self.user.name.charAt(0),
                        shape: "circle",
                        class: "mr-2 text-white"
                      }, null, 8, ["label"])),
                  _createTextVNode(" " + _toDisplayString(self.user.name) + " ", 1),
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "pl-2 pi pi-chevron-down" }, null, -1))
                ]),
                _: 1
              }),
              _createVNode(_component_Menu, {
                ref_key: "profileMenu",
                ref: profileMenu,
                id: "profile_menu",
                model: self.profile_menu,
                popup: true
              }, {
                item: _withCtx(({ label, item, props }) => [
                  (item.to)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: item.to,
                        class: "w-full"
                      }, {
                        default: _withCtx((routerProps) => [
                          _createElementVNode("a", _mergeProps({
                            href: routerProps.href
                          }, props.action), [
                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                          ], 16, _hoisted_15)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : (_openBlock(), _createElementBlock("a", _mergeProps({
                        key: 1,
                        href: item.url,
                        target: item.target
                      }, props.action), [
                        _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                        _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                      ], 16, _hoisted_16))
                ]),
                _: 1
              }, 8, ["model"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("audio", {
      ref_key: "notification_audio",
      ref: notification_audio
    }, [
      _createElementVNode("source", {
        src: self.notification_sound,
        type: "audio/mpeg"
      }, null, 8, _hoisted_17)
    ], 512)
  ]))
}
}

})