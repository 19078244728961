import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "application_activity",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      queue: {
        view: true,
        text: `Total Active Users: ${_ctx.self.pagination.total_records}`,
      },
      onReloadData: _ctx.reloadAllData
    }, null, 8, ["breadcrumb_home", "options", "loading", "queue", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        loading: _ctx.self.loading,
        items: _ctx.self.users,
        permission_type: "application_activity",
        disable_select: true,
        fields: _ctx.self.table_fields,
        pagination: _ctx.self.pagination,
        actions: _ctx.self.action_buttons,
        onLoadMoreData: _ctx.getActiveUsers
      }, null, 8, ["loading", "items", "fields", "pagination", "actions", "onLoadMoreData"])
    ])
  ]))
}