
export default <{ [index: string]: any }>{
    ApiBaseUrl:process.env.VUE_APP_API_PATH,
    PublicApiBaseUrl:process.env.VUE_APP_PUBLIC_API_URL,
    layout: () => import("@/modules/system/views/layouts/applicationLayout.vue"),
    publicLayout: () => import("@/modules/system/views/layouts/public/publicLayout.vue"),
    projectShareLayout: () => import("@/modules/system/views/layouts/public/projectShareLayout.vue"),
    agreemetLayout: () => import("@/modules/system/views/layouts/public/agreements/agreementLayout.vue"),
    maintananceLayout: () => import("@/modules/system/views/layouts/public/maintanance/maintananceLayout.vue"),
    healthLayout:() => import("@/modules/system/views/layouts/applicationHealth.vue"),
    dashboard:{
        index: () => import("@/modules/dashboard/views/dashboardIndex.vue"),
        queue_monitor: () => import("@/modules/dashboard/views/queueMonitorIndex.vue"),
        active_user: () => import("@/modules/dashboard/views/activeUser.vue")
    },
    project:{
        index: () => import("@/modules/projects/views/index.vue"),
        summary: () => import("@/modules/projects/views/summary.vue")
    },
    report:{
        index:() => import("@/modules/reports/views/index.vue")
    },
    setting:{
        index:()=> import("@/modules/settings/views/system-settings/settingIndex.vue"),
        profile: () => import("@/modules/settings/views/profile/index.vue"),
        user: () => import("@/modules/settings/views/user/userIndex.vue"),
        user_group: () => import("@/modules/settings/views/user-group/groupIndex.vue"),
        role: () => import("@/modules/settings/views/role/roleIndex.vue"),
        resources: () => import("@/modules/settings/views/resources/resourceIndex.vue"),
        folder_template: () => import("@/modules/settings/views/folder-template/templateIndex.vue"),
        email_template: () => import("@/modules/settings/views/email-template/templateIndex.vue"),
        tag_group: () => import("@/modules/settings/views/tag-group/tagIndex.vue"),
        user_agreement: () => import("@/modules/settings/views/user-agreement/agreementIndex.vue")
    },
    shared:{
        by_me: () => import("@/modules/shared/views/shared-by-me/index.vue"),
        with_me: () => import("@/modules/shared/views/shared-with-me/index.vue")
    },
    system:{
        pubic:{
            shared:{
                index:() => import("@/modules/system/views/layouts/public/share/index.vue"),
                public:() => import("@/modules/system/views/layouts/public/share/project.vue")
            }
        }
    }
};