<template>
  <div>
    <div
      v-if="self.view_timeline"
      :id="`timeline-${data.val.id}-${data.row.index}-${data.sub_row.index}-${self.page}`"
      class="w-11 mx-auto"
    ></div>
    <Paginator
      v-if="self.pagination.total_records > 10"
      :rows="self.pagination.per_page"
      :totalRecords="self.pagination.total_records"
      @page="onPageChage"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, getCurrentInstance, reactive } from "vue";
import { GoogleCharts } from "google-charts";
export default defineComponent({
  name: "AnalyticsTimeSpent",
  props: {
    data: {
      type: Object,
    } as any,
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["preview-doc"],
  setup(props: any, { emit }) {
    const appData: any = getCurrentInstance();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
      page: 1,
      pagination: {
        per_page: 10,
        view_data: 1,
        total_records: 0,
      },
      google_chart: GoogleCharts,
      chart_package: {
        packages: ["corechart"],
      },
      time_data_values: [],
      view_timeline: false,
    });
    
    onMounted(() => {
      timelineChart();
    });

    function timelineChart() {
      self.google_chart.load(getTimeChart, self.chart_package);
    }

    function getTimeChart() {
      let time_data = "" as any;
      const time_data_values = [] as any;
      const value_data = props.data ? props.data.val : null;
      if (value_data) {
        Object.keys(value_data).forEach((data: any) => {
          if (data == "time_spent") {
            time_data = JSON.parse(value_data.time_spent);
          }
        });
        if (time_data) {
          time_data.pages.forEach((data: any) => {
            time_data_values.push([
              `Page ${data.page_no ? Number(data.page_no) : 1}`,
              Math.floor(Number(data.time_spent) / 1000),
              `
                <div style="margin:3px; padding:0;">
                  <p>
                    <b>Viewed Date:</b> ${self.properties.$calendarFormat(Number(data.view_start))}
                  </p>
                  <p>
                    <b>Page No:</b> ${data.page_no ? Number(data.page_no) : 1}
                  </p>
                  <p>
                    <b>Time Spent:</b> ${self.properties.$timeStamp(data.time_spent)}
                  </p>
                </div>
              `,
              data.page_no ? Number(data.page_no) : 1,
              self.properties.$timeStamp(data.time_spent),
            ]);
          });
          const time_value_array = self.properties.$chunkArrayData(
            time_data_values,
            self.pagination.per_page
          );
          self.pagination.total_records = time_data.pages.length;
          time_value_array.forEach((data: any) => {
            data.unshift([
              "Page No",
              "Time Spent",
              { role: "tooltip", p: { html: true } },
              { role: "link" },
              { role: "annotation" },
            ]);
          });
          self.time_data_values = time_value_array;
          getTimeDataValues();
        }
      }
    }

    function getTimeDataValues() {
      const time_spent = JSON.parse(props.data.val.time_spent);
      self.view_timeline = true;
      setTimeout(() => {
        const container = document.getElementById(
          `timeline-${props.data.val.id}-${props.data.row.index}-${props.data.sub_row.index}-${self.page}`
        );
        const options = {
          title: `Last Updated ${self.properties.$calendarFormat(
            Number(time_spent.last_update)
          )}`,
          legend: { position: "none" },
          hAxis: { format: "" },
          tooltip: { isHtml: true },
        };
        const chart = new self.google_chart.api.visualization.ColumnChart(container);
        const data = new self.google_chart.api.visualization.arrayToDataTable(self.time_data_values[self.page - 1]);
        if (self.properties.$getContentType(props.doc) == "pdf") {
          self.google_chart.api.visualization.events.addListener(
            chart,
            "select",
            () => {
              const selection = chart.getSelection();
              if (selection.length) {
                const row = selection[0].row;
                const page = data.getValue(row, 3);
                emit("preview-doc", page);
              }
            }
          );
        }
        chart.draw(data, options);
      }, 100);
    }
    function onPageChage(e: any) {
      self.page = e.page + 1;
      getTimeDataValues();
    }
    return {
      appData,
      self,
      getTimeChart,
      onPageChage,
    };
  },
});
</script>