import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.self.view_timeline)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: `timeline-${_ctx.data.val.id}-${_ctx.data.row.index}-${_ctx.data.sub_row.index}-${_ctx.self.page}`,
          class: "w-11 mx-auto"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.self.pagination.total_records > 10)
      ? (_openBlock(), _createBlock(_component_Paginator, {
          key: 1,
          rows: _ctx.self.pagination.per_page,
          totalRecords: _ctx.self.pagination.total_records,
          onPage: _ctx.onPageChage
        }, null, 8, ["rows", "totalRecords", "onPage"]))
      : _createCommentVNode("", true)
  ]))
}