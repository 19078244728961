import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_TableView = _resolveComponent("TableView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TableView, {
      permission_type: "user",
      selected_items: _ctx.self.selected_projects,
      "onUpdate:selected_items": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.selected_projects) = $event)),
      selected_item: _ctx.self.selected_project,
      "onUpdate:selected_item": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.selected_project) = $event)),
      disable_select: "",
      loading: _ctx.self.loading,
      items: _ctx.self.projects,
      fields: _ctx.self.table_fields,
      pagination: _ctx.self.pagination,
      actions: _ctx.self.action_buttons,
      expandedRows: _ctx.self.expandedRows,
      onLoadMoreData: _ctx.getUserProjects
    }, {
      "expanded-col": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_TableView, {
            loading: _ctx.self.shared_detail.loading,
            items: _ctx.self.shared_detail.data,
            permission_type: "user",
            fields: _ctx.self.shared_detail.table_fields,
            disable_select: "",
            pagination: _ctx.self.shared_detail.pagination,
            expander: "",
            expanded_info: ""
          }, {
            footer: _withCtx(() => [
              (
                  _ctx.self.shared_detail.pagination.total_page > 1 &&
                  !_ctx.self.shared_detail.loading
                )
                ? (_openBlock(), _createBlock(_component_Paginator, {
                    key: 0,
                    rows: _ctx.self.shared_detail.pagination.items_per_page,
                    totalRecords: _ctx.self.shared_detail.pagination.total_records,
                    onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeSharedDataPage($event)))
                  }, null, 8, ["rows", "totalRecords"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["loading", "items", "fields", "pagination"])
        ])
      ]),
      _: 1
    }, 8, ["selected_items", "selected_item", "loading", "items", "fields", "pagination", "actions", "expandedRows", "onLoadMoreData"])
  ]))
}