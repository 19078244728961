<template>
  <div
    :id="id"
    class="infinite-scroller"
    :style="[relative ? 'position:relative;' : '']"
  ></div>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount } from "vue";
const emit = defineEmits(["loadMore"]);
const props: any = defineProps({
  id: {
    type: String,
    default: "scroll-more",
  },
  target: {
    type: String,
    default: null,
  },
  relative: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  if (props.target) {
    const element = document.querySelector(props.target);
    element.addEventListener("scroll", infiniteHandler);
  } else {
    document.addEventListener("scroll", infiniteHandler);
  }
});

onBeforeUnmount(() => {
  if (props.target) {
    const element = document.querySelector(props.target);
    element.removeEventListener("scroll", infiniteHandler);
  } else {
    document.removeEventListener("scroll", infiniteHandler);
  }
});

function infiniteHandler() {
  const topEle = document.getElementById(props.id);
  if (isElementInViewPort(topEle)) {
    emit("loadMore");
  }
}

function isElementInViewPort(element: any) {
  const rect = element.getBoundingClientRect();
  const viewPortBottom = window.innerHeight || document.documentElement.clientHeight;
  const viewPortRight = window.innerWidth || document.documentElement.clientWidth;
  const isTopInViewPort = rect.top >= 0,
    isLeftInViewPort = rect.left >= 0,
    isBottomInViewPort = rect.bottom <= viewPortBottom,
    isRightInViewPort = rect.right <= viewPortRight;
  return isTopInViewPort && isLeftInViewPort && isBottomInViewPort && isRightInViewPort;
}
</script>
<style lang="scss" scoped>
.infinite-scroller {
  position: relative;
  height: 60px;
  width: 100%;
  margin-top: -150px;
  z-index: -1;
}
</style>