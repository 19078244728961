import appLayout from "@/configs/app";
const routes= [
  {
    path: "/dashboard",
    name: "projects",
    component: appLayout.layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "projects",
        name: "projectIndex",
        component: appLayout.project.index,
        meta: {
          title: "Projects",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "project",
          },
        },
      },
      {
        path: "projects/:id/:tab/:doc_type/:doc_id",
        name: "projectInfo",
        component: appLayout.project.summary,
        meta: {
          title: "Projects Detail",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "project",
          },
        },
      },
    ],
  },
];

export default routes;