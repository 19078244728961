import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_EditAgreement = _resolveComponent("EditAgreement")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_CreateAgreement = _resolveComponent("CreateAgreement")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "user_agreement",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      filter: _ctx.self.filterOptions,
      loading: _ctx.self.loading,
      suggestions: _ctx.self.suggestions,
      search_placeholder: "Search User Agreement",
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true)),
      onSubmitFilterData: _ctx.searchAgreements,
      onGetSuggestions: _ctx.getFilterSuggestions
    }, null, 8, ["breadcrumb_home", "options", "filter", "loading", "suggestions", "onReloadData", "onSubmitFilterData", "onGetSuggestions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "user_agreement",
        disable_select: "",
        loading: _ctx.self.loading,
        items: _ctx.self.templates,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        onLoadMoreData: _ctx.getAgreementListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_agreement,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.edit_agreement) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Edit Agreement")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_EditAgreement, {
            template: _ctx.self.selected_agreement,
            userAgreementTypes: _ctx.self.userAgreementTypes,
            toolbar: _ctx.self.toolbar,
            emailTags: _ctx.self.agreementTags,
            onUpdatedTemplate: _ctx.reloadAllData
          }, null, 8, ["template", "userAgreementTypes", "toolbar", "emailTags", "onUpdatedTemplate"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Add Agreement")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_CreateAgreement, {
            userAgreementTypes: _ctx.self.userAgreementTypes,
            toolbar: _ctx.self.toolbar,
            emailTags: _ctx.self.agreementTags,
            onCreatedTemplate: _ctx.reloadAllData
          }, null, 8, ["userAgreementTypes", "toolbar", "emailTags", "onCreatedTemplate"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}