<template>
  <TabView id="project-panel" v-model:activeIndex="self.active_tab">
    <TabPanel header="General">
      <GeneralInfo :project_detail="project" :project_shared_list="shared_list" />
    </TabPanel>
    <TabPanel header="Dataroom">
      <MyVault id="dataroom"  :load_all="true"/> 
    </TabPanel>
  </TabView>
</template>
<script lang="ts">
import { defineComponent, reactive, onMounted, getCurrentInstance, watch } from "vue";
import GeneralInfo from "./generalInfo.vue";
import MyVault from "@/modules/my-vault/views/my-vault/index.vue";
export default defineComponent({
  name: "ProjectDetail",
  props: ["project", "shared_list", "active_tab"],
  emits: ["update:active_tab"],
  components: {
    GeneralInfo,
    MyVault,
  },
  setup(props: any, { emit }) {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      active_tab: props.active_tab,
      properties: appContext.config.globalProperties,
      tabs: ["info", "vault"] as any,
    });
    watch(
      () => self.active_tab,
      (val: any) => {
        getRoute();
        emit("update:active_tab", val);
      }
    );
    onMounted(() => {
      getRoute;
    });
    function getRoute() {
      self.properties.$router.replace({
        params: {
          tab: self.tabs[self.active_tab],
        },
      });
    }
    return {
      self,
      getRoute,
    };
  },
});
</script>
