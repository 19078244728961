import appLayout from "@/configs/app";
const routes = [
  {
    path: "/dashboard",
    name: "vault",
    component: appLayout.layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "docs",
        name: "myVault",
        component: () => import("../views/my-vault/index.vue"),
        meta: {
          title: "File Manager",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "my_vault",
          },
        },
      },
      {
        path: "docs/:doc_type/:doc_id",
        name: "myVaultDetail",
        component: () => import("../views/my-vault/index.vue"),
        meta: {
          title: "File Manager",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "my_vault",
          },
        },
      },
      {
        path: "starred",
        name: "starredDocs",
        component: () => import("../views/starred/index.vue"),
        meta: {
          title: "Starred", 
          requiresAuth: true, 
          faq: {
            view: true,
            slug: "starred",
          },
        },
      },
      {
        path: "starred/:doc_type/:doc_id",
        name: "starredDocsDetail",
        component: () => import("../views/starred/index.vue"),
        meta: {
          title: "Starred", 
          requiresAuth: true,
          faq: {
            view: true,
            slug: "starred",
          },
        },
      },
      {
        path: "trash",
        name: "trashDocs",
        component: () => import("../views/trash/index.vue"),
        meta: {
          title: "Trash",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "trash",
          },
        },
      },
      {
        path: "notification/setting",
        name: "notificationSettings",
        component: () => import("../views/notification-setting/index.vue"),
        meta: {
          title: "Notification Setting",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "notification_setting",
          },
        },
      },
    ],
  },
];
export default routes;