import { defineRule, configure } from "vee-validate";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const AllRules = require("@vee-validate/rules"); 
Object.keys(AllRules.all).forEach((rule) => { 
  if(rule != 'a;;'){
    defineRule(rule, AllRules[rule]);
  }
});

defineRule("password_requirement", (value: string)=>{
  // eslint-disable-next-line no-useless-escape
  const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[~?|<`>{};?~|!=@#$%^&*()_+,.\\\/':"-]).{8,}$/gm;
  const matches = new RegExp(pattern);
  if(matches.test(value)){
    return true
  } else {
    return "The Password field must have at least 1 uppercase letter, 1 lowercase letter, and one special character"
  }
})

configure({
  generateMessage: (context:any) => {
    return `The field ${context.field} is invalid`;
  },
});

defineRule("confirmed", (value:any, [target]:any, ctx:any) => {
  if (value === ctx.form[target]) {
    return true;
  }
  return "Password & Confirm Password must match with each other";
});