<template>
  <div
    v-if="!self.loading_items"
    id="sidebar"
    :class="{
      'menu-collapsed': self.collapse_sidebar,
    }"
  >
    <sidebar-menu
      v-if="!self.collapse_sidebar && self.windowWidth > 767"
      :menu="self.menu_items"
      theme="white-theme"
      width="220px"
      widthCollapsed="65px"
      :collapsed="false"
      :showOneChild="true"
      @update:collapsed="onToggleCollapse"
      @item-click="onItemClick"
    >
      <template v-slot:toggle-icon>
        <span>
          <i class="fa-solid fa-angles-right"></i>
          <span class="toggle-text">Collapse Sidebar</span>
        </span>
      </template>
    </sidebar-menu>
    <sidebar-menu
      v-if="self.collapse_sidebar && self.windowWidth > 767"
      :menu="self.menu_items"
      theme="white-theme"
      width="220px"
      widthCollapsed="65px"
      :collapsed="true"
      :showOneChild="true"
      @update:collapsed="onToggleCollapse"
      @item-click="onItemClick"
    >
      <template v-slot:toggle-icon>
        <span>
          <i class="fa-solid fa-angles-left"></i>
        </span>
      </template>
    </sidebar-menu>
    <sidebar-menu
      v-if="self.windowWidth < 767"
      :menu="self.menu_items"
      theme="white-theme"
      width="65px"
      widthCollapsed="65px"
      :collapsed="true"
      :showOneChild="true"
      @update:collapsed="onToggleCollapse"
      @item-click="onItemClick"
    >
      <template v-slot:toggle-icon>
        <!-- <span>
          <i class="fa-solid fa-angles-left"></i>
        </span> -->
      </template>
    </sidebar-menu>
  </div>
</template>

<script lang="ts">
import {
  reactive,
  onMounted,
  getCurrentInstance,
  defineComponent,
} from "vue";
import SidebarListingService from "@/modules/system/services/sidebar/sidebar.loader";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
export default defineComponent({
  name: "AppSidebar",
  emits: ["change-sidebar-value"],
  setup(props, { emit }) {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      sidebar_listing: new SidebarListingService(),
      properties: appContext.config.globalProperties,
      collapse_sidebar: false as boolean,
      windowWidth: window.innerWidth,
      navigation: [],
      allowed_navigation: ["share"] as any,
      menu_items: [],
      loading_items: true,
      user_preference: {} as any,
      routes: appContext.config.globalProperties.$router.getRoutes(),
    });
    onMounted(() => {
      setTimeout(() => {
        getNavigation();
      }, 1000);
      window.addEventListener("resize", onResize);
    });

    function onResize() {
      self.windowWidth = window.innerHeight;
    }

    async function getNavigation() {
      appContext.config.globalProperties.app_service.getData(
        "navigations",
        null,
        (response: any) => {
          if (response) {
            self.navigation = response;
            self.navigation.forEach((nav: any) => {
              if (nav.permission.read == 1 || nav.permission.manage == 1) {
                self.allowed_navigation.push(nav.resource_slug);
              }
            });
            self.properties.$store.commit(
              "system/setNavigationsPermission",
              self.navigation
            );
            getApplicationMenu();
          }
        },
        false
      );
    }

    function getApplicationMenu() {
      self.loading_items = true;
      self.sidebar_listing.loadMenu((response: any) => {
        const data = response.map((res: any) => {
          return {
            ...res,
            href: getMenuLink(res),
            child: getMenuChild(res.child),
          };
        });
        const filtered_data = data.filter(
          (d: any) =>
            self.allowed_navigation.includes(d.value) ||
            d.value == "dashboard" ||
            isChildAllowed(d)
        );
        self.menu_items = filtered_data;
        setTimeout(() => {
          self.user_preference =
            appContext.config.globalProperties.$store.getters[
              "system/getUserPreference"
            ];
          self.collapse_sidebar = !self.user_preference.menu_open;
          emit("change-sidebar-value", self.collapse_sidebar);
          self.loading_items = false;
        }, 1000);
      });
    }

    function getMenuChild(child: any) {
      if (child) {
        const data = child.map((ch: any) => {
          return {
            ...ch,
            href: getMenuLink(ch),
            child: getMenuChild(ch.child),
          };
        });
        const filtered_data = data.filter(
          (d: any) =>
            self.allowed_navigation.includes(d.value) || isChildAllowed(d)
        );
        return filtered_data;
      } else {
        return [];
      }
    }

    function isChildAllowed(item: any) {
      if (item.child) {
        const data = item.child.filter((d: any) =>
          self.allowed_navigation.includes(d.value)
        );
        return data.length ? true : false;
      } else {
        return false;
      }
    }

    function getMenuLink(menu: any) {
      if (menu.to) {
        const currentRoute = self.routes.filter(
          (route: any) => route.name == menu.to.name
        );
        return currentRoute[0].path;
      } else {
        return null;
      }
    }

    function onToggleCollapse(collapsed: any) {
      self.collapse_sidebar = collapsed;
      updateUserPreference();
      emit("change-sidebar-value", collapsed);
    }

    function onItemClick(event: any, item: any) {
      if (item.to) {
        appContext.config.globalProperties.$router.push(item.to);
      }
    }

    function updateUserPreference() {
      appContext.config.globalProperties.app_service.updateUserPreference(
        appContext.config.globalProperties,
        "menu_open",
        !self.collapse_sidebar,
        () => {
          return;
        }
      );
    }

    return {
      appContext,
      self,
      getNavigation,
      getApplicationMenu,
      onToggleCollapse,
      onItemClick,
      updateUserPreference,
    };
  },
});
</script>
