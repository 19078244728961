<template>
  <div>
    <AppToolbar
      permission="application_activity"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      :queue="{
        view: true,
        text: `Total Queue: ${self.pagination.total_records} | Pending Queue Items: ${self.pagination.pending_queue} | Processing Queue Items: ${self.pagination.processing_queue}`,
      }"
      @reload-data="reloadAllData"
    />
    <div class="the-content">
      <TableView
        id="full-text-table"
        :loading="self.loading"
        :items="self.queue"
        :disable_select="true"
        :fields="self.table_fields"
        :pagination="self.pagination"
        :expandedRows="self.expandedRows"
        :actions="self.action_buttons"
        @load-more-data="getQueueListing"
      >
        <template #expanded-col="{ slotProps }">
          <div class="json-data">
            <pre>{{ getFormattedJSON(slotProps.data.reference) }}</pre>
          </div>
        </template>
        <template #action-buttons="slotProps">
          <ul @click.stop class="action-btn">
            <li
              v-for="action in self.action_buttons"
              :key="action.type"
              v-tooltip.bottom="
                typeof action.type == 'string'
                  ? action.type
                  : action.type(slotProps)
              "
              @click.stop="action.command(slotProps.data)"
            >
              <i v-if="typeof action.icon == 'string'" :class="action.icon"></i>
              <span v-else v-html="action.icon(slotProps)" />
            </li>
          </ul>
        </template>
      </TableView>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  getCurrentInstance,
  capitalize,
} from "vue";
import { useConfirm } from "primevue/useconfirm";
export default defineComponent({
  name: "QueueMonitoring",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      api_url: "_queue",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fa-solid fa-book mr-1",
        label: "Queue",
        to: { name: "queueMonitior" },
      },
      json_options: {},
      expandedRows: [] as any,
      toolbarOptions: {},
      loading: false,
      queue: [] as any,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 0,
        current_page: 1,
        total_page: 1,
        pending_queue: 0,
        processing_queue: 0,
      },
      table_fields: [
        {
          key: "queue_id",
          label: "Queue ID",
          sortable: true,
          style: "width:15%",
        },
        {
          key: "queue_type",
          label: "Queue Type",
          sortable: true,
          style: "width:15%",
        },
        {
          key: "reason",
          label: "Reason",
          sortable: true,
          style: "width:20%",
        },
        {
          key: "failed_count",
          label: "Failed Count",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          format: (val: any) => {
            return capitalize(val);
          },
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$dateTimeToLocal(val);
          },
        },
        {
          key: "updated_at",
          label: "Updated At",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$dateTimeToLocal(val);
          },
        },
      ],
      action_buttons: [
        {
          type: (row: any) => {
            const type: any = self.expandedRows.length
              ? self.expandedRows[0].id == row.data.id
                ? "Hide"
                : "View"
              : "View";
            return `${type} Reference`;
          },
          icon: (row: any) => {
            const icon: any = self.expandedRows.length
              ? self.expandedRows[0].id == row.data.id
                ? '<i class="fa-solid fa-eye-slash"></i>'
                : '<i class="icofont icofont-eye-alt"></i>'
              : '<i class="icofont icofont-eye-alt"></i>';
            return icon;
          },
          command: (val: any) => {
            viewQueueRefrence(val);
          },
          permission: "read",
        },
      ],
    });
    onMounted(() => {
      getQueueListing();
    });

    function reloadAllData() {
      getQueueListing(true);
    }

    function getQueueListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.queue = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.queue.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    function viewQueueRefrence(slot: any) {
      if (self.expandedRows.length) {
        if (slot.id == self.expandedRows[0].id) {
          self.expandedRows = [];
          return;
        }
      }
      self.expandedRows = self.queue.filter((q: any) => q.id == slot.id);
    }

    function getFormattedJSON(data: any) {
      return JSON.stringify(data, null, 2);
    }

    return {
      self,
      getQueueListing,
      reloadAllData,
      getFormattedJSON,
    };
  },
});
</script>
