<template>
  <div class="grid">
    <div class="col-3">
      <TabView class="user-detail" v-model:activeIndex="self.active" >
        <TabPanel v-for="(tab, index) in self.profile_tabs" :key="index">
          <template #header>
            <h3>{{ tab.name }}</h3>
            {{ tab.description }}
          </template>
        </TabPanel>
      </TabView>
    </div>
    <div class="col-9">
      <div v-if="self.active == 0" class="profile-detail-data">
        <UserInfo
          :user_detail="user_detail"
          :summary_detail="summary_detail"
          @image-uploaded="$emit('image-uploaded', $event)"
        />
        <Card class="mt-3">
          <template #title> Update Your Profile information </template>
          <template #content>
            <DetailForm
              :user_detail="user_detail"
              @image-uploaded="$emit('image-uploaded', $event)"
              @updated-profile="$emit('updated-profile')"
            />
          </template>
        </Card>
      </div>
      <Card v-if="self.active == 1">
        <template #title> Update Your password </template>
        <template #content>
          <change-password @updated-profile="$emit('updated-profile')" />
        </template>
      </Card>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance } from "vue";
import DetailForm from "./userDetailForm.vue";
import UserInfo from "./userInfo.vue";
import ChangePassword from "./userPasswordForm.vue";
export default defineComponent({
  name: "ProfileDetailInfo",
  props: {
    user_detail: {
      type: Object,
    } as any,
    summary_detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    DetailForm,
    UserInfo,
    ChangePassword,
  },
  setup() {
    const appData: any = getCurrentInstance();
    const self = reactive({
      active: 0,
      profile_tabs: [
        {
          name: "Account Detail",
          description: "Detail about your Personal Information",
        },
        {
          name: "Password & Security",
          description: "Update your Password",
        },
      ],
    });
    return {
      appData,
      self,
    };
  },
});
</script>
