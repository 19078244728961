<template>
  <div id="sub-header" :class="{ 'w-full': is_public }">
    <Toolbar id="breadcrumb" class="py-1">
      <template #start>
        <div v-if="self.view_breadcrumb" class="flex flex-row flex-wrap">
          <Breadcrumb
            v-if="self.breadcrumbItem.length < 3"
            :home="breadcrumb_home"
            :model="self.breadcrumbItem"
          >
            <template #item="{ label, item, props }">
              <router-link v-if="item.to" :to="item.to">
                <span v-bind="props.action">
                  <span v-bind="props.icon" />
                  <span v-bind="props.label">{{ label }}</span>
                </span>
              </router-link>
              <a
                v-else
                :href="item.url"
                :target="item.target"
                v-bind="props.action"
              >
                <span v-if="item.icon" v-bind="props.icon" />
                <span v-bind="props.label">{{ label }}</span>
              </a>
            </template>
          </Breadcrumb>
          <div v-else class="flex flex-row flex-wrap">
            <Breadcrumb
              :home="breadcrumb_home"
              :model="self.breadcrumbItem.slice(0, 1)"
            >
              <template #item="{ label, item, props }">
                <router-link v-if="item.to" :to="item.to">
                  <span v-bind="props.action">
                    <span v-bind="props.icon" />
                    <span v-bind="props.label">{{ label }}</span>
                  </span>
                </router-link>
                <a
                  v-else
                  :href="item.url"
                  :target="item.target"
                  v-bind="props.action"
                >
                  <span v-if="item.icon" v-bind="props.icon" />
                  <span v-bind="props.label">{{ label }}</span>
                </a>
              </template>
            </Breadcrumb>
            <div class="flex align-items-center breadcrumb-data">
              <i class="pi pi-chevron-right px-2"></i>
              <Button
                type="button"
                text
                icon="pi pi-ellipsis-h"
                class="breadcrumb-overflow p-0 w-auto"
                @click="toggleBreadcrumbItem"
              />
              <OverlayPanel class="breadcrumb-panel" ref="breadcrumbItem">
                <Listbox
                  :options="
                    self.breadcrumbItem.slice(1, self.breadcrumbItem.length - 1)
                  "
                  class="w-full md:w-14rem"
                >
                  <template #option="slotProps">
                    <div class="w-100 h-100" @click="toggleBreadcrumbItem">
                      <router-link
                        v-if="slotProps.option.to"
                        :to="slotProps.option.to"
                      >
                        <div class="flex align-items-center">
                          <div>{{ slotProps.option.label }}</div>
                        </div>
                      </router-link>
                      <a
                        v-else
                        :href="slotProps.option.url"
                        :target="slotProps.option.target"
                      >
                        <span>{{ slotProps.option.label }}</span>
                      </a>
                    </div>
                  </template>
                </Listbox>
              </OverlayPanel>
              <div class="flex align-items-center">
                <i class="pi pi-chevron-right px-2"></i>
                <ul>
                  <li class="text-primary">
                    {{
                      self.breadcrumbItem[self.breadcrumbItem.length - 1].label
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #center>
        <div class="w-full" v-if="selected_items.length">
          <Button
            v-tooltip.bottom="'Clear Selection'"
            @click="$emit('update:selected_items', [])"
            :label="`${selected_items.length} item(s) Selected`"
            icon="icofont icofont-close"
            iconPos="right"
            text
          />
          <Button
            v-tooltip.bottom="'Download Selected Item(s)'"
            @click="$emit('download-item')"
            aria-label="Download"
            text
            icon="fas fa-download"
          />
        </div>
      </template>
      <template #end>
        <Button
          v-if="
            options.empty &&
            (self.permissions[permission]
              ? self.permissions[permission].edit == 1 ||
                self.permissions[permission].manage == 1
              : false)
          "
          @click="$emit('empty-trash')"
          label="Empty Trash"
          icon="icofont icofont-trash"
          text
        />
        <Button
          v-if="options.widget"
          @click="$emit('open-widget')"
          icon="fas fa-th-large mr-1"
          aria-label="Widget"
          label="Widget"
          class="widget-btn"
        />

        <span
          v-if="options.filter && (self.permissions[permission] ? self.permissions[permission].read == 1 || self.permissions[permission].manage == 1 : false)"
          class="p-input-icon-left pr-2"
        >
          <i class="icofont icofont-search-1" />
          <Field
            as="span"
            type="text"
            id="title"
            name="Title"
            rules="required"
            autocomplete="off"
            v-model="self.filter_value.query"
          >
            <InputText
              type="text"
              v-model="self.filter_value.query"
              :placeholder="search_placeholder"
              @keyup.enter="submitFilterData"
            />
          </Field>
          <Button
            v-if="self.filter_value.query"
            @click="self.filter_value.query = null"
            icon="fa-solid fa-times"
            aria-label="Clear Query"
            class="search-dropdown mr-5"
          />
          <Button
            @click="toggleSearch"
            icon="fa-solid fa-caret-down"
            aria-label="Search Dropdown"
            class="search-dropdown mr-1"
          />
        </span>
        <OverlayPanel
          ref="searchPanel"
          appendTo="body"
          id="search_panel"
          style="width: 500px"
          :breakpoints="{ '960px': '75vw' }"
        >
          <div class="close-icon">
            <Button
              icon="icofont icofont-close"
              @click.prevent="toggleSearch"
              text
              rounded
              class="text-900 bg-transparent"
            />
          </div>
          <div class="fluid">
            <div name="field" v-if="filter.status">
              <div class="field grid justify-content-between">
                <label for="field" class="col-3 mb-0">Status</label>
                <div class="col-9">
                  <div class="formgroup-inline checkbox-wrap">
                    <div
                      v-for="(status, key) in self.status_field"
                      :key="key"
                      class="flex align-items-center mr-2"
                    >
                      <Checkbox
                        @click="closeAndReOpenPanel"
                        v-model="self.filter_value.status"
                        :value="status.value"
                        :inputId="`${status.value}-${status.text}`"
                        :name="status.text"
                      />
                      <label
                        :for="`${status.value}-${status.text}`"
                        class="ml-2"
                      >
                        {{ status.text }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div name="field" v-if="filter.type">
              <div class="field grid justify-content-between">
                <label for="field" class="col-3 mb-0">Type</label>
                <div class="col-9">
                  <div class="formgroup-inline checkbox-wrap">
                    <div
                      class="field-checkbox"
                      v-for="(field, index) in self.typeOptions"
                      :key="index"
                    >
                      <Checkbox
                        @click="closeAndReOpenPanel"
                        v-model="self.filter_value.type"
                        :value="field.value"
                        :inputId="`${field.value}-${field.text}`"
                        :name="field.text"
                      />
                      <label :for="`${field.value}-${field.text}`" class="ml-0">
                        {{ field.text }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div name="field" v-if="filter.owner">
              <div class="field grid justify-content-between">
                <label for="owner" class="col-3 mb-0">Owner</label>
                <div class="col-9">
                  <Dropdown
                    inputId="owner"
                    v-model="self.filter_value.owner"
                    :options="self.filterOwnerOptions"
                    optionLabel="text"
                    optionValue="value"
                    placeholder="Select a Owner Type"
                  />
                </div>
              </div>
            </div>
            <div name="field" v-if="filter.role">
              <div class="field grid justify-content-between">
                <label for="role" class="col-3 mb-0">Role</label>
                <div class="col-9">
                  <Dropdown
                    inputId="role"
                    v-model="self.filter_value.role"
                    :options="roles"
                    optionLabel="name"
                    optionValue="slug"
                    placeholder="Select a Role"
                  />
                </div>
              </div>
            </div>
            <div name="field" v-if="filter.date">
              <div class="field grid justify-content-between">
                <label class="col-3 mb-0">Created Date</label>
                <div class="col-9 grid justify-content-between">
                  <div class="col-6 field grid mb-0 p-0">
                    <label class="col-4 mb-0 p-0">From</label>
                    <div class="col-8 p-0">
                      <Calendar
                        @focus="closeAndReOpenPanel"
                        @keydown="closeAndReOpenPanel"
                        id="date-from"
                        v-model="self.filter_value.start_date"
                        dateFormat="yy-mm-dd"
                      />
                    </div>
                  </div>
                  <div class="col-6 field grid mb-0 p-0">
                    <label class="col-2 mb-0 p-0">To</label>
                    <div class="col-10 pl-0">
                      <Calendar
                        @focus="closeAndReOpenPanel"
                        @keydown="closeAndReOpenPanel"
                        id="date-to"
                        v-model="self.filter_value.end_date"
                        dateFormat="yy-mm-dd"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex align-items-center justify-content-end mt-2">
              <Button
                label="Reset"
                @click="resetFilterOptions"
                class="mr-2"
                severity="danger"
              />
              <Button
                label="Search"
                class="button-primary"
                @click="submitFilterData"
              />
            </div>
          </div>
        </OverlayPanel>
        <Button
          class="activities-btn mr-1"
          v-tooltip.bottom="
            view_activities ? 'Hide Activities' : 'View Activities'
          "
          v-if="
            options.activities &&
            (self.permissions[permission]
              ? self.permissions[permission].read == 1 ||
                self.permissions[permission].manage == 1
              : false)
          "
          @click="toggleActivities"
        >
          <span class="switch-title pr-2">Activities</span>
          <InputSwitch disabled :modelValue="view_activities" />
        </Button>
        <Button
          v-if="
            options.refresh &&
            (self.permissions[permission]
              ? self.permissions[permission].read == 1 ||
                self.permissions[permission].manage == 1
              : false)
          "
          v-tooltip.bottom="'Refresh'"
          class="mr-1"
          :class="{
            'mr-5': options.add && options.add_dropdown && !options.view_data,
          }"
          :loading="loading"
          loadingIcon="fas fa-sync load-icon"
          icon="fas fa-sync load-icon"
          @click="$emit('reload-data')"
        />
         
          <Button
            v-if="!is_public && options.view_sort"
            v-tooltip.bottom="'Sorting'"
            type="button"
            aria-label="Sorting"
            @click="toggleSorting"
            aria-haspopup="true"
            aria-controls="view-sorting"
            icon="fa-solid fa-arrow-up-z-a"
          />
          <OverlayPanel
            v-if="!is_public"
            ref="viewSorting"
            appendTo="body"
            :showCloseIcon="false"
            class="view-sorting w-17rem"
          >
            <Listbox
              :options="self.sorting_data"
              optionLabel="label"
              optionValue="value"
              :model-value="current_sort"
            >
              <template #option="slotProps">
                <div
                  @click.stop="updateSorting($event, slotProps.option.value)"
                  class="flex align-items-center"
                >
                  <div>{{ slotProps.option.label }}</div>
                </div>
              </template>
            </Listbox>
          </OverlayPanel>
          <Button
            v-if="options.view_data"
            v-tooltip.bottom="'Show View'"
            type="button"
            aria-label="Show View"
            @click="toggleView"
            aria-haspopup="true"
            aria-controls="view-menu"
            icon="fas fa-bars"
          />
          <OverlayPanel
            ref="viewMenu"
            appendTo="body"
            :showCloseIcon="false"
            class="view-menu w-10rem"
          >
            <Listbox
              :options="self.view_data"
              optionLabel="label"
              optionValue="value"
              :model-value="current_view"
            >
              <template #option="slotProps">
                <div
                  class="flex align-items-center"
                  @click.stop="updateView($event, slotProps.option.value)"
                >
                  <i :class="`${slotProps.option.icon} mr-1`"></i>
                  <div>{{ slotProps.option.label }}</div>
                </div>
              </template>
            </Listbox>
          </OverlayPanel> 
        <Button
          v-if="
            options.add &&
            (self.permissions[permission]
              ? self.permissions[permission].write == 1 ||
                self.permissions[permission].manage == 1
              : false)
          "
          v-tooltip.bottom="'Add'"
          :icon="add_icon"
          @click="
            !options.add_dropdown
              ? $emit('create-new', true)
              : toggleAdd($event)
          "
        />
        <OverlayPanel
          ref="speedDial"
          appendTo="body"
          :showCloseIcon="false"
          class="view-menu w-16rem"
        >
          <Listbox
            :options="add_options"
            optionLabel="label"
            optionValue="value"
          >
            <template #option="slotProps">
              <div
                @click.stop="
                  $emit('create-new', slotProps.option.value);
                  toggleAdd($event);
                "
                class="flex align-items-center"
              >
                <i :class="`${slotProps.option.icon} mr-1`"></i>
                <div>{{ slotProps.option.label }}</div>
              </div>
            </template>
          </Listbox>
        </OverlayPanel>
        <div
          v-if="
            queue.view &&
            (self.permissions[permission]
              ? self.permissions[permission].read == 1 ||
                self.permissions[permission].manage == 1
              : false)
          "
          class="mr-3 text-primary"
        >
          {{ queue.text }}
        </div>
      </template>
    </Toolbar>
    <AppProgressBar v-if="!hideProgress" :loading="loading" />
  </div>
</template>

<script lang="ts">
import ToolbarService from "./toolbar";
import AppProgressBar from "../progress_bar/loading.indicator.vue";
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
} from "vue";
// eslint-disable-next-line vue/no-mutating-props
export default defineComponent({
  name: "AppToolbar",
  components: {
    AppProgressBar,
  },
  props: {
    breadcrumb_home: {
      type: Object,
      default: () => {
        return {};
      },
    },
    breadcrumb_items: {
      type: Array,
      default: () => [] as any,
    } as any,
    add_icon: {
      type: String,
      default: "fas fa-plus",
    },
    options: {
      type: Object,
      default: () => {
        return {
          widgets: false,
          filter: false,
          activities: false,
          refresh: false,
          view_data: false,
          add: false,
          add_dropdown: false,
          view_sort:false
        };
      },
    },
    current_view: {
      type: String,
      default: "list",
    },
    current_sort: {
      type: String,
      default: "A_Z",
    },
    view_options: {
      type: Array,
      default: () => ["list", "grid"],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {
        return {
          role: false,
          status: true,
          date: true,
          type: false,
          owner: false,
        };
      },
    },
    roles: {
      type: Array,
      default: () => [] as any,
    },
    add_options: {
      type: Array,
      default: () => [] as any,
    },
    type_options: {
      type: Array,
      default: () => ["privacy_policy", "terms_of_use"],
    },
    status_fields: {
      type: Array,
      default: () => ["active", "trash"] as any,
    },
    trash_options: {
      type: Array,
      default: () => [] as any,
    },
    search_placeholder: {
      type: String,
      default: "Search",
    },
    view_activities: {
      type: Boolean,
      default: false,
    },
    selected_items: {
      type: Array,
      default: () => [] as any,
    } as any,
    is_public: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: String,
    } as any,
    suggestions: {
      type: Object,
      default: () => {
        return {
          data: [] as any,
          loading: false,
          page: 1,
          pagination: {
            current_page: 1,
            items_per_page: 0,
            total_page: 0,
            total_records: 0,
          },
        };
      },
    },
    hideProgress: {
      type: Boolean,
      default: false,
    },
    queue: {
      type: Object,
      default: () => {
        return {
          view: true,
          text: "",
        };
      },
    },
  },
  emits: [
    "create-new",
    "open-widget",
    "submit-filter-data",
    "reload-data",
    "view-activities",
    "download-item",
    "update:current_view",
    "update:current_sort",
    "update:view_activities",
    "update:selected_items",
    "empty-trash",
    "get-suggestions",
  ],
  setup(props: any, { emit }) {
    const appData: any = getCurrentInstance();
    const properties = appData.appContext.config.globalProperties;
    const viewMenu = ref();
    const viewSorting = ref();
    const addMenu = ref();
    const speedDial = ref();
    const searchPanel = ref();
    const breadcrumbItem = ref();
    const self = reactive({
      view_breadcrumb: true,
      status_field: [
        { text: "Active", value: "active" },
        { text: "Trash", value: "trash" },
        { text: "Suspended", value: "suspended" },
      ] as any,
      typeOptions: [
        { text: "Privacy Policy", value: "privacy_policy" },
        { text: "Terms & Conditions", value: "terms_of_use" },
        { text: "Project", value: "project" },
        { text: "Folder", value: "folder" },
        { text: "File", value: "file" },
      ],
      filterOwnerOptions: [
        { value: "me", text: "Owned by me" },
        { value: "not_me", text: "Not owned by me" },
        { value: "all", text: "Any one" },
      ],
      trashOptions: [
        {
          text: "",
          value: "",
        },
      ],
      view_data: [
        {
          label: "Grid",
          value: "grid",
          icon: "fas fa-th-large",
        },
        {
          label: "List",
          value: "list",
          icon: "fa fa-th-list",
        },
      ],
      sorting_data: [
        {
          label: "A-Z",
          value: "A_Z",
        },
        {
          label: "Z-A",
          value: "Z_A",
        },
        {
          label: "Created Asc",
          value: "CREATED_ASC",
        },
        {
          label: "Created Desc",
          value: "CREATED_DESC",
        },
        {
          label: "Modified Asc",
          value: "MODIFIED_ASC",
        },
        {
          label: "Modified Desc",
          value: "MODIFIED_DESC",
        },
        {
          label: "Document Type",
          value: "DOCUMENT_TYPE",
        },
      ],
      toolbarService: new ToolbarService(),
      filter_value: {} as any,
      loading_percentage: 0,
      permissions: {} as any,
      breadcrumbItem: [],
    });
    watch(
      () => props.breadcrumb_items,
      () => {
        updateBreadcrumbData();
      }
    );
    onMounted(() => {
      getUserPermission();
      filterViewData();
      resetFilterOptions();
      self.typeOptions = self.typeOptions.filter((option: any) => props.type_options.includes(option.value));
      self.trashOptions = self.trashOptions.filter((option: any) => props.trash_options.includes(option.value));
    });

    function updateBreadcrumbData() {
      self.view_breadcrumb = false;
      self.breadcrumbItem = new Array(...props.breadcrumb_items);
      self.view_breadcrumb = true;
    }

    function getUserPermission() {
      self.permissions = properties.$store.getters["system/getNavigationsPermission"] as any;
    }

    function filterViewData() {
      self.view_data = self.view_data.filter((data: any) => props.view_options.includes(data.value) );
      self.status_field = self.status_field.filter((data: any) => props.status_fields.includes(data.value) );
    }

    function resetFilterOptions(event: any = null) {
      self.toolbarService.resetFilter(props.filter, (response: any) => {
        self.filter_value = response;
        if (event) closeAndReOpenPanel(event);
      });
    }

    function toggleSearch(e: Event) {
      searchPanel.value.toggle(e);
    }

    function closeAndReOpenPanel(e: Event) {
      searchPanel.value.hide();
      searchPanel.value.show(e);
    }

    async function submitFilterData() {
      if (!self.filter_value.query) {
        return properties.$toastMessage({
          status: 400,
          data: {
            message: "Please Enter a text to search.",
          },
        });
      }
      if (self.filter_value.start_date) {
        self.filter_value.start_date = properties.$YMD(self.filter_value.start_date);
      } else {
        delete self.filter_value.start_date;
      }
      if (self.filter_value.end_date) {
        self.filter_value.end_date = properties.$YMD(self.filter_value.end_date);
      } else {
        delete self.filter_value.end_date;
      }
      emit("submit-filter-data", self.filter_value, true);
      searchPanel.value.hide();
    }

    function toggleActivities() {
      emit("update:view_activities", !props.view_activities);
    }

    function toggleAdd(e: any) {
      speedDial.value.toggle(e);
    }

    function toggleView(e: any) {
      viewMenu.value.toggle(e);
    }

    function toggleSorting(e: any) {
      viewSorting.value.toggle(e);
    }

    function updateView(e: any, value: any) {
      if (!props.is_public) {
        properties.app_service.updateUserPreference(
          properties,
          "view",
          value,
          () => {
            return;
          }
        );
      }
      emit("update:current_view", value);
      toggleView(e);
    }

    function updateSorting(e: any, value: any) {
      if (!props.is_public) {
        properties.app_service.updateUserPreference(
          properties,
          "sorting",
          value,
          () => {
            return;
          }
        );
      }
      emit("update:current_sort", value);
      toggleSorting(e);
    }

    function getSuggestions(e: any) {
      emit("get-suggestions", e.query, true);
    }

    function toggleBreadcrumbItem(e: any) {
      breadcrumbItem.value.toggle(e);
    }

    return {
      appData,
      properties,
      self,
      viewMenu,
      viewSorting,
      addMenu,
      speedDial,
      searchPanel,
      breadcrumbItem,
      filterViewData,
      toggleView,
      toggleSorting,
      resetFilterOptions,
      toggleSearch,
      closeAndReOpenPanel,
      submitFilterData,
      toggleActivities,
      toggleAdd,
      updateView,
      updateSorting,
      getSuggestions,
      toggleBreadcrumbItem,
    };
  },
});
</script>