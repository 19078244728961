import appLayout from "@/configs/app";
const routes = [
  {
    path: "/shared",
    name: "sharedVault",
    component: appLayout.layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "by-me",
        name: "sharedByMe",
        component: appLayout.shared.by_me,
        meta: {
          title: "Shared By Me",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "share",
          },
        },
      },
      {
        path: "by-me/:doc_type/:doc_id",
        name: "sharedByMeDetail",
        component: appLayout.shared.by_me,
        meta: {
          title: "Shared By Me",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "my_vault",
          },
        },
      },
      {
        path: "with-me",
        name: "sharedWithMe",
        component: appLayout.shared.with_me,
        meta: {
          title: "Shared With Me",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "share",
          },
        },
      },
      {
        path: "with-me/:doc_type/:doc_id",
        name: "sharedWithMeDetail",
        component: appLayout.shared.with_me,
        meta: {
          title: "Shared With Me",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "my_vault",
          },
        },
      },
    ],
  },
];

export default routes;