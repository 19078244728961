import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "page" }
const _hoisted_3 = { class: "body-content" }
const _hoisted_4 = { class: "flex flex-wrap align-items-center justify-content-center h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Card, { class: "border-round w-3 mx-auto shadow-5 text-center p-6" }, {
            header: _withCtx(() => [
              _createVNode(_component_Image, {
                src: _ctx.self.logo
              }, null, 8, ["src"])
            ]),
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.self.data.title), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_component_Message, {
                class: "text-left",
                closable: false,
                severity: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.self.data.message), 1)
                ]),
                _: 1
              })
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.$keycloak.login())),
                label: "Login",
                class: "button-primary block w-full uppercase p-3 text-lg"
              })
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}