import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "sub-header" }
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = { class: "the-content" }
const _hoisted_4 = { class: "grid m-0" }
const _hoisted_5 = {
  key: 1,
  class: "text-center my-4"
}

import {
  getCurrentInstance,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from "vue"; 
import type { Ref } from "vue"; 

export default /*@__PURE__*/_defineComponent({
  __name: 'dashboardIndex',
  setup(__props) {

const { appContext }:any = getCurrentInstance(); 
const AppProgressBar = defineAsyncComponent(
  () => import("@/components/common/progress_bar/loading.indicator.vue")
);
const WidgetsDisplay = defineAsyncComponent(
  () => import("./dashboard/widgetDisplay.vue")
);
const WidgetsLists = defineAsyncComponent(
  () => import("./dashboard/widgetList.vue")
);
const breadcrumbItems = reactive({
  icon: "fas fa-home mr-1",
  label: "Dashboard",
  to: { name: "dashboard" },
}); 
const store = appContext.config.globalProperties.$store;
const view_widget_sidebar: Ref<boolean> = ref(false);
const sidebar_refresh: Ref<boolean> = ref(false);
const widgetListData = ref();
const view_widgets: Ref<boolean> = ref(false);
let widget_lists = reactive([]);
let dw_id:any = reactive([]);
let summary = reactive({});
let dragged_widgets = reactive([]);
let un_dragged_widgets = reactive([]);
let userData: any = reactive({});
onMounted(() => {
  getSummary();
});

function toggleWidgetSidebar() {
  view_widget_sidebar.value = !view_widget_sidebar.value;
}
async function getAllWidgets() {
  await appContext.config.globalProperties.app_service.getData(
    "widgets",
    null,
    (response: any, error: any) => {
      if (response) {
        widget_lists = response.data;
        filterWidgets();
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    },
    false
  );
}
async function getSummary() {
  await appContext.config.globalProperties.app_service.getData(
    "summary",
    null,
    (response: any) => {
      if (response) {
        summary = response;
        userData = store.getters["setting/getUserDetail"];
        getAllWidgets();
      }
    },
    false
  );
}

function filterWidgets() {
  view_widgets.value = false;
  if (userData.preferences) {
    dragged_widgets = userData.preferences.widgets
      ? appContext.config.globalProperties.app_service.parseJSON(
          userData.preferences.widgets,
          (data: any) => {
            return data;
          }
        )
      : [];
  }
  updateWidgets();
  const my_widgets = [] as any;
  widget_lists.forEach((widget: any) => {
    if (!dw_id.includes(widget.id)) {
      my_widgets.push(widget);
    }
  });
  un_dragged_widgets = my_widgets;
  view_widgets.value = true;
}

function removeDragged(el: any) {
  dragged_widgets = dragged_widgets.filter((widget: any) => el.id != widget.id);
  userData.preferences.widgets = dragged_widgets;
  updateWidgets();
  updatePreference();
}

function updateWidgets() {
  dw_id = dragged_widgets.map((dw: any) => { return dw.id; });
  sidebar_refresh.value = true;
  setTimeout(()=>{
    sidebar_refresh.value = false;
  },50)
}

function updatePreference() {
  appContext.config.globalProperties.app_service.updateUserPreference(
    appContext.config.globalProperties,
    "widgets",
    dragged_widgets,
    (response: any) => {
      if (response) {
        userData.preferences.widgets = dragged_widgets;
        filterWidgets();
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Toolbar, {
        id: "breadcrumb",
        class: "py-1 main-toolbar"
      }, {
        start: _withCtx(() => [
          _createVNode(_component_Breadcrumb, { home: breadcrumbItems }, {
            item: _withCtx(({ label, item, props }) => [
              (item.to)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: item.to
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.action)), [
                        _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                        _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                      ], 16)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock("a", _mergeProps({
                    key: 1,
                    href: item.url,
                    target: item.target
                  }, props.action), [
                    (item.icon)
                      ? (_openBlock(), _createElementBlock("span", _normalizeProps(_mergeProps({ key: 0 }, props.icon)), null, 16))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                  ], 16, _hoisted_2))
            ]),
            _: 1
          }, 8, ["home"])
        ]),
        end: _withCtx(() => [
          _createVNode(_component_Button, {
            onClick: toggleWidgetSidebar,
            icon: "fas fa-th-large mr-1",
            "aria-label": "Widget",
            label: "Widget",
            class: "widget-btn"
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(AppProgressBar), {
        loading: !view_widgets.value
      }, null, 8, ["loading"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["col width-transaction", [view_widget_sidebar.value ? 'col-9' : 'col-12']])
        }, [
          (view_widgets.value)
            ? (_openBlock(), _createBlock(_unref(WidgetsDisplay), {
                key: 0,
                widgets: _unref(dragged_widgets),
                summary: _unref(summary),
                "onUpdate:updateWidgets": removeDragged,
                onUpdateList: updatePreference
              }, null, 8, ["widgets", "summary"]))
            : _createCommentVNode("", true),
          (!view_widgets.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ProgressSpinner, {
                  style: {"width":"20px","height":"20px"},
                  strokeWidth: "5",
                  animationDuration: ".5s",
                  "aria-label": "Loading"
                })
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_Sidebar, {
          visible: view_widget_sidebar.value,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((view_widget_sidebar).value = $event)),
          position: "right",
          dismissable: false,
          modal: false
        }, {
          header: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Widgets ")
          ])),
          default: _withCtx(() => [
            _createVNode(_unref(WidgetsLists), {
              ref_key: "widgetListData",
              ref: widgetListData,
              widgets: _unref(un_dragged_widgets),
              sidebar_refresh: sidebar_refresh.value,
              viewSidebar: view_widget_sidebar.value,
              "onUpdate:viewSidebar": _cache[0] || (_cache[0] = ($event: any) => ((view_widget_sidebar).value = $event)),
              onUpdateList: updatePreference
            }, null, 8, ["widgets", "sidebar_refresh", "viewSidebar"])
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ])
  ]))
}
}

})