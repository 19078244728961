import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "page" }
const _hoisted_3 = {
  key: 0,
  class: "body-wrap"
}
const _hoisted_4 = {
  key: 0,
  class: "flex justify-content-center flex-wrap page-loading w-screen h-screen"
}
const _hoisted_5 = { class: "flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppHeader, { onUserLoaded: _ctx.userLoaded }, null, 8, ["onUserLoaded"]),
      _createElementVNode("div", {
        class: _normalizeClass(["body-content", { 'sidebar-collapsed': _ctx.self.sidebar_collapsed }])
      }, [
        _createVNode(_component_AppSidebar, { onChangeSidebarValue: _ctx.sidebarCollapsed }, null, 8, ["onChangeSidebarValue"]),
        (_ctx.self.user_loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_router_view)
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], 512), [
      [_vShow, _ctx.self.user_loaded]
    ]),
    (!_ctx.self.user_loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ProgressSpinner, {
              strokeWidth: "3",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}