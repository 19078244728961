<template>
  <div class="p-2">
    <div class="activities-wrapper">
      <div id="activites-view" class="activities-view">
        <Card
          v-for="(item, index) in activities"
          :key="index"
          class="p-0 border-bottom-1 cursor-pointer border-noround"
          @click="
            item.action == 'trashed' || item.action == 'deleted'
              ? null
              : item.reference
              ? $emit('view-activities-detail', item)
              : null
          "
        >
          <template #content>
            <div class="flex">
              <div
                class="flex-initial flex align-items-top justify-content-center w-1"
              >
                <span
                  class="bg-primary border-circle pt-1 h-2rem w-2rem text-center"
                  v-html="
                    item.reference
                      ? properties.$getIcon({
                          type: item.reference.reference_object,
                        })
                      : ''
                  "
                ></span>
              </div>
              <div
                class="flex-initial flex align-items-top justify-content-start min-w-full grid pl-2"
              >
                <span class="col-11">
                  <span
                    class="block font-medium text-900 white-space-nowrap overflow-hidden text-overflow-ellipsis"
                  >
                    {{ item.message }}
                  </span>
                  <small>{{ properties.$duration(item.created_at) }}</small>
                </span>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="mt-6">
        <infinite-loader
          target="#activites-view"
          id="activities-scroll"
          relative
          @loadMore="$emit('load-more-data')"
        />
      </div>
      <app-error-message
        v-if="!loading && !activities.length"
        page_type="activities"
        page_icon="fa-solid fa-chart-line"
        hide_button
      />
      <div v-if="loading" class="text-center my-4">
        <progress-spinner
          style="width: 20px; height: 20px"
          strokeWidth="5"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  onBeforeUnmount,
} from "vue";
export default defineComponent({
  name: "ActivitiesList",
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    activities: {
      type: Array,
      default: () => [] as any,
    } as any,
    pending_activities: {
      type: Array,
      default: () => [] as any,
    } as any,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "load-more-data",
    "view-activities-detail",
    "cancel-upload",
    "remove-canceled-upload",
  ],
  setup(props, { emit }) {
    const self = getCurrentInstance() as any;
    const properties = self.appContext.config.globalProperties;
    const userOnline = ref(true);
    onMounted(() => {
      emit("load-more-data");
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    });

    function activityCanceled(activity: any) {
      if (activity.err) {
        return (activity.done = false);
      } else if (activity.canceled) {
        emit("remove-canceled-upload", activity);
      } else {
        activity.source.cancel("Operation Cancelled by user.");
        if (activity.message) {
          activity.done = true;
        }
        activity.progress = 0;
        activity.message = "Cancelled by user.";
        activity.canceled = true;
        emit("cancel-upload", activity);
      }
    }
    function updateOnlineStatus(e: any) {
      const { type } = e;
      userOnline.value = type === "online";
    }

    return {
      self,
      properties,
      userOnline,
      activityCanceled,
    };
  },
});
</script>