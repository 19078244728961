import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-2 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_TableView = _resolveComponent("TableView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        class: _normalizeClass(["button-primary", {'cursor-ban':!_ctx.self.selected_projects.length}]),
        disabled: !_ctx.self.selected_projects.length,
        onClick: _ctx.revokeAllShare,
        label: "Revoke"
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _createVNode(_component_TableView, {
      permission_type: "user",
      selected_items: _ctx.self.selected_projects,
      "onUpdate:selected_items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.selected_projects) = $event)),
      selected_item: _ctx.self.selected_project,
      "onUpdate:selected_item": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.selected_project) = $event)),
      loading: _ctx.self.loading,
      items: _ctx.self.projects,
      fields: _ctx.self.table_fields,
      actions: _ctx.self.action_buttons,
      pagination: _ctx.self.pagination,
      onLoadMoreData: _ctx.getUserProjects
    }, null, 8, ["selected_items", "selected_item", "loading", "items", "fields", "actions", "pagination", "onLoadMoreData"])
  ]))
}