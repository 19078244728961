import appLayout from "@/configs/app";
const routes = [
  {
    path: "/dashboard",
    name: "reportsDashboard",
    component: appLayout.layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "docs/reports",
        name: "reportsIndex",
        component: appLayout.report.index,
        meta: {
          title: "Reports",
          requiresAuth: true,
          faq: {
            view: true,
            slug: "reports",
          },
        },
      },
    ],
  },
];

export default routes;