import store from './store';
import routes from './router';
 
export default {
	name: "Report Module",
	id: "report-module",
    type:"report",
	description: "Manages Report",
	store: store,
	routes: routes
}