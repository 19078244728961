<template>
  <div>
    <template v-if="self.loaded_user && self.groups.length">
      <AppToolbar
        class="relative w-full px-0"
        :options="self.toolbarOptions"
        :loading="!self.loaded_user"
        @reload-data="reloadAllData"
        @create-new="self.create_new = true"
        permission="user_groups"
        hideProgress
      />
      <div class="the-content bg-transparent p-0 relative top-0">
        <TableView
          permission_type="user_groups"
          :loading="self.loading"
          :items="self.groups"
          :fields="self.table_fields"
          :actions="self.action_buttons"
          :pagination="self.pagination"
          @load-more-data="getGroupListing"
        />
      </div>
    </template>
    <template v-if="self.loaded_user && !self.groups.length">
      <AppErrorMessage
        page_type="users in group"
        page_icon="fa fa-users"
        @add-new="self.create_new = true"
      />
    </template>
    <Sidebar
      id="sidebar-form"
      v-model:visible="self.edit_group"
      position="right"
    >
      <template #header> Edit User in Group</template>
      <EditGroupUser
        :group="group"
        :users="users"
        :user="self.selected_group_user"
        @updated-group-user="reloadAllData"
        @search-users="$emit('search-users', $event)"
      />
    </Sidebar>

    <Sidebar
      id="sidebar-form"
      v-model:visible="self.create_new"
      position="right"
    >
      <template #header> Add User in Group </template>
      <AddGroupUser
        :group="group"
        :users="users"
        @created-user="reloadAllData"
        @search-users="$emit('search-users', $event)"
      />
    </Sidebar>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  defineAsyncComponent,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import UserGroupEditService from "@/modules/settings/services/user-groups/userGroupEdit";
const EditGroupUser = defineAsyncComponent(() => import("./editGroupUser.vue"));
const AddGroupUser = defineAsyncComponent(
  () => import("./createGroupUser.vue")
);
const props:any = defineProps({
  group: {
    type: Object,
  },
  users: {
    type: Object,
  },
});
const emit = defineEmits(["search-users", "update-user-data"]);
const { appContext }: any = getCurrentInstance();
const self = reactive({
  api_url: `user-groups/${props.group.id}/users`,
  user_group_edit_service: new UserGroupEditService(),
  confirm: useConfirm(),
  store: useStore(),
  toolbarOptions: {
    filter: false,
    refresh: false,
    add: true,
  },
  groups: [],
  loading: false,
  loaded_user: false,
  page: 1,
  pagination: {
    per_page: 15,
    total_records: 30,
    current_page: 1,
    total_page: 1,
  },
  table_fields: [
    {
      key: "user",
      label: "Name",
      sortable: true,
      style: "width:190px",
      format: (val: any) => {
        return val.name ? val.name : val;
      },
    },
    {
      key: "permission",
      label: "Read",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.read == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Write",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.write == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Edit",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.edit == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Delete",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.delete == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Manage",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.manage == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
  ],
  selected_group_user: {} as any,
  create_new: false,
  edit_group: false,
  action_buttons: [
    {
      type: "Edit",
      icon: "icofont icofont-edit",
      command: (val: any) => {
        editGroup(val);
      },
    },
    {
      type: "Delete",
      icon: "icofont icofont-trash",
      command: (val: any) => {
        deleteGroup(val);
      },
    },
  ],
});

onMounted(() => {
  getGroupListing();
});
function reloadAllData(data: object = {}, created_new: boolean = false) {
  self.create_new = false;
  self.edit_group = false;
  if (created_new) {
    self.groups.unshift(data);
  } else {
    getGroupListing(true);
  }
}

function editGroup(val: any) {
  self.selected_group_user = val;
  if (val.user) {
    emit("update-user-data", val.user);
  }
  self.edit_group = true;
}

function deleteGroup(val: any) {
  self.selected_group_user = val;
  self.confirm.require({
    group: "confirmDialog",
    message: "Are you sure you want to delete?",
    header: "Delete Group",
    position: "top",
    rejectLabel: "Cancel",
    acceptLabel: "Ok",
    accept: () => {
      self.user_group_edit_service.deleteGroupUser(
        props.group,
        self.selected_group_user.user,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            updatedUserListing();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    },
  });
}

function updatedUserListing() {
  self.edit_group = false;
  getGroupListing(true);
}

function getGroupListing(reset = false) {
  if (reset) {
    self.page = 1;
    self.groups = [];
  }
  if (
    (self.page <= self.pagination.total_page && !self.loading) ||
    (self.page == 1 && !self.loading)
  ) {
    self.loading = true;
    appContext.config.globalProperties.app_service.getData(
      self.api_url,
      self.page,
      (response: any, error: any) => {
        processListing(response, error);
      }
    );
  }
}

function processListing(res: any, err: any) {
  if (res) {
    self.pagination = res.metadata;
    self.groups.push(...res.data);
    self.page += 1;
  }
  if (err) {
    appContext.config.globalProperties.$toastMessage(
      appContext.config.globalProperties.$getErrorResponse(err)
    );
  }
  self.loading = false;
  self.loaded_user = true;
}
</script>
