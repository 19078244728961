<template>
  <div id="form-content" class="page-content">
    <Form ref="editGroup" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0"
          >Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="Name"
            rules="required"
            autocomplete="off"
            v-model="self.group.name"
          >
            <InputText type="text" v-model="self.group.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Name" />
        </div>
      </div>

      <div class="field grid">
        <label for="description" class="col-12 mb-2 md:col-3 md:mb-0"
          >Description <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="description"
            name="Description"
            rules="required"
            autocomplete="off"
            v-model="self.group.description"
          >
            <Textarea v-model="self.group.description" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Description" />
        </div>
      </div>
      <div class="field grid">
        <label for="share-projects" class="col-12 mb-2 md:col-3 md:mb-0"
          >Share Projects</label
        >
        <div class="col-12 md:col-9"> 
          <Checkbox
            v-model="self.group.share_projects"
            inputId="share-projects"
            name="Share Projects"
            :binary="true"
          /> 
          <ErrorMessage as="small" class="p-error" name="Share Projects" />
        </div>
      </div>
      <div class="field grid">
        <label for="permission" class="col-12 mb-2 md:col-3 md:mb-0"
          >Permission</label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full formgroup-inline checkbox-wrap justify-content-between"
            as="div"
            type="text"
            id="permission"
            name="Permission"
            autocomplete="off"
          >
            <div
              class="field-checkbox"
              v-for="(permission, key) in self.group.default_permission"
              :key="permission"
            >
              <Checkbox
                v-model="self.group.default_permission[key]"
                :inputId="key.toString()"
                :name="key.toString()"
                binary
                @change="updateAll(key)"
              />
              <label :for="key.toString()" class="ml-0"> {{ capitalize(`${key.toString()}`) }}</label>
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" name="Permission" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update Group"
          :loading="self.updating_group"
          @click.prevent="updateUserGroup"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive, ref, capitalize } from "vue";
const { appContext }: any = getCurrentInstance();
const emit = defineEmits(["updated-group"]);
const props:any = defineProps({
  group: {
    type: Object,
  },
});
const self = reactive({
  group: getGroupDetail(),
  updating_group: false,
  api_url: `user-groups`,
});
const editGroup = ref();

function getGroupDetail() {
  return {
    name: props.group.name,
    description: props.group.description,
    share_projects: props.group.share_projects?props.group.share_projects:false,
    default_permission: {
      read: props.group.default_permission.read == 1,
      write: props.group.default_permission.write == 1,
      edit: props.group.default_permission.edit == 1,
      delete: props.group.default_permission.delete == 1,
      manage: props.group.default_permission.manage == 1,
    },
  } as any;
}

function updateAll(key: any) {
  appContext.config.globalProperties.$checkAll(
    self.group.default_permission,
    key
  );
}
async function updateUserGroup() {
  const validate = await editGroup.value.validate();
  if (!validate.valid) {
    return;
  } 
  const form_data = {
    name: self.group.name,
    description: self.group.description,
    share_projects: self.group.share_projects,
    default_permission: {
      read: self.group.default_permission.read ? 1 : 0,
      write: self.group.default_permission.write ? 1 : 0,
      edit: self.group.default_permission.edit ? 1 : 0,
      delete: self.group.default_permission.delete ? 1 : 0,
      manage: self.group.default_permission.manage ? 1 : 0,
    },
  };
  self.updating_group = true;
  await appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    form_data,
    props.group.id,
    (response: any, error: any) => {
      self.updating_group = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        emit("updated-group", response.data.data);
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>
