<template>
  <div
    id="doc-wrap"
    class="document-wrapper min-h-screen bg-blue-50 p-2 mt-3"
    @contextmenu.prevent="$emit('view-default-context', $event)"
    @dragover.prevent="
      !is_root
        ? $emit(
            'update:view_dropzone',
            Object.keys(dragged_selected_document).length ? false : true
          )
        : null
    "
    @dragleave.stop.prevent="$emit('update:view_dropzone', false)"
    @drop.prevent="uploadDocument"
  >
    <slot name="documents"></slot>
    <input
      type="file"
      @change="onUpload"
      style="display: none"
      ref="fileInput"
      name="myfile"
      multiple
    />
    <div
      v-if="(items.length == 0 && !loading) || view_dropzone"
      class="absolute w-full top-0 dropzone flex align-content-end justify-content-center flex-wrap"
      :class="{'align-content-center': is_root, 'align-content-end border-dashed border-2': !is_root}"
    >
      <div class="text-center drop-cloud">
        <div class="error-content">
          <i v-if="!is_root" class="float fa-solid fa-cloud-arrow-up text-8xl icon-error"></i>
        </div>
        <div class="heading-bg px-3 py-2 my-2 mb-4 border-round">
          <p
            v-html="
              is_root
                ? is_share_page
                  ? 'There are no shared documents'
                  : is_starred_page
                  ? 'There are no Starred documents'
                  : 'Upload is not allowed here, please right click and create a project folder to get started.'
                : 'Drop files to instantly upload them to:'
            "
            class="menu-color mb-3"></p>
          <Image :src="self.logo" :alt="self.app_name" width="200" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  watch,
  // onMounted,
  onBeforeUnmount,
} from "vue";
export default defineComponent({
  name: "DocUploader",
  props: {
    is_share_page: {
      type: Boolean,
      default: false,
    },
    is_starred_page: {
      type: Boolean,
      default: false,
    },
    is_root: {
      type: Boolean,
      default: false,
    },
    view_dropzone: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [] as any,
    } as any,
    parent: {
      type: Object,
      default: () => {
        return {};
      },
    } as any,
    selected_document: {
      type: Object,
      default: () => {
        return {};
      },
    } as any,
    loading: {
      type: Boolean,
      default: false,
    },
    canceled_upload: {
      type: Number,
    },
    dragged_selected_document: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permission: {
      type: String,
    },
  },
  emits: [
    "media-uploaded",
    "update:view_dropzone",
    "update-activities",
    "update-upload-activity",
    "view-default-context",
  ],
  setup(props: any, { emit }) {
    const appData: any = getCurrentInstance();
    const properties: any = appData.appContext.config.globalProperties;
    const fileInput = ref();
    const self = reactive({
      logo: properties.$getImage(
        process.env.VUE_APP_THEME == "green"
          ? process.env.VUE_APP_LOGO
          : process.env.VUE_APP_MAIN_LOGO
      ),
      app_name: process.env.VUE_APP_NAME,
    });

    watch(
      () => props.canceled_upload,
      (val: any) => {
        appData.root.refs.uploadProgress.self.upload.process.total_files.uploading -= val;
        if (
          appData.root.refs.uploadProgress.self.upload.process.total_files.uploading <= 0
        ) {
          appData.root.refs.uploadProgress.self.upload.process.total_files.uploading = 0;
          appData.root.refs.uploadProgress.self.upload.process.view = false;
          appData.root.refs.uploadProgress.self.upload.progressing = false;
        }
      }
    );

    watch(
      () => appData.root.refs.uploadProgress.self.upload.media_uploaded,
      (val: any) => {
        emit("media-uploaded", val.uploaded, val.uploaded_all);
      }
    );

    watch(
      () => appData.root.refs.uploadProgress.self.upload.update_activity,
      (val: any) => {
        if (val) {
          updateActivities();
        }
      }
    );

    onBeforeUnmount(() => {
      document.body.style.userSelect = null as any;
    });

    async function uploadDocument(e: any) {
      appData.root.refs.uploadProgress.self.selected_parent = Object.keys(
        props.dragged_selected_document
      ).length
        ? props.dragged_selected_document
        : props.parent;
      if (props.is_root) {
        emit("update:view_dropzone", false);
        return unAllowedUpload();
      }
      if (
        appData.root.refs.uploadProgress.self.upload.process.total_files.uploading ==
        appData.root.refs.uploadProgress.self.upload.process.total_files.uploaded
      ) {
        resetUploadStatus();
      }
      emit("update:view_dropzone", false);
      if (props.selected_document == undefined) {
        if (props.is_root) {
          return unAllowedUpload();
        } else {
          return properties.$toastMessage({
            status: 400,
            data: {
              message: "Please select project/folder to upload",
            },
          });
        }
      }
      if (props.is_root)
        appData.root.refs.uploadProgress.self.upload.queue = [] as any;
      if (Array.from(e.dataTransfer.files).length == 0) {
        return null;
      } else {
        appData.root.refs.uploadProgress.uploadDocument(e.dataTransfer.items);
      }
    }

    function unAllowedUpload() {
      properties.$toastMessage({
        status: 400,
        data: {
          message: `Upload is not allowed here ${
            props.is_starred_page || props.is_share_page
              ? ""
              : ", please right click and create a project folder to get started"
          }.`
        },
      });
    }

    function updateActivities() {
      emit(
        "update-activities",
        appData.root.refs.uploadProgress.self.upload.activity
      );
      appData.root.refs.uploadProgress.self.upload.update_activity = false;
    }

    function onUpload(e: any) {
      appData.root.refs.uploadProgress.uploadFile(
        e.target.files,
        Object.keys(props.dragged_selected_document).length
          ? props.dragged_selected_document
          : props.parent
      );
      setTimeout(()=>{
       fileInput.value.value = ""
      },1500)
    }

    function getSignedSource() {
      return properties.signed_url.axios.CancelToken.source();
    }

    function resetUploadStatus() {
      appData.root.refs.uploadProgress.self.selected_parent = Object.keys(
        props.dragged_selected_document
      ).length
        ? props.dragged_selected_document
        : props.parent;
      appData.root.refs.uploadProgress.self.upload.process.total_folders.uploading = 0;
      appData.root.refs.uploadProgress.self.upload.process.total_folders.uploaded = 0;
      appData.root.refs.uploadProgress.self.upload.process.total_files.uploading = 0;
      appData.root.refs.uploadProgress.self.upload.process.total_files.uploaded = 0;
      appData.root.refs.uploadProgress.self.upload.media_uploaded = {
        uploaded: false,
        uploaded_all: false,
      };
      appData.root.refs.uploadProgress.self.upload.progress = {
        process: null,
        key: null,
      };
      appData.root.refs.uploadProgress.self.upload.activity = [];
      appData.root.refs.uploadProgress.self.upload.update_activity = false;
    }

    return {
      appData,
      properties,
      fileInput,
      self,
      uploadDocument,
      updateActivities,
      getSignedSource,
      onUpload,
      resetUploadStatus,
    };
  },
});
</script>