import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import AutoComplete from "primevue/autocomplete";
import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import Message from "primevue/message";
import Button from "primevue/button";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Chips from "primevue/chips";
import ContextMenu from "primevue/contextmenu";
import ConfirmDialog from "primevue/confirmdialog";
import Image from "primevue/image";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Inplace from "primevue/inplace";
import InputSwitch from "primevue/inputswitch";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import DataView from "primevue/dataview";
import Password from "primevue/password";
import Textarea from "primevue/textarea";
import Listbox from "primevue/listbox";
import AvatarGroup from "primevue/avatargroup";
import Avatar from "primevue/avatar";
import SelectButton from "primevue/selectbutton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ToggleButton from "primevue/togglebutton";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Paginator from "primevue/paginator";
import Badge from "primevue/badge";
import OverlayPanel from "primevue/overlaypanel";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import TieredMenu from "primevue/tieredmenu";
import RadioButton from "primevue/radiobutton";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Toolbar from "primevue/toolbar";
import Toast from "primevue/toast";
import Tree from "primevue/tree";
import Sidebar from "primevue/sidebar";
import SpeedDial from "primevue/speeddial";
import ScrollPanel from "primevue/scrollpanel";
import Breadcrumb from "primevue/breadcrumb";
import ProgressSpinner from "primevue/progressspinner";
import ProgressBar from "primevue/progressbar";
import ColorPicker from "primevue/colorpicker";
import MultiSelect from "primevue/multiselect";
import JsonEditorVue from "json-editor-vue3";
import Timeline from "primevue/timeline";
//VeeValidate
import { Field, Form, ErrorMessage } from "vee-validate";
import Divider from "primevue/divider";
import AppMessage from "@/components/AppMessage.vue";
import AppErrorMessage from "@/components/common/message/error_message.vue";
import AppToolbar from "@/components/common/toolbar/toolbarIndex.vue";
import TableView from "@/components/common/views/tableIndex.vue";
import DetailView from "@/components/common/views/detailIndex.vue";
import AppEditor from "@/components/common/views/classicEditor.vue";
import InfiniteLoader from "@/components/common/progress_bar/infiniteLoader.vue";
import uploadProgress from "@/components/common/progress_bar/upload.progress.vue";
export default {
  Accordion,
  AccordionTab,
  AutoComplete,
  Menubar,
  Menu,
  Message,
  Button,
  Card,
  Carousel,
  ConfirmDialog,
  ContextMenu,
  Calendar,
  Checkbox,
  Chips,
  Image,
  InputText,
  InputNumber,
  Inplace,
  InputSwitch,
  Dialog,
  Dropdown,
  Password,
  SelectButton,
  Textarea,
  AvatarGroup,
  Avatar,
  TabView,
  TabPanel,
  ToggleButton,
  DataTable,
  Column,
  ColumnGroup,
  Row,
  Paginator,
  Badge,
  DataView,
  OverlayPanel,
  Skeleton,
  SpeedDial,
  ScrollPanel,
  TieredMenu,
  RadioButton,
  Panel,
  PanelMenu,
  Tree,
  Tag,
  Toolbar,
  Toast,
  MultiSelect,
  ColorPicker,
  Sidebar,
  JsonEditorVue,
  Timeline,
  Breadcrumb,
  Listbox,
  ProgressSpinner,
  ProgressBar,
  Divider,
  Form,
  Field,
  ErrorMessage,
  AppMessage,
  AppEditor,
  TableView,
  DetailView,
  AppToolbar,
  AppErrorMessage,
  InfiniteLoader,
  uploadProgress,
};