import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "the-content bg-transparent p-0 relative top-0" }

import {
  onMounted,
  defineAsyncComponent,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import UserGroupEditService from "@/modules/settings/services/user-groups/userGroupEdit";

export default /*@__PURE__*/_defineComponent({
  __name: 'groupUsers',
  props: {
  group: {
    type: Object,
  },
  users: {
    type: Object,
  },
},
  emits: ["search-users", "update-user-data"],
  setup(__props, { emit: __emit }) {

const EditGroupUser = defineAsyncComponent(() => import("./editGroupUser.vue"));
const AddGroupUser = defineAsyncComponent(
  () => import("./createGroupUser.vue")
);
const props:any = __props;
const emit = __emit;
const { appContext }: any = getCurrentInstance();
const self = reactive({
  api_url: `user-groups/${props.group.id}/users`,
  user_group_edit_service: new UserGroupEditService(),
  confirm: useConfirm(),
  store: useStore(),
  toolbarOptions: {
    filter: false,
    refresh: false,
    add: true,
  },
  groups: [],
  loading: false,
  loaded_user: false,
  page: 1,
  pagination: {
    per_page: 15,
    total_records: 30,
    current_page: 1,
    total_page: 1,
  },
  table_fields: [
    {
      key: "user",
      label: "Name",
      sortable: true,
      style: "width:190px",
      format: (val: any) => {
        return val.name ? val.name : val;
      },
    },
    {
      key: "permission",
      label: "Read",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.read == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Write",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.write == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Edit",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.edit == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Delete",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.delete == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
    {
      key: "permission",
      label: "Manage",
      sortable: false,
      style: "width:90px",
      format: (val: any) => {
        return val.manage == 1
          ? "<i class='fas fa-check success'></i>"
          : "<i class='fas fa-times danger'></i>";
      },
    },
  ],
  selected_group_user: {} as any,
  create_new: false,
  edit_group: false,
  action_buttons: [
    {
      type: "Edit",
      icon: "icofont icofont-edit",
      command: (val: any) => {
        editGroup(val);
      },
    },
    {
      type: "Delete",
      icon: "icofont icofont-trash",
      command: (val: any) => {
        deleteGroup(val);
      },
    },
  ],
});

onMounted(() => {
  getGroupListing();
});
function reloadAllData(data: object = {}, created_new: boolean = false) {
  self.create_new = false;
  self.edit_group = false;
  if (created_new) {
    self.groups.unshift(data);
  } else {
    getGroupListing(true);
  }
}

function editGroup(val: any) {
  self.selected_group_user = val;
  if (val.user) {
    emit("update-user-data", val.user);
  }
  self.edit_group = true;
}

function deleteGroup(val: any) {
  self.selected_group_user = val;
  self.confirm.require({
    group: "confirmDialog",
    message: "Are you sure you want to delete?",
    header: "Delete Group",
    position: "top",
    rejectLabel: "Cancel",
    acceptLabel: "Ok",
    accept: () => {
      self.user_group_edit_service.deleteGroupUser(
        props.group,
        self.selected_group_user.user,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            updatedUserListing();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    },
  });
}

function updatedUserListing() {
  self.edit_group = false;
  getGroupListing(true);
}

function getGroupListing(reset = false) {
  if (reset) {
    self.page = 1;
    self.groups = [];
  }
  if (
    (self.page <= self.pagination.total_page && !self.loading) ||
    (self.page == 1 && !self.loading)
  ) {
    self.loading = true;
    appContext.config.globalProperties.app_service.getData(
      self.api_url,
      self.page,
      (response: any, error: any) => {
        processListing(response, error);
      }
    );
  }
}

function processListing(res: any, err: any) {
  if (res) {
    self.pagination = res.metadata;
    self.groups.push(...res.data);
    self.page += 1;
  }
  if (err) {
    appContext.config.globalProperties.$toastMessage(
      appContext.config.globalProperties.$getErrorResponse(err)
    );
  }
  self.loading = false;
  self.loaded_user = true;
}

return (_ctx: any,_cache: any) => {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (self.loaded_user && self.groups.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_AppToolbar, {
            class: "relative w-full px-0",
            options: self.toolbarOptions,
            loading: !self.loaded_user,
            onReloadData: reloadAllData,
            onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (self.create_new = true)),
            permission: "user_groups",
            hideProgress: ""
          }, null, 8, ["options", "loading"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TableView, {
              permission_type: "user_groups",
              loading: self.loading,
              items: self.groups,
              fields: self.table_fields,
              actions: self.action_buttons,
              pagination: self.pagination,
              onLoadMoreData: getGroupListing
            }, null, 8, ["loading", "items", "fields", "actions", "pagination"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (self.loaded_user && !self.groups.length)
      ? (_openBlock(), _createBlock(_component_AppErrorMessage, {
          key: 1,
          page_type: "users in group",
          page_icon: "fa fa-users",
          onAddNew: _cache[1] || (_cache[1] = ($event: any) => (self.create_new = true))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Sidebar, {
      id: "sidebar-form",
      visible: self.edit_group,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((self.edit_group) = $event)),
      position: "right"
    }, {
      header: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode(" Edit User in Group")
      ])),
      default: _withCtx(() => [
        _createVNode(_unref(EditGroupUser), {
          group: __props.group,
          users: __props.users,
          user: self.selected_group_user,
          onUpdatedGroupUser: reloadAllData,
          onSearchUsers: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('search-users', $event)))
        }, null, 8, ["group", "users", "user"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Sidebar, {
      id: "sidebar-form",
      visible: self.create_new,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((self.create_new) = $event)),
      position: "right"
    }, {
      header: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode(" Add User in Group ")
      ])),
      default: _withCtx(() => [
        _createVNode(_unref(AddGroupUser), {
          group: __props.group,
          users: __props.users,
          onCreatedUser: reloadAllData,
          onSearchUsers: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('search-users', $event)))
        }, null, 8, ["group", "users"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})