export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    token_detail: {},
    all_users: [],
    roles: [],
    role_permission_detail: {
      "user": "Can see all owned projects and shared item with user.",
      "roles": "No effect.",
      "resources": "No effect.",
      "project": "Can see every project and change ownership of project in a system.",
      "email_template": "No effect.",
      "folder_template": "No effect.",
      "media": "Can see every folder in a system.",
      "share": "Can see shared user list of all document.",
      "application_settings": "No effect.",
      "trash": "No effect.",
      "user_agreement": "No effect.",
      "tags": "Can see & manage all user tags.",
      "reports": "Can see & manage all user reports.",
      "user_groups": "Can see & manage all user groups.",
      "application_activity": "No effect."
    },
    tags: [],
    faqs: {
      "dashboard": [
        {
          "question": "What are widgets and how to manage widgets?",
          "video_link": "/faq/dashboard_widget.mov"
        }
      ],
      "project": [
        {
          "question": "What are projects?",
          "video_link": ""
        },
        {
          "question": "How to view project detail?",
          "video_link": ""
        },
        {
          "question": "How to share projects?",
          "video_link": "/faq/share.mp4"
        },
        {
          "question": "How to change ownership of projects?",
          "video_link": ""
        },
        {
          "question": "How to download projects?",
          "video_link": "/faq/download_project.mov"
        },
        {
          "question": "How to create folders and upload documents?",
          "video_link": ""
        }
      ],
      "my_vault": [
        {
          "question": "How to change view?",
          "video_link": "/faq/change_view.mov"
        },
        {
          "question": "How to manage files and folders?",
          "video_link": "/faq/manage_files_and_folder.mp4"
        },
        {
          "question": "How to share projects, folders and document?",
          "video_link": "/faq/share.mp4"
        },
        {
          "question": "How to change ownership of folder and files?",
          "video_link": "/faq/change_ownership.mp4"
        },
        {
          "question": "How to download projects and documents?",
          "video_link": "/faq/download_project.mov"
        }
      ],
      "trash": [
        {
          "question": "What is trash and how to delete documents permanently?",
          "video_link": ""
        }
      ],
      "starred": [
        {
          "question": "What is starred and its navigation?",
          "video_link": ""
        }
      ],
      "notification_setting": [
        {
          "question": "How to enable and disable notifications for documents?",
          "video_link": ""
        }
      ],
      "shared_with_me": [
        {
          "question": "What is shared with me and its navigation?",
          "video_link": ""
        }
      ],
      "shared_by_me": [
        {
          "question": "What is shared by me and its navigation?",
          "video_link": ""
        }
      ],
      "reports": [
        {
          "question": "How to generate different kind of reports?",
          "video_link": ""
        }
      ],
      "users_settings": [
        {
          "question": "How to add new users and its action?",
          "video_link": ""
        }
      ],
      "group_settings": [
        {
          "question": "What is user group?",
          "video_link": ""
        }
      ],
      "roles_settings": [
        {
          "question": "What are roles and its permissions?",
          "video_link": ""
        }
      ],
      "folder_templates_settings": [
        {
          "question": "What is folder templates and how to use it?",
          "video_link": ""
        }
      ],
      "email_templates_settings": [
        {
          "question": "What is email templates?",
          "video_link": ""
        }
      ],
      "tag_group_settings": [
        {
          "question": "What is tag group and its usages? How can we use it in reports?",
          "video_link": ""
        }
      ],
      "user_agreement_settings": [
        {
          "question": "What is user agreement?",
          "video_link": ""
        }
      ],
      "system_settings": [
        {
          "question": "What is system settings and how does it affect our system?",
          "video_link": ""
        }
      ]
    },
  },
  getters: {
    getUserDetail(state: any) {
      return state.user;
    },
    getFaqs(state: any) {
      return state.faqs
    },
    getUserToken(state: any) {
      return state.token;
    },
    getAllUsers(state: any) {
      return state.all_users;
    },
    getUserTokenDetail(state: any) {
      return state.token_detail;
    },

    getRoles(state: any) {
      return state.roles;
    },
    getRolesPermissionDetail(state: any) {
      return state.role_permission_detail;
    },
    getTagLists(state: any) {
      return state.tags;
    },
  },
  mutations: {
    setUserDetail: (state: { user: any }, value: any) => {
      state.user = value;
    },
    setUserToken: (state: { token: any }, value: any) => {
      state.token = value; 

    },
    setAllUsers: (state: { all_users: any }, value: any) => {
      state.all_users = value;
    },
    setUserTokenDetail: (state: any, data: any) => {
      state.token_detail = data;
    },

    setRoles: (state: any, data: any) => {
      state.roles = data;
    },
    setFaqs: (state: any, data: any) => {
      state.faqs = data;
    },
    setTagLists: (state: any, data: any, reset = false) => {
      if (reset) {
        state.tags = []
      }
      state.tags.push(...data);
    },
  },
  actions: {
    initialize() {
      null;
    },
  },
};