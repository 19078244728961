<template>
  <div>
    <div
      class="field grid"
      v-for="(detail, index) in filteredDetail"
      :key="index"
    >
      <label :for="detail.name" class="col-fixed">{{ detail.name }}</label>
      <div class="col">
        <slot name="description" :data="detail">{{ detail.description }}</slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, onMounted, getCurrentInstance } from "vue";
const props = defineProps({
  detail: {
    type: Object,
    default: () => {
      return {};
    },
  },
  exclude_keys: {
    type: Array,
    default: () => [""],
  },
});
props;
const self = getCurrentInstance() as any;
const exclude_items: any = reactive(["id", "created_at", "status"]);
const filteredDetail: any = reactive([]);
onMounted(() => {
  fetchData();
});

function fetchData() {
  exclude_items.push(...props.exclude_keys);
  for (const key in props.detail) {
    const description =
      typeof props.detail[key] === "object" && props.detail[key] != null
        ? props.detail[key].name
        : props.detail[key]
        ? props.detail[key]
        : "";
    if (!exclude_items.includes(key) && description) {
      filteredDetail.push({
        name: self.appContext.config.globalProperties.$getName(key, "_"),
        description:
          description == true
            ? "Yes"
            : description == false
            ? "No"
            : description,
      });
    }
  }
}
</script>
