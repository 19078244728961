import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-9" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = { class: "col-12 md:col-12" }
const _hoisted_8 = { class: "flex align-items-center justify-content-end mt-2" }

import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'editTemplate',
  props: {
  template: {
    type: Object,
    default: () => {
      return {};
    },
  },
},
  emits: ["updated-template"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();
const props = __props;
const editTemplate = ref();
const emit = __emit;
const self = reactive({
  store: useStore(),
  template: getFormDetail(),
  updating_template: false,
});
function getFormDetail() {
  return {
    name: props.template.name,
    description: props.template.description,
    content: parseData(props.template.content),
  };
}

function parseData(content:any){
  try {
    return JSON.parse(content);
  } catch (e) {
    return content;
  }
}

async function updateTemplate() {
  const validate = await editTemplate.value.validate();
  if (!validate.valid) return;
  self.updating_template = true;
  await appContext.config.globalProperties.app_service.updateData(
    "folder-templates",
    self.template,
    props.template.id,
    (response: any, error: any) => {
      if (response) {
        self.updating_template = false;
      appContext.config.globalProperties.$toastMessage(response);
      if (response.status >= 200 && response.status < 300) {
        emit("updated-template", response.data.data);
      }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_json_editor_vue = _resolveComponent("json-editor-vue")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "editTemplate",
      ref: editTemplate,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "name",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Name "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "name",
              name: "name",
              rules: "required",
              autocomplete: "off",
              modelValue: self.template.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.template.name) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.template.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.template.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "name"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "description",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Description "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "description",
              name: "description",
              rules: "required",
              autocomplete: "off",
              modelValue: self.template.description,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((self.template.description) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Textarea, {
                  modelValue: self.template.description,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((self.template.description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "description"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "content",
            class: "col-12 mb-2 md:col-12 md:mb-2"
          }, [
            _createTextVNode("Content "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_json_editor_vue, {
              id: "json-editor",
              class: "editor w-full",
              modelValue: self.template.content,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((self.template.content) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "content"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Update Template",
            loading: self.updating_template,
            onClick: _withModifiers(updateTemplate, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})