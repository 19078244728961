<template>
  <div>
    <AppToolbar
      permission="roles"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      @reload-data="reloadAllData"
      @create-new="self.create_new = true"
    />
    <div class="the-content">
      <TableView
        permission_type="roles"
        :loading="self.loading"
        :items="self.roles"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getRoleListing"
      />
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.view_role"
        position="right"
      >
        <template #header> Role Detail</template>
        <ViewRole :role="self.selected_role" />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_role"
        position="right"
        class="w-7"
      >
        <template #header> Edit Role</template>
        <EditRole
          :role="self.selected_role"
          :view_role="true"
          @updated-role="reloadAllData"
        />
      </Sidebar>

      <Sidebar
        id="sidebar-form"
        v-model:visible="self.create_new"
        position="right"
        class="w-7"
      >
        <template #header> Add Role </template>
        <CreateRole :resources="self.resources" @created-role="reloadAllData" />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
const CreateRole = defineAsyncComponent(() => import("./components/createRole.vue"));
const EditRole = defineAsyncComponent(() => import("./components/editRole.vue"));
const ViewRole = defineAsyncComponent(() => import("./components/viewRole.vue"));
export default defineComponent({
  name: "RoleIndexData",
  components: {
    CreateRole,
    EditRole,
    ViewRole,
  },
  setup() {
    const { appContext }:any = getCurrentInstance();
    const self = reactive({
      api_url: "roles",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-user-tag mr-1",
        label: "Roles",
        to: { name: "roleSettings" },
      },
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        filter: false,
        refresh: true,
        add: true,
      },
      exclude_keys: ["role"],
      roles: [] as any,
      resources: [] as any,
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "name",
          label: "Role",
          sortable: false,
          style: "width:90%",
        },
      ],
      selected_role: {},
      create_new: false,
      edit_role: false,
      view_role: false,
      action_buttons: [
        {
          type: "View",
          icon: "icofont icofont-eye-alt",
          command: (val: any) => {
            viewRole(val);
          },
          permission: "read",
        },
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editRole(val);
          },
          permission: "edit",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteRole(val);
          },
          permission: "delete",
        },
      ],
    });

    onMounted(() => {
      getResourceListing();
      getRoleListing();
    });
    function reloadAllData(data: object = {}, created_new: boolean = false) {
      self.view_role = false;
      self.edit_role = false;
      self.create_new = false;
      if (created_new) {
        self.roles.unshift(data);
      } else {
        getRoleListing(true);
      }
    }

    function viewRole(val: any) {
      self.selected_role = val;
      self.view_role = true;
    }

    function editRole(val: any) {
      self.selected_role = val;
      self.edit_role = true;
    }

    function deleteRole(val: any) {
      self.selected_role = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to delete?",
        header: "Delete Role",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_role,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedRoleListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedRoleListing() {
      self.edit_role = false;
      getRoleListing(true);
    }

    function getRoleListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.roles = [];
      }
      if ((self.page <= self.pagination.total_page && !self.loading) || (self.page == 1 && !self.loading)) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.roles.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    function getResourceListing() {
      appContext.config.globalProperties.app_service.getData(
        "resources",
        1,
        (response: any) => {
          if (response) {
            self.resources.push(...response.data);
          }
        }
      );
    }

    return {
      self,
      viewRole,
      editRole,
      updatedRoleListing,
      deleteRole,
      getRoleListing,
      getResourceListing,
      reloadAllData,
    };
  },
});
</script>