import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-center drop-cloud" }
const _hoisted_2 = { class: "error-content" }
const _hoisted_3 = {
  key: 0,
  class: "float fa-solid fa-cloud-arrow-up text-8xl icon-error"
}
const _hoisted_4 = { class: "heading-bg px-3 py-2 my-2 mb-4 border-round" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", {
    id: "doc-wrap",
    class: "document-wrapper min-h-screen bg-blue-50 p-2 mt-3",
    onContextmenu: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('view-default-context', $event)), ["prevent"])),
    onDragover: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
      !_ctx.is_root
        ? _ctx.$emit(
            'update:view_dropzone',
            Object.keys(_ctx.dragged_selected_document).length ? false : true
          )
        : null
    ), ["prevent"])),
    onDragleave: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('update:view_dropzone', false)), ["stop","prevent"])),
    onDrop: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadDocument && _ctx.uploadDocument(...args)), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "documents"),
    _createElementVNode("input", {
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onUpload && _ctx.onUpload(...args))),
      style: {"display":"none"},
      ref: "fileInput",
      name: "myfile",
      multiple: ""
    }, null, 544),
    ((_ctx.items.length == 0 && !_ctx.loading) || _ctx.view_dropzone)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute w-full top-0 dropzone flex align-content-end justify-content-center flex-wrap", {'align-content-center': _ctx.is_root, 'align-content-end border-dashed border-2': !_ctx.is_root}])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.is_root)
                ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", {
                innerHTML: 
              _ctx.is_root
                ? _ctx.is_share_page
                  ? 'There are no shared documents'
                  : _ctx.is_starred_page
                  ? 'There are no Starred documents'
                  : 'Upload is not allowed here, please right click and create a project folder to get started.'
                : 'Drop files to instantly upload them to:'
            ,
                class: "menu-color mb-3"
              }, null, 8, _hoisted_5),
              _createVNode(_component_Image, {
                src: _ctx.self.logo,
                alt: _ctx.self.app_name,
                width: "200"
              }, null, 8, ["src", "alt"])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 32))
}