<template>
  <div id="form-content" class="page-content">
    <Form ref="editUserProfile" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0"
          >Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="Name"
            rules="required"
            autocomplete="off"
            v-model="self.user.name"
          >
            <InputText type="text" v-model="self.user.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Name" />
        </div>
      </div>
      <div class="field grid">
        <label for="email" class="col-12 mb-2 md:col-3 md:mb-0">Email </label>
        <div class="col-12 md:col-9">
          <InputText disabled type="text" :value="user_detail.email" />
        </div>
      </div>
      <div class="field grid">
        <label for="alternate-email" class="col-12 mb-2 md:col-3 md:mb-0"
          >Alternate Email
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="alternate-email"
            name="Alternate Email"
            rules="email"
            autocomplete="off"
            v-model="self.user.alternate_email"
          >
            <InputText type="text" v-model="self.user.alternate_email" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Alternate Email" />
        </div>
      </div>
      <div class="field grid">
        <label for="phone" class="col-12 mb-2 md:col-3 md:mb-0">Phone </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="phone"
            name="Phone"
            autocomplete="off"
            v-model="self.user.phone"
          >
            <InputText type="text" v-model="self.user.phone" />
          </Field>
        </div>
      </div>
      <div class="field grid">
        <label for="mobile" class="col-12 mb-2 md:col-3 md:mb-0">Mobile </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="mobile"
            name="Mobile"
            autocomplete="off"
            v-model="self.user.mobile"
          >
            <InputText type="text" v-model="self.user.mobile" />
          </Field>
        </div>
      </div>
      <div class="field grid">
        <label for="permanent-address" class="col-12 mb-2 md:col-3 md:mb-0"
          >Permanent Address
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="permanent-address"
            name="Permanent Address"
            autocomplete="off"
            v-model="self.user.permanent_address"
          >
            <InputText type="text" v-model="self.user.permanent_address" />
          </Field>
        </div>
      </div>
      <div class="field grid">
        <label for="temporary-address" class="col-12 mb-2 md:col-3 md:mb-0"
          >Temporary Address
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="temporary-address"
            name="Temporary Address"
            autocomplete="off"
            v-model="self.user.temporary_address"
          >
            <InputText type="text" v-model="self.user.temporary_address" />
          </Field>
        </div>
      </div>
      <div class="field grid pl-3 mt-4">
        <h3>Social Profile</h3>
      </div>
      <div class="field grid">
        <div class="col-12 md:col-12">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="facebook"
            name="Facebook"
            autocomplete="off"
            rules="url"
            v-model="self.user.social_media.facebook"
          >
            <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon social-icon">
                <i class="fa-brands fa-square-facebook"></i>
              </span>
              <InputText
                type="text"
                v-model="self.user.social_media.facebook"
              />
            </div>
          </Field>

          <ErrorMessage as="small" class="p-error" name="Facebook" />
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 md:col-12">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="twitter"
            name="Twitter"
            autocomplete="off"
            rules="url"
            v-model="self.user.social_media.twitter"
          >
            <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon social-icon">
                <i class="fa-brands fa-square-x-twitter"></i>
              </span>
              <InputText type="text" v-model="self.user.social_media.twitter" />
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" name="Twitter" />
        </div>
      </div>
      <div class="field grid">
        <div class="col-12 md:col-12">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="linkedin"
            name="Linkedin"
            autocomplete="off"
            rules="url"
            v-model="self.user.social_media.linkedin"
          >
            <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon social-icon">
                <i class="fa-brands fa-linkedin"></i>
              </span>
              <InputText
                type="text"
                v-model="self.user.social_media.linkedin"
              />
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" name="Linkedin" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update Profile"
          :loading="self.updating_user"
          @click.prevent="updateUser"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script lang="ts">
import { reactive, ref, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "UserDetailForm",
  props: {
    user_detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["image-uploaded", "updated-profile"],
  setup(props: any, { emit }) {
    const appData: any = getCurrentInstance();
    const editUserProfile = ref();
    const fileInputImg = ref();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
      user: getUserDetailForm(),
      updating_user: false,
    });

    function getUserDetailForm() {
      return {
        name: props.user_detail.name,
        alternate_email: props.user_detail.alternate_email,
        phone: props.user_detail.phone,
        mobile: props.user_detail.mobile,
        permanent_address: props.user_detail.permanent_address,
        temporary_address: props.user_detail.temporary_address,
        social_media: {
          facebook: props.user_detail.social_media.facebook,
          twitter: props.user_detail.social_media.twitter,
          linkedin: props.user_detail.social_media.linkedin,
        },
      } as any;
    }

    async function updateUser() {
      const validate = await editUserProfile.value.validate();
      if (!validate.valid) return;
      self.updating_user = true;
      await self.properties.app_service.updateData(
        "profile",
        self.user,
        null,
        (response: any, error: any) => {
          self.updating_user = false;
          if (response) {
            self.properties.$toastMessage(response);
            emit("updated-profile", response.data.data);
            self.user = getUserDetailForm();
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        }
      );
    }
    return {
      appData,
      self,
      editUserProfile,
      fileInputImg,
      getUserDetailForm,
      updateUser,
    };
  },
});
</script>