const user = {
  token: "" as string,
};

let application_properties: any = {};
export function getUserCredentials(app: any) {
  application_properties = app.config.globalProperties;
  user.token = getUserToken();
  if (!user.token) {
    goToLogin();
  }
  if (!application_properties.$store.getters["setting/getUserDetail"]) {
    getUserDetail(application_properties, (response: any) => {
      application_properties.$store.commit("setting/setUserDetail", response);
    });
  }
}

function getUserToken() {
  if (!application_properties.$store.getters["setting/getUserToken"]) {
    application_properties.$store.commit(
      "setting/setUserToken",
      application_properties.$keycloak.token
    );
  }
  updateToken(application_properties);
  return (
    application_properties.$store.getters["setting/getUserToken"] ||
    application_properties.$keycloak.token
  );
}

export function getUserDetail(properties: any, cb: any) {
  properties.app_service.getData(
    "profile",
    null,
    (response: any, error: any) => {
      if (response) {
        cb(response);
      }
      if (error) {
        goToLogin();
      }
    },
    false
  );
}

function updateToken(properties: any) {
  setInterval(() => {
    properties.$keycloak
      .updateToken(250)
      .then((refreshed: any) => {
        if (refreshed) {
          properties.$store.commit(
            "setting/setUserToken",
            properties.$keycloak.token
          );
        }
      })
      .catch(() => {
        properties.$keycloak.clearToken();
        properties.$keycloak.logout();
        properties.$keycloak.token = null;
      });
  }, 10000);
}

function goToLogin() {
  if (application_properties.$keycloak) {
    application_properties.$keycloak.logout();
    return application_properties.$keycloak.login();
  }
}