<template>
  <Dialog
    modal
    class="download-dialog"
    v-model:visible="self.view_dialog"
    @hide="$emit('update:view_modal', false)"
    :style="{ width: '70vw' }"
    position="top"
  >
    <template #header>
      <Button
        label="Downloads"
        icon="fa fa-download"
        text
        class="primary-color p-0 font-bold opacity-100"
        disabled
      />
    </template>
    <div class="modal-body">
      {{ message }}
    </div>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, capitalize, watch } from "vue";
export default defineComponent({
  name: "DownloadModal",
  props: {
    view_modal: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  emits: ["update:view_modal"],
  setup(props:any) {
    watch(
      () => props.view_modal,
      (val: any) => {
        self.view_dialog = val;
      }
    );
    const self = reactive({
      view_dialog: false,
    });
    return {
      capitalize,
      self,
    };
  },
});
</script>