import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createSlots as _createSlots, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  id: "sub-header",
  class: "relative w-full h-full"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex-initial flex align-items-top justify-content-center w-2" }
const _hoisted_4 = { class: "flex-initial flex align-items-top justify-content-start w-10" }
const _hoisted_5 = {
  key: 0,
  class: "font-bold text-900 block"
}
const _hoisted_6 = {
  key: 1,
  class: "block font-medium text-900"
}
const _hoisted_7 = {
  key: 0,
  class: "card flex justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Card = _resolveComponent("Card")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InfiniteLoader = _resolveComponent("InfiniteLoader")!
  const _component_DataView = _resolveComponent("DataView")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Toolbar, {
        id: "breadcrumb",
        class: "py-2"
      }, {
        start: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("b", null, "Notifications", -1)
        ])),
        end: _withCtx(() => [
          (_ctx.pagination.total_unread_notification >= 1)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: _ctx.readAllNotifications,
                label: "Mark all as read",
                plain: "",
                link: ""
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_DataView, {
      value: _ctx.notifications,
      class: "w-350 notification-view-list scrollable h-350",
      ref: "containerRef",
      id: "notification-view"
    }, _createSlots({
      list: _withCtx((slotProps) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (item, index) => {
          return (_openBlock(), _createBlock(_component_Card, {
            key: index,
            class: _normalizeClass(["p-0 border-bottom-1 cursor-pointer border-noround", { 'surface-100': item.read }]),
            onClick: ($event: any) => (_ctx.viewUrl(item))
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (item.image)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 0,
                        image: _ctx.self.properties.$getFullPath(item.image),
                        class: "mr-2",
                        shape: "circle"
                      }, null, 8, ["image"]))
                    : (_openBlock(), _createBlock(_component_Avatar, {
                        key: 1,
                        icon: "pi pi-user",
                        class: "mr-2",
                        shape: "circle",
                        style: {"background":"var(--app-heading-bg)","color":"var(--app-menu-color)"}
                      }))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", null, [
                    (!item.read)
                      ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(item.created_by) + " " + _toDisplayString(item.message), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.created_by) + " " + _toDisplayString(item.message), 1)),
                    _createElementVNode("small", null, _toDisplayString(_ctx.self.properties.$duration(item.created_at)), 1)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        }), 128))
      ]),
      empty: _withCtx(() => [
        _createVNode(_component_AppErrorMessage, {
          page_type: "notifications",
          page_icon: _ctx.loading ? '' : 'fa fa-bell',
          hide_button: "",
          hide_page_type: _ctx.loading,
          message: _ctx.loading ? 'Loading Notifications Please Wait ...' : ''
        }, null, 8, ["page_icon", "hide_page_type", "message"])
      ]),
      _: 2
    }, [
      (_ctx.pagination.current_page < _ctx.pagination.total_page)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_ProgressSpinner, {
                      style: {"width":"30px","height":"30px"},
                      strokeWidth: "5",
                      animationDuration: ".5s",
                      "aria-label": "Loading"
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_InfiniteLoader, {
                id: "notification-scroll",
                target: "#notification-view",
                relative: "",
                onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('load-more-notification')))
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["value"])
  ], 16))
}