import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { id: "document-grid" }
const _hoisted_2 = ["draggable", "disabled", "onContextmenu", "onClick", "onDragstart", "onDragover", "onDragenter", "onDrop", "onMouseover", "onMouseleave"]
const _hoisted_3 = {
  key: 0,
  class: "max-w-26rem"
}
const _hoisted_4 = { class: "max-w-26rem" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = ["id"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { key: 4 }
const _hoisted_14 = ["onUpdate:modelValue", "onKeypress"]
const _hoisted_15 = {
  key: 0,
  class: "card flex justify-content-center"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Image = _resolveComponent("Image")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _component_InfiniteLoader = _resolveComponent("InfiniteLoader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentData.documents, (documents, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "grid p-3",
          key: index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(documents, (document, sub_index) => {
            return (_openBlock(), _createElementBlock("div", {
              draggable: _ctx.properties.$getContentType(document) != 'project' && _ctx.drag_selector,
              key: sub_index,
              disabled: document.renaming || document.new || !_ctx.drag_selector,
              class: _normalizeClass(["col col-2 cursor-pointer document mx-2 border-2 border-transparent", [
            _ctx.selected_documents.includes(document) ||
            _ctx.selected_document.id == document.id ||
            _ctx.highlight_doc.id == document.id ||
            _ctx.dragged_over_doc.id == document.id
              ? 'p-highlight'
              : '',
            (_ctx.moving_doc.type == 'cut' && _ctx.moving_doc.detail.id == document.id) ||
            document.text_extraction_pending ||
            _ctx.permission == 'trash'
              ? 'opacity-60'
              : '',
            _ctx.disable_select ? 'pt-5' : '',
            _ctx.dragged_over_doc.id == document.id
              ? 'border-dashed border-2 border-primary'
              : '',
          ]]),
              onContextmenu: _withModifiers(($event: any) => (
            _ctx.viewContextMenu({ data: document, originalEvent: $event })
          ), ["prevent"]),
              onClick: _withModifiers(($event: any) => (
            document.renaming || document.new ? null : _ctx.onItemClick($event, document)
          ), ["prevent"]),
              onDragstart: ($event: any) => (_ctx.drag_selector ? _ctx.$emit('dragged-doc', $event, document) : null),
              onDragend: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('stop-dragged', $event))),
              onDragover: ($event: any) => (_ctx.$emit('drag-over-sub', $event, document)),
              onDragleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('drag-leave-sub', $event))),
              onDragenter: ($event: any) => (_ctx.$emit('drag-over-sub', $event, document)),
              onDrop: _withModifiers(($event: any) => (_ctx.$emit('upload-on-document', $event, document)), ["stop","prevent"]),
              onMouseover: ($event: any) => (
            document.renaming || document.new ? null : _ctx.togglePopover($event, document)
          ),
              onMouseleave: ($event: any) => (
            document.renaming || document.new
              ? null
              : _ctx.togglePopover($event, document, false)
          )
            }, [
              _createVNode(_component_OverlayPanel, {
                ref_for: true,
                ref: `popOver${document.id}`,
                appendTo: "body"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", null, [
                    (
                  _ctx.view_parent && document.parent_project && document.type != 'project'
                )
                      ? (_openBlock(), _createElementBlock("li", _hoisted_3, " Parent Project: " + _toDisplayString(document.parent_project.name), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("li", _hoisted_4, " Name: " + _toDisplayString(document.name), 1),
                    (!_ctx.disable_select)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_5, " Created at: " + _toDisplayString(_ctx.properties.$dayMonthDateYear(document.created_at)), 1))
                      : _createCommentVNode("", true),
                    (document.metadata && !_ctx.disable_select)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_6, " Size: " + _toDisplayString(_ctx.properties.$getFileSize(
                    _ctx.properties.app_service.parseJSON(document.metadata).size
                  )), 1))
                      : _createCommentVNode("", true),
                    (!_ctx.disable_select)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_7, " Owner: " + _toDisplayString(document.owner.name ? document.owner.name : document.owner), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1536),
              (!_ctx.disable_select)
                ? (_openBlock(), _createBlock(_component_Checkbox, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                    modelValue: _ctx.componentData.selected_documents,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.componentData.selected_documents) = $event)),
                    inputId: `${document.slug}-${document.id}`,
                    name: document.slug,
                    value: document
                  }, null, 8, ["modelValue", "inputId", "name", "value"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                id: `${document.id}`,
                class: "selectable-doc text-center"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["document-icon mb-1", _ctx.properties.$getContentType(document)])
                }, [
                  (_ctx.properties.$getContentType(document) == 'image')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                        _createVNode(_component_Image, {
                          width: "32",
                          src: _ctx.properties.$getFullPath(document.path),
                          alt: document.name
                        }, null, 8, ["src", "alt"])
                      ]))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.properties.$getIcon(document)
                      }, null, 8, _hoisted_10)),
                  (document.shared)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[8] || (_cache[8] = [
                        _createElementVNode("i", { class: "overlap-icon shared-icon fas fa-share-alt" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  (document.starred)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[9] || (_cache[9] = [
                        _createElementVNode("i", { class: "overlap-icon starred-icon fas fa-star" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  (document.pinned)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _cache[10] || (_cache[10] = [
                        _createElementVNode("i", { class: "overlap-icon pinned-icon fa-solid fa-thumbtack" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ], 2),
                (document.renaming || document.new)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      "onUpdate:modelValue": ($event: any) => ((document.newName) = $event),
                      ref_for: true,
                      ref: `docName${document.id}`,
                      class: "p-inputtext p-component p-filled",
                      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:disabled_selection', true))),
                      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:disabled_selection', false))),
                      onKeypress: _withKeys(($event: any) => (
                document.new
                  ? _ctx.$emit('create-document', document.newName)
                  : _ctx.$emit('update-document', document.newName)
              ), ["enter"])
                    }, null, 40, _hoisted_14)), [
                      [_vModelText, document.newName]
                    ])
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["white-space-nowrap overflow-hidden text-overflow-ellipsis", [_ctx.activities_view ? 'max-w-10rem' : 'max-w-12rem']])
                    }, _toDisplayString(document.name), 3))
              ], 8, _hoisted_8)
            ], 42, _hoisted_2))
          }), 128))
        ]))
      }), 128)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"30px","height":"30px"},
              strokeWidth: "5",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ContextMenu, {
        ref: "gridContext",
        onBeforeShow: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update-menu'))),
        model: _ctx.context_items
      }, {
        item: _withCtx(({ label, item, props }) => [
          (item.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.to,
                custom: ""
              }, {
                default: _withCtx((routerProps) => [
                  _createElementVNode("a", _mergeProps({
                    href: routerProps.href
                  }, props.action), [
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                  ], 16, _hoisted_16)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("a", _mergeProps({
                key: 1,
                href: item.url,
                target: item.target
              }, props.action), [
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
              ], 16, _hoisted_17))
        ]),
        _: 1
      }, 8, ["model"]),
      _createVNode(_component_InfiniteLoader, {
        target: _ctx.target,
        onLoadMore: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('load-more-docs')))
      }, null, 8, ["target"])
    ]),
    (_ctx.self.updating_doc)
      ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
          key: 0,
          style: {"width":"50px","height":"50px"},
          strokeWidth: "8",
          fill: "transparent",
          animationDuration: ".5s",
          "aria-label": "Custom ProgressSpinner"
        }))
      : _createCommentVNode("", true)
  ]))
}