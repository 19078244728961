<template>
  <div>
    <AppToolbar
      permission="application_settings"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
    />
    <div class="the-content">
      <Card>
        <template #title>
          These setting will impact behavior of overall system.
        </template>
        <template #content>
          <Toolbar>
            <template #start>
              Enable notification to users after file upload.
            </template>
            <template #end>
              <InputSwitch class="custom-switch" v-model="self.enabled" />
            </template>
          </Toolbar>
        </template>
        <template #footer>
          <div class="flex align-items-center justify-content-end mt-2">
            <Button
              type="submit"
              label="Update Settings"
              :loading="self.updating_setting"
              @click.prevent="updateSetting"
              class="button-primary"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
export default defineComponent({
  name: "SystemSetting",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-wrench mr-1",
        label: "System Settings",
        to: { name: "systemSettings" },
      },
      loading:true,
      store: useStore(),
      toolbarOptions: {},
      api_url: "system-settings",
      enabled: false,
      updating_setting: false,
    });

    onMounted(() => {
      getSystemSetting();
    });

    function getSystemSetting() {
      self.loading = true;
      appContext.config.globalProperties.app_service.getData(
        self.api_url,
        1,
        (response: any) => {
          response.data.forEach((data: any) => {
            if (data.key == "enable_file_upload_notification") {
              self.enabled = data.value == "yes";
            }
          });
          self.loading = false;
        }
      );
    }

    function updateSetting() {
      const data = { enable_file_upload_notification: self.enabled ? "yes" : "no" };
      appContext.config.globalProperties.app_service.updateData(
        self.api_url,
        data,
        null,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        null,
        true
      );
    }

    return {
      self,
      getSystemSetting,
      updateSetting,
    };
  },
});
</script>