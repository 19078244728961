import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = { class: "project-table" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-content-start flex-wrap"
}
const _hoisted_4 = { class: "flex align-items-center justify-content-start" }
const _hoisted_5 = ["onUpdate:modelValue", "onKeypress"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_SharedModal = _resolveComponent("SharedModal")!
  const _component_OwnerModal = _resolveComponent("OwnerModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      current_sort: _ctx.self.current_sort,
      "onUpdate:current_sort": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.current_sort) = $event)),
      permission: "project",
      search_placeholder: "Search Project",
      breadcrumb_home: _ctx.self.breadcrumbHome,
      breadcrumb_items: _ctx.self.breadcrumb_items,
      options: _ctx.self.toolbarOptions,
      filter: _ctx.self.filterOptions,
      loading: _ctx.self.loading,
      suggestions: _ctx.self.suggestions,
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _ctx.createNewProject,
      onSubmitFilterData: _ctx.searchProjects,
      onGetSuggestions: _ctx.getFilterSuggestions
    }, null, 8, ["current_sort", "breadcrumb_home", "breadcrumb_items", "options", "filter", "loading", "suggestions", "onReloadData", "onCreateNew", "onSubmitFilterData", "onGetSuggestions"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TableView, {
          class: "clickable-table",
          permission_type: "project",
          loading: _ctx.self.loading,
          items: _ctx.self.projects,
          fields: _ctx.self.table_fields,
          actions: _ctx.self.action_buttons,
          pagination: _ctx.self.pagination,
          row_clickable: true,
          disable_select: "",
          onLoadMoreData: _ctx.getProjectsListing,
          onGetRowDetail: _ctx.viewGeneralInfo
        }, {
          "col-data": _withCtx(({ data }) => [
            (data.key == 'name')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (data.data.new)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          "onUpdate:modelValue": ($event: any) => ((data.data.newName) = $event),
                          ref: `docName${data.data.id}`,
                          class: "p-inputtext p-component p-filled",
                          onKeypress: _withKeys(($event: any) => (_ctx.createProject(data.data.newName)), ["enter"])
                        }, null, 40, _hoisted_5)), [
                          [_vModelText, data.data.newName]
                        ])
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(data.value), 1))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  innerHTML: data.value
                }, null, 8, _hoisted_7))
          ]),
          "action-buttons": _withCtx(({ data }) => [
            (!data.new)
              ? (_openBlock(), _createElementBlock("ul", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                  class: "action-btn projects-icon"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.action_buttons, (action) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (_ctx.viewButton(action, data))
                        ? _withDirectives((_openBlock(), _createElementBlock("li", {
                            key: action.type,
                            onClick: _withModifiers(($event: any) => (action.command(data)), ["stop"])
                          }, [
                            _createElementVNode("i", {
                              class: _normalizeClass(action.icon)
                            }, null, 2)
                          ], 8, _hoisted_8)), [
                            [
                              _directive_tooltip,
                              action.type,
                              void 0,
                              { bottom: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData", "onGetRowDetail"]),
        _createVNode(_component_Dialog, {
          visible: _ctx.self.view_download,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.view_download) = $event)),
          modal: "",
          style: { width: '50vw' }
        }, {
          header: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Download Processing",
              icon: "fas fa-download",
              text: "",
              class: "primary-color p-0 font-bold opacity-100",
              disabled: ""
            })
          ]),
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("h2", null, " We are preparing your files for download and will notify you when they are ready. ", -1)),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.self.download_message), 1)
          ]),
          _: 1
        }, 8, ["visible"])
      ]),
      (_ctx.self.share_modal)
        ? (_openBlock(), _createBlock(_component_SharedModal, {
            key: 0,
            permission: "project",
            view_modal: _ctx.self.share_modal,
            "onUpdate:view_modal": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.share_modal) = $event)),
            doc: _ctx.self.selected_project,
            onSharedDocument: _ctx.revokedShared,
            onShareRevoked: _ctx.revokedShared
          }, null, 8, ["view_modal", "doc", "onSharedDocument", "onShareRevoked"]))
        : _createCommentVNode("", true),
      (_ctx.self.owner_modal)
        ? (_openBlock(), _createBlock(_component_OwnerModal, {
            key: 1,
            view_modal: _ctx.self.owner_modal,
            "onUpdate:view_modal": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.owner_modal) = $event)),
            doc: _ctx.self.selected_project
          }, null, 8, ["view_modal", "doc"]))
        : _createCommentVNode("", true)
    ])
  ]))
}