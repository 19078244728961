<template>
  <div class="widget-sidebar">
    <p v-if="widgets.length">
      Drag widgets over dashboard to appear in your dashboard
    </p>
    <draggable
      v-if="!sidebar_refresh"
      :list="widgets"
      :disabled="false"
      item-key="name"
      class="list-group"
      group="widget"
      @start="dragging = true"
      @end="dragging = false"
      @change="updateData"
    >
      <template #item="{ element }">
        <Accordion class="mb-1" :draggable="enabled">
          <AccordionTab>
            <template #header>
              {{ element.title }}
            </template>
            <div class="list-group-item">
              {{ element.title }}
            </div>
          </AccordionTab>
        </Accordion>
      </template>
    </draggable>
    <div v-if="sidebar_refresh" class="text-center my-4">
      <ProgressSpinner
        style="width: 20px; height: 20px"
        strokeWidth="5"
        animationDuration=".5s"
        aria-label="Loading"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineAsyncComponent, ref } from "vue";
import type { Ref } from "vue";
defineProps(["widgets", "viewSidebar","sidebar_refresh"]);
const emit = defineEmits(["update:viewSidebar", "update-list"]);
const draggable = defineAsyncComponent(() => import("vuedraggable"));
const enabled: Ref<boolean> = ref(true);
const dragging: Ref<boolean> = ref(false);  
function updateData(e: any) { 
  emit("update-list", {
    type: "un_dragged",
    event: e,
  }); 
}
</script>