<template>
  <Message v-if="message.view" @close="$emit('close')" :severity="message.type" :closable="closeable">
    <div v-if="typeof message.text === 'string'">{{ message.text }}</div>
    <div v-else>
      <div v-for="(msg, key) in message.text" :key="key">
        {{ capitalize( key.toString() ) }} : {{ typeof message.text[key] == 'object' ? message.text[key][0] : message.text[key] }}
      </div>
    </div>
  </Message>
</template>
<script lang="ts">
import { defineComponent, capitalize } from "vue";
export default defineComponent({
  name: "AppMessage",
  emits:["close"],
  props: {
    message: {
      type: Object,
      default: () => {
        return {
          view: false,
          type: 'success',
          text: ''
        };
      },
      required: true
    },
    closeable:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      capitalize: capitalize
    };
  }
});
</script>