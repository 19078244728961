import appConfig from "@/configs/app";
import SystemService from "@/library/system/service";

export default class RoleEditService extends SystemService {
  constructor() {
    super();
    this.setBaseUrl(appConfig.ApiBaseUrl);
    this.setHttpClient();
  }

  updateRole(role: any, cb: any) {
    const data = {
      name: role.name,
      permissions: role.resources.map((resource: any) => {
        return {
          resource_id: resource.id,
          permission: {
            read: resource.permission.read ? 1 : 0,
            write: resource.permission.write ? 1 : 0,
            edit: resource.permission.edit ? 1 : 0,
            delete: resource.permission.delete ? 1 : 0,
            manage: resource.permission.manage ? 1 : 0,
          },
        };
      }),
    };
    this.patch(`roles/${role.id}`, data, {})
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }
}