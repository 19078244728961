<template>
  <div>
    <DataTable
      v-model:selection="self.selectedDatas"
      v-model:contextMenuSelection="self.selectedData"
      id="table-content"
      class="th-width-3"
      ref="dataTable"
      :value="items"
      :expandedRows="expandedRows"
      :rowClass="self.rowClass"
      :stripedRows="stripedRows"
      :contextMenu="contextMenu"
      @rowContextmenu="viewContextMenu"
      @row-click="onRowClick"
    >
      <template #empty>
        {{ loading ? null : "No Items Found" }}
      </template>
      <Column
        v-if="!disable_select"
        @click.stop
        selectionMode="multiple"
        headerStyle="width: 1.2rem"
      ></Column>
      <Column
        v-for="(field, index) in fields"
        :key="index"
        :field="field.key"
        :header="field.label"
        :style="field.style"
        :sortable="field.sortable"
      >
        <template #body="{ data }">
          <div
            :draggable="
              allow_drag_drop &&
              self.properties.$getContentType(data) != 'project' &&
              drag_selector
            "
            @dragstart="drag_selector ? onRowDragStart($event, data) : null"
            @dragend="onRowDragEnd"
            @dragover="onRowDragOver($event, data)"
            @dragleave="onRowDragLeave"
            @dragenter="onRowDragOver($event, data)"
            @drop.stop.prevent="onRowDrop"
          >
            <template v-if="actions.length">
              <slot
                v-if="self.view_action_buttons"
                name="col-data"
                :data="generateColData(field, data, index)"
              >
                <span
                  v-html="
                    field.format
                      ? field.format(data[field.key])
                      : data[field.key]
                  "
                ></span>
              </slot>
              <Skeleton v-else width="5rem" class="mb-2"></Skeleton>
            </template>
            <template v-else>
              <slot name="col-data" :data="generateColData(field, data, index)">
                <span
                  v-html="
                    field.format
                      ? field.format(data[field.key])
                      : data[field.key]
                  "
                ></span>
              </slot>
            </template>
          </div>
        </template>
      </Column>
      <Column
        @click.stop
        v-if="actions.length"
        field="action"
        :header="action_title"
      >
        <template #body="slotProps">
          <slot
            v-if="self.view_action_buttons"
            name="action-buttons"
            :data="slotProps.data"
            :row="slotProps"
          >
            <ul @click.stop class="action-btn">
              <template v-for="action in actions">
                <li
                  :key="action.type"
                  v-if="
                    (isButtonVisible(action, slotProps) &&
                      !slotProps.data.new) ||
                    is_public
                  "
                  v-tooltip.bottom="
                    typeof action.type == 'string'
                      ? action.type
                      : action.type(slotProps)
                  "
                  @click.stop="action.command(slotProps.data, slotProps)"
                >
                  <i
                    v-if="typeof action.icon == 'string'"
                    :class="action.icon"
                  ></i>
                  <span v-else v-html="action.icon(slotProps)" />
                </li>
              </template>
            </ul>
          </slot>
          <Skeleton v-else width="5rem" class="mb-2"></Skeleton>
        </template>
      </Column>
      <template #expansion="slotProps">
        <slot
          name="expanded-col"
          :slotProps="slotProps"
          :subSlotProps="slotProps"
        ></slot>
      </template>
      <template #footer>
        <div v-if="loading" class="card flex justify-content-center">
          <ProgressSpinner
            style="width: 30px; height: 30px"
            strokeWidth="5"
            animationDuration=".5s"
            aria-label="Loading"
          />
        </div>
        <InfiniteLoader :target="target" @loadMore="$emit('load-more-data')" />
       
        <slot name="footer" />
      </template>
    </DataTable>
    <ContextMenu
      ref="tableContext"
      @before-show="$emit('update-menu')"
      :model="context_items"
    >
      <template #item="{ label, item, props }">
        <router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
        </a>
      </template>
    </ContextMenu>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  watch,
  ref,
  onMounted,
  getCurrentInstance,
} from "vue";
export default defineComponent({
  name: "TableIndex",
  props: {
    target: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
    },
    contextMenu: {
      type: Boolean,
      default: false,
    },
    stripedRows: {
      type: Boolean,
      default: true,
    },
    fields: {
      type: Array,
      default: () => [] as any,
    } as any,
    actions: {
      type: Array,
      default: () => [] as any,
    } as any,
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
    },
    context_items: {
      type: Array,
      default: () => [] as any,
    },
    permission_type: {
      type: String,
    },
    selected_items: {
      type: Array,
    },
    drag_selector: {
      type: Boolean,
      default: false,
    },
    selected_item: {
      type: Object,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    row_clickable: {
      type: Boolean,
      default: false,
    },
    moving_doc: {
      type: Object,
      default: () => {
        return {
          detail: {},
          type: "",
        };
      },
    },
    expandedRows: {
      type: Array,
      default: () => [] as any,
    },
    action_title: {
      type: String,
      default: "Actions",
    },
    allow_drag_drop: {
      type: Boolean,
      default: false,
    },
    is_public: {
      type: Boolean,
      default: false,
    },
    highlight_doc: {
      type: Object,
      default: () => {
        return {
          type: null,
          id: null,
        } as any;
      },
    },
    dragged_over_doc: {
      type: Object,
      default: () => {
        return {} as any;
      },
    },
  },
  emits: [
    "load-more-data",
    "update:selected_items",
    "update:selected_item",
    "row-context",
    "get-row-detail",
    "update-menu",
    "drag-started",
    "dragged-over",
    "drag-leave",
    "drag-stoped",
    "dropped-item",
  ],
  setup(props: any, { emit }) {
    const appData: any = getCurrentInstance();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
      datas: [],
      selectedDatas: [],
      selectedData: {},
      rowClass: (data: any) => {
        return [
          data.id ? `${data.id}` : null,
          props.highlight_doc.id
            ? props.highlight_doc.id == data.id
              ? "p-highlight"
              : null
            : null,
          Object.keys(props.dragged_over_doc).length
            ? props.dragged_over_doc.id == data.id
              ? "p-highlight"
              : ""
            : "",
        ];
      },
      permissions: {} as any,
      view_action_buttons: false,
    });
    const dataTable = ref();
    const tableContext = ref();
    onMounted(() => {
      getUserPermission();
      setTimeout(() => {
        self.view_action_buttons = true;
      }, 300);
    });

    watch(
      () => self.selectedDatas,
      (data: any) => {
        emit("update:selected_items", data);
      }
    );

    watch(
      () => self.selectedData,
      (data: any) => {
        emit("update:selected_item", data);
      }
    );

    watch(
      () => props.selected_items,
      (data: any) => {
        self.selectedDatas = data;
      }
    );

    watch(
      () => props.selected_item,
      (data: any) => {
        self.selectedData = data;
      }
    );

    watch(
      () => props.loading,
      (val: any) => {
        setTimeout(() => {
          if (val) {
            self.view_action_buttons = false;
          } else {
            self.view_action_buttons = true;
          }
        }, 300);
      }
    );

    function getUserPermission() {
      self.permissions = self.properties.$appPermissions();
    }

    function generateColData(fil: any, data: any, index: any) {
      return {
        value: fil.format ? fil.format(data[fil.key]) : data[fil.key],
        key: fil.key,
        data: data,
        index: index,
      };
    }

    function viewContextMenu(e: any) {
      if (props.contextMenu) {
        tableContext.value.hide();
        setTimeout(() => {
          emit("update-menu", e.originalEvent);
          emit("update:selected_item", {});
          emit("update:selected_item", e.data);
          tableContext.value.show(e.originalEvent);
        }, 5);
      }
    }

    function onRowClick(event: any) {
      props.row_clickable
        ? event.data.permission
          ? event.data.permission.read
            ? emit("get-row-detail", event.data)
            : null
          : emit("get-row-detail", event.data)
        : null;
    }

    function isButtonVisible(action: any, slot: any) {
      let view_button = false;
      if (Object.keys(self.permissions).length) {
        view_button =
          self.permissions[props.permission_type][
            action.permission ? action.permission : "read"
          ] == 1 || self.permissions[props.permission_type].manage == 1;
      } else {
        view_button = false;
      }
      if (view_button) {
        if (action.activity_type) {
          view_button = slot.data.activity_type == "view";
        }
        if (action.view_on) {
          view_button = slot.data[action.view_on] ? true : false;
        }
        if (action.status) {
          view_button = action.status.includes(slot.data.status);
        }
        if (action.permission && slot.data.permission) {
          view_button = slot.data.permission[action.permission] == 1;
        }
      }
      return view_button;
    }

    function onRowDragStart(e: any, data: any) {
      if (props.allow_drag_drop) {
        emit("drag-started", e, data);
      }
    }

    function onRowDragOver(e: any, data: any) {
      if (props.allow_drag_drop) {
        emit("dragged-over", e, data);
      }
    }

    function onRowDragLeave(e: any) {
      if (props.allow_drag_drop) {
        emit("drag-leave", e);
      }
    }

    function onRowDragEnd(e: any) {
      if (props.allow_drag_drop) {
        emit("drag-stoped", e);
      }
    }

    function onRowDrop(e: any) {
      if (props.allow_drag_drop) {
        emit("dropped-item", e);
      }
    }

    return {
      self,
      tableContext,
      dataTable,
      generateColData,
      viewContextMenu,
      onRowClick,
      isButtonVisible,
      onRowDragStart,
      onRowDragOver,
      onRowDragLeave,
      onRowDragEnd,
      onRowDrop,
    };
  },
});
</script>
