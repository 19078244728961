<template>
  <div>
    <AppToolbar
      permission="user_agreement"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :filter="self.filterOptions"
      :loading="self.loading"
      :suggestions="self.suggestions"
      search_placeholder="Search User Agreement"
      @reload-data="reloadAllData"
      @create-new="self.create_new = true"
      @submit-filter-data="searchAgreements"
      @get-suggestions="getFilterSuggestions"
    />
    <div class="the-content">
      <TableView
        permission_type="user_agreement"
        disable_select
        :loading="self.loading"
        :items="self.templates"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        @load-more-data="getAgreementListing"
      />
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_agreement"
        position="right"
        class="w-7"
      >
        <template #header>Edit Agreement</template>
        <EditAgreement
          :template="self.selected_agreement"
          :userAgreementTypes="self.userAgreementTypes"
          :toolbar="self.toolbar"
          :emailTags="self.agreementTags"
          @updated-template="reloadAllData"
        />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.create_new"
        position="right"
        class="w-7"
      >
        <template #header>Add Agreement</template>
        <CreateAgreement
          :userAgreementTypes="self.userAgreementTypes"
          :toolbar="self.toolbar"
          :emailTags="self.agreementTags"
          @created-template="reloadAllData"
        />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
  capitalize,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";

const EditAgreement = defineAsyncComponent(() => import("./components/editAgreement.vue"));
const CreateAgreement = defineAsyncComponent(() => import("./components/createAgreement.vue"));

export default defineComponent({
  name: "AgreementIndex",
  components: {
    EditAgreement,
    CreateAgreement,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      api_url: "user-agreements",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-cogs mr-1",
        label: "User Agreements",
        to: { name: "userAgreements" },
      },
      store: useStore(),
      agreementTags: [
        { text: "Full Name", value: "full_name" },
        { text: "Activation Code",value: "activation_code"},
        { text: "Password", value: "password"},
        { text: "Password Reset Token", value: "password_reset_token"},
        {
          text: "Reset Password Code Expiry Time",
          value: "password_reset_code_expiry_time",
        },
        { text: "2FA Code Expiry Time", value: "twofa_code_expiry_time" },
        { text: "Owner Name", value: "owner_name" },
        { text: "Shared Name", value: "shared_content_name" },
        { text: "File Name", value: "file_name" },
        { text: "File Count", value: "file_count" },
        { text: "Year", value: "year" },
        { text: "Application Name" , value: "application_name"},
        { text: "Web Url", value: "web_url" },
      ],
      userAgreementTypes: [
        { option: "Privacy Policy", value: "privacy_policy" },
        { option: "Terms & Conditions", value: "terms_of_use" },
      ],
      toolbar: [
        "bold",
        "italic",
        "underline",
        "textStyle",
        "fontColor",
        "hiliteColor",
        "font",
        "fontSize",
        "formatBlock",
        "blockquote",
        "link",
        "codeView",
      ],
      toolbarOptions: {
        filter: true,
        refresh: true,
        add: true,
      },
      filterOptions: {
        fields: true,
        status: true,
        date: true,
      },
      templates: [] as any,
      search_view: false,
      search_values: {},
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "title",
          label: "Name",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          format: (val: any) => {
            return capitalize(val);
          },
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$getName(val, "_");
          },
        },
      ],
      selected_agreement: {},
      create_new: false,
      edit_agreement: false,
      view_send: false,
      action_buttons: [
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editAgreement(val);
          },
          permission: "edit",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteAgreement(val);
          },
          permission: "delete",
        },
      ],
      suggestions: {
        data: [] as any,
        loading: false,
        page: 1,
        pagination: {
          current_page: 1,
          items_per_page: 0,
          total_page: 0,
          total_records: 0,
        },
      },
    });

    onMounted(() => {
      getAgreementListing();
    });
    function reloadAllData(data: object = {}, created_new: boolean = false) {
      self.edit_agreement = false;
      self.create_new = false;
      if (created_new) {
        self.templates.unshift(data);
      } else {
        getAgreementListing(true);
      }
    }

    function editAgreement(val: any) {
      self.selected_agreement = val;
      self.edit_agreement = true;
    }

    function deleteAgreement(val: any) {
      self.selected_agreement = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to delete?",
        header: "Delete user Agreement",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_agreement,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedAgreementListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedAgreementListing() {
      self.edit_agreement = false;
      getAgreementListing(true);
    }

    function getAgreementListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.templates = [];
      }
      if (self.search_view) {
        searchAgreements(self.search_values, reset);
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    async function getFilterSuggestions(query: string, reset = false) {
      if (reset) {
        self.suggestions.page = 1;
        self.suggestions.data = [];
      }
      if (
        (self.suggestions.page <= self.suggestions.pagination.total_page &&
          !self.suggestions.loading) ||
        (self.suggestions.page == 1 && !self.suggestions.loading)
      ) {
        self.suggestions.loading = true;
        await appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.suggestions.page,
          {
            query: query,
          },
          (response: any, error: any) => {
            if (response) {
              if (response.metadata) {
                self.pagination = response.metadata;
              }
              const data = response.data.map((res: any) => {
                return {
                  name: res.title,
                };
              });
              self.suggestions.data.push(...data);
              self.suggestions.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.suggestions.loading = false;
          }
        );
      }
    }

    function searchAgreements(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.templates = [];
      }
      self.search_values = filter_value;
      self.search_view = true;
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.templates.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }
    return {
      self,
      updatedAgreementListing,
      getAgreementListing,
      getFilterSuggestions,
      searchAgreements,
      reloadAllData,
    };
  },
});
</script>