<template>
  <div>
    <AppToolbar
      :breadcrumb_home="self.breadcrumbHome"
      :breadcrumb_items="self.breadcrumb_items"
      permission="project"
      :options="self.toolbarOptions"
      :loading="self.loading_project_detail"
      @reload-data="reloadAllData"
    />
    <div class="the-content">
      <div v-if="!self.loading_project_detail" class="project-general">
        <ProjectDetail
          v-model:active_tab="self.active_tab"
          :project="self.selected_project"
          :shared_list="self.shared_list"
        />
      </div>
      <div
        v-if="self.loading_project_detail"
        class="flex justify-content-center flex-wrap w-full h-full"
      >
        <div class="flex align-items-center justify-content-center">
          <ProgressSpinner
            strokeWidth="3"
            animationDuration=".5s"
            aria-label="Loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, getCurrentInstance } from "vue";
import ProjectDetail from "./components/projectDetail.vue";
import ShareService from "@/modules/system/services/share";
export default defineComponent({
  name: "ProjectIndexData",
  components: {
    ProjectDetail,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      share_service: new ShareService(),
      properties: appContext.config.globalProperties,
      api_url: "projects",
      breadcrumbHome: {
        icon: "fas fa-layer-group mr-1",
        label: "Projects",
        to: { name: "projectIndex" },
      },
      breadcrumb_items: [] as any,
      active_tab: 0,
      toolbarOptions: {
        refresh: true,
      } as any,
      shared_list: [] as any,
      loading_project_detail: true,
      selected_project: {} as any,
      user_detail: {} as any,
    });

    onMounted(() => {
      reloadAllData();
      self.user_detail = self.properties.$store.getters["setting/getUserDetail"];
    });

    function getProjectDetail(project_id: any) {
      appContext.config.globalProperties.app_service.getData(
        `${self.api_url}/${project_id}`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.selected_project = response;
            viewGeneralInfo();
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        false
      );
    }

    function reloadAllData() {
      self.loading_project_detail = true;
      getProjectDetail(self.properties.$route.params.id);
    }

    function viewGeneralInfo() {
      self.toolbarOptions = {
        refresh: true,
      };
      if (self.properties.$route.params.tab == "vault") {
        self.active_tab = 1;
      }
      getProjectSharedDetail();
      setUpProjectBreadCrumb();
    }

    function getProjectSharedDetail() {
      self.share_service.getPrivateShareList(
        self.selected_project.id,
        self.selected_project.type == "project" ||
          self.selected_project.type == null
          ? "projects"
          : "media",
        (response: any) => {
          if (response) {
            self.shared_list = response.data;
          }
          self.loading_project_detail = false;
        }
      );
    }

    function setUpProjectBreadCrumb() {
      self.breadcrumb_items = [
        {
          label: self.selected_project.name,
        },
      ];
    }

    return {
      self,
      getProjectSharedDetail,
      setUpProjectBreadCrumb,
      reloadAllData,
      getProjectDetail,
    };
  },
});
</script>