<template>
  <div class="app-layout">
    <div v-show="self.user_loaded" class="page">
      <div class="body-content">
        <div
          v-if="self.user_loaded"
          class="body-wrap p-0 top-0"
        >
          <router-view />
        </div>
      </div>
    </div>
    <div
      v-if="!self.user_loaded"
      class="flex justify-content-center flex-wrap page-loading w-screen h-screen"
    >
      <div class="flex align-items-center justify-content-center">
        <ProgressSpinner
          strokeWidth="3"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  getCurrentInstance,
  onMounted
} from "vue";
export default defineComponent({
  name: "ProjectShareLayout",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      user_loaded: false,
      hide_header: false,
    });
    function userLoaded() {
      self.user_loaded = true;
    }
    onMounted(() => {
      setTimeout(()=>{
        self.user_loaded = true;
      },3000)
       
    });
    return {
      self,
      userLoaded, 
    };
  },
});
</script>
<style>
body{
  min-height: 1000px;
}
</style>
