<template>
  <div class="relative block h-full w-full">
    <div class="activities-tab">
      <TabView v-model:activeIndex="self.active">
        <TabPanel v-if="tabs.includes('details')" header="Details">
          <Detail v-if="self.active == 0" :doc="doc" :view_parent="view_parent" />
        </TabPanel>
        <TabPanel v-if="tabs.includes('share')" header="Share">
          <Shares
            v-if="self.active == 1"
            :doc="doc"
            @view-share-modal="$emit('open-share-modal')"
          />
        </TabPanel>
        <TabPanel v-if="tabs.includes('activities')" header="Activities">
          <Activities
            :doc="doc"
            :activities="activities"
            :pending_activities="pending_activities"
            :loading="loading"
            @cancel-upload="$emit('cancel-doc-upload', $event)"
            @remove-canceled-upload="$emit('remove-canceled-upload', $event)"
            @load-more-data="$emit('load-more-activities')"
            @view-activities-detail="$emit('view-activity-detail', $event)"
          />
        </TabPanel>
        <TabPanel v-if="tabs.includes('tags')" header="Tags">
          <Tags v-if="self.active == 3" :doc="doc" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import Detail from "./components/detail.vue";
import Shares from "./components/shares.vue";
import Activities from "./components/activities.vue";
import Tags from "./components/tags.vue";
export default defineComponent({
  name: "DocActivities",
  components: {
    Detail,
    Shares,
    Activities,
    Tags,
  },
  props: {
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    active_tab: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array,
      default: () => ["details", "share", "activities", "tags"] as any,
    } as any,
    activities: {
      type: Array,
      default: () => [] as any,
    },
    pending_activities: {
      type: Array,
      default: () => [] as any,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    view_parent:{
      type: Boolean,
      default: false,
    }
  },
  emits: [
    "update:active_tab",
    "open-share-modal",
    "load-more-activities",
    "view-activity-detail",
    "cancel-doc-upload",
    "remove-canceled-upload",
    "reupload-file"
  ],
  setup(props:any, { emit }) {
    const self = reactive({
      active: props.active_tab,
    });
    watch(
      () => self.active,
      (val: any) => {
        emit("update:active_tab", val);
        if (val == 2 || props.tabs[0] == "activities") {
          emit("load-more-activities", true);
        }
      }
    );
    watch(
      () => props.active_tab,
      (val: any) => {
        self.active = val;
      }
    );
    return {
      self,
    };
  },
});
</script> 