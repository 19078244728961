import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneralInfo = _resolveComponent("GeneralInfo")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_MyVault = _resolveComponent("MyVault")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, {
    id: "project-panel",
    activeIndex: _ctx.self.active_tab,
    "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.active_tab) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, { header: "General" }, {
        default: _withCtx(() => [
          _createVNode(_component_GeneralInfo, {
            project_detail: _ctx.project,
            project_shared_list: _ctx.shared_list
          }, null, 8, ["project_detail", "project_shared_list"])
        ]),
        _: 1
      }),
      _createVNode(_component_TabPanel, { header: "Dataroom" }, {
        default: _withCtx(() => [
          _createVNode(_component_MyVault, {
            id: "dataroom",
            load_all: true
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeIndex"]))
}