<template>
  <div>
    <AppToolbar
      :breadcrumb_home="self.breadcrumbItems"
      :options="{}"
      :loading="self.loading"
    />
    <div class="the-content">
      <div class="relative">
        <Tree
          v-model:expandedKeys="self.expandedKeys"
          :loading="self.loading"
          id="notification-setting"
          :value="self.treeList"
          v-if="self.treeList.length"
        >
          <template #default="slotProps">
            <div
              class="flex align-content-center justify-content-between flex-wrap"
            >
              <span
                class="flex align-content-center justify-content-between flex-wrap"
                @click="getNotificationDetail(slotProps)"
              >
                <span
                  v-html="appContext.config.globalProperties.$getIcon(slotProps.node)"
                  class="mr-2 tree-icon"
                >
                </span>
                <span 
                  class="pt-1" 
                  v-tooltip.bottom="slotProps.node.name"
                >
                  {{ slotProps.node.name }}
                </span>
              </span>
              <span class="flex align-content-center justify-content-between flex-wrap">
                <Button
                  class="ml-1"
                  :severity="`${slotProps.node.notification.is_mixed ? 'secondary' : slotProps.node.notification.enabled ? 'success' : 'danger'}`"
                  rounded
                  :aria-label="`${slotProps.node.notification.enabled ? 'Enabled' : 'Disabled' } Notification Setting`"
                  v-tooltip.right="`All Notification ${slotProps.node.notification.enabled ? 'Enabled' : 'Disabled'}`"
                  text
                  @click="updateSetting(slotProps.node)"
                >
                  <span v-if="!slotProps.node.hide_icon">
                    <i v-if="slotProps.node.notification.enabled || slotProps.node.notification.is_mixed" class="fas fa-bell"></i>
                    <i v-else class="fa-solid fa-bell-slash"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-bell"></i>
                  </span>
                </Button>
              </span>
            </div>
          </template>
        </Tree>
        <AppErrorMessage
          v-if="!self.treeList.length && !self.loading"
          message="There are no Projects/Folder (Shared with or Created by) you to view it's Notification Settings"
          page_icon="fa-solid fa-bell"
          hide_button
          hide_page_type
        />
        <InfiniteLoader @loadMore="getTreeList" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
export default defineComponent({
  name: "NotificationSettingIndex",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      confirm: useConfirm(),
      store: useStore(),
      expandedKeys: {} as any,
      breadcrumbItems: {
        icon: "fa fa-bell mr-1",
        label: "Notification Setting",
        to: { name: "notificationSettings" },
      },
      treeList: [] as any,
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
    }) as any;

    onMounted(() => {
      getTreeList();
    });

    function getTreeList() {
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          "tree",
          self.page,
          (response: any, error: any) => {
            if (response) {
              const res_data = getFormattedValue(response.data);
              self.treeList.push(...res_data);
              self.pagination = response.metadata;
              self.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.loading = false;
          }
        );
      }
    }

    function getFormattedValue(data: any, node_key: any = null) {
      let formatted_data = data.map((d: any, index: number) => {
        return {
          key: node_key ? `${index}_${node_key}` : index,
          id: d.id,
          name: d.name,
          doc_type: d.type ? d.type : "project",
          notification: d.notification,
          type: d.type ? d.type : "project",
          children: d.has_child
            ? [
                {
                  id: 0,
                },
              ]
            : [],
        };
      }) as any;
      formatted_data = formatted_data.filter((data: any) => data.doc_type == "project" || data.doc_type == "folder");
      return formatted_data;
    }

    function getNodeChild(
      node = {} as any,
      reset = false,
      no_collapse = false
    ) {
      if (reset) {
        node.page = 1;
        node.children = [] as any;
        node.pagination = {
          per_page: 15,
          total_records: 30,
          current_page: 1,
          total_page: 1,
        };
      }
      if (
        (node.page <= node.pagination.total_page && !self.loading) ||
        (node.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          `${node.doc_type == "project" ? "projects" : "media"}/${node.id}/childs`,
          node.page,
          (response: any, error: any) => {
            if (response) {
              node.pagination = response.metadata;
              const node_data = getFormattedValue(response.data, `${node.key}`) || [];
              if (node_data.length) {
                node.children.push(...node_data);
              }
              if (self.expandedKeys[node.key] && !no_collapse) {
                delete self.expandedKeys[node.key];
              } else {
                self.expandedKeys[node.key] = true;
              }
              node.page += 1;
              if (node.pagination.current_page < node.pagination.total_page) {
                self.loading = false;
                return getNodeChild(node, false, true);
              }
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.loading = false;
          }
        );
      }
    }

    async function updateSetting(node: any) {
      node.hide_icon = true;
      const notification_data = {
        doc_type: node.doc_type == "project" ? "projects" : node.doc_type,
        doc_id: node.id,
        receive_notification: !node.notification.enabled,
      };
      await appContext.config.globalProperties.app_service.createData(
        "upload-notifications/settings",
        notification_data,
        (response: any, error: any) => {
          if (response) {
            appContext.config.globalProperties.$toastMessage(response);
            node.notification.enabled = !node.notification.enabled;
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
          node.hide_icon = false;
        }
      );
    }
    function getNotificationDetail(data: any) {
      if (self.expandedKeys[data.node.key]) {
        delete self.expandedKeys[data.node.key];
        // } else if (data.node.children) {
        //   self.expandedKeys[data.node.key] = true;
      } else {
        getNodeChild(data.node, true, false);
      }
    }
    return {
      appContext,
      self,
      getTreeList,
      getNodeChild,
      updateSetting,
      getNotificationDetail,
    };
  },
});
</script>
