import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_EditTemplate = _resolveComponent("EditTemplate")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_CreateTemplate = _resolveComponent("CreateTemplate")!
  const _component_Button = _resolveComponent("Button")!
  const _component_EmailTest = _resolveComponent("EmailTest")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "email_template",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      filter: _ctx.self.filterOptions,
      loading: _ctx.self.loading,
      suggestions: _ctx.self.suggestions,
      search_placeholder: "Search Email Templates",
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true)),
      onSubmitFilterData: _ctx.searchTemplates,
      onGetSuggestions: _ctx.getFilterSuggestions
    }, null, 8, ["breadcrumb_home", "options", "filter", "loading", "suggestions", "onReloadData", "onSubmitFilterData", "onGetSuggestions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "email_template",
        loading: _ctx.self.loading,
        items: _ctx.self.templates,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getTemplateListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_template,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.edit_template) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Edit Template")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_EditTemplate, {
            template: _ctx.self.selected_template,
            emailTemplateTypes: _ctx.self.emailTemplateTypes,
            emailTags: _ctx.self.emailTags,
            toolbar: _ctx.self.toolbar,
            onUpdatedTemplate: _ctx.reloadAllData
          }, null, 8, ["template", "emailTemplateTypes", "emailTags", "toolbar", "onUpdatedTemplate"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Add Template")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_CreateTemplate, {
            emailTemplateTypes: _ctx.self.emailTemplateTypes,
            emailTags: _ctx.self.emailTags,
            toolbar: _ctx.self.toolbar,
            onCreatedTemplate: _ctx.reloadAllData
          }, null, 8, ["emailTemplateTypes", "emailTags", "toolbar", "onCreatedTemplate"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Dialog, {
        visible: _ctx.self.view_send,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.view_send) = $event)),
        modal: "",
        style: { width: '50vw' }
      }, {
        header: _withCtx(() => [
          _createVNode(_component_Button, {
            label: "Test Email",
            icon: "icofont icofont-paper-plane",
            text: "",
            class: "primary-color p-0 font-bold opacity-100",
            disabled: ""
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_EmailTest, {
            template: _ctx.self.selected_template,
            onSentMail: _cache[3] || (_cache[3] = ($event: any) => (_ctx.self.view_send = false))
          }, null, 8, ["template"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}