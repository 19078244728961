<template>
  <div class="app-layout">
    <div v-show="self.user_loaded" class="page">
      <AppHeader @user-loaded="userLoaded" />
      <div
        class="body-content"
        :class="{ 'sidebar-collapsed': self.sidebar_collapsed }"
      >
        <AppSidebar @change-sidebar-value="sidebarCollapsed" />
        <div class="body-wrap" v-if="self.user_loaded"> 
          <router-view />
        </div>
      </div>
    </div>
    <div
      v-if="!self.user_loaded"
      class="flex justify-content-center flex-wrap page-loading w-screen h-screen"
    >
      <div class="flex align-items-center justify-content-center">
        <ProgressSpinner
          strokeWidth="3"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  reactive, 
  getCurrentInstance,
  watch
} from "vue";
import AppSidebar from "./applicationSidebar.vue";
const AppHeader = defineAsyncComponent(() => import('./applicationHeader.vue'));
export default defineComponent({
  name: "AppLayout",
  components: {
    AppHeader,
    AppSidebar,
  },
  setup() {
    const { appContext }:any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      user_loaded: false,
      sidebar_collapsed: false,
    }); 

    watch(
      () => self.properties.$route,
      () => {
        window.scrollTo(
          { 
            top: 0,
            behavior: 'smooth'
          }
        );
      }
    );
   
    function sidebarCollapsed(val: any) {
      self.sidebar_collapsed = val;
    }

    function userLoaded() {
      self.user_loaded = true;
    }

    return {
      self, 
      sidebarCollapsed,
      userLoaded,
     
    };
  },
});
</script>