<template>
  <div v-show="!self.loading">
    <h2 class="mt-3">
      <i class="fas fa-info-circle mr-1"></i> General Information
    </h2>
    <div class="grid">
      <div class="col-4">
        <Card class="h-17rem">
          <template #content>
            <div class="grid" v-for="(detail, key) in self.info_detail" :key="key">
              <div class="col-4">
                <b v-html="appContext.config.globalProperties.$getKeyName(key, '_')
                  "></b>
              </div>
              <div class="col" v-html="detail"></div>
            </div>
            <div v-if="project_detail.shared" class="grid">
              <div class="col-4">
                <b class="mb-2 block">Shared With:</b>
              </div>
              <div class="col">
                <div class="flex flex-row flex-wrap">
                  <template v-for="(user, index) in project_shared_list.slice(0, 2)">
                    <Avatar v-if="user.image" :image="appContext.config.globalProperties.$getFullPath(
                      user.image
                    )
                      " :key="index" shape="circle" class="mr-1 flex align-items-center justify-content-center"
                      v-tooltip.bottom="user.name" />
                    <Avatar v-else :key="user.id" :label="user.name ? user.name.charAt(0) : user.email.charAt(0)
                      " shape="circle" class="mr-1 text-white flex align-items-center justify-content-center"
                      :class="`bg-${self.bg_colors[index]}-300`" v-tooltip.bottom="user.name" />
                  </template>
                  <Avatar v-if="project_shared_list.length > 2" @click="toggleAvatarPanel"
                    :label="`+ ${project_shared_list.length - 2}`" shape="circle" class="cursor-pointer" />
                </div>
              </div>
              <OverlayPanel ref="avatarPanel" class="project-panel">
                <Listbox
                  :options="
                    project_shared_list.slice(2, project_shared_list.length)
                  "
                  optionLabel="name"
                  class="w-full p-0 border-none"
                  listStyle="max-height:250px"
                >
                  <template #option="slotProps">
                    <div class="flex align-items-center p-2">
                      <Avatar
                        v-if="slotProps.option.image"
                        :image="
                          appContext.config.globalProperties.$getFullPath(
                            slotProps.option.image
                          )
                        "
                        shape="circle"
                        class="mr-1"
                      />
                      <Avatar
                        v-else
                        :label="
                          slotProps.option.name
                            ? slotProps.option.name.charAt(0)
                            : slotProps.option.name
                        "
                        shape="circle"
                        class="mr-1 text-white"
                        :class="`bg-${self.bg_colors[Math.floor(Math.random() * slotProps.index + 4)]}-300`"
                      />
                      {{ slotProps.option.name }}
                    </div>
                  </template>
                </Listbox>
                 
              </OverlayPanel>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-8">
        <div class="grid">
          <div class="col">
            <Card class="h-17rem">
              <template #content>
                <div class="text-center uppercase">
                  <div class="the-icon p-4 border-circle max-w-6rem m-auto text-center mb-3"
                    style="background: var(--app-table-highlight)">
                    <i class="fas fa-folder text-6xl" style="color: var(--app-main-color)"></i>
                  </div>
                  <h2>{{ self.project_detail.folder_count }}</h2>
                  Folders
                </div>
              </template>
            </Card>
          </div>
          <div class="col">
            <Card class="h-17rem">
              <template #content>
                <div class="text-center uppercase">
                  <div class="the-icon p-4 border-circle max-w-6rem m-auto text-center mb-3"
                    style="background: var(--app-loading-bg)">
                    <i class="fas fa-file text-6xl" style="color: var(--app-thumbnail-icon)"></i>
                  </div>
                  <h2>
                    {{ self.project_detail.file_count }}
                    <small>
                      (
                      {{
                        appContext.config.globalProperties.$getFileSize(
                          self.project_detail.file_size
                        )
                      }}
                      )
                    </small>
                  </h2>
                  Files
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted,
  getCurrentInstance,
  ref,
} from "vue";
export default defineComponent({
  name: "GeneralInfo",
  props: {
    project_detail: {
      type: Object,
    } as any,
    project_shared_list: {
      type: Array,
    } as any,
  },
  setup(props: any) {
    const avatarPanel = ref();
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      info_detail: {},
      project_detail: {} as any,
      loading: true,
      bg_colors: [
        "blue",
        "green",
        "yellow",
        "cyan",
        "indigo",
        "teal",
        "orange",
        "red",
        "primary",
      ],
    });
    onMounted(() => {
      if (Object.keys(props.project_detail).length) {
        getProjectGeneralInfo();
        getProjectDetail();
      }
    });
    function getProjectGeneralInfo() {
      self.info_detail = {
        name: props.project_detail.name,
        created_by: props.project_detail.owner
          ? props.project_detail.owner.name
            ? props.project_detail.owner.name
            : null
          : null,
        created_at: appContext.config.globalProperties.$dayMonthDateYear(
          props.project_detail.created_at
        ),
        is_shared: `<i class="${props.project_detail.shared
          ? "fa-solid fa-check"
          : "fa-solid fa-times"
          } text-primary"></i>`,
      };
    }

    function getProjectDetail() {
      self.loading = true;
      appContext.config.globalProperties.app_service.getData(
        `projects/${props.project_detail.id}/aggregations`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.project_detail = response;
            self.loading = false;
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        false
      );
    }

    function toggleAvatarPanel(ev: Event) {
      avatarPanel.value.toggle(ev);
    }

    return {
      appContext,
      avatarPanel,
      self,
      getProjectDetail,
      toggleAvatarPanel,
      getProjectGeneralInfo,
    };
  },
});
</script>
