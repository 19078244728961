import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = {
  key: 0,
  class: "vault-docs"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_DocTableView = _resolveComponent("DocTableView")!
  const _component_DocGrid = _resolveComponent("DocGrid")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_Activities = _resolveComponent("Activities")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      current_view: _ctx.self.current_view,
      "onUpdate:current_view": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.current_view) = $event)),
      current_sort: _ctx.self.current_sort,
      "onUpdate:current_sort": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.current_sort) = $event)),
      view_activities: _ctx.self.activities.view,
      "onUpdate:view_activities": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.activities.view) = $event)),
      selected_items: _ctx.self.selectedDocuments,
      "onUpdate:selected_items": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
      permission: "trash",
      search_placeholder: "Search Trash",
      breadcrumb_home: _ctx.self.breadcrumbHome,
      options: _ctx.self.toolbarOptions,
      type_options: _ctx.self.filterTypeOptions,
      filter: _ctx.self.filterOptions,
      loading: _ctx.self.loading,
      onReloadData: _ctx.reloadAllData,
      onEmptyTrash: _ctx.emptyTrash,
      onSubmitFilterData: _ctx.searchDocuments
    }, null, 8, ["current_view", "current_sort", "view_activities", "selected_items", "breadcrumb_home", "options", "type_options", "filter", "loading", "onReloadData", "onEmptyTrash", "onSubmitFilterData"]),
    _createElementVNode("div", {
      class: "the-content",
      onContextmenu: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.self.activities.view ? 'col-8' : 'col-12 width-transaction'])
        }, [
          (_ctx.self.documents.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _withDirectives(_createVNode(_component_DocTableView, {
                  ref: "docTable",
                  selected_documents: _ctx.self.selectedDocuments,
                  "onUpdate:selected_documents": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
                  selected_document: _ctx.self.selected_document,
                  "onUpdate:selected_document": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.selected_document) = $event)),
                  permission: "trash",
                  context_items: _ctx.self.menu_items,
                  loading: _ctx.self.loading,
                  items: _ctx.self.documents,
                  fields: _ctx.self.table_fields,
                  pagination: _ctx.self.pagination,
                  activities_view: _ctx.self.activities.view,
                  clickable: "",
                  drag_selector: false,
                  disable_select: "",
                  onLoadMoreDocs: _ctx.getDocument,
                  onUpdateMenu: _ctx.getMenuItems
                }, null, 8, ["selected_documents", "selected_document", "context_items", "loading", "items", "fields", "pagination", "activities_view", "onLoadMoreDocs", "onUpdateMenu"]), [
                  [_vShow, _ctx.self.current_view == 'list' && !_ctx.self.loading_docs]
                ]),
                _withDirectives(_createVNode(_component_DocGrid, {
                  ref: "docGrid",
                  selected_documents: _ctx.self.selectedDocuments,
                  "onUpdate:selected_documents": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
                  selected_document: _ctx.self.selected_document,
                  "onUpdate:selected_document": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.self.selected_document) = $event)),
                  permission: "trash",
                  context_items: _ctx.self.menu_items,
                  loading: _ctx.self.loading,
                  items: _ctx.self.documents,
                  disable_select: "",
                  drag_selector: false,
                  create: {},
                  activities_view: _ctx.self.activities.view,
                  onLoadMoreDocs: _ctx.getDocument,
                  onUpdateMenu: _ctx.getMenuItems
                }, null, 8, ["selected_documents", "selected_document", "context_items", "loading", "items", "activities_view", "onLoadMoreDocs", "onUpdateMenu"]), [
                  [_vShow, _ctx.self.current_view == 'grid' && !_ctx.self.loading_docs]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.self.loading_docs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_ProgressSpinner, {
                  style: {"width":"20px","height":"20px"},
                  strokeWidth: "5",
                  animationDuration: ".5s",
                  "aria-label": "Loading"
                })
              ]))
            : _createCommentVNode("", true),
          (!_ctx.self.loading_docs && !_ctx.self.documents.length)
            ? (_openBlock(), _createBlock(_component_AppErrorMessage, {
                key: 2,
                page_type: "trashed items",
                page_icon: "fas fa-trash",
                hide_button: ""
              }))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.self.activities.view)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "col-4",
              onContextmenu: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode(_component_Activities, {
                active_tab: _ctx.self.activities.active_tab,
                "onUpdate:active_tab": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.self.activities.active_tab) = $event)),
                doc: _ctx.self.selected_document,
                activities: _ctx.self.activities.data,
                loading: _ctx.self.activities.loading,
                tabs: ['activities'],
                onLoadMoreActivities: _ctx.getActivities
              }, null, 8, ["active_tab", "doc", "activities", "loading", "onLoadMoreActivities"])
            ], 32))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ]))
}