<template>
  <div id="form-content" class="page-content">
    <Form ref="editTemplate" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0"
          >Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="name"
            rules="required"
            autocomplete="off"
            v-model="self.template.name"
          >
            <InputText type="text" v-model="self.template.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="name" />
        </div>
      </div>
      <div class="field grid">
        <label for="description" class="col-12 mb-2 md:col-3 md:mb-0"
          >Description <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="description"
            name="description"
            rules="required"
            autocomplete="off"
            v-model="self.template.description"
          >
            <Textarea v-model="self.template.description" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="description" />
        </div>
      </div>
      <div class="field grid">
        <label for="content" class="col-12 mb-2 md:col-12 md:mb-2"
          >Content <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-12">
         
          <json-editor-vue id="json-editor" class="editor w-full"   v-model="self.template.content" /> 
          <ErrorMessage as="small" class="p-error" name="content" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update Template"
          :loading="self.updating_template"
          @click.prevent="updateTemplate"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { appContext }: any = getCurrentInstance();
const props = defineProps({
  template: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const editTemplate = ref();
const emit = defineEmits(["updated-template"]);
const self = reactive({
  store: useStore(),
  template: getFormDetail(),
  updating_template: false,
});
function getFormDetail() {
  return {
    name: props.template.name,
    description: props.template.description,
    content: parseData(props.template.content),
  };
}

function parseData(content:any){
  try {
    return JSON.parse(content);
  } catch (e) {
    return content;
  }
}

async function updateTemplate() {
  const validate = await editTemplate.value.validate();
  if (!validate.valid) return;
  self.updating_template = true;
  await appContext.config.globalProperties.app_service.updateData(
    "folder-templates",
    self.template,
    props.template.id,
    (response: any, error: any) => {
      if (response) {
        self.updating_template = false;
      appContext.config.globalProperties.$toastMessage(response);
      if (response.status >= 200 && response.status < 300) {
        emit("updated-template", response.data.data);
      }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>