import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row flex-wrap"
}
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row flex-wrap"
}
const _hoisted_4 = ["href", "target"]
const _hoisted_5 = { class: "flex align-items-center breadcrumb-data" }
const _hoisted_6 = { class: "flex align-items-center" }
const _hoisted_7 = ["href", "target"]
const _hoisted_8 = { class: "flex align-items-center" }
const _hoisted_9 = { class: "text-primary" }
const _hoisted_10 = {
  key: 0,
  class: "w-full"
}
const _hoisted_11 = {
  key: 2,
  class: "p-input-icon-left pr-2"
}
const _hoisted_12 = { class: "close-icon" }
const _hoisted_13 = { class: "fluid" }
const _hoisted_14 = {
  key: 0,
  name: "field"
}
const _hoisted_15 = { class: "field grid justify-content-between" }
const _hoisted_16 = { class: "col-9" }
const _hoisted_17 = { class: "formgroup-inline checkbox-wrap" }
const _hoisted_18 = ["for"]
const _hoisted_19 = {
  key: 1,
  name: "field"
}
const _hoisted_20 = { class: "field grid justify-content-between" }
const _hoisted_21 = { class: "col-9" }
const _hoisted_22 = { class: "formgroup-inline checkbox-wrap" }
const _hoisted_23 = ["for"]
const _hoisted_24 = {
  key: 2,
  name: "field"
}
const _hoisted_25 = { class: "field grid justify-content-between" }
const _hoisted_26 = { class: "col-9" }
const _hoisted_27 = {
  key: 3,
  name: "field"
}
const _hoisted_28 = { class: "field grid justify-content-between" }
const _hoisted_29 = { class: "col-9" }
const _hoisted_30 = {
  key: 4,
  name: "field"
}
const _hoisted_31 = { class: "field grid justify-content-between" }
const _hoisted_32 = { class: "col-9 grid justify-content-between" }
const _hoisted_33 = { class: "col-6 field grid mb-0 p-0" }
const _hoisted_34 = { class: "col-8 p-0" }
const _hoisted_35 = { class: "col-6 field grid mb-0 p-0" }
const _hoisted_36 = { class: "col-10 pl-0" }
const _hoisted_37 = { class: "flex align-items-center justify-content-end mt-2" }
const _hoisted_38 = ["onClick"]
const _hoisted_39 = ["onClick"]
const _hoisted_40 = ["onClick"]
const _hoisted_41 = {
  key: 9,
  class: "mr-3 text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_AppProgressBar = _resolveComponent("AppProgressBar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    id: "sub-header",
    class: _normalizeClass({ 'w-full': _ctx.is_public })
  }, [
    _createVNode(_component_Toolbar, {
      id: "breadcrumb",
      class: "py-1"
    }, {
      start: _withCtx(() => [
        (_ctx.self.view_breadcrumb)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.self.breadcrumbItem.length < 3)
                ? (_openBlock(), _createBlock(_component_Breadcrumb, {
                    key: 0,
                    home: _ctx.breadcrumb_home,
                    model: _ctx.self.breadcrumbItem
                  }, {
                    item: _withCtx(({ label, item, props }) => [
                      (item.to)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: item.to
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.action)), [
                                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                              ], 16)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : (_openBlock(), _createElementBlock("a", _mergeProps({
                            key: 1,
                            href: item.url,
                            target: item.target
                          }, props.action), [
                            (item.icon)
                              ? (_openBlock(), _createElementBlock("span", _normalizeProps(_mergeProps({ key: 0 }, props.icon)), null, 16))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                          ], 16, _hoisted_2))
                    ]),
                    _: 1
                  }, 8, ["home", "model"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Breadcrumb, {
                      home: _ctx.breadcrumb_home,
                      model: _ctx.self.breadcrumbItem.slice(0, 1)
                    }, {
                      item: _withCtx(({ label, item, props }) => [
                        (item.to)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: item.to
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.action)), [
                                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                                ], 16)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : (_openBlock(), _createElementBlock("a", _mergeProps({
                              key: 1,
                              href: item.url,
                              target: item.target
                            }, props.action), [
                              (item.icon)
                                ? (_openBlock(), _createElementBlock("span", _normalizeProps(_mergeProps({ key: 0 }, props.icon)), null, 16))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                            ], 16, _hoisted_4))
                      ]),
                      _: 1
                    }, 8, ["home", "model"]),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[16] || (_cache[16] = _createElementVNode("i", { class: "pi pi-chevron-right px-2" }, null, -1)),
                      _createVNode(_component_Button, {
                        type: "button",
                        text: "",
                        icon: "pi pi-ellipsis-h",
                        class: "breadcrumb-overflow p-0 w-auto",
                        onClick: _ctx.toggleBreadcrumbItem
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_OverlayPanel, {
                        class: "breadcrumb-panel",
                        ref: "breadcrumbItem"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Listbox, {
                            options: 
                    _ctx.self.breadcrumbItem.slice(1, _ctx.self.breadcrumbItem.length - 1)
                  ,
                            class: "w-full md:w-14rem"
                          }, {
                            option: _withCtx((slotProps) => [
                              _createElementVNode("div", {
                                class: "w-100 h-100",
                                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleBreadcrumbItem && _ctx.toggleBreadcrumbItem(...args)))
                              }, [
                                (slotProps.option.to)
                                  ? (_openBlock(), _createBlock(_component_router_link, {
                                      key: 0,
                                      to: slotProps.option.to
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_6, [
                                          _createElementVNode("div", null, _toDisplayString(slotProps.option.label), 1)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["to"]))
                                  : (_openBlock(), _createElementBlock("a", {
                                      key: 1,
                                      href: slotProps.option.url,
                                      target: slotProps.option.target
                                    }, [
                                      _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                                    ], 8, _hoisted_7))
                              ])
                            ]),
                            _: 1
                          }, 8, ["options"])
                        ]),
                        _: 1
                      }, 512),
                      _createElementVNode("div", _hoisted_8, [
                        _cache[15] || (_cache[15] = _createElementVNode("i", { class: "pi pi-chevron-right px-2" }, null, -1)),
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.self.breadcrumbItem[_ctx.self.breadcrumbItem.length - 1].label), 1)
                        ])
                      ])
                    ])
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      center: _withCtx(() => [
        (_ctx.selected_items.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _withDirectives(_createVNode(_component_Button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:selected_items', []))),
                label: `${_ctx.selected_items.length} item(s) Selected`,
                icon: "icofont icofont-close",
                iconPos: "right",
                text: ""
              }, null, 8, ["label"]), [
                [
                  _directive_tooltip,
                  'Clear Selection',
                  void 0,
                  { bottom: true }
                ]
              ]),
              _withDirectives(_createVNode(_component_Button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('download-item'))),
                "aria-label": "Download",
                text: "",
                icon: "fas fa-download"
              }, null, 512), [
                [
                  _directive_tooltip,
                  'Download Selected Item(s)',
                  void 0,
                  { bottom: true }
                ]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      end: _withCtx(() => [
        (
            _ctx.options.empty &&
            (_ctx.self.permissions[_ctx.permission]
              ? _ctx.self.permissions[_ctx.permission].edit == 1 ||
                _ctx.self.permissions[_ctx.permission].manage == 1
              : false)
          )
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('empty-trash'))),
              label: "Empty Trash",
              icon: "icofont icofont-trash",
              text: ""
            }))
          : _createCommentVNode("", true),
        (_ctx.options.widget)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('open-widget'))),
              icon: "fas fa-th-large mr-1",
              "aria-label": "Widget",
              label: "Widget",
              class: "widget-btn"
            }))
          : _createCommentVNode("", true),
        (_ctx.options.filter && (_ctx.self.permissions[_ctx.permission] ? _ctx.self.permissions[_ctx.permission].read == 1 || _ctx.self.permissions[_ctx.permission].manage == 1 : false))
          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
              _cache[17] || (_cache[17] = _createElementVNode("i", { class: "icofont icofont-search-1" }, null, -1)),
              _createVNode(_component_Field, {
                as: "span",
                type: "text",
                id: "title",
                name: "Title",
                rules: "required",
                autocomplete: "off",
                modelValue: _ctx.self.filter_value.query,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.self.filter_value.query) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: _ctx.self.filter_value.query,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.filter_value.query) = $event)),
                    placeholder: _ctx.search_placeholder,
                    onKeyup: _withKeys(_ctx.submitFilterData, ["enter"])
                  }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.self.filter_value.query)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.self.filter_value.query = null)),
                    icon: "fa-solid fa-times",
                    "aria-label": "Clear Query",
                    class: "search-dropdown mr-5"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_Button, {
                onClick: _ctx.toggleSearch,
                icon: "fa-solid fa-caret-down",
                "aria-label": "Search Dropdown",
                class: "search-dropdown mr-1"
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_OverlayPanel, {
          ref: "searchPanel",
          appendTo: "body",
          id: "search_panel",
          style: {"width":"500px"},
          breakpoints: { '960px': '75vw' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Button, {
                icon: "icofont icofont-close",
                onClick: _withModifiers(_ctx.toggleSearch, ["prevent"]),
                text: "",
                rounded: "",
                class: "text-900 bg-transparent"
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_ctx.filter.status)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", {
                        for: "field",
                        class: "col-3 mb-0"
                      }, "Status", -1)),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.status_field, (status, key) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: key,
                              class: "flex align-items-center mr-2"
                            }, [
                              _createVNode(_component_Checkbox, {
                                onClick: _ctx.closeAndReOpenPanel,
                                modelValue: _ctx.self.filter_value.status,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.self.filter_value.status) = $event),
                                value: status.value,
                                inputId: `${status.value}-${status.text}`,
                                name: status.text
                              }, null, 8, ["onClick", "modelValue", "onUpdate:modelValue", "value", "inputId", "name"]),
                              _createElementVNode("label", {
                                for: `${status.value}-${status.text}`,
                                class: "ml-2"
                              }, _toDisplayString(status.text), 9, _hoisted_18)
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.filter.type)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _cache[19] || (_cache[19] = _createElementVNode("label", {
                        for: "field",
                        class: "col-3 mb-0"
                      }, "Type", -1)),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.typeOptions, (field, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "field-checkbox",
                              key: index
                            }, [
                              _createVNode(_component_Checkbox, {
                                onClick: _ctx.closeAndReOpenPanel,
                                modelValue: _ctx.self.filter_value.type,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.self.filter_value.type) = $event)),
                                value: field.value,
                                inputId: `${field.value}-${field.text}`,
                                name: field.text
                              }, null, 8, ["onClick", "modelValue", "value", "inputId", "name"]),
                              _createElementVNode("label", {
                                for: `${field.value}-${field.text}`,
                                class: "ml-0"
                              }, _toDisplayString(field.text), 9, _hoisted_23)
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.filter.owner)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", {
                        for: "owner",
                        class: "col-3 mb-0"
                      }, "Owner", -1)),
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_Dropdown, {
                          inputId: "owner",
                          modelValue: _ctx.self.filter_value.owner,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.self.filter_value.owner) = $event)),
                          options: _ctx.self.filterOwnerOptions,
                          optionLabel: "text",
                          optionValue: "value",
                          placeholder: "Select a Owner Type"
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.filter.role)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _cache[21] || (_cache[21] = _createElementVNode("label", {
                        for: "role",
                        class: "col-3 mb-0"
                      }, "Role", -1)),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_Dropdown, {
                          inputId: "role",
                          modelValue: _ctx.self.filter_value.role,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.self.filter_value.role) = $event)),
                          options: _ctx.roles,
                          optionLabel: "name",
                          optionValue: "slug",
                          placeholder: "Select a Role"
                        }, null, 8, ["modelValue", "options"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.filter.date)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _cache[24] || (_cache[24] = _createElementVNode("label", { class: "col-3 mb-0" }, "Created Date", -1)),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, [
                          _cache[22] || (_cache[22] = _createElementVNode("label", { class: "col-4 mb-0 p-0" }, "From", -1)),
                          _createElementVNode("div", _hoisted_34, [
                            _createVNode(_component_Calendar, {
                              onFocus: _ctx.closeAndReOpenPanel,
                              onKeydown: _ctx.closeAndReOpenPanel,
                              id: "date-from",
                              modelValue: _ctx.self.filter_value.start_date,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.self.filter_value.start_date) = $event)),
                              dateFormat: "yy-mm-dd"
                            }, null, 8, ["onFocus", "onKeydown", "modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_35, [
                          _cache[23] || (_cache[23] = _createElementVNode("label", { class: "col-2 mb-0 p-0" }, "To", -1)),
                          _createElementVNode("div", _hoisted_36, [
                            _createVNode(_component_Calendar, {
                              onFocus: _ctx.closeAndReOpenPanel,
                              onKeydown: _ctx.closeAndReOpenPanel,
                              id: "date-to",
                              modelValue: _ctx.self.filter_value.end_date,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.self.filter_value.end_date) = $event)),
                              dateFormat: "yy-mm-dd"
                            }, null, 8, ["onFocus", "onKeydown", "modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_Button, {
                  label: "Reset",
                  onClick: _ctx.resetFilterOptions,
                  class: "mr-2",
                  severity: "danger"
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  label: "Search",
                  class: "button-primary",
                  onClick: _ctx.submitFilterData
                }, null, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }, 512),
        (
            _ctx.options.activities &&
            (_ctx.self.permissions[_ctx.permission]
              ? _ctx.self.permissions[_ctx.permission].read == 1 ||
                _ctx.self.permissions[_ctx.permission].manage == 1
              : false)
          )
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 3,
              class: "activities-btn mr-1",
              onClick: _ctx.toggleActivities
            }, {
              default: _withCtx(() => [
                _cache[25] || (_cache[25] = _createElementVNode("span", { class: "switch-title pr-2" }, "Activities", -1)),
                _createVNode(_component_InputSwitch, {
                  disabled: "",
                  modelValue: _ctx.view_activities
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["onClick"])), [
              [
                _directive_tooltip,
                
            _ctx.view_activities ? 'Hide Activities' : 'View Activities'
          ,
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true),
        (
            _ctx.options.refresh &&
            (_ctx.self.permissions[_ctx.permission]
              ? _ctx.self.permissions[_ctx.permission].read == 1 ||
                _ctx.self.permissions[_ctx.permission].manage == 1
              : false)
          )
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 4,
              class: _normalizeClass(["mr-1", {
            'mr-5': _ctx.options.add && _ctx.options.add_dropdown && !_ctx.options.view_data,
          }]),
              loading: _ctx.loading,
              loadingIcon: "fas fa-sync load-icon",
              icon: "fas fa-sync load-icon",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('reload-data')))
            }, null, 8, ["class", "loading"])), [
              [
                _directive_tooltip,
                'Refresh',
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true),
        (!_ctx.is_public && _ctx.options.view_sort)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 5,
              type: "button",
              "aria-label": "Sorting",
              onClick: _ctx.toggleSorting,
              "aria-haspopup": "true",
              "aria-controls": "view-sorting",
              icon: "fa-solid fa-arrow-up-z-a"
            }, null, 8, ["onClick"])), [
              [
                _directive_tooltip,
                'Sorting',
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true),
        (!_ctx.is_public)
          ? (_openBlock(), _createBlock(_component_OverlayPanel, {
              key: 6,
              ref: "viewSorting",
              appendTo: "body",
              showCloseIcon: false,
              class: "view-sorting w-17rem"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Listbox, {
                  options: _ctx.self.sorting_data,
                  optionLabel: "label",
                  optionValue: "value",
                  "model-value": _ctx.current_sort
                }, {
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", {
                      onClick: _withModifiers(($event: any) => (_ctx.updateSorting($event, slotProps.option.value)), ["stop"]),
                      class: "flex align-items-center"
                    }, [
                      _createElementVNode("div", null, _toDisplayString(slotProps.option.label), 1)
                    ], 8, _hoisted_38)
                  ]),
                  _: 1
                }, 8, ["options", "model-value"])
              ]),
              _: 1
            }, 512))
          : _createCommentVNode("", true),
        (_ctx.options.view_data)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 7,
              type: "button",
              "aria-label": "Show View",
              onClick: _ctx.toggleView,
              "aria-haspopup": "true",
              "aria-controls": "view-menu",
              icon: "fas fa-bars"
            }, null, 8, ["onClick"])), [
              [
                _directive_tooltip,
                'Show View',
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true),
        _createVNode(_component_OverlayPanel, {
          ref: "viewMenu",
          appendTo: "body",
          showCloseIcon: false,
          class: "view-menu w-10rem"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Listbox, {
              options: _ctx.self.view_data,
              optionLabel: "label",
              optionValue: "value",
              "model-value": _ctx.current_view
            }, {
              option: _withCtx((slotProps) => [
                _createElementVNode("div", {
                  class: "flex align-items-center",
                  onClick: _withModifiers(($event: any) => (_ctx.updateView($event, slotProps.option.value)), ["stop"])
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`${slotProps.option.icon} mr-1`)
                  }, null, 2),
                  _createElementVNode("div", null, _toDisplayString(slotProps.option.label), 1)
                ], 8, _hoisted_39)
              ]),
              _: 1
            }, 8, ["options", "model-value"])
          ]),
          _: 1
        }, 512),
        (
            _ctx.options.add &&
            (_ctx.self.permissions[_ctx.permission]
              ? _ctx.self.permissions[_ctx.permission].write == 1 ||
                _ctx.self.permissions[_ctx.permission].manage == 1
              : false)
          )
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 8,
              icon: _ctx.add_icon,
              onClick: _cache[14] || (_cache[14] = ($event: any) => (
            !_ctx.options.add_dropdown
              ? _ctx.$emit('create-new', true)
              : _ctx.toggleAdd($event)
          ))
            }, null, 8, ["icon"])), [
              [
                _directive_tooltip,
                'Add',
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true),
        _createVNode(_component_OverlayPanel, {
          ref: "speedDial",
          appendTo: "body",
          showCloseIcon: false,
          class: "view-menu w-16rem"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Listbox, {
              options: _ctx.add_options,
              optionLabel: "label",
              optionValue: "value"
            }, {
              option: _withCtx((slotProps) => [
                _createElementVNode("div", {
                  onClick: _withModifiers(($event: any) => {
                  _ctx.$emit('create-new', slotProps.option.value);
                  _ctx.toggleAdd($event);
                }, ["stop"]),
                  class: "flex align-items-center"
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`${slotProps.option.icon} mr-1`)
                  }, null, 2),
                  _createElementVNode("div", null, _toDisplayString(slotProps.option.label), 1)
                ], 8, _hoisted_40)
              ]),
              _: 1
            }, 8, ["options"])
          ]),
          _: 1
        }, 512),
        (
            _ctx.queue.view &&
            (_ctx.self.permissions[_ctx.permission]
              ? _ctx.self.permissions[_ctx.permission].read == 1 ||
                _ctx.self.permissions[_ctx.permission].manage == 1
              : false)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_ctx.queue.text), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.hideProgress)
      ? (_openBlock(), _createBlock(_component_AppProgressBar, {
          key: 0,
          loading: _ctx.loading
        }, null, 8, ["loading"]))
      : _createCommentVNode("", true)
  ], 2))
}