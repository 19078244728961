import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "flex align-items-center justify-content-end mt-2" }

import { getCurrentInstance, reactive, ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'suspendUser',
  props: {
  user: {
    type: Object,
  },
},
  emits: ["user-suspended"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();
const props: any = __props;
const emit = __emit;
const suspendReason = ref();
const self = reactive({
  api_url: "users",
  user: {
    reason: "",
  },
  suspending_user: false,
});

async function suspendUser() {
  const validate = await suspendReason.value.validate();
  if (!validate.valid) {
    return;
  }
  self.suspending_user = true;
  await appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    self.user,
    props.user.id,
    (response: any) => {
      self.suspending_user = false;
      appContext.config.globalProperties.$toastMessage(response);
      if (response.status >= 200 && response.status < 300) {
        emit("user-suspended", null);
      }
    },
    "suspend",
    true
  );
}

return (_ctx: any,_cache: any) => {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "suspendReason",
      ref: suspendReason,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Field, {
          class: "w-full",
          as: "div",
          type: "text",
          id: "suspend-reason",
          name: "Suspend Reason",
          rules: "required",
          autocomplete: "off",
          modelValue: self.user.reason,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.user.reason) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              modelValue: self.user.reason,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.user.reason) = $event)),
              rows: "5",
              cols: "30"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_ErrorMessage, {
          as: "small",
          class: "p-error",
          name: "Suspend Reason"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Suspend User",
            loading: self.suspending_user,
            onClick: _withModifiers(suspendUser, ["prevent"]),
            severity: "danger"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})