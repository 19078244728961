import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_ViewRole = _resolveComponent("ViewRole")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_EditRole = _resolveComponent("EditRole")!
  const _component_CreateRole = _resolveComponent("CreateRole")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "roles",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true))
    }, null, 8, ["breadcrumb_home", "options", "loading", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "roles",
        loading: _ctx.self.loading,
        items: _ctx.self.roles,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getRoleListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.view_role,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.view_role) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Role Detail")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_ViewRole, {
            role: _ctx.self.selected_role
          }, null, 8, ["role"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_role,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.edit_role) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Edit Role")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_EditRole, {
            role: _ctx.self.selected_role,
            view_role: true,
            onUpdatedRole: _ctx.reloadAllData
          }, null, 8, ["role", "onUpdatedRole"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right",
        class: "w-7"
      }, {
        header: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Add Role ")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_CreateRole, {
            resources: _ctx.self.resources,
            onCreatedRole: _ctx.reloadAllData
          }, null, 8, ["resources", "onCreatedRole"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}