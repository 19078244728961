import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ml-3" }
const _hoisted_2 = {
  key: 0,
  class: "grid m-auto w-11"
}
const _hoisted_3 = { class: "col-8 m-auto" }
const _hoisted_4 = {
  key: 0,
  class: "mt-3 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_VuePdf = _resolveComponent("VuePdf")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    class: "pdf-dialog",
    visible: _ctx.self.view_pdf,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.self.view_pdf) = $event)),
    onHide: _ctx.closePdf,
    closeOnEscape: false,
    showCloseIcon: false,
    position: "full"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_Toolbar, {
        id: "pdf-toolbar",
        class: "w-full"
      }, {
        start: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.doc.name), 1)
        ]),
        center: _withCtx(() => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "fas fa-angle-left",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.self.page == 1 || _ctx.self.page == 0
                ? null
                : _ctx.changePage(_ctx.self.page - 1)
            ))
          }, null, 512), [
            [
              _directive_tooltip,
              
              _ctx.self.page > 1 ? `Go to Page: ${_ctx.self.page - 1}` : ''
            ,
              void 0,
              { bottom: true }
            ]
          ]),
          _createVNode(_component_InputNumber, {
            min: 1,
            max: _ctx.numOfPages,
            modelValue: _ctx.self.page,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.page) = $event)),
            autofocus: "",
            class: "mr-2 page-number"
          }, null, 8, ["max", "modelValue"]),
          _cache[7] || (_cache[7] = _createTextVNode(" / ")),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.numOfPages), 1),
          _withDirectives(_createVNode(_component_Button, {
            icon: "fas fa-angle-right",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.self.page >= _ctx.numOfPages ? null : _ctx.changePage(_ctx.self.page + 1)))
          }, null, 512), [
            [
              _directive_tooltip,
              
              _ctx.self.page >= _ctx.numOfPages ? '' : `Go to Page: ${_ctx.self.page + 1}`
            ,
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        end: _withCtx(() => [
          _withDirectives(_createVNode(_component_Button, {
            disabled: _ctx.self.scale <= 1,
            icon: "fa-solid fa-minus",
            class: "mr-1",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateZoom(_ctx.self.scale - 0.1)))
          }, null, 8, ["disabled"]), [
            [
              _directive_tooltip,
              'Zoom Out',
              void 0,
              { bottom: true }
            ]
          ]),
          _createVNode(_component_Tag, {
            value: `${Math.round(_ctx.self.scale * 100)}%`
          }, null, 8, ["value"]),
          _withDirectives(_createVNode(_component_Button, {
            disabled: _ctx.self.scale >= 2.5,
            icon: "fa-solid fa-plus",
            class: "mr-1",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateZoom(_ctx.self.scale + 0.1)))
          }, null, 8, ["disabled"]), [
            [
              _directive_tooltip,
              'Zoom In',
              void 0,
              { bottom: true }
            ]
          ]),
          (!_ctx.is_public)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "fa-solid fa-download",
                class: "mr-1",
                onClick: _ctx.downloadPdf
              }, null, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  'Download',
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_Button, {
            icon: "fa fa-times",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close-pdf')))
          }, null, 512), [
            [
              _directive_tooltip,
              'Close',
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numOfPages, (page) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (page == _ctx.self.page)
            ? (_openBlock(), _createBlock(_component_VuePdf, {
                key: page,
                class: "pdf-viewer",
                src: _ctx.pdfSrc,
                page: page,
                scale: _ctx.self.scale,
                enableAnnotations: false,
                enableTextSelection: ""
              }, null, 8, ["src", "page", "scale"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256)),
      (_ctx.self.error.view || _ctx.self.loading.view)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.self.loading.view && _ctx.self.loading.percentage < 100)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_ProgressSpinner, {
                      style: {"width":"100px","height":"100px"},
                      strokeWidth: "5",
                      animationDuration: ".5s",
                      "aria-label": "Loading"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.self.error.view)
                ? (_openBlock(), _createBlock(_component_Message, {
                    key: 1,
                    severity: _ctx.self.error.type,
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.self.error.text), 1)
                    ]),
                    _: 1
                  }, 8, ["severity"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}