import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = {
  key: 0,
  class: "project-general"
}
const _hoisted_3 = {
  key: 1,
  class: "flex justify-content-center flex-wrap w-full h-full"
}
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_ProjectDetail = _resolveComponent("ProjectDetail")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      breadcrumb_home: _ctx.self.breadcrumbHome,
      breadcrumb_items: _ctx.self.breadcrumb_items,
      permission: "project",
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading_project_detail,
      onReloadData: _ctx.reloadAllData
    }, null, 8, ["breadcrumb_home", "breadcrumb_items", "options", "loading", "onReloadData"]),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.self.loading_project_detail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProjectDetail, {
              active_tab: _ctx.self.active_tab,
              "onUpdate:active_tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.active_tab) = $event)),
              project: _ctx.self.selected_project,
              shared_list: _ctx.self.shared_list
            }, null, 8, ["active_tab", "project", "shared_list"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.self.loading_project_detail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ProgressSpinner, {
                strokeWidth: "3",
                animationDuration: ".5s",
                "aria-label": "Loading"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}