<template>
  <Sidebar
    class="pdf-dialog"
    v-model:visible="self.view_pdf"
    @hide="closePdf"
    :closeOnEscape="false"
    :showCloseIcon="false"
    position="full"
  >
    <template #header>
      <Toolbar id="pdf-toolbar" class="w-full">
        <template #start>
          {{ doc.name }}
        </template>
        <template #center>
          <Button
            v-tooltip.bottom="
              self.page > 1 ? `Go to Page: ${self.page - 1}` : ''
            "
            icon="fas fa-angle-left"
            @click="
              self.page == 1 || self.page == 0
                ? null
                : changePage(self.page - 1)
            "
          />
          <InputNumber
            :min="1"
            :max="numOfPages"
            v-model="self.page"
            autofocus
            class="mr-2 page-number"
          />
          /
          <div class="ml-3">{{ numOfPages }}</div>
          <Button
            v-tooltip.bottom="
              self.page >= numOfPages ? '' : `Go to Page: ${self.page + 1}`
            "
            icon="fas fa-angle-right"
            @click="self.page >= numOfPages ? null : changePage(self.page + 1)"
          />
        </template>
        <template #end>
          <Button
            :disabled="self.scale <= 1"
            v-tooltip.bottom="'Zoom Out'"
            icon="fa-solid fa-minus"
            class="mr-1"
            @click="updateZoom(self.scale - 0.1)"
          />
          <Tag :value="`${Math.round(self.scale * 100)}%`"></Tag>
          <Button
            :disabled="self.scale >= 2.5"
            v-tooltip.bottom="'Zoom In'"
            icon="fa-solid fa-plus"
            class="mr-1"
            @click="updateZoom(self.scale + 0.1)"
          />
          <Button
            v-if="!is_public"
            v-tooltip.bottom="'Download'"
            icon="fa-solid fa-download"
            class="mr-1"
            @click="downloadPdf"
          />
          <Button
            v-tooltip.bottom="'Close'"
            icon="fa fa-times"
            @click="$emit('close-pdf')"
          />
        </template>
      </Toolbar>
    </template>
    <template v-for="page in numOfPages">
      <VuePdf
        v-if="page == self.page"
        :key="page"
        class="pdf-viewer"
        :src="pdfSrc"
        :page="page"
        :scale="self.scale"
        :enableAnnotations="false"
        enableTextSelection
      />
    </template>

    <div v-if="self.error.view || self.loading.view" class="grid m-auto w-11">
      <div class="col-8 m-auto">
        <div
          v-if="self.loading.view && self.loading.percentage < 100"
          class="mt-3 text-center"
        >
          <ProgressSpinner
            style="width: 100px; height: 100px"
            strokeWidth="5"
            animationDuration=".5s"
            aria-label="Loading"
          />
        </div>
        <Message
          v-if="self.error.view"
          :severity="self.error.type"
          :closable="false"
        >
          {{ self.error.text }}
        </Message>
      </div>
    </div>
  </Sidebar>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  watch,
  ref,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { VuePdf, createLoadingTask } from "vue3-pdfjs/esm";
import { VuePdfPropsType } from "vue3-pdfjs/components/vue-pdf/vue-pdf-props";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { v4 as uuidv4 } from "uuid";
export default defineComponent({
  name: "PDFViewer",
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    pdf_url: {
      type: String,
    },
    doc: {
      type: Object,
    } as any,
    user_session: {
      type: String,
    },
    pdf_page: {
      type: Object,
      default: () => {
        return {
          view: false,
          page: 1,
        };
      },
    },
    is_public: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-pdf"],
  components: {
    VuePdf,
  },
  setup(props: any, { emit }) {
    const { appContext }: any = getCurrentInstance();
    const inputInplace = ref();
    const pdfSrc = ref<VuePdfPropsType["src"]>(
      appContext.config.globalProperties.$getFullPath(props.pdf_url)
    );
    const numOfPages = ref(0);
    const self: any = reactive({
      properties: appContext.config.globalProperties,
      view_pdf: true,
      page: 1,
      scale: 1.5,
      pdf_app: null,
      loading: {
        view: true,
        percentage: 0,
      },
      error: {
        view: false,
        type: "error",
        text: "",
      },
      updateInterval: null as any,
      user_session: uuidv4(),
      view_input: false,
    });
    
    onMounted(() => {
      self.user_session = uuidv4();
      if (props.pdf_page.view) {
        self.page = props.pdf_page.page ? props.pdf_page.page : 1;
      }
      document.body.style.overflow = "hidden";
      loadPdfData();
    });

    onBeforeUnmount(() => {
      closePdf();
      document.body.style.overflow = null as any;
    });

    window.onbeforeunload = function () {
      closePdf();
    };

    watch(
      () => props.preview,
      (val: any) => {
        self.view_pdf = val;
      }
    );

    watch(
      () => self.page,
      (val: any) => {
        changePage(val);
      }
    );

    function changePage(page: any) {
      self.loading.view = true;
      updateAnalytics(() => {
        clearInterval(self.updateInterval);
        self.page = page;
        self.loading.view = false;
        self.updateInterval = setInterval(updateAnalytics, 5000);
      });
    }

    function updateZoom(zoom: any) {
      self.scale = zoom;
      const page = self.page.toString();
      self.page = 0;
      self.loading.view = true;
      setTimeout(() => { 
        self.page = Number(page);
        self.loading.view = false;
      }, 500);
    }

    function closePdf() {
      updateAnalytics(() => {
        clearInterval(self.updateInterval);
        emit("close-pdf");
      });
    }

    function updateAnalytics(cb: any) {
      const query = {
        user_session: self.user_session,
        document_id: props.doc.id,
        current_timestamp: Date.now(),
        page_no: self.page,
      };
      self.properties[
        props.is_public ? "public_service" : "app_service"
      ].createData(
        `${props.is_public ? "public/" : ""}document/analytics`,
        query,
        (response: any) => {
          if (response) {
            cb();
          }
        }
      );
    }

    function downloadPdf() {
      const download_list = {
        projects: null as any,
        media: [props.doc.id] as any,
      };
      if (props.is_public) {
        appContext.config.globalProperties.public_service.createData(
          "public/downloads",
          download_list,
          (response: any, error: any) => {
            if (response) {
              if (response.data.data.zip_download) {
                self.download = {
                  modal: true,
                  message: response.data.message,
                };
                checkDownloadGeneration();
              } else {
                appContext.config.globalProperties.$downloadItem(
                  response.data.data.url,
                  false
                );
              }
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
          },
          {
            token: appContext.config.globalProperties.$route.query.token,
            downloadKey: props.user_session,
          }
        );
      } else {
        self.properties.app_service.createData(
          "downloads",
          download_list,
          (response: any, error: any) => {
            if (response) {
              self.properties.$downloadItem(response.data.data.url, false);
            }
            if (error) {
              self.properties.$toastMessage(
                self.properties.$getErrorResponse(error)
              );
            }
          }
        );
      }
    }

    function onPageRendered() {
      updateAnalytics(() => {
        self.updateInterval = setInterval(updateAnalytics, 5000);
        self.loading.view = false;
      });
    }

    function checkDownloadGeneration() {
      const download_interval = setInterval(() => {
        appContext.config.globalProperties.public_service.getData(
          "public/downloads",
          null,
          (response: any) => {
            if (response) {
              if (response.status == "COMPLETED") {
                clearInterval(download_interval);
                appContext.config.globalProperties.$downloadItem(
                  response.url,
                  false
                );
              }
            }
          },
          true,
          {
            token: appContext.config.globalProperties.$route.query.token,
            downloadKey: props.user_session,
          }
        );
      }, 5000);
    }

    function loadPdfData() {
      const loadingTask = createLoadingTask(pdfSrc.value);
      loadingTask.promise.then((pdf: PDFDocumentProxy) => {
        numOfPages.value = pdf.numPages;
        self.loading.view = false;
        self.error.view = false;
        self.pdf_app = pdf;
        onPageRendered();
      });
    }
    
    return {
      appContext,
      self,
      inputInplace,
      numOfPages,
      pdfSrc,
      changePage,
      updateZoom,
      downloadPdf,
      closePdf,
      onPageRendered,
      checkDownloadGeneration,
      loadPdfData,
    };
  },
});
</script>
