import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "widget-sidebar" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "list-group-item" }
const _hoisted_4 = {
  key: 2,
  class: "text-center my-4"
}

import { defineAsyncComponent, ref } from "vue";
import type { Ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'widgetList',
  props: ["widgets", "viewSidebar","sidebar_refresh"],
  emits: ["update:viewSidebar", "update-list"],
  setup(__props, { emit: __emit }) {


const emit = __emit;
const draggable = defineAsyncComponent(() => import("vuedraggable"));
const enabled: Ref<boolean> = ref(true);
const dragging: Ref<boolean> = ref(false);  
function updateData(e: any) { 
  emit("update-list", {
    type: "un_dragged",
    event: e,
  }); 
}

return (_ctx: any,_cache: any) => {
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.widgets.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Drag widgets over dashboard to appear in your dashboard "))
      : _createCommentVNode("", true),
    (!__props.sidebar_refresh)
      ? (_openBlock(), _createBlock(_unref(draggable), {
          key: 1,
          list: __props.widgets,
          disabled: false,
          "item-key": "name",
          class: "list-group",
          group: "widget",
          onStart: _cache[0] || (_cache[0] = ($event: any) => (dragging.value = true)),
          onEnd: _cache[1] || (_cache[1] = ($event: any) => (dragging.value = false)),
          onChange: updateData
        }, {
          item: _withCtx(({ element }) => [
            _createVNode(_component_Accordion, {
              class: "mb-1",
              draggable: enabled.value
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, null, {
                  header: _withCtx(() => [
                    _createTextVNode(_toDisplayString(element.title), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(element.title), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["draggable"])
          ]),
          _: 1
        }, 8, ["list"]))
      : _createCommentVNode("", true),
    (__props.sidebar_refresh)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ProgressSpinner, {
            style: {"width":"20px","height":"20px"},
            strokeWidth: "5",
            animationDuration: ".5s",
            "aria-label": "Loading"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})