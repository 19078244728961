import appConfig from "@/configs/app";
import SystemService from "@/library/system/service";
export default class UserGroupEditService extends SystemService {
  constructor() {
    super();
    this.setBaseUrl(appConfig.ApiBaseUrl);
    this.setHttpClient();
  }

  updateGroup(group: object, user_id: string, cb: any) {
    const group_data: any = Object.assign({}, group);
    Object.keys(group_data.default_permission).forEach((permission: any) => {
      return group_data.default_permission[permission]
        ? (group_data.default_permission[permission] = 1)
        : (group_data.default_permission[permission] = 0);
    });
    this.patch(`user-groups/${user_id}`, group_data, {})
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }

  deleteGroupUser(group: any, user: any, cb: any) {
    this.delete(`user-groups/${group.id}/users/${user.id}`, {}, {})
      .then((response: any) => {
        cb(response);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }
}