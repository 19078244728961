<template>
  <div>
    <!-- Total Active Users:  -->
    <AppToolbar
      permission="application_activity"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      :queue="{
        view: true,
        text: `Total Active Users: ${self.pagination.total_records}`,
      }"
      @reload-data="reloadAllData"
    />
    <div class="the-content">
      <TableView
        :loading="self.loading"
        :items="self.users"
        permission_type="application_activity"
        :disable_select="true"
        :fields="self.table_fields"
        :pagination="self.pagination"
        :actions="self.action_buttons"
        @load-more-data="getActiveUsers"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, getCurrentInstance } from "vue";
export default defineComponent({
  name: "ActiveUser",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      breadcrumbItems: {
        icon: "fa-solid fa-person-circle-check mr-1",
        label: "Active User",
        to: { name: "activeUsers" },
      },
      properties: appContext.config.globalProperties,
      toolbarOptions: {},
      api_url: {
        count: "online/users/_count",
        users: "online/users",
      },
      loading: false,
      users: [],
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 0,
        current_page: 1,
        total_page: 1,
      },
      view_user_table: false,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:15%",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$dayMonthDateYear(val);
          },
        },
        {
          key: "last_active",
          label: "Last Active",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$dateTimeToLocal(val);
          },
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          style: "width:15%",
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
          style: "width:20%",
          format: (val: any) => {
            return val.name ? val.name : val;
          },
        },
      ],
      action_buttons: [
        {
          type: "View User Information",
          icon: "icofont icofont-info-circle",
          permission: "read",
          command: (val: any) => {
            self.properties.$router.push({
              name: "userSettings",
              query: {
                user_id: val.id,
              },
            });
          },
        },
      ],
    });

    onMounted(() => {
      getActiveUsers(true);
    });

    function reloadAllData() {
      getActiveUsers(true);
    }

    function getActiveUsers(reset = false) {
      if (reset) {
        self.page = 1;
        self.users = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        self.properties.app_service.getData(
          self.api_url.users,
          null,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.users.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    return {
      self,
      reloadAllData,
      getActiveUsers,
    };
  },
});
</script>
