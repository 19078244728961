<template>
  <div id="form-content" class="page-content">
    <Form ref="createTag" class="form-wrap">
      <div class="field grid">
        <label for="type" class="col-12 mb-2 md:col-3 md:mb-0"
          >Type <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="type"
            name="type"
            rules="required"
            autocomplete="off"
            v-model="self.tag.name"
          >
            <InputText type="text" v-model="self.tag.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="type" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Create Tag"
          :loading="self.creating_tag"
          @click.prevent="createFolderTag"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { appContext }: any = getCurrentInstance();
const createTag = ref();
const emit = defineEmits(["created-tag"]);
const self = reactive({
  store: useStore(),
  tag: {
    name: null,
  },
  creating_tag: false,
});

async function createFolderTag() {
  const validate = await createTag.value.validate();
  if (!validate.valid) return;
  self.creating_tag = true;
  await appContext.config.globalProperties.app_service.createData(
    "tags",
    self.tag,
    (response: any, error: any) => {
      self.creating_tag = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-tag", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>
