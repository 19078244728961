<template>
  <div id="form-content" class="page-content">
    <Form ref="editUser" class="form-wrap">
      <div class="field grid">
        <label for="full-name" class="col-12 mb-2 md:col-4 md:mb-0"
          >Full Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="name"
            rules="required"
            autocomplete="off"
            v-model="self.user.name"
          >
            <InputText type="text" v-model="self.user.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="name" />
        </div>
      </div>
      <div class="field grid">
        <label for="email" class="col-12 mb-2 md:col-4 md:mb-0"
          >Email <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="email"
            name="email"
            rules="required|email"
            autocomplete="off"
            v-model="self.user.email"
          >
            <InputText
              type="text"
              v-model="self.user.email"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="email" />
        </div>
      </div>

      <div class="field grid">
        <label for="role" class="col-12 mb-2 md:col-4 md:mb-0"
          >Assign Role <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-7">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="role"
            name="role"
            rules="required"
            autocomplete="off"
            v-model="self.user.role"
          >
            <Dropdown
              inputId="state"
              v-model="self.user.role"
              :options="userRoles"
              optionLabel="name"
              optionValue="slug"
              placeholder="Select a Role"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="role" />
        </div>
        <div class="col-12 md:col-1 text-center mt-2">
          <Button
            @click="$emit('edit-role-permission', self.user.role)"
            icon="fa-solid fa-question"
            class="role-button"
            rounded
            aria-label="Help"
            v-tooltip.bottom="'Edit Permission'"
          />
        </div>
      </div>

      <div class="field grid">
        <label for="alternate-email" class="col-12 mb-2 md:col-4 md:mb-0"
          >Alternate Email</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="alternate-email"
            name="Alternate Email"
            rules="email"
            autocomplete="off"
            v-model="self.user.alternate_email"
          >
            <InputText type="text" v-model="self.user.alternate_email" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Alternate Email" />
        </div>
      </div>
      <div class="field grid">
        <label for="permanent-address" class="col-12 mb-2 md:col-4 md:mb-0"
          >Permanent Address</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="permanent-address"
            name="Permanent Address"
            autocomplete="off"
            v-model="self.user.permanent_address"
          >
            <InputText type="text" v-model="self.user.permanent_address" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Permanent Address" />
        </div>
      </div>
      <div class="field grid">
        <label for="temporary-address" class="col-12 mb-2 md:col-4 md:mb-0"
          >Temporary Address</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="temporary-address"
            name="Temporary Address"
            autocomplete="off"
            v-model="self.user.temporary_address"
          >
            <InputText type="text" v-model="self.user.temporary_address" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Temporary Address" />
        </div>
      </div>
      <div class="field grid">
        <label for="mobile" class="col-12 mb-2 md:col-4 md:mb-0">Mobile</label>
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="mobile"
            name="Mobile"
            autocomplete="off"
            v-model="self.user.mobile"
          >
            <InputText type="text" v-model="self.user.mobile" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Mobile" />
        </div>
      </div>
      <div class="field grid">
        <label for="phone" class="col-12 mb-2 md:col-4 md:mb-0">Phone</label>
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="phone"
            name="Phone"
            autocomplete="off"
            v-model="self.user.phone"
          >
            <InputText type="text" v-model="self.user.phone" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Phone" />
        </div>
      </div>
      <div class="field grid">
        <label for="facebook" class="col-12 mb-2 md:col-4 md:mb-0"
          >Facebook</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            rules="url"
            as="div"
            type="text"
            id="Facebook"
            name="Facebook"
            autocomplete="off"
            v-model="self.user.facebook"
          >
            <InputText type="text" v-model="self.user.facebook" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Facebook" />
        </div>
      </div>
      <div class="field grid">
        <label for="twitter" class="col-12 mb-2 md:col-4 md:mb-0"
          >Twitter</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            rules="url"
            type="text"
            id="twitter"
            name="Twitter"
            autocomplete="off"
            v-model="self.user.twitter"
          >
            <InputText type="text" v-model="self.user.twitter" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Twitter" />
        </div>
      </div>
      <div class="field grid">
        <label for="linkedin" class="col-12 mb-2 md:col-4 md:mb-0"
          >Linkedin</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            rules="url"
            type="text"
            id="linkedin"
            name="Linkedin"
            autocomplete="off"
            v-model="self.user.linkedin"
          >
            <InputText type="text" v-model="self.user.linkedin" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Linkedin" />
        </div>
      </div>

      <div class="field grid">
        <label for="enable-two-fa" class="col-12 mb-2 md:col-4 md:mb-0"
          >Enable 2FA</label
        >
        <div class="col-12 md:col-8">
          <div class="w-full">
            <Checkbox
              v-model="self.user.two_factor_auth"
              inputId="enable-two-fa"
              name="Enable 2FA"
              :binary="true"
              :value="self.user.two_factor_auth"
            /> 
          </div>
         
        </div>
      </div>

      <div class="field grid">
        <label for="force-sso" class="col-12 mb-2 md:col-4 md:mb-0"
          >Force SSO</label
        >
        <div class="col-12 md:col-8">
          <div class="w-full">
            <Checkbox
              v-model="self.user.force_sso"
              inputId="force-sso"
              name="Force user to login using SSO"
              :binary="true"
              :value="self.user.force_sso"
            /> 
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update User"
          :loading="self.updating_user"
          @click.prevent="updateUser"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { getCurrentInstance, reactive, ref } from "vue";
const { appContext }: any = getCurrentInstance();
const props: any = defineProps({
  user: {
    type: Object,
  },
  userRoles: {
    type: Array,
  },
  transactionRoles: {
    type: Array,
  },
});
const emit = defineEmits(["updated-user", "edit-role-permission"]);
const self = reactive({
  api_url: "users",
  user: getUserDetail(),
  updating_user: false,
});
const editUser = ref();

function getUserDetail() {
  return {
    name: props.user.name,
    email: props.user.email,
    role: props.user.role?props.user.role.slug:null,
    alternate_email: props.user.alternate_email,
    permanent_address: props.user.permanent_address,
    temporary_address: props.user.temporary_address,
    mobile: props.user.mobile,
    phone: props.user.phone,
    facebook: props.user.social_media.facebook,
    twitter: props.user.social_media.twitter,
    linkedin: props.user.social_media.linkedin,
    two_factor_auth: props.user.two_factor_auth
      ? props.user.two_factor_auth
      : false,
    force_sso: props.user.force_sso ? props.user.force_sso : false,
  };
}
async function updateUser() {
  const validate = await editUser.value.validate();
  if (!validate.valid) return;
  self.updating_user = true;
  await appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    self.user,
    props.user.id,
    (response: any, error: any) => {
      self.updating_user = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("updated-user", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>