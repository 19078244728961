<template>
  <div>
    <AppToolbar
      permission="user"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :roles="self.roles"
      :loading="self.loading"
      :filter="self.filterOptions"
      :status_fields="self.status_fields"
      :suggestions="self.suggestions"
      search_placeholder="Search User"
      add_icon="fas fa-user-plus"
      @reload-data="reloadAllData"
      @submit-filter-data="searchUsers"
      @create-new="self.create_new = true"
      @get-suggestions="getFilterSuggestions"
    />
    <div class="the-content">
      <TableView
        permission_type="user"
        v-model:selected_items="self.selected_users"
        v-model:selected_item="self.selected_user"
        :loading="self.loading"
        :items="self.users"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getUserListing"
      />
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.view_detail"
        position="right"
        class="w-6"
      >
        <template #header>
          <TabView
            v-model:activeIndex="self.active_tab"
            class="w-12"
          >
            <TabPanel>
              <template #header> User Detail </template>
            </TabPanel>
            <TabPanel>
              <template #header> User Projects </template>
            </TabPanel>
            <TabPanel>
              <template #header> Shared With User </template>
            </TabPanel>
          </TabView> 
        </template>
        <TabView
          v-model:activeIndex="self.active_tab"
          class="hidden-header sidebar-tabs"
        >
          <TabPanel>
            <DetailView
              :detail="self.selected_user"
              :exclude_keys="self.exclude_keys"
            />
          </TabPanel>
          <TabPanel>
            <UserProjects
              v-if="self.active_tab == 1"
              :user="self.selected_user"
            />
          </TabPanel>
          <TabPanel>
            <SharedProjects
              v-if="self.active_tab == 2"
              :user="self.selected_user"
            />
          </TabPanel>
        </TabView>
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.change_password"
        position="right"
      >
        <template #header> Change User Password </template>
        <ChangePassword
          :user="self.selected_user"
          @updated-password="self.change_password = false"
        />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_user"
        position="right"
      >
        <template #header> Edit User </template>
        <EditUser
          :user="self.selected_user"
          :userRoles="self.roles"
          @edit-role-permission="editUserRole"
          @updated-user="updatedUserListing"
        />
      </Sidebar>

      <Sidebar
        id="sidebar-form"
        v-model:visible="self.edit_role"
        position="right"
        class="w-7"
      >
        <template #header>
          Permission For {{ self.selected_role[0].name }}
        </template>
        <EditRole :role="self.selected_role[0]" @updated-role="getUserData" />
      </Sidebar>

      <Dialog
        v-model:visible="self.suspend_view"
        modal
        header="Suspend Reason"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
      >
        <SuspendUser
          :user="self.selected_user"
          @user-suspended="updatedUserListing"
        />
      </Dialog>

      <Sidebar
        id="sidebar-form"
        v-model:visible="self.create_new"
        position="right"
      >
        <template #header> Add User </template>
        <CreateUser
          :userRoles="self.roles"
          @edit-role-permission="editUserRole"
          @created-user="updatedUserListing"
        />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
  capitalize,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
const EditUser = defineAsyncComponent(
  () => import("./components/editUser.vue")
);
const ChangePassword = defineAsyncComponent(
  () => import("./components/changePassword.vue")
);
const SuspendUser = defineAsyncComponent(
  () => import("./components/suspendUser.vue")
);
const EditRole = defineAsyncComponent(
  () => import("../role/components/editRole.vue")
);
const CreateUser = defineAsyncComponent(
  () => import("./components/createUser.vue")
);
import UserProjects from "./components/userProjects.vue";
import SharedProjects from "./components/sharedProjects.vue";
export default defineComponent({
  name: "UserIndexData",
  components: {
    EditUser,
    ChangePassword,
    SuspendUser,
    EditRole,
    CreateUser,
    UserProjects,
    SharedProjects,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_url: "users",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fa fa-users mr-1",
        label: "Users",
        to: { name: "userSettings" },
      },
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        filter: true,
        refresh: true,
        add: true,
      },
      filterOptions: {
        date: true,
        status: true,
      },
      status_fields: ["active", "trash", "suspended"],
      exclude_keys: [
        "role",
        "email_verified",
        "updated_at",
        "last_active",
        "image",
      ],
      users: [] as any,
      selected_users: [] as any,
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      search_view: false,
      search_values: {},
      table_fields: [
        {
          key: "name",
          label: "Full Name",
          sortable: true,
          style: "width:190px",
        },
        {
          key: "created_at",
          label: "Created At",
          format: (val: any) => {
            return appContext.config.globalProperties.$dateFormatterWithAMD(val);
          },
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          format: (val: any) => {
            return val ? capitalize(val) : null;
          },
        },
        {
          key: "role",
          label: "Role",
          sortable: false,
          format: (val: any) => {
            return val ? (val.name ? val.name : val) : null;
          },
        },
      ],
      selected_user: {} as any,
      create_new: false,
      view_detail: false,
      change_password: false,
      edit_user: false,
      suspend_view: false,
      action_buttons: [
        {
          type: "View Detail",
          icon: "icofont icofont-eye-alt",
          command: (val: any) => {
            viewUserDetail(val);
          },
          permission: "read",
          status: ["active"],
        },
        {
          type: "Change Password",
          icon: "icofont icofont-key",
          command: (val: any) => {
            changeUserPassword(val);
          },
          permission: "edit",
          status: ["active"],
        },
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editUser(val);
          },
          permission: "edit",
          status: ["active"],
        },
        {
          type: "Suspend",
          icon: "icofont icofont-minus-circle",
          command: (val: any) => {
            suspendUser(val);
          },
          permission: "edit",
          status: ["active"],
        },
        {
          type: "Restore",
          icon: "pi pi-arrow-circle-up p-error",
          command: (val: any) => {
            restoreUser(val);
          },
          permission: "edit",
          status: ["suspended", "trash"],
        },
        {
          type: "Move To Trash",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            trashUser(val);
          },
          permission: "delete",
          status: ["suspended", "active"],
        },
        {
          type: "Delete Permanently",
          icon: "icofont icofont-trash p-error",
          command: (val: any) => {
            deleteUser(val);
          },
          permission: "delete",
          status: ["trash", "suspended"],
        },
      ],
      roles: [] as any,
      selected_role: [] as any,
      edit_role: false,
      suggestions: {
        data: [] as any,
        loading: false,
        page: 1,
        pagination: {
          current_page: 1,
          items_per_page: 0,
          total_page: 0,
          total_records: 0,
        },
      },
    });

    onMounted(() => {
      const query: any = self.properties.$route.query;
      if (Object.keys(query).length) {
        if (query.user_id) {
          getUserDetail(query);
        }
      }
      getUserListing();
      getUserData();
    });
    function getUserData() {
      if (
        appContext.config.globalProperties.$appPermissions("roles").edit == 1 ||
        appContext.config.globalProperties.$appPermissions("roles").manage == 1
      ) {
        self.edit_role = false;
        appContext.config.globalProperties.app_service.getData(
          "roles",
          1,
          (response: any) => {
            if (response) {
              self.roles = response.data;
            }
          }
        );
      }
    }

    function editUserRole(data: any = null) {
      if (!data) {
        return appContext.config.globalProperties.$toastMessage({
          status: 400,
          data: { message: "Please Select a role to edit" },
        });
      } else {
        self.selected_role = self.roles.filter((role: any) => role.slug == data);
        self.edit_role = true;
      }
    }

    function reloadAllData() {
      getUserListing(true);
    }
    function getUserDetail(val: any) {
      appContext.config.globalProperties.app_service.getData(
        `users/${val.user_id}`,
        null,
        (response: any) => {
          viewUserDetail(response);
        }
      );
    }
    function viewUserDetail(val: any) {
      self.selected_user = val;
      self.view_detail = true;
    }
    function changeUserPassword(val: any) {
      self.selected_user = val;
      self.change_password = true;
    }
    function editUser(val: any) {
      self.selected_user = val;
      self.edit_user = true;
    }
    function suspendUser(val: any) {
      self.selected_user = val;
      self.suspend_view = true;
    }
    function restoreUser(val: any) {
      self.selected_user = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to Restore?",
        header: "Restore Confirmation",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.updateData(
            self.api_url,
            {},
            self.selected_user.id,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedUserListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            },
            "restore",
            true
          );
        },
      });
    }
    function trashUser(val: any) {
      self.selected_user = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to trash?",
        header: "Trash User",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_user,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedUserListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            },
            { permanent: false }
          );
        },
      });
    }
    function deleteUser(val: any) {
      self.selected_user = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "All data related to user will be gone permanently.",
        header: "Delete User",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_user,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedUserListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            },
            { permanent: true }
          );
        },
      });
    }

    function updatedUserListing(data: any = {}, created_new: any = false) {
      self.create_new = false;
      self.suspend_view = false;
      self.edit_user = false;
      if (created_new) {
        self.users.unshift(data);
      } else {
        getUserListing(true);
      }
    }

    function getUserListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.users = [];
      }
      if (self.search_view) {
        searchUsers(self.search_values, reset);
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    async function getFilterSuggestions(query: string, reset = false) {
      if (reset) {
        self.suggestions.page = 1;
        self.suggestions.data = [];
      }
      if (
        (self.suggestions.page <= self.suggestions.pagination.total_page &&
          !self.suggestions.loading) ||
        (self.suggestions.page == 1 && !self.suggestions.loading)
      ) {
        self.suggestions.loading = true;
        await appContext.config.globalProperties.app_service.searchData(
          `${self.api_url}/_admin_search`,
          self.suggestions.page,
          {
            query: query,
          },
          (response: any, error: any) => {
            if (response) {
              if (response.metadata) {
                self.pagination = response.metadata;
              }

              self.suggestions.data.push(...response.data);
              self.suggestions.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.suggestions.loading = false;
          },
          true
        );
      }
    }

    function searchUsers(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.users = [];
      }
      self.search_values = filter_value;
      self.search_view = true;
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          `${self.api_url}/_admin_search`,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          },
          true
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        if (res.metadata) {
          self.pagination = res.metadata;
        }
        self.users.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    watch(
      () => self.view_detail,
      (val: any) => {
        if (!val) {
          self.active_tab = 0;
        }
      }
    );
    return {
      self,
      viewUserDetail,
      changeUserPassword,
      editUser,
      editUserRole,
      updatedUserListing,
      suspendUser,
      restoreUser,
      trashUser,
      deleteUser,
      getUserListing,
      getUserData,
      searchUsers,
      reloadAllData,
      getFilterSuggestions,
    };
  },
});
</script>
