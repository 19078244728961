import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-9" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = { class: "col-12 md:col-9" }
const _hoisted_8 = { class: "flex align-items-center justify-content-end mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Password = _resolveComponent("Password")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref: "editUserPassword",
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "current-password",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Current Password "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "current-password",
              name: "Current Password",
              rules: "required",
              autocomplete: "off",
              modelValue: _ctx.self.user.current_password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.user.current_password) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Password, {
                  class: "w-full",
                  feedback: false,
                  modelValue: _ctx.self.user.current_password,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.user.current_password) = $event)),
                  toggleMask: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Current Password"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "password",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Password "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "password",
              name: "password",
              rules: "required|password_requirement",
              autocomplete: "off",
              modelValue: _ctx.self.user.new_password,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.user.new_password) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Password, {
                  class: "w-full",
                  modelValue: _ctx.self.user.new_password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.user.new_password) = $event)),
                  toggleMask: ""
                }, {
                  header: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("h6", null, "Enter Password", -1)
                  ])),
                  footer: _withCtx(() => [
                    _createVNode(_component_Divider),
                    _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mt-2" }, "Suggestions", -1)),
                    _cache[9] || (_cache[9] = _createElementVNode("ul", { class: "pl-2 ml-2 mt-0" }, [
                      _createElementVNode("li", null, "At least one lowercase"),
                      _createElementVNode("li", null, "At least one uppercase"),
                      _createElementVNode("li", null, "At least one numeric"),
                      _createElementVNode("li", null, "Minimum 8 characters")
                    ], -1))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "password"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            for: "confirm-password",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Re-type Password "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "confirm-password",
              name: "confirm-password",
              rules: "required|confirmed:password",
              autocomplete: "off",
              modelValue: _ctx.self.user.re_password,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.user.re_password) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Password, {
                  class: "w-full",
                  modelValue: _ctx.self.user.re_password,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.user.re_password) = $event)),
                  toggleMask: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "confirm-password"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Update Password",
            loading: _ctx.self.updating_user,
            onClick: _withModifiers(_ctx.updateUserPassword, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading", "onClick"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}