<template>
  <div id="form-content" class="page-content">
    <Form ref="addUser" class="form-wrap">
      <div class="field grid">
        <label for="full-name" class="col-12 mb-2 md:col-4 md:mb-0"
          >Full Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="name"
            rules="required"
            autocomplete="off"
            v-model="self.user.name"
          >
            <InputText type="text" v-model="self.user.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="name" />
        </div>
      </div>
      <div class="field grid">
        <label for="email" class="col-12 mb-2 md:col-4 md:mb-0"
          >Email <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="email"
            name="email"
            rules="required|email"
            autocomplete="off"
            v-model="self.user.email"
          >
            <InputText type="text" v-model="self.user.email" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="email" />
        </div>
      </div>

      <div class="field grid">
        <label for="role" class="col-12 mb-2 md:col-4 md:mb-0"
          >Assign Role <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-7">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="role"
            name="role"
            rules="required"
            autocomplete="off"
            v-model="self.user.role"
          >
            <Dropdown
              inputId="state"
              v-model="self.user.role"
              :options="userRoles"
              optionLabel="name"
              optionValue="slug"
              placeholder="Select a Role"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="role" />
        </div>
        <div class="col-12 md:col-1 text-center">
          <Button
            @click="$emit('edit-role-permission', self.user.role)"
            icon="fa-solid fa-question"
            class="role-button"
            rounded
            aria-label="Help"
            v-tooltip.bottom="'Edit Permission'"
          />
        </div>
      </div>

      <div class="field grid">
        <label for="alternate-email" class="col-12 mb-2 md:col-4 md:mb-0"
          >Alternate Email</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="alternate-email"
            name="Alternate Email"
            rules="email"
            autocomplete="off"
            v-model="self.user.alternate_email"
          >
            <InputText type="text" v-model="self.user.alternate_email" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Alternate Email" />
        </div>
      </div>
      <div class="field grid">
        <label for="permanent-address" class="col-12 mb-2 md:col-4 md:mb-0"
          >Permanent Address</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="permanent-address"
            name="Permanent Address"
            autocomplete="off"
            v-model="self.user.permanent_address"
          >
            <InputText type="text" v-model="self.user.permanent_address" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Permanent Address" />
        </div>
      </div>
      <div class="field grid">
        <label for="temporary-address" class="col-12 mb-2 md:col-4 md:mb-0"
          >Temporary Address</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="temporary-address"
            name="Temporary Address"
            autocomplete="off"
            v-model="self.user.temporary_address"
          >
            <InputText type="text" v-model="self.user.temporary_address" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Temporary Address" />
        </div>
      </div>
      <div class="field grid">
        <label for="mobile" class="col-12 mb-2 md:col-4 md:mb-0">Mobile</label>
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="mobile"
            name="Mobile"
            autocomplete="off"
            v-model="self.user.mobile"
          >
            <InputText type="text" v-model="self.user.mobile" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Mobile" />
        </div>
      </div>
      <div class="field grid">
        <label for="phone" class="col-12 mb-2 md:col-4 md:mb-0">Phone</label>
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="phone"
            name="Phone"
            autocomplete="off"
            v-model="self.user.phone"
          >
            <InputText type="text" v-model="self.user.phone" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Phone" />
        </div>
      </div>
      <div class="field grid">
        <label for="facebook" class="col-12 mb-2 md:col-4 md:mb-0"
          >Facebook</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            rules="url"
            as="div"
            type="text"
            id="Facebook"
            name="Facebook"
            autocomplete="off"
            v-model="self.user.facebook"
          >
            <InputText type="text" v-model="self.user.facebook" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Facebook" />
        </div>
      </div>
      <div class="field grid">
        <label for="twitter" class="col-12 mb-2 md:col-4 md:mb-0"
          >Twitter</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            rules="url"
            type="text"
            id="twitter"
            name="Twitter"
            autocomplete="off"
            v-model="self.user.twitter"
          >
            <InputText type="text" v-model="self.user.twitter" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Twitter" />
        </div>
      </div>
      <div class="field grid">
        <label for="linkedin" class="col-12 mb-2 md:col-4 md:mb-0"
          >Linkedin</label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            rules="url"
            type="text"
            id="linkedin"
            name="Linkedin"
            autocomplete="off"
            v-model="self.user.linkedin"
          >
            <InputText type="text" v-model="self.user.linkedin" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Linkedin" />
        </div>
      </div>
      <div class="field grid">
        <label for="enable-two-fa" class="col-12 mb-2 md:col-4 md:mb-0"
          >Enable 2FA</label
        >
        <div class="col-12 md:col-8">
          <div class="w-full">
            <Checkbox
              v-model="self.user.two_factor_auth"
              inputId="enable-two-fa"
              name="Enable 2FA"
              :binary="true"
            />
          </div>
        </div>
      </div>
      <div class="field grid">
        <label for="force-sso" class="col-12 mb-2 md:col-4 md:mb-0"
          >Force SSO</label
        >
        <div class="col-12 md:col-8">
          <div class="w-full">
            <Checkbox
              v-model="self.user.force_sso"
              inputId="force-sso"
              name="Force user to login using SSO"
              :binary="true"
              :value="self.user.force_sso"
            />
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Create User"
          :loading="self.creating_user"
          @click.prevent="createNewUser"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive, ref } from "vue";
const { appContext }: any = getCurrentInstance();
defineProps({
  userRoles: {
    type: Array,
  },
  transactionRoles: {
    type: Array,
  },
});
const emit = defineEmits(["created-user", "edit-role-permission"]);
const self = reactive({
  api_url: "users",
  user: getUserDetail(),
  creating_user: false,
});
const addUser = ref();

function getUserDetail() {
  return {
    name: null,
    email: null,
    role: null,
    alternate_email: null,
    permanent_address: null,
    temporary_address: null,
    mobile: null,
    phone: null,
    facebook: null,
    twitter: null,
    linkedin: null,
    two_factor_auth: false,
    force_sso: false,
  } as any;
}
async function createNewUser() {
  const validate = await addUser.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_user = true;
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    self.user,
    (response: any, error: any) => {
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-user", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
      self.creating_user = false;
    }
  );
}
</script>