<template>
  <div id="form-content" class="page-content">
    <Form ref="editRole" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0"
          >Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="name"
            rules="required"
            autocomplete="off"
            v-model="self.role.name"
          >
            <InputText type="text" v-model="self.role.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="name" />
        </div>
      </div>
      <div
        class="field grid"
        v-for="(resource, index) in self.role.resources"
        :key="index"
      >
        <label
          :for="resource.slug"
          class="col-12 mb-2 md:col-3 md:mb-0 justify-content-between"
        >
          <div class="w-9">
            {{ resource.name }}
          </div>
          <Button
            icon="fa-solid fa-question"
            class="role-button ml-2"
            rounded
            aria-label="Help"
            v-tooltip.bottom="self.permissions[resource.slug]"
          />
          <span class="w-2"></span>
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full flex flex-wrap justify-content-between"
            as="div"
            type="text"
            :id="resource.slug"
            :name="resource.name"
            autocomplete="off"
          >
            <div 
              v-for="(permission, key) in resource.permission"
              :key="key"
              class="flex align-items-center mr-2"
            >
              <Checkbox
                v-model="resource.permission[key]"
                :inputId="`${resource.slug}-${key}`"
                :name="capitalize(key.toString())"
                :binary="true"
                @change="updateAll(index, key)"
              />
              <label :for="`${resource.slug}-${key}`" class="ml-2">
                {{ capitalize(key.toString()) }}
              </label>
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" :name="resource.name" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update Role"
          :loading="self.updating_role"
          @click.prevent="updateRole"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, onMounted, capitalize, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import RoleEditService from "@/modules/settings/services/roles/roleEdit";
const { appContext }:any = getCurrentInstance();
const props = defineProps({
  view_role: {
    type: Boolean,
    default: false,
  },
  role: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const editRole = ref();
const emit = defineEmits(["updated-role"]);
const self = reactive({
  role_edit_service: new RoleEditService(),
  store: useStore(),
  role: {} as any,
  permissions: {} as any,
  updating_role: false,
});
onMounted(() => {
  self.permissions = self.store.getters["setting/getRolesPermissionDetail"];
  getRoleAndResources();
});
function getRoleAndResources() {
  self.role = Object.assign({}, props.role);
  self.role.resources = props.role.resources.map((resource: any) => {
    return {
      id: resource.id,
      name: resource.name,
      slug: resource.slug,
      permission: {
        read: resource.permission.read == 1,
        write: resource.permission.write == 1,
        edit: resource.permission.edit == 1,
        delete: resource.permission.delete == 1,
        manage: resource.permission.manage == 1,
      },
    };
  });
}

function updateAll(index: number, key: any) {
  appContext.config.globalProperties.$allowACL(
    self.role.resources,
    index,
    key
  );
}

async function updateRole() {
  const validate = await editRole.value.validate();
  if (!validate.valid) return;
  self.updating_role = true; 
  self.role_edit_service.updateRole(
    self.role,
    (response: any, error: any) => {
      if (response) {
        self.updating_role = false;
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("updated-role", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>