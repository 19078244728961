import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_EditTag = _resolveComponent("EditTag")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_CreateTag = _resolveComponent("CreateTag")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      permission: "tags",
      breadcrumb_home: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      loading: _ctx.self.loading,
      suggestions: _ctx.self.suggestions,
      filter: _ctx.self.filterOptions,
      search_placeholder: "Search Tag Group",
      onReloadData: _ctx.reloadAllData,
      onCreateNew: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.create_new = true)),
      onSubmitFilterData: _ctx.searchTags,
      onGetSuggestions: _ctx.getFilterSuggestions
    }, null, 8, ["breadcrumb_home", "options", "loading", "suggestions", "filter", "onReloadData", "onSubmitFilterData", "onGetSuggestions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TableView, {
        permission_type: "tags",
        loading: _ctx.self.loading,
        items: _ctx.self.tags,
        fields: _ctx.self.table_fields,
        actions: _ctx.self.action_buttons,
        pagination: _ctx.self.pagination,
        disable_select: "",
        onLoadMoreData: _ctx.getTagListing
      }, null, 8, ["loading", "items", "fields", "actions", "pagination", "onLoadMoreData"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.edit_tag,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.edit_tag) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Edit Tag")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_EditTag, {
            tag: _ctx.self.selected_tag,
            onUpdatedTag: _ctx.reloadAllData
          }, null, 8, ["tag", "onUpdatedTag"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Sidebar, {
        id: "sidebar-form",
        visible: _ctx.self.create_new,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.create_new) = $event)),
        position: "right"
      }, {
        header: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Add Tag")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_CreateTag, { onCreatedTag: _ctx.reloadAllData }, null, 8, ["onCreatedTag"])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}