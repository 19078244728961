import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = {
  class: "the-content relative h-auto",
  style: {"top":"0"}
}
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Tree = _resolveComponent("Tree")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: "",
    class: "owner-dialog",
    visible: _ctx.self.view_modal,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.view_modal) = $event)),
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:view_modal', false))),
    style: { width: '70vw' },
    position: "top"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Folder Templates",
        icon: "fa fa-file-alt",
        class: "primary-color p-0 font-bold opacity-100",
        disabled: "",
        text: ""
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TableView, {
          loading: _ctx.self.loading,
          items: _ctx.self.folder_templates,
          permission_type: "folder_template",
          fields: _ctx.self.table_fields,
          actions: _ctx.self.action_buttons,
          disable_select: "",
          pagination: _ctx.self.pagination,
          expandedRows: _ctx.self.expandedRows,
          onLoadMoreData: _ctx.getFolderTemplates
        }, {
          "expanded-col": _withCtx(({ slotProps }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Tree, {
                id: "notification-setting",
                class: "template-setting",
                value: slotProps.data.content
              }, {
                default: _withCtx((sub_slot_props) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.self.properties.$getIcon(sub_slot_props.node),
                      class: "mr-2 tree-icon"
                    }, null, 8, _hoisted_4),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(sub_slot_props.node.name), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["value"])
            ])
          ]),
          _: 1
        }, 8, ["loading", "items", "fields", "actions", "pagination", "expandedRows", "onLoadMoreData"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}