import Store from '../store/index';
import Router from '../router/index';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import VueSidebarMenu from 'vue-sidebar-menu';
// Register modules
import system from '@/modules/system/module';
import dashboard from '@/modules/dashboard/module';
import profile from "@/modules/settings/module";
import project from "@/modules/projects/module";
import myVault from "@/modules/my-vault/module";
import report from "@/modules/reports/module";
import shared from "@/modules/shared/module";
// Modules to activate.
const modules: Array<any> = [
    system,
    dashboard,
    profile,
    project,
    myVault,
    report,
    shared
]
// Activate all registered modules
modules.forEach((module: any) => {
    Store.registerModule(module.type, module.store)
    if (module.routes) {
        module.routes.map((route: any) => Router.addRoute(route))
    }
})

export default {
    Router,
    Store,
    PrimeVue,
    ConfirmationService,
    ToastService,
    DialogService,
    VueSidebarMenu
}