import appConfig from "@/configs/app";
import SystemService from "@/library/system/service";
export default class ToolBarService extends SystemService {
  constructor() {
    super();
    this.setBaseUrl(appConfig.ApiBaseUrl);
    this.setHttpClient();
  }

  resetFilter(filter: any, cb: any) {
    const val: any = {
      query: null,
      status: null,
      trash: null,
      role: null,
      type: null,
      user: null,
      owner: null,
    };
    if (filter.owner) {
      val.owner = "all";
    }
    if (filter.date) {
      Object.assign(val, {
        start_date: null,
        end_date: null,
      });
    }
    cb(val);
  }
}