import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-12" }
const _hoisted_4 = { class: "flex align-items-center justify-content-end mt-2" }

import { reactive, ref, getCurrentInstance } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'sendMail',
  props: ["template"],
  emits: ["sent-mail"],
  setup(__props, { emit: __emit }) {

const sendTestMail = ref();
const { appContext }: any = getCurrentInstance();
const emit = __emit;
const props = __props;
const self = reactive({
  email_address: null,
  sending_email: false,
});

async function sendEmail() {
  const validate = await sendTestMail.value.validate();
  if (!validate.valid) return;
  self.sending_email = true;
  const data = {
    email: self.email_address,
  };
  await appContext.config.globalProperties.app_service.createData(
    `email-templates/${props.template.id}/test`,
    data,
    (response: any, error: any) => {
      self.sending_email = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("sent-mail", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "sendTestMail",
      ref: sendTestMail,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            for: "email",
            class: "col-12 mb-2 md:col-12 md:mb-0"
          }, [
            _createElementVNode("h4", { class: "primary-color font-bold" }, " Receiver email address ")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "email",
              name: "email",
              rules: "required|email",
              autocomplete: "off",
              modelValue: self.email_address,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.email_address) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.email_address,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.email_address) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "email"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Send",
            icon: "icofont icofont-paper-plane",
            loading: self.sending_email,
            onClick: _withModifiers(sendEmail, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})