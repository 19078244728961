<template>
  <div>
    <AppToolbar
     v-model:current_sort="self.current_sort"
      permission="project"
      search_placeholder="Search Project"
      :breadcrumb_home="self.breadcrumbHome"
      :breadcrumb_items="self.breadcrumb_items"
      :options="self.toolbarOptions"
      :filter="self.filterOptions"
      :loading="self.loading"
      :suggestions="self.suggestions"
      @reload-data="reloadAllData"
      @create-new="createNewProject"
      @submit-filter-data="searchProjects"
      @get-suggestions="getFilterSuggestions"
    />
    <div class="the-content">
      <div class="project-table">
        <TableView
          class="clickable-table"
          permission_type="project"
          :loading="self.loading"
          :items="self.projects"
          :fields="self.table_fields"
          :actions="self.action_buttons"
          :pagination="self.pagination"
          :row_clickable="true"
          disable_select
          @load-more-data="getProjectsListing"
          @get-row-detail="viewGeneralInfo"
        >
          <template #col-data="{ data }">
            <div
              v-if="data.key == 'name'"
              class="flex justify-content-start flex-wrap"
            >
              <div class="flex align-items-center justify-content-start">
                <input
                  v-if="data.data.new"
                  v-model="data.data.newName"
                  :ref="`docName${data.data.id}`"
                  class="p-inputtext p-component p-filled"
                  @keypress.enter="createProject(data.data.newName)"
                />
                <span v-else>{{ data.value }}</span>
              </div>
            </div>
            <span v-else v-html="data.value"></span>
          </template>
          <template #action-buttons="{ data }">
            <ul @click.stop v-if="!data.new" class="action-btn projects-icon">
              <template v-for="action in self.action_buttons">
                <li
                  v-if="viewButton(action, data)"
                  :key="action.type"
                  v-tooltip.bottom="action.type"
                  @click.stop="action.command(data)"
                >
                  <i :class="action.icon"></i>
                </li>
              </template>
            </ul>
          </template>
        </TableView>
        <Dialog
          v-model:visible="self.view_download"
          modal
          :style="{ width: '50vw' }"
        >
          <template #header>
            <Button
              label="Download Processing"
              icon="fas fa-download"
              text
              class="primary-color p-0 font-bold opacity-100"
              disabled
            />
          </template>
          <h2>
            We are preparing your files for download and will notify you when
            they are ready.
          </h2>
          <p class="mb-2">{{ self.download_message }}</p>
        </Dialog>
      </div>
      <SharedModal
        v-if="self.share_modal"
        permission="project"
        v-model:view_modal="self.share_modal"
        :doc="self.selected_project"
        @shared-document="revokedShared"
        @share-revoked="revokedShared"
      />
      <OwnerModal
        v-if="self.owner_modal"
        v-model:view_modal="self.owner_modal"
        :doc="self.selected_project"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, getCurrentInstance,watch } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import ShareService from "@/modules/system/services/share";
import SharedModal from "@/components/common/share/index.vue";
import OwnerModal from "@/components/common/owner/index.vue";
export default defineComponent({
  name: "ProjectIndexData",
  components: {
    SharedModal,
    OwnerModal,
  },
  setup() {
    const appdata: any = getCurrentInstance();
    const appContext: any = appdata.appContext;
    const self = reactive({
      share_service: new ShareService(),
      api_url: "projects",
      current_sort: null,
      confirm: useConfirm(),
      properties: appContext.config.globalProperties,
      breadcrumbHome: {
        icon: "fas fa-layer-group mr-1",
        label: "Projects",
        to: { name: "projectIndex" },
      },
      breadcrumb_items: [] as any,
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        filter: true,
        refresh: true,
        add: true,
        view_sort:true
      } as any,
      filterOptions: {
        date: true,
      } as any,
      search_view: false,
      search_values: {},
      projects: [] as any,
      loading: false,
      loading_project_detail: true,
      view_download: false,
      download_message: "",
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:40%",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          style: "width:40%",
          format: (val: any) => {
            return appContext.config.globalProperties.$dateFormatterWithAMD(
              val
            );
          },
        },
        {
          key: "owner",
          label: "Created By",
          sortable: true,
          style: "width:40%",
          format: (val: any) => {
            return val ? (val.name ? val.name : val) : null;
          },
        },
      ],
      selected_project: {} as any,
      nested_path: "",
      share_modal: false,
      owner_modal: false,
      shared_list: [] as any,
      create: {
        id: "new",
        new: true,
        type: "project",
        name: "New Project",
        newName: "New Project",
      },
      action_buttons: [
        {
          type: "View General Information",
          icon: "icofont icofont-info-circle",
          permission: "read",
          permission_type: "project",
          command: (val: any) => {
            viewGeneralInfo(val);
          },
        },
        {
          type: "Share Project",
          icon: "icofont icofont-share",
          permission: "write",
          permission_type: "share",
          command: (val: any) => {
            shareProject(val);
          },
        },
        {
          type: "Change Owner",
          icon: "icofont icofont-user-alt-2",
          permission: "edit",
          permission_type: "project",
          command: (val: any) => {
            changeOwner(val);
          },
        },
        {
          type: "View DataRoom",
          icon: "icofont icofont-ui-file",
          permission: "read",
          permission_type: "project",
          command: (val: any) => {
            viewGeneralInfo(val, true);
          },
        },
        {
          type: "Download",
          icon: "icofont icofont-download",
          permission: "read",
          permission_type: "project",
          command: (val: any) => {
            downloadProjects(val);
          },
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          permission: "delete",
          permission_type: "project",
          command: (val: any) => {
            deleteProjects(val);
          },
        },
      ],
      suggestions: {
        data: [] as any,
        loading: false,
        page: 1,
        pagination: {
          current_page: 1,
          items_per_page: 0,
          total_page: 0,
          total_records: 0,
        },
      },
      permissions: {} as any,
    });

    onMounted(() => {
      triggerKeyPress();
      getProjectsListing();
      getUserPermission();
      self.current_sort = self.properties.$store.getters[
        "system/getUserPreference"
      ]
        ? self.properties.$store.getters["system/getUserPreference"].sorting
          ? self.properties.$store.getters["system/getUserPreference"].sorting
          : null
        : null;
    });

    watch(
      () => self.current_sort,
      () => {
        if (!self.loading) {
          reloadAllData();
        }
      }
    );

    function getUserPermission() {
      self.permissions = self.properties.$appPermissions();
    }

    function triggerKeyPress() {
      document.addEventListener("keyup", (evt: any) => {
        if (evt.keyCode === 27) {
          cancelCreateNew();
        }
      });
    }

    function cancelCreateNew() {
      self.create.new = false;
      self.projects = self.projects.filter((project: any) => project.id != "new");
    }

    function createProject(name: any) {
      const data = {
        name: name,
      };
      self.properties.app_service.createData(
        `projects`,
        data,
        (response: any, error: any) => {
          if (response) {
            self.properties.$toastMessage(response);
            self.projects.unshift(response.data.data);
            cancelCreateNew();
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        }
      );
      self.create.new = false;
    }

    function createNewProject() {
      self.create = {
        id: "new",
        new: true,
        type: "project",
        name: "New Project",
        newName: "New Project",
      };
      self.projects.unshift(self.create);
      documentInputSelect(self.create);
    }

    function documentInputSelect(doc: any) {
      setTimeout(() => {
        const inputData: any = appdata.refs[`docName${doc.id}`];
        if (inputData) {
          inputData.focus();
          inputData.select();
          self.properties.$scrollToDiv("#new");
        }
      }, 200);
    }

    function reloadAllData() {
      getProjectsListing(true);
    }

    function viewGeneralInfo(val: any, view_project = false) {
      if (val.new) return;
      self.selected_project = val;
      self.properties.$router.push({
        name: "projectInfo",
        params: {
          id: val.id,
          tab: view_project ? "vault" : "info",
          doc_type: "project",
          doc_id: val.id,
        },
      });
    }

    function downloadProjects(val: any) {
      self.selected_project = val;
      appContext.config.globalProperties.app_service.createData(
        "downloads",
        {
          projects: [val.id],
        },
        (response: any, error: any) => {
          if (response) {
            self.download_message = response.data.message;
            self.view_download = true;
          }
          appContext.config.globalProperties.$getErrorResponse(
            response ? response : error
          );
        }
      );
    }

    function deleteProjects(val: any) {
      self.selected_project = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to delete?",
        header: "Delete Project",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_project,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedProjectsListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            },
            { permanent: false }
          );
        },
      });
    }

    function updatedProjectsListing() {
      getProjectsListing(true);
    }

    function getProjectsListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.projects = [];
      }
      if (self.search_view) {
        searchProjects(self.search_values, reset);
      }
      self.loading_project_detail = false;
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          },
          true,
          { sorting: self.current_sort }
        );
      }
    }

    async function getFilterSuggestions(query: string, reset = false) {
      if (reset) {
        self.suggestions.page = 1;
        self.suggestions.data = [];
      }
      if (
        (self.suggestions.page <= self.suggestions.pagination.total_page &&
          !self.suggestions.loading) ||
        (self.suggestions.page == 1 && !self.suggestions.loading)
      ) {
        self.suggestions.loading = true;
        await appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.suggestions.page,
          {
            query: query,
          },
          (response: any, error: any) => {
            if (response) {
              if (response.metadata) {
                self.pagination = response.metadata;
              }
              self.suggestions.data.push(...response.data);
              self.suggestions.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.suggestions.loading = false;
          }
        );
      }
    }

    function searchProjects(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.projects = [];
      }
      self.search_values = filter_value;
      self.search_view = true;
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          self.api_url,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.projects.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    function shareProject(val: any) {
      self.share_modal = true;
      self.selected_project = val;
    }

    function changeOwner(val: any) {
      self.owner_modal = true;
      self.selected_project = val;
    }

    function revokedShared(revoked_all = false) {
      if (revoked_all) {
        self.share_modal = false;
      }
    }

    function viewButton(action: any, data: any) {
      let view_button: boolean = false;
      if (action.type == "Change Owner") {
        if (data.can_change_ownership) {
          if (
            data.permission.manage == 1 ||
            data.permission[action.permission] == 1
          ) {
            view_button = true;
          } else {
            view_button = false;
          }
        }
      } else {
        if (action.permission_type) {
          if (action.permission_type == "share") {
            if (
              self.properties.$appPermissions(action.permission_type).manage ==
                1 ||
              data.permission.manage == 1
            ) {
              view_button = true;
            } else {
              view_button = false;
            }
          } else {
            if (
              self.properties.$appPermissions(action.permission_type)[
                action.permission
              ] == 1 ||
              self.properties.$appPermissions(action.permission_type).manage ==
                1
            ) {
              view_button = true;
            } else {
              view_button = false;
            }
          }
        } else {
          if (self.permissions.project && data.permission) {
            if (
              (self.permissions.project[action.permission] == 1 ||
                self.permissions.project.manage == 1) &&
              (data.permission[action.permission] == 1 ||
                data.permission.manage == 1)
            ) {
              view_button = true;
            } else {
              view_button = false;
            }
          }
        }
      }
      return view_button;
    }

    return {
      self,
      cancelCreateNew,
      createProject,
      createNewProject,
      updatedProjectsListing,
      viewGeneralInfo,
      getProjectsListing,
      downloadProjects,
      deleteProjects,
      reloadAllData,
      searchProjects,
      shareProject,
      getFilterSuggestions,
      revokedShared,
      viewButton,
    };
  },
});
</script>
