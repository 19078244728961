import moment from "moment";
import "moment-timezone";

// Detect user's locale (e.g., from the browser or server)
const userLocale = navigator.language || "en-US";

// Get the user's time zone (using moment-timezone)
const userTimeZone = moment.tz.guess();

// Set the locale in moment
moment.locale(userLocale);

// Set the global timezone for moment
moment.tz.setDefault(userTimeZone);

function YMD(date: any) {
  // // return date ? moment(date).format("L") : null;
  // return date ? moment(date).format() : null;
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return date ? [year, month, day].join("-") : null;
}

function calendarFormat(date: any) {
  return date ? moment(date).calendar() : null;
}

function dateTimeToLocal(date: any) {
  return date ? moment(date).local().format("LLL") : null;
}

function dateFormatterWithTime(date: any) {
  return date ? moment(date).local().format("LLL") : null;
}

function dateFormatterWithAMD(date: any) {
  return date ? moment(date).format("LLL") : null;
}

function dayMonthDateYear(date: any) {
  return date ? moment(date).format("LLL") : null;
}

function monthDateYear(date: any) {
  return date ? moment(date).format("LL") : null;
}

function monthDateYearWithTime(date: any) {
  return date ? moment(date).format("LLL") : null;
}

function UTCFormat(date: any) {
  return date ? new Date(date) : null;
}

function duration(date: any) {
  return date ? moment(date).fromNow() : null;
}

function timeStamp(time: any) {
  const seconds = Math.floor(time / 1000);
  return [3600, 60]
    .reduceRight(
      (p, b) => (r: number) => [Math.floor(r / b)].concat(p(r % b)),
      (r: any) => [r]
    )(seconds)
    .map((a: { toString: () => string }) => a.toString().padStart(2, "0"))
    .join(":");
}

export default {
  $YMD: YMD,
  $calendarFormat: calendarFormat,
  $dateTimeToLocal: dateTimeToLocal,
  $dateFormatterWithTime: dateFormatterWithTime,
  $dateFormatterWithAMD: dateFormatterWithAMD,
  $UTCFormat: UTCFormat,
  $duration: duration,
  $dayMonthDateYear: dayMonthDateYear,
  $timeStamp: timeStamp,
  $monthDateYear: monthDateYear,
  $monthDateYearWithTime: monthDateYearWithTime,
};
