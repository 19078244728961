<template>
  <div id="form-content" class="page-content">
    <Form ref="createRole" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0"
          >Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="name"
            rules="required"
            autocomplete="off"
            v-model="self.role.name"
          >
            <InputText type="text" v-model="self.role.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="name" />
        </div>
      </div>

      <div
        class="field grid"
        v-for="(resource, index) in self.role.permissions"
        :key="index"
      >
        <label
          :for="resource.slug"
          class="col-12 mb-2 md:col-3 md:mb-0 justify-content-between"
          >
          <div class="w-9">{{ resource.name }}</div>
          <Button
            icon="fa-solid fa-question"
            class="role-button ml-2"
            rounded
            aria-label="Help"
            v-tooltip.bottom="self.permissions[resource.slug]"
          />
          <span class="w-2"></span>
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full flex flex-wrap justify-content-between"
            as="div"
            type="text"
            :id="resource.slug"
            :name="resource.name"
            autocomplete="off"
          >
            <div
              class="flex align-items-center mr-2"
              v-for="(permission, key) in resource.permission"
              :key="key"
            >
              <Checkbox
                v-model="resource.permission[key]"
                :inputId="`${resource.slug}-${key}`"
                :name="capitalize(key.toString())"
                :binary="true"
                @change="updateAll(index, key)"
              />
              <label :for="`${resource.slug}-${key}`" class="ml-2">
                {{ capitalize(key.toString()) }}
              </label>
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" :name="resource.name" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Create Role"
          :loading="self.creating_role"
          @click.prevent="createNewRole"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, onMounted, capitalize, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { appContext }: any = getCurrentInstance();
const props = defineProps({
  resources: {
    type: Array,
    default: () => [],
  },
});
const createRole = ref();
const emit = defineEmits(["created-role"]);
const self = reactive({
  api_url: "roles",
  store: useStore(),
  role: {
    name: null,
    permissions: getResources(),
  },
  permissions: {} as any,
  creating_role: false,
});
onMounted(() => {
  self.permissions = self.store.getters["setting/getRolesPermissionDetail"];
});

function getResources() {
  const resource_data = props.resources.map((resource: any) => {
    return {
      resource_id: resource.id,
      name: resource.name,
      slug: resource.slug,
      permission: {
        read: false,
        write: false,
        edit: false,
        delete: false,
        manage: false,
      },
    };
  });
  return resource_data;
}

function updateAll(index: number, key: any) {
  appContext.config.globalProperties.$allowACL(
    self.role.permissions,
    index,
    key
  );
}

async function createNewRole() {
  const validate = await createRole.value.validate();
  if (!validate.valid) return;
  self.creating_role = true;
  const role_data = {
    name: self.role.name,
    permissions: self.role.permissions.map((permission:any) => {
      return {
        resource_id: permission.resource_id,
        permission: {
          read: permission.permission.read ? 1 : 0,
          write: permission.permission.write ? 1 : 0,
          edit: permission.permission.edit ? 1 : 0,
          delete: permission.permission.delete ? 1 : 0,
          manage: permission.permission.manage ? 1 : 0,
        },
      };
    }),
  };
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    role_data,
    (response: any, error: any) => {
      self.creating_role = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-role", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>