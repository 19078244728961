<template>
  <div id="form-content" class="page-content">
    <Form ref="addGroupUser" class="form-wrap">
      <div class="field grid">
        <label for="user" class="col-12 mb-2 md:col-3 md:mb-0"
          >User <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="User"
            rules="required"
            autocomplete="off"
            v-model="self.group.user_id"
          >
            <Dropdown
              v-model="self.group.user_id"
              :options="users.data"
              optionLabel="name"
              optionValue="id"
              placeholder="Search & Select a User"
              class="w-full"
              filter
              :loading="users.loading"
              :emptyMessage="users.emptyFilterMessage"
              :emptyFilterMessage="users.emptyFilterMessage"
              @filter="$emit('search-users', { originalEvent: $event, user_id: self.group.user_id })"
            /> 
          </Field>
          <ErrorMessage as="small" class="p-error" name="User" />
        </div>
      </div>
      <div class="field grid">
        <label for="permission" class="col-12 mb-2 md:col-3 md:mb-0"
          >Permission</label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full formgroup-inline checkbox-wrap justify-content-between"
            as="div"
            type="text"
            id="permission"
            name="Permission"
            autocomplete="off"
          >
            <div
              class="field-checkbox"
              v-for="(permission, key) in self.group.permission"
              :key="key"
            >
              <Checkbox
                v-model="self.group.permission[key]"
                :inputId="key.toString()"
                :name="key.toString()"
                binary
                @change="updateAll(key)"
              />
              <label :for="key.toString()" class="ml-0"> {{ capitalize(key.toString()) }}</label>
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" name="Permission" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Add User"
          :loading="self.creating_group"
          @click.prevent="addUser"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive, ref, capitalize } from "vue";
const { appContext }: any = getCurrentInstance();
const emit = defineEmits(["created-user","search-users"]);
const props:any = defineProps({
  group: {
    type: Object,
  } as any,
  users: {
    type: Object,
  },
});
const self = reactive({
  group: getGroupDetail(),
  creating_group: false,
});
const addGroupUser = ref();

function getGroupDetail() {
  return {
    user_id: null,
    permission: {
      read: false,
      write: false,
      edit: false,
      delete: false,
      manage: false,
    },
  } as any;
}
function updateAll(key: any) {
  appContext.config.globalProperties.$checkAll(self.group.permission, key);
}
async function addUser() {
  const validate = await addGroupUser.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_group = true;
  const form_data = {
    user_id: self.group.user_id,
    permission: {
      read: self.group.permission.read ? 1 : 0,
      write: self.group.permission.write ? 1 : 0,
      edit: self.group.permission.edit ? 1 : 0,
      delete: self.group.permission.delete ? 1 : 0,
      manage: self.group.permission.manage ? 1 : 0,
    },
  };
  await appContext.config.globalProperties.app_service.createData(
    `user-groups/${props.group.id}/users`,
    form_data,
    (response: any, error: any) => { 
      if (response) { 
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-user", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
      self.creating_group = false;
    }
  );
}
</script>