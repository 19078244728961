import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "grid"
}
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "flex flex-row flex-wrap" }
const _hoisted_9 = { class: "flex align-items-center p-2" }
const _hoisted_10 = { class: "col-8" }
const _hoisted_11 = { class: "grid" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "text-center uppercase" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "text-center uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "mt-3" }, [
      _createElementVNode("i", { class: "fas fa-info-circle mr-1" }),
      _createTextVNode(" General Information ")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, { class: "h-17rem" }, {
          content: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.info_detail, (detail, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "grid",
                key: key
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("b", {
                    innerHTML: _ctx.appContext.config.globalProperties.$getKeyName(key, '_')
                  
                  }, null, 8, _hoisted_4)
                ]),
                _createElementVNode("div", {
                  class: "col",
                  innerHTML: detail
                }, null, 8, _hoisted_5)
              ]))
            }), 128)),
            (_ctx.project_detail.shared)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-4" }, [
                    _createElementVNode("b", { class: "mb-2 block" }, "Shared With:")
                  ], -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project_shared_list.slice(0, 2), (user, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (user.image)
                            ? _withDirectives((_openBlock(), _createBlock(_component_Avatar, {
                                image: _ctx.appContext.config.globalProperties.$getFullPath(
                      user.image
                    )
                      ,
                                key: index,
                                shape: "circle",
                                class: "mr-1 flex align-items-center justify-content-center"
                              }, null, 8, ["image"])), [
                                [
                                  _directive_tooltip,
                                  user.name,
                                  void 0,
                                  { bottom: true }
                                ]
                              ])
                            : _withDirectives((_openBlock(), _createBlock(_component_Avatar, {
                                key: user.id,
                                label: user.name ? user.name.charAt(0) : user.email.charAt(0)
                      ,
                                shape: "circle",
                                class: _normalizeClass(["mr-1 text-white flex align-items-center justify-content-center", `bg-${_ctx.self.bg_colors[index]}-300`])
                              }, null, 8, ["label", "class"])), [
                                [
                                  _directive_tooltip,
                                  user.name,
                                  void 0,
                                  { bottom: true }
                                ]
                              ])
                        ], 64))
                      }), 256)),
                      (_ctx.project_shared_list.length > 2)
                        ? (_openBlock(), _createBlock(_component_Avatar, {
                            key: 0,
                            onClick: _ctx.toggleAvatarPanel,
                            label: `+ ${_ctx.project_shared_list.length - 2}`,
                            shape: "circle",
                            class: "cursor-pointer"
                          }, null, 8, ["onClick", "label"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createVNode(_component_OverlayPanel, {
                    ref: "avatarPanel",
                    class: "project-panel"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Listbox, {
                        options: 
                    _ctx.project_shared_list.slice(2, _ctx.project_shared_list.length)
                  ,
                        optionLabel: "name",
                        class: "w-full p-0 border-none",
                        listStyle: "max-height:250px"
                      }, {
                        option: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_9, [
                            (slotProps.option.image)
                              ? (_openBlock(), _createBlock(_component_Avatar, {
                                  key: 0,
                                  image: 
                          _ctx.appContext.config.globalProperties.$getFullPath(
                            slotProps.option.image
                          )
                        ,
                                  shape: "circle",
                                  class: "mr-1"
                                }, null, 8, ["image"]))
                              : (_openBlock(), _createBlock(_component_Avatar, {
                                  key: 1,
                                  label: 
                          slotProps.option.name
                            ? slotProps.option.name.charAt(0)
                            : slotProps.option.name
                        ,
                                  shape: "circle",
                                  class: _normalizeClass(["mr-1 text-white", `bg-${_ctx.self.bg_colors[Math.floor(Math.random() * slotProps.index + 4)]}-300`])
                                }, null, 8, ["label", "class"])),
                            _createTextVNode(" " + _toDisplayString(slotProps.option.name), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["options"])
                    ]),
                    _: 1
                  }, 512)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Card, { class: "h-17rem" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", {
                    class: "the-icon p-4 border-circle max-w-6rem m-auto text-center mb-3",
                    style: {"background":"var(--app-table-highlight)"}
                  }, [
                    _createElementVNode("i", {
                      class: "fas fa-folder text-6xl",
                      style: {"color":"var(--app-main-color)"}
                    })
                  ], -1)),
                  _createElementVNode("h2", null, _toDisplayString(_ctx.self.project_detail.folder_count), 1),
                  _cache[2] || (_cache[2] = _createTextVNode(" Folders "))
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Card, { class: "h-17rem" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", {
                    class: "the-icon p-4 border-circle max-w-6rem m-auto text-center mb-3",
                    style: {"background":"var(--app-loading-bg)"}
                  }, [
                    _createElementVNode("i", {
                      class: "fas fa-file text-6xl",
                      style: {"color":"var(--app-thumbnail-icon)"}
                    })
                  ], -1)),
                  _createElementVNode("h2", null, [
                    _createTextVNode(_toDisplayString(_ctx.self.project_detail.file_count) + " ", 1),
                    _createElementVNode("small", null, " ( " + _toDisplayString(_ctx.appContext.config.globalProperties.$getFileSize(
                          _ctx.self.project_detail.file_size
                        )) + " ) ", 1)
                  ]),
                  _cache[4] || (_cache[4] = _createTextVNode(" Files "))
                ])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ], 512)), [
    [_vShow, !_ctx.self.loading]
  ])
}