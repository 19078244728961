import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "flex align-content-center flex-wrap" }
const _hoisted_3 = { class: "flex align-items-center justify-content-center" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: "",
    class: "owner-dialog",
    visible: _ctx.self.view_modal,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.view_modal) = $event)),
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:view_modal', false))),
    style: { width: '70vw' },
    position: "top"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Change Owner",
        icon: "fas fa-user",
        text: "",
        class: "primary-color p-0 font-bold opacity-100",
        disabled: ""
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AutoComplete, {
          modelValue: _ctx.self.new_owner_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.new_owner_id) = $event)),
          placeholder: "Enter Name / Email Address",
          suggestions: _ctx.self.users,
          optionLabel: "email",
          optionValue: "id",
          onComplete: _ctx.searchUsers,
          class: "w-full owner-change my-2"
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, [
                (slotProps.option.image)
                  ? (_openBlock(), _createBlock(_component_Avatar, {
                      key: 0,
                      image: _ctx.self.properties.$getFullPath(slotProps.option.image),
                      shape: "circle",
                      class: "mr-1"
                    }, null, 8, ["image"]))
                  : (_openBlock(), _createBlock(_component_Avatar, {
                      key: 1,
                      label: slotProps.option.name.charAt(0),
                      shape: "circle",
                      class: _normalizeClass(["mr-1 text-white", `bg-${
                  _ctx.self.bg_colors[
                    slotProps.index > 8
                      ? Math.floor(Math.random() * 8)
                      : slotProps.index
                  ]
                }-300`])
                    }, null, 8, ["label", "class"]))
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.email), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "suggestions", "onComplete"]),
        _createVNode(_component_Button, {
          onClick: _ctx.changeOwnerShip,
          label: "Change Owner",
          class: "button-primary",
          icon: "fas fa-user"
        }, null, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}