import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-8" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-8" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = { class: "col-12 md:col-7" }
const _hoisted_8 = { class: "col-12 md:col-1 text-center" }
const _hoisted_9 = { class: "field grid" }
const _hoisted_10 = { class: "col-12 md:col-8" }
const _hoisted_11 = { class: "field grid" }
const _hoisted_12 = { class: "col-12 md:col-8" }
const _hoisted_13 = { class: "field grid" }
const _hoisted_14 = { class: "col-12 md:col-8" }
const _hoisted_15 = { class: "field grid" }
const _hoisted_16 = { class: "col-12 md:col-8" }
const _hoisted_17 = { class: "field grid" }
const _hoisted_18 = { class: "col-12 md:col-8" }
const _hoisted_19 = { class: "field grid" }
const _hoisted_20 = { class: "col-12 md:col-8" }
const _hoisted_21 = { class: "field grid" }
const _hoisted_22 = { class: "col-12 md:col-8" }
const _hoisted_23 = { class: "field grid" }
const _hoisted_24 = { class: "col-12 md:col-8" }
const _hoisted_25 = { class: "field grid" }
const _hoisted_26 = { class: "col-12 md:col-8" }
const _hoisted_27 = { class: "w-full" }
const _hoisted_28 = { class: "field grid" }
const _hoisted_29 = { class: "col-12 md:col-8" }
const _hoisted_30 = { class: "w-full" }
const _hoisted_31 = { class: "flex align-items-center justify-content-end mt-2" }

import { getCurrentInstance, reactive, ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'createUser',
  props: {
  userRoles: {
    type: Array,
  },
  transactionRoles: {
    type: Array,
  },
},
  emits: ["created-user", "edit-role-permission"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();

const emit = __emit;
const self = reactive({
  api_url: "users",
  user: getUserDetail(),
  creating_user: false,
});
const addUser = ref();

function getUserDetail() {
  return {
    name: null,
    email: null,
    role: null,
    alternate_email: null,
    permanent_address: null,
    temporary_address: null,
    mobile: null,
    phone: null,
    facebook: null,
    twitter: null,
    linkedin: null,
    two_factor_auth: false,
    force_sso: false,
  } as any;
}
async function createNewUser() {
  const validate = await addUser.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_user = true;
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    self.user,
    (response: any, error: any) => {
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-user", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
      self.creating_user = false;
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Form = _resolveComponent("Form")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "addUser",
      ref: addUser,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[25] || (_cache[25] = _createElementVNode("label", {
            for: "full-name",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, [
            _createTextVNode("Full Name "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "name",
              name: "name",
              rules: "required",
              autocomplete: "off",
              modelValue: self.user.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.user.name) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.user.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "name"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[26] || (_cache[26] = _createElementVNode("label", {
            for: "email",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, [
            _createTextVNode("Email "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "email",
              name: "email",
              rules: "required|email",
              autocomplete: "off",
              modelValue: self.user.email,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((self.user.email) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((self.user.email) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "email"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[27] || (_cache[27] = _createElementVNode("label", {
            for: "role",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, [
            _createTextVNode("Assign Role "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "role",
              name: "role",
              rules: "required",
              autocomplete: "off",
              modelValue: self.user.role,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((self.user.role) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  inputId: "state",
                  modelValue: self.user.role,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((self.user.role) = $event)),
                  options: __props.userRoles,
                  optionLabel: "name",
                  optionValue: "slug",
                  placeholder: "Select a Role"
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "role"
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_component_Button, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('edit-role-permission', self.user.role))),
              icon: "fa-solid fa-question",
              class: "role-button",
              rounded: "",
              "aria-label": "Help"
            }, null, 512), [
              [
                _directive_tooltip,
                'Edit Permission',
                void 0,
                { bottom: true }
              ]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[28] || (_cache[28] = _createElementVNode("label", {
            for: "alternate-email",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Alternate Email", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "alternate-email",
              name: "Alternate Email",
              rules: "email",
              autocomplete: "off",
              modelValue: self.user.alternate_email,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((self.user.alternate_email) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.alternate_email,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((self.user.alternate_email) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Alternate Email"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[29] || (_cache[29] = _createElementVNode("label", {
            for: "permanent-address",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Permanent Address", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "permanent-address",
              name: "Permanent Address",
              autocomplete: "off",
              modelValue: self.user.permanent_address,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((self.user.permanent_address) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.permanent_address,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((self.user.permanent_address) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Permanent Address"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[30] || (_cache[30] = _createElementVNode("label", {
            for: "temporary-address",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Temporary Address", -1)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "temporary-address",
              name: "Temporary Address",
              autocomplete: "off",
              modelValue: self.user.temporary_address,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((self.user.temporary_address) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.temporary_address,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((self.user.temporary_address) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Temporary Address"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[31] || (_cache[31] = _createElementVNode("label", {
            for: "mobile",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Mobile", -1)),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "mobile",
              name: "Mobile",
              autocomplete: "off",
              modelValue: self.user.mobile,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((self.user.mobile) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.mobile,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((self.user.mobile) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Mobile"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _cache[32] || (_cache[32] = _createElementVNode("label", {
            for: "phone",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Phone", -1)),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "phone",
              name: "Phone",
              autocomplete: "off",
              modelValue: self.user.phone,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((self.user.phone) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.phone,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((self.user.phone) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Phone"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[33] || (_cache[33] = _createElementVNode("label", {
            for: "facebook",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Facebook", -1)),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_Field, {
              class: "w-full",
              rules: "url",
              as: "div",
              type: "text",
              id: "Facebook",
              name: "Facebook",
              autocomplete: "off",
              modelValue: self.user.facebook,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((self.user.facebook) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.facebook,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((self.user.facebook) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Facebook"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[34] || (_cache[34] = _createElementVNode("label", {
            for: "twitter",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Twitter", -1)),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              rules: "url",
              type: "text",
              id: "twitter",
              name: "Twitter",
              autocomplete: "off",
              modelValue: self.user.twitter,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((self.user.twitter) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.twitter,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((self.user.twitter) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Twitter"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _cache[35] || (_cache[35] = _createElementVNode("label", {
            for: "linkedin",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Linkedin", -1)),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              rules: "url",
              type: "text",
              id: "linkedin",
              name: "Linkedin",
              autocomplete: "off",
              modelValue: self.user.linkedin,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((self.user.linkedin) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.user.linkedin,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((self.user.linkedin) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Linkedin"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _cache[36] || (_cache[36] = _createElementVNode("label", {
            for: "enable-two-fa",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Enable 2FA", -1)),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createVNode(_component_Checkbox, {
                modelValue: self.user.two_factor_auth,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((self.user.two_factor_auth) = $event)),
                inputId: "enable-two-fa",
                name: "Enable 2FA",
                binary: true
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _cache[37] || (_cache[37] = _createElementVNode("label", {
            for: "force-sso",
            class: "col-12 mb-2 md:col-4 md:mb-0"
          }, "Force SSO", -1)),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createVNode(_component_Checkbox, {
                modelValue: self.user.force_sso,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((self.user.force_sso) = $event)),
                inputId: "force-sso",
                name: "Force user to login using SSO",
                binary: true,
                value: self.user.force_sso
              }, null, 8, ["modelValue", "value"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Create User",
            loading: self.creating_user,
            onClick: _withModifiers(createNewUser, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})