<template>
  <Dialog
    v-model:visible="self.view_modal"
    @hide="$emit('update:view_modal', false)"
    :style="{ width: '70vw' }"
    position="top"
    modal
    class="owner-dialog"
  >
    <template #header>
      <div class="analytics-tab">
        <TabView v-model:activeIndex="self.active">
          <TabPanel
            v-for="(tab, index) in self.analytics_types"
            :key="index"
            :header="tab.name"
          >
          </TabPanel>
        </TabView>
      </div>
    </template>
    <div class="modal-body">
      <TabView v-model:activeIndex="self.active" class="hidden-header">
        <TabPanel v-for="(tab, index) in self.analytics_types" :key="index">
          <TableView
            :loading="self.loading"
            :items="self.analytic_detail"
            :fields="self.table_fields"
            permission_type="media"
            :actions="self.action_buttons"
            :action_title="'Analytic Detail'"
            disable_select
            :pagination="self.pagination"
            :expandedRows="self.expandedRows"
            expander
            :is_public="self.active == 1"
          >
            <template #expanded-col="{ slotProps }">
              <TableView
                :loading="
                  self.analytic_detail[slotProps.index].detail_info.loading
                "
                :items="self.analytic_detail[slotProps.index].detail_info.data"
                permission_type="media"
                :fields="
                  self.analytic_detail[slotProps.index].detail_info.table_fields
                "
                :actions="
                  self.analytic_detail[slotProps.index].detail_info
                    .action_buttons
                "
                :action_title="'Time Spent'"
                disable_select
                :pagination="
                  self.analytic_detail[slotProps.index].detail_info.pagination
                "
                :expandedRows="
                  self.analytic_detail[slotProps.index].detail_info
                    .expanded_rows
                "
                expander
                expanded_info
              >
                <template #expanded-col="{ subSlotProps }">
                  <TimeSpent
                    :data="
                      self.analytic_detail[slotProps.index].detail_info.data[
                        subSlotProps.index
                      ].data
                    "
                    :doc="doc"
                    @preview-doc="$emit('preview-document', $event)"
                  />
                </template>
                <template #footer>
                  <Paginator
                    v-if="
                      self.analytic_detail[slotProps.index].detail_info
                        .pagination.total_page > 1
                    "
                    :rows="
                      self.analytic_detail[slotProps.index].detail_info
                        .pagination.items_per_page
                    "
                    :totalRecords="
                      self.analytic_detail[slotProps.index].detail_info
                        .pagination.total_records
                    "
                    @page="onPageChage($event, slotProps)"
                  />
                </template>
              </TableView>
            </template>
            <template #footer>
              <Paginator
                v-if="self.pagination.total_page > 1"
                :rows="self.pagination.items_per_page"
                :totalRecords="self.pagination.total_records"
                @page="onAnalyticsPageChage($event)"
              />
            </template>
          </TableView>
        </TabPanel>
      </TabView>
    </div>
  </Dialog>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  capitalize,
} from "vue";
import TimeSpent from "./timeSpent.vue";
import { v4 as uuidv4 } from "uuid";
export default defineComponent({
  name: "DocumentAnalytics",
  props: {
    view_modal: {
      type: Boolean,
      default: false,
    },
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:view_modal", "preview-document"],
  components: {
    TimeSpent,
  },
  setup(props: any) {
    const appData: any = getCurrentInstance();
    const self = reactive({
      view_modal: false,
      api_url: "document/analytics",
      properties: appData.appContext.config.globalProperties,
      analytic_detail: [] as any,
      loading: false,
      new_owner_id: null,
      page: 1,
      pagination: {
        current_page: 1,
        items_per_page: 30,
        total_page: 1,
        total_records: 10,
      },
      expandedRows: [] as any,
      table_fields: [
        {
          key: "views",
          label: "Views",
          sortable: false,
        },
        {
          key: "downloads",
          label: "Downloads",
          sortable: false,
        },
      ] as any,
      action_buttons: [] as any,
      active: 0,
      analytics_types: [
        {
          name: "Document Analytics",
          icon: "fa fa-file",
        },
        {
          name: "Public Analytics",
          icon: "fa fa-share",
        },
      ],
    });
    onMounted(() => {
      updateIcon();
      self.view_modal = props.view_modal;
      updateTableField();
      getDocumentAnalytics();
    });
    watch(
      () => props.view_modal,
      (val: any) => {
        self.view_modal = val;
      }
    );
    watch(
      () => self.active,
      () => {
        updateTableField();
        getDocumentAnalytics(true);
        self.expandedRows = [] as any;
      }
    );
    function updateTableField() {
      if (self.active == 0) {
        self.table_fields.splice(0, 0, {
          key: "user_detail",
          label: "User",
          sortable: false,
          format: (val: any) => {
            return val.name ? val.name : val;
          },
        });
      } else {
        self.table_fields = self.table_fields.filter((field: any) => field.key != "user_detail");
      }
    }
    function expandRow(val: any, row_data: any) {
      if (self.expandedRows.length) {
        if (val.uuid == self.expandedRows[0].uuid) {
          self.expandedRows = [];
          return;
        }
      }
      getRowDetail(val, row_data);
    }

    function getRowDetail(val: any, row: any) {
      self.expandedRows = self.analytic_detail.filter((detail: any) => detail.user_id == val.user_id);
      updateIcon();
      getAnalyticsDetail(true, val, row);
    }
    function updateIcon() {
      self.action_buttons = [
        {
          type: (row: any) => {
            const type: any = self.expandedRows.length
              ? self.expandedRows[0].id == row.data.user_id
                ? "Hide"
                : "View"
              : "View";
            return `${type} Detail`;
          },
          icon: (row: any) => {
            const icon: any = self.expandedRows.length
              ? self.expandedRows[0].user_id == row.data.user_id
                ? '<i class="fa-solid fa-eye-slash"></i>'
                : '<i class="icofont icofont-eye-alt"></i>'
              : '<i class="icofont icofont-eye-alt"></i>';
            return icon;
          },
          command: (val: any, row: any) => {
            expandRow(val, row);
          },
          permission: "read",
        },
      ];
    }

    function onPageChage(e: any, slot: any) {
      self.analytic_detail[slot.index].detail_info.page = e.page + 1;
      getAnalyticsDetail(false, slot.data, slot);
    }

    function getAnalyticsDetail(reset = false, val: any, row: any) {
      if (reset) {
        resetAnalyticDetail(row);
      }
      const query = {
        doc_type: props.doc.type,
        doc_id: props.doc.id,
        public: self.active == 1,
      };
      if (self.active == 0) {
        Object.assign(query, {
          user_id: val.user_id,
        });
      } else {
        Object.assign(query, {
          summary: self.active == 1,
        });
      }
      self.analytic_detail[row.index].detail_info.loading = true;
      self.properties.app_service.getData(
        self.api_url,
        self.analytic_detail[row.index].detail_info.page,
        (response: any, error: any) => {
          if (response) {
            self.analytic_detail[row.index].detail_info.pagination = response.metadata;
            self.analytic_detail[row.index].detail_info.data = response.data;
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
          self.analytic_detail[row.index].detail_info.loading = false;
        },
        true,
        query
      );
    }

    function resetAnalyticDetail(row: any) {
      self.analytic_detail[row.index].detail_info = {
        data: [],
        loading: false,
        page: self.analytic_detail[row.index].detail_info
          ? self.analytic_detail[row.index].detail_info.page
            ? self.analytic_detail[row.index].detail_info.page
            : 1
          : 1,
        pagination: {
          current_page: self.analytic_detail[row.index].detail_info
            ? self.analytic_detail[row.index].detail_info.pagination
              ? self.analytic_detail[row.index].detail_info.pagination
                  .current_page
                ? self.analytic_detail[row.index].detail_info.pagination
                    .current_page
                : 1
              : 1
            : 1,
          items_per_page: self.analytic_detail[row.index].detail_info
            ? self.analytic_detail[row.index].detail_info.pagination
              ? self.analytic_detail[row.index].detail_info.pagination
                  .items_per_page
                ? self.analytic_detail[row.index].detail_info.pagination
                    .items_per_page
                : 15
              : 15
            : 15,
          total_page: self.analytic_detail[row.index].detail_info
            ? self.analytic_detail[row.index].detail_info.pagination
              ? self.analytic_detail[row.index].detail_info.pagination
                  .total_page
                ? self.analytic_detail[row.index].detail_info.pagination
                    .total_page
                : 1
              : 1
            : 1,
          total_records: self.analytic_detail[row.index].detail_info
            ? self.analytic_detail[row.index].detail_info.pagination
              ? self.analytic_detail[row.index].detail_info.pagination
                  .total_records
                ? self.analytic_detail[row.index].detail_info.pagination
                    .total_records
                : 30
              : 30
            : 30,
        },
        expanded_rows: [],
        table_fields: [
          {
            key: "device",
            label: "OS",
            sortable: false,
            format: (val: any) => {
              return getinfo(val, "os");
            },
            style: "width:20%;",
          },
          {
            key: "device",
            label: "Type",
            sortable: false,
            format: (val: any) => {
              return capitalize(getinfo(val, "type"));
            },
          },
          {
            key: "device",
            label: "Name",
            sortable: false,
            format: (val: any) => {
              return capitalize(getinfo(val, "name"));
            },
          },
          {
            key: "device",
            label: "Version",
            sortable: false,
            format: (val: any) => {
              return getinfo(val, "version");
            },
          },
          {
            key: "ip_address",
            label: "IP Address",
            sortable: false,
            format: (val: any) => {
              return self.properties.$getFormattedIP(val);
            },
          },
          {
            key: "updated_at",
            label: "Last Updated",
            sortable: false,
            format: (val: any) => {
              return self.properties.$dateFormatterWithAMD(val);
            },
          },
          {
            key: "activity_type",
            label: "Activity",
            sortable: false,
            format: (val: any) => {
              return capitalize(val);
            },
          },
        ],
        action_buttons:
          self.properties.$getContentType(props.doc) == "pdf" || props.doc.proxy_path
            ? [
                {
                  type: (sub_row: any) => {
                    let type: any = null;
                    if (
                      self.analytic_detail[row.index].detail_info.expanded_rows.length
                    ) {
                      type =
                        self.analytic_detail[row.index].detail_info
                          .expanded_rows[0].id == sub_row.data.id
                          ? "Hide"
                          : "View";
                    } else {
                      type = "View";
                    }
                    return `${type} Time Spent`;
                  },
                  icon: (sub_row: any) => {
                    let icon: any = null;
                    if (
                      self.analytic_detail[row.index].detail_info.expanded_rows
                        .length
                    ) {
                      icon =
                        self.analytic_detail[row.index].detail_info
                          .expanded_rows[0].id == sub_row.data.id
                          ? "fa-eye-slash"
                          : "fa-eye";
                    } else {
                      icon = "fa-eye";
                    }
                    const time_spent: any = JSON.parse(sub_row.data.time_spent) || {};
                    let totalTimeSpent: any = 0;
                    time_spent.pages.forEach((time: any) => {
                      totalTimeSpent += time.time_spent;
                    });
                    return `<i class='fas ${icon}'></i> ${
                      totalTimeSpent > 1
                        ? self.properties.$timeStamp(totalTimeSpent)
                        : ""
                    }`;
                  },
                  command: (val: any, sub_row: any) => {
                    getTimeChart(val, row, sub_row);
                  },
                  activity_type: true,
                  permission: "read",
                },
              ]
            : [],
      };
    }

    function getTimeChart(val: any, row: any, sub_row: any) {
      if (self.analytic_detail[row.index].detail_info.expanded_rows.length) {
        if (
          self.analytic_detail[row.index].detail_info.expanded_rows[0].id == sub_row.data.id
        ) {
          self.analytic_detail[row.index].detail_info.expanded_rows = [];
          return;
        }
      }
      self.analytic_detail[row.index].detail_info.expanded_rows = [
        self.analytic_detail[row.index].detail_info.data[sub_row.index],
      ];
      self.analytic_detail[row.index].detail_info.data[sub_row.index].data = {
        val: val,
        row: row,
        sub_row: sub_row,
      };
    }

    function getinfo(val: any, type: any) {
      const data = JSON.parse(val);
      return data.browser_information[type];
    }

    function onAnalyticsPageChage(e: any) {
      self.page = e.page + 1;
      getDocumentAnalytics(false);
    }

    function getDocumentAnalytics(reset = false) {
      if (reset) {
        self.page = 1;
        self.analytic_detail = [];
      }
      const query = {
        doc_type: props.doc.type,
        doc_id: props.doc.id,
        public: self.active == 1,
      };
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        self.properties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            if (response) {
              self.pagination = response.metadata;
              const data = response.data.map((res: any) => {
                return {
                  ...res,
                  uuid: uuidv4(),
                };
              });
              self.analytic_detail = data;
            }
            if (error) {
              self.properties.$toastMessage(
                self.properties.$getErrorResponse(error)
              );
            }
            self.loading = false;
          },
          true,
          query
        );
      }
    }

    return {
      capitalize,
      self,
      appData,
      expandRow,
      getDocumentAnalytics,
      getAnalyticsDetail,
      onPageChage,
      onAnalyticsPageChage,
    };
  },
});
</script>