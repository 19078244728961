import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "page" }
const _hoisted_3 = { class: "body-content" }
const _hoisted_4 = { class: "flex flex-column" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "flex justify-content-center flex-wrap page-loading w-screen h-screen"
}
const _hoisted_8 = { class: "flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Card, { class: "agreement-content border-1 border-round w-6 mx-auto my-6" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  icon: "icofont icofont-close",
                  class: "button-primary absolute",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.self.$keycloak.login())),
                  rounded: "",
                  "aria-label": "Cancel"
                })
              ])
            ]),
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.self.agreement.title), 1)
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.self.agreement.content
              }, null, 8, _hoisted_6)
            ]),
            _: 1
          })
        ])
      ])
    ], 512), [
      [_vShow, _ctx.self.agreement_loaded]
    ]),
    (!_ctx.self.agreement_loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_ProgressSpinner, {
              strokeWidth: "3",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}