<template>
  <div class="app-layout">
    <div v-show="self.user_loaded" class="page">
      <AppHeader v-if="!self.hide_header" @user-loaded="userLoaded" />
      <div class="body-content">
        <div
          v-if="self.user_loaded"
          class="body-wrap p-0"
          :class="{ 'top-0': self.hide_header }"
        >
          <router-view @remove-header="removeHeader" />
        </div>
      </div>
    </div>
    <div
      v-if="!self.user_loaded"
      class="flex justify-content-center flex-wrap page-loading w-screen h-screen"
    >
      <div class="flex align-items-center justify-content-center">
        <ProgressSpinner
          strokeWidth="3"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  reactive,
  getCurrentInstance,
} from "vue";
const AppHeader = defineAsyncComponent(() => import("./publicHeader.vue"));
export default defineComponent({
  name: "PublicLayout",
  components: {
    AppHeader,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      user_loaded: false,
      hide_header: false,
    });
    function userLoaded() {
      self.user_loaded = true;
    }

    function removeHeader(val:any){
      self.hide_header = val
      self.user_loaded = true;
    }
    return {
      self,
      userLoaded,
      removeHeader
    };
  },
});
</script>
