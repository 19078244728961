import Keycloak from "keycloak-js";
import { getUserCredentials } from "./auth";
const clientOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
};

const initOptions = {
  adapter: "default",
  responseMode: "query",
  checkLoginIframe: true,
  onLoad: "check-sso",
  pkceMethod: "S256",
};

export const keycloak = new Keycloak(clientOptions);

export function checkKeycloakLogin(app: any, cb: any) {
  keycloak.init(initOptions).then((auth: any) => {
    if (auth) {
      getUserCredentials(app);
    } else {
      setTimeout(() => {
        if (app.config.globalProperties.$router.currentRoute.value.meta.requiresAuth) {
          getUserCredentials(app);
        }
      }, 100);
    }
    cb();
  });
}