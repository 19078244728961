export default {
  namespaced: true,
  state: {
    menu_items: [],
    user_preference: {},
    nav_permissions: {}
  },
  getters: {
    getMenuItems(state: any) {
      return state.menu_items;
    },
    getUserPreference(state: any) {
      return state.user_preference;
    },
    getNavigationsPermission(state: any) {
      return state.nav_permissions;
    },
  },
  mutations: {
    setMenuItems: (state: any, value: any) => {
      state.menu_items = value;
    },
    setUserPreference: (state: any, value: any) => {
      state.user_preference = value;
    },
    setNavigationsPermission: (state: any, value: any) => {
      value.forEach((val: any) => {
        Object.assign(state.nav_permissions, {
          [val.resource_slug]: val.permission
        })
      });
    },
    updateUserPreference:(state:any, value:any)=>{
      Object.assign(state.user_preference, value); 
    }
  },
  actions: {},
};