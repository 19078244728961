<template>
  <div class="editor_container">
    <div id="app-editor"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
export default defineComponent({
  name: "AppEditor",
  props: {
    value: {
      type: String,
      default: "",
    },
    tags: {
      type: Array,
      default: () => [] as any,
    } as any,
    toolbar: {
      type: Array,
      default: () => ["bold", "italic", "codeView"],
    },
  },
  emits: ["update:value"],
  data() {
    return {
      editor: null as any,
      tag_menu: null as any,
      toolbars: [] as any,
    };
  },
  mounted() {
    this.toolbars = new Array(...this.toolbar);
    if (this.tags.length) {
      this.addTags();
    } else {
      this.createEditor();
    }
  },
  methods: {
    createEditor() {
      this.editor = suneditor.create("app-editor", {
        plugins: this.tag_menu
          ? {
              ...plugins,
              tags: this.tag_menu,
            }
          : plugins,
        height: "800",
        buttonList: [this.toolbars],
      });
      this.editor.setContents(this.value);
      this.editor.onChange = (contents: any) => {
        this.$emit("update:value", contents);
      };
    },
    addTags() {
      const self = this as any;
      this.tag_menu = {
        name: "tags",
        display: "submenu",
        add(core: any, targetElement: any) {
          const listDiv = this.setSubmenu.call(core);
          listDiv.querySelectorAll(".se-btn-list").forEach((btn: any) => {
            btn.addEventListener("click", this.onClick.bind(core));
          });
          core.initMenuTarget(this.name, targetElement, listDiv);
        },
        setSubmenu() {
          const listDiv = this.util.createElement("DIV");
          listDiv.className = "se-submenu se-list-layer";
          let tag_list = "";
          for (let i = 0; i < self.tags.length; i++) {
            tag_list += `<li> 
              <button
                type="button"
                class="se-btn-list"
                value="{{${self.tags[i].value}}}">
                 ${self.tags[i].text}
              </button>
            </li>`;
          }
          listDiv.innerHTML =
            '<div class="se-list-inner se-list-font-size">' +
            '<ul class="se-list-basic">' +
            tag_list;
          "</ul>" + "</div>";
          return listDiv;
        },
        onClick(e: any) {
          const value = e.target.value;
          const node = this.util.createElement("span");
          this.util.addClass(node, "se-custom-tag");
          node.textContent = value;
          this.insertNode(node);
          const zeroWidthSpace = this.util.createTextNode(this.util. zeroWidthSpace);
          node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);
          this.submenuOff();
        },
      };
      this.toolbars.splice(this.toolbars.length - 2, 0, {
        name: "tags",
        dataCommand: "tags",
        buttonClass: "se-btn-select",
        title: "Tags",
        dataDisplay: "submenu",
        innerHTML: "<i class='fa fa-tag'></i> Select Tags <i class='fa-solid fa-angle-down'></i>",
      });
      this.createEditor();
    },
  },
  watch: {
    content_value(val: any) {
      this.$emit("update:value", val);
    },
  },
});
</script>
<style>
#fr-logo {
  display: none;
}
@import "suneditor/dist/css/suneditor.min.css";
</style>
