import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["draggable", "onDragstart", "onDragover", "onDragenter"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "card flex justify-content-center"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InfiniteLoader = _resolveComponent("InfiniteLoader")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      selection: _ctx.self.selectedDatas,
      "onUpdate:selection": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.self.selectedDatas) = $event)),
      contextMenuSelection: _ctx.self.selectedData,
      "onUpdate:contextMenuSelection": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.self.selectedData) = $event)),
      id: "table-content",
      class: "th-width-3",
      ref: "dataTable",
      value: _ctx.items,
      expandedRows: _ctx.expandedRows,
      rowClass: _ctx.self.rowClass,
      stripedRows: _ctx.stripedRows,
      contextMenu: _ctx.contextMenu,
      onRowContextmenu: _ctx.viewContextMenu,
      onRowClick: _ctx.onRowClick
    }, {
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.loading ? null : "No Items Found"), 1)
      ]),
      expansion: _withCtx((slotProps) => [
        _renderSlot(_ctx.$slots, "expanded-col", {
          slotProps: slotProps,
          subSlotProps: slotProps
        })
      ]),
      footer: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_ProgressSpinner, {
                style: {"width":"30px","height":"30px"},
                strokeWidth: "5",
                animationDuration: ".5s",
                "aria-label": "Loading"
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_InfiniteLoader, {
          target: _ctx.target,
          onLoadMore: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('load-more-data')))
        }, null, 8, ["target"]),
        _renderSlot(_ctx.$slots, "footer")
      ]),
      default: _withCtx(() => [
        (!_ctx.disable_select)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
              selectionMode: "multiple",
              headerStyle: "width: 1.2rem"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
          return (_openBlock(), _createBlock(_component_Column, {
            key: index,
            field: field.key,
            header: field.label,
            style: _normalizeStyle(field.style),
            sortable: field.sortable
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", {
                draggable: 
              _ctx.allow_drag_drop &&
              _ctx.self.properties.$getContentType(data) != 'project' &&
              _ctx.drag_selector
            ,
                onDragstart: ($event: any) => (_ctx.drag_selector ? _ctx.onRowDragStart($event, data) : null),
                onDragend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onRowDragEnd && _ctx.onRowDragEnd(...args))),
                onDragover: ($event: any) => (_ctx.onRowDragOver($event, data)),
                onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onRowDragLeave && _ctx.onRowDragLeave(...args))),
                onDragenter: ($event: any) => (_ctx.onRowDragOver($event, data)),
                onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRowDrop && _ctx.onRowDrop(...args)), ["stop","prevent"]))
              }, [
                (_ctx.actions.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.self.view_action_buttons)
                        ? _renderSlot(_ctx.$slots, "col-data", {
                            key: 0,
                            data: _ctx.generateColData(field, data, index)
                          }, () => [
                            _createElementVNode("span", {
                              innerHTML: 
                    field.format
                      ? field.format(data[field.key])
                      : data[field.key]
                  
                            }, null, 8, _hoisted_2)
                          ])
                        : (_openBlock(), _createBlock(_component_Skeleton, {
                            key: 1,
                            width: "5rem",
                            class: "mb-2"
                          }))
                    ], 64))
                  : _renderSlot(_ctx.$slots, "col-data", {
                      key: 1,
                      data: _ctx.generateColData(field, data, index)
                    }, () => [
                      _createElementVNode("span", {
                        innerHTML: 
                    field.format
                      ? field.format(data[field.key])
                      : data[field.key]
                  
                      }, null, 8, _hoisted_3)
                    ])
              ], 40, _hoisted_1)
            ]),
            _: 2
          }, 1032, ["field", "header", "style", "sortable"]))
        }), 128)),
        (_ctx.actions.length)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 1,
              onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
              field: "action",
              header: _ctx.action_title
            }, {
              body: _withCtx((slotProps) => [
                (_ctx.self.view_action_buttons)
                  ? _renderSlot(_ctx.$slots, "action-buttons", {
                      key: 0,
                      data: slotProps.data,
                      row: slotProps
                    }, () => [
                      _createElementVNode("ul", {
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
                        class: "action-btn"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (
                    (_ctx.isButtonVisible(action, slotProps) &&
                      !slotProps.data.new) ||
                    _ctx.is_public
                  )
                              ? _withDirectives((_openBlock(), _createElementBlock("li", {
                                  key: action.type,
                                  onClick: _withModifiers(($event: any) => (action.command(slotProps.data, slotProps)), ["stop"])
                                }, [
                                  (typeof action.icon == 'string')
                                    ? (_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: _normalizeClass(action.icon)
                                      }, null, 2))
                                    : (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        innerHTML: action.icon(slotProps)
                                      }, null, 8, _hoisted_5))
                                ], 8, _hoisted_4)), [
                                  [
                                    _directive_tooltip,
                                    
                    typeof action.type == 'string'
                      ? action.type
                      : action.type(slotProps)
                  ,
                                    void 0,
                                    { bottom: true }
                                  ]
                                ])
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 256))
                      ])
                    ])
                  : (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 1,
                      width: "5rem",
                      class: "mb-2"
                    }))
              ]),
              _: 3
            }, 8, ["header"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["selection", "contextMenuSelection", "value", "expandedRows", "rowClass", "stripedRows", "contextMenu", "onRowContextmenu", "onRowClick"]),
    _createVNode(_component_ContextMenu, {
      ref: "tableContext",
      onBeforeShow: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('update-menu'))),
      model: _ctx.context_items
    }, {
      item: _withCtx(({ label, item, props }) => [
        (item.to)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.to,
              custom: ""
            }, {
              default: _withCtx((routerProps) => [
                _createElementVNode("a", _mergeProps({
                  href: routerProps.href
                }, props.action), [
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                ], 16, _hoisted_7)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("a", _mergeProps({
              key: 1,
              href: item.url,
              target: item.target
            }, props.action), [
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
            ], 16, _hoisted_8))
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}