import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = { class: "activities-wrapper" }
const _hoisted_3 = {
  id: "activites-view",
  class: "activities-view"
}
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex-initial flex align-items-top justify-content-center w-1" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex-initial flex align-items-top justify-content-start min-w-full grid pl-2" }
const _hoisted_8 = { class: "col-11" }
const _hoisted_9 = { class: "block font-medium text-900 white-space-nowrap overflow-hidden text-overflow-ellipsis" }
const _hoisted_10 = { class: "mt-6" }
const _hoisted_11 = {
  key: 1,
  class: "text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_infinite_loader = _resolveComponent("infinite-loader")!
  const _component_app_error_message = _resolveComponent("app-error-message")!
  const _component_progress_spinner = _resolveComponent("progress-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activities, (item, index) => {
          return (_openBlock(), _createBlock(_component_Card, {
            key: index,
            class: "p-0 border-bottom-1 cursor-pointer border-noround",
            onClick: ($event: any) => (
            item.action == 'trashed' || item.action == 'deleted'
              ? null
              : item.reference
              ? _ctx.$emit('view-activities-detail', item)
              : null
          )
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", {
                    class: "bg-primary border-circle pt-1 h-2rem w-2rem text-center",
                    innerHTML: 
                    item.reference
                      ? _ctx.properties.$getIcon({
                          type: item.reference.reference_object,
                        })
                      : ''
                  
                  }, null, 8, _hoisted_6)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(item.message), 1),
                    _createElementVNode("small", null, _toDisplayString(_ctx.properties.$duration(item.created_at)), 1)
                  ])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_infinite_loader, {
          target: "#activites-view",
          id: "activities-scroll",
          relative: "",
          onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('load-more-data')))
        })
      ]),
      (!_ctx.loading && !_ctx.activities.length)
        ? (_openBlock(), _createBlock(_component_app_error_message, {
            key: 0,
            page_type: "activities",
            page_icon: "fa-solid fa-chart-line",
            hide_button: ""
          }))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_progress_spinner, {
              style: {"width":"20px","height":"20px"},
              strokeWidth: "5",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}