import StyleClass from "primevue/styleclass";
import BadgeDirective from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";
const onOutsideClick = {
  beforeMount(el: any, binding: any) {
    el.clickOutsideEvent = (ev: any) => {
      if (!(el === ev.target || el.contains(ev.target))) {
        if (typeof binding.value === "function") {
          binding.value(ev);
        }
      }
    };
    el.addEventListener("click", el.stopProp);
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el: any) {
    el.removeEventListener("click", el.stopProp);
    document.removeEventListener("click", el.clickOutsideEvent);
  },
  stopProp(el: any) {
    el.stopPropagation();
  },
};
const directives = {
  styleclass: StyleClass,
  badge: BadgeDirective,
  tooltip: Tooltip,
  "click-outside": onOutsideClick,
};

export default directives;