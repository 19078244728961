<template>
  <div id="main-header">
    <Menubar>
      <template #start>
        <router-link :to="{ name: 'dashboard' }">
          <Image class="logo" :src="self.main_logo" />
        </router-link>
      </template>

      <template #item="{ label, item, props, root, hasSubmenu }">
        <router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span
            :class="[
              hasSubmenu &&
                (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
            ]"
            v-bind="props.submenuicon"
          />
        </a>
      </template>

      <template #end>
        <div class="navbar-message" v-if="self.view_error_msg">
          <p>
            This browser is not supported. Use the latest version of Chrome to
            get the best experience.
          </p>
        </div>
        <span>
          <Button v-show="self.faq.view" @click="togglefaqMenu" type="button">
            <i class="text-xl fa-regular fa-circle-question"></i>
          </Button>
          <OverlayPanel ref="faqMenu" class="faq-menu" appendTo="body">
            <ul class="faq-list">
              <li
                v-for="(faq, index) in self.faq.data"
                :key="index"
                @click="faq.command"
              >
                <div class="flex align-items-center">
                  <i :class="faq.icon"> </i>
                  <div class="pl-2">{{ faq.label }}</div>
                </div>
              </li>
            </ul>
          </OverlayPanel>

          <Dialog
            v-model:visible="self.faq.modal.view"
            modal
            :header="self.faq.modal.selected.question"
            :style="{ width: '50vw' }"
            @hide="refreshFaqParams"
          >
            <video
              class="w-full h-50"
              v-if="self.faq.modal.selected.video_link"
              autoplay
              controls
            >
              <source
                :src="self.faq.modal.selected.video_link"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <p v-else>No Video Available</p>
          </Dialog>
          <Button @click="toogleNotification" rounded aria-label="Notification">
            <i
              v-if="self.notification.pagination.total_unread_notification >= 1"
              v-badge.danger="
                self.notification.pagination.total_unread_notification > 9
                  ? '9+'
                  : self.notification.pagination.total_unread_notification
              "
              class="pi pi-bell p-overlay-badge"
            />
            <i v-else class="pi pi-bell" />
          </Button>
          <OverlayPanel
            ref="notificationPanel"
            class="notification"
            appendTo="body"
          >
            <Notification
              :notifications="self.notification.lists"
              :pagination="self.notification.pagination"
              :loading="self.notification.loading"
              @load-more-notification="getNotifications"
              @marked-all="getNotifications"
              @update-notification-count="getNotifications"
            />
          </OverlayPanel>
        </span>
        <span v-if="self.user_loaded && self.user">
          <Button @click="toggleProfileMenu" type="button">
            <Avatar
              v-if="self.user.image"
              :image="
                appContext.config.globalProperties.$getFullPath(self.user.image)
              "
              shape="circle"
              class="mr-2"
            />
            <Avatar
              v-else
              :label="self.user.name.charAt(0)"
              shape="circle"
              class="mr-2 text-white"
            />
            {{ self.user.name }}
            <i class="pl-2 pi pi-chevron-down" />
          </Button>
          <Menu
            ref="profileMenu"
            id="profile_menu"
            :model="self.profile_menu"
            :popup="true"
          >
            <template #item="{ label, item, props }">
              <router-link
                v-if="item.to"
                v-slot="routerProps"
                :to="item.to"
                class="w-full"
              >
                <a :href="routerProps.href" v-bind="props.action">
                  <span v-bind="props.icon" />
                  <span v-bind="props.label">{{ label }}</span>
                </a>
              </router-link>
              <a
                v-else
                :href="item.url"
                :target="item.target"
                v-bind="props.action"
              >
                <span v-bind="props.icon" />
                <span v-bind="props.label">{{ label }}</span>
              </a>
            </template>
          </Menu>
        </span>
      </template>
    </Menubar>
    <audio ref="notification_audio">
      <source :src="self.notification_sound" type="audio/mpeg" />
    </audio>
  </div>
</template>
<script setup lang="ts">
import { detect } from "detect-browser";
import { useStore } from "vuex";
import { getCurrentInstance, ref, onMounted, reactive, watch } from "vue";
import Notification from "./applicationNotification.vue";
import { getUserDetail } from "@/configs/auth";
const emit = defineEmits(["user-loaded"]);
const { appContext }: any = getCurrentInstance();
const notificationPanel = ref();
const profileMenu = ref();
const faqMenu = ref();
const notification_audio = ref();
const self = reactive({
  main_logo: appContext.config.globalProperties.$getImage(
    process.env.VUE_APP_LOGO
  ),
  notification_sound: require("@/assets/sound/notification_sound.mp3"),
  properties: appContext.config.globalProperties,
  view_error_msg: false,
  user_loaded: false,
  browser: detect() as any,
  user: {} as any,
  store: useStore() as any,
  profile_menu: [
    {
      label: "My Profile",
      icon: "pi pi-fw pi-user",
      to: { name: "myProfile" },
    },
    {
      label: "Log Out",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        logOutUser();
      },
    },
  ],
  notification: {
    loading: false,
    lists: [] as any,
    page: 1,
    pagination: {
      current_page: 1,
      total_page: 2,
      total_records: 0,
      total_notification: 0,
      total_unread_notification: 0,
    },
  },
  faqs: [] as any,
  faq: {
    view: false,
    from: null,
    data: [] as any,
    modal: {
      view: false,
      selected: {} as any,
    },
  },
  permissions: {} as any,
});

onMounted(() => {
  detectBrowser();
  getFaqLists();
  updateFaqView(self.properties.$route);
  setTimeout(() => {
    getCurrentProfile();
    getNotifications();
    openSocketConnection();
    checkUserOnline();
  }, 1500);
});
watch(
  () => self.properties.$route,
  (val: any) => {
    updateFaqView(val);
  }
);
function openSocketConnection() {
  self.properties.app_service.getSocketConnection(
    self.properties,
    "notifications",
    (response: any) => {
      if (response.data) {
        self.notification.lists.unshift(response.data);
        self.notification.pagination.total_unread_notification += 1;
        notification_audio.value.play();
        setTimeout(() => {
          if (response.reload) {
            return openSocketConnection();
          }
        }, 300);
      }
    }
  );
}

function checkUserOnline() {
  self.properties.app_service.getSocketConnection(
    self.properties,
    "_online",
    () => {
      return;
    }
  );
}

function updateFaqView(val: any) {
  if (val.meta.faq) {
    self.faq.view = val.meta.faq.view;
    self.faq.from = val.meta.faq.slug;
    updateFaqList(self.faq.from);
  } else {
    self.faq.view = false;
  }
}
function updateFaqList(val: any) {
  const faq_lists = self.properties.$store.getters["setting/getFaqs"];
  if (faq_lists[val]) {
    self.faq.data = faq_lists[val].map((faq: any) => {
      return {
        label: faq.question,
        icon: "fa-solid fa-video",
        command: () => {
          viewVideo(faq);
        },
      };
    });
  }
}
function viewVideo(faq: any) {
  self.faq.modal = {
    view: true,
    selected: faq,
  };
  faqMenu.value.hide();
}
function refreshFaqParams() {
  self.faq = {
    view: true,
    from: self.faq.from,
    data: self.faq.data,
    modal: {
      view: false,
      selected: {} as any,
    },
  };
}

function detectBrowser() {
  if (self.browser) {
    if (self.browser.name == "Chrome" || self.browser.name == "chrome") {
      self.view_error_msg = false;
    } else {
      self.view_error_msg = true;
    }
  }
}
function toogleNotification(e: any) {
  notificationPanel.value.toggle(e);
}
function getNotifications(reset = false) {
  if (reset) {
    self.notification.lists = [];
    self.notification.page = 1;
  }
  if (
    (self.notification.pagination.current_page <
      self.notification.pagination.total_page &&
      !self.notification.loading) ||
    (self.notification.page == 1 && !self.notification.loading)
  ) {
    self.notification.loading = true;
    appContext.config.globalProperties.app_service.getData(
      "notifications",
      self.notification.page,
      (response: any) => {
        if (response) {
          self.notification.lists.push(...response.data);
          if (response.metadata) {
            self.notification.pagination = response.metadata;
          }
          self.notification.loading = false;
          self.notification.page += 1;
        }
      }
    );
  }
}

async function getFaqLists() {
  self.faqs = self.store.getters["setting/getFaqs"];
}

function getCurrentProfile() {
  self.user_loaded = false;
  const userData = self.store.getters["setting/getUserDetail"];
  self.permissions =
    appContext.config.globalProperties.$store.getters[
      "system/getNavigationsPermission"
    ];
  if (!userData) {
    getUserDetail(appContext.config.globalProperties, (response: any) => {
      self.user = response;
      self.store.commit("setting/setUserDetail", self.user);
      self.user_loaded = true;
      emit("user-loaded");
      getAllDetails();
    });
  } else {
    getAllDetails();
    self.user = userData;
  }
}

function logOutUser() {
  appContext.config.globalProperties.$keycloak.logout();
}

function toggleProfileMenu(e: any) {
  profileMenu.value.toggle(e);
}

function togglefaqMenu(e: any) {
  faqMenu.value.toggle(e);
}

async function getAllDetails() {
  await appContext.config.globalProperties.app_service.getData(
    "preferences",
    null,
    (response: any) => {
      if (response) {
        self.store.commit("system/setUserPreference", response);
        self.user_loaded = true;
        emit("user-loaded");
      }
    },
    false
  );
}
</script>
