<template>
  <div v-bind="$attrs">
    <div id="sub-header" class="relative w-full h-full">
      <Toolbar id="breadcrumb" class="py-2">
        <template #start> <b>Notifications</b> </template>
        <template #end>
          <Button
            v-if="pagination.total_unread_notification >= 1"
            @click="readAllNotifications"
            label="Mark all as read"
            plain
            link
          />
        </template>
      </Toolbar>
    </div>
    <DataView
      :value="notifications"
      class="w-350 notification-view-list scrollable h-350"
      ref="containerRef"
      id="notification-view"
    >
      <template #list="slotProps">
        <Card
          v-for="(item, index) in slotProps.items"
          :key="index"
          class="p-0 border-bottom-1 cursor-pointer border-noround"
          :class="{ 'surface-100': item.read }"
          @click="viewUrl(item)"
        >
          <template #content>
            <div class="flex">
              <div
                class="flex-initial flex align-items-top justify-content-center w-2"
              >
                <Avatar
                  v-if="item.image"
                  :image="self.properties.$getFullPath(item.image)"
                  class="mr-2"
                  shape="circle"
                />
                <Avatar
                  v-else
                  icon="pi pi-user"
                  class="mr-2"
                  shape="circle"
                  style="
                    background: var(--app-heading-bg);
                    color: var(--app-menu-color);
                  "
                />
              </div>
              <div
                class="flex-initial flex align-items-top justify-content-start w-10"
              >
                <span>
                  <b v-if="!item.read" class="font-bold text-900 block">
                    {{ item.created_by }} {{ item.message }}
                  </b>
                  <span v-else class="block font-medium text-900">
                    {{ item.created_by }} {{ item.message }}
                  </span>
                  <small>
                    {{ self.properties.$duration(item.created_at) }}
                  </small>
                </span>
              </div>
            </div>
          </template>
        </Card>
      </template>
      <template #empty>
        <AppErrorMessage
          page_type="notifications"
          :page_icon="loading ? '' : 'fa fa-bell'"
          hide_button
          :hide_page_type="loading"
          :message="loading ? 'Loading Notifications Please Wait ...' : ''"
        />
      </template>
      <template v-if="pagination.current_page < pagination.total_page" #footer>
        <div v-if="loading" class="card flex justify-content-center">
          <ProgressSpinner
            style="width: 30px; height: 30px"
            strokeWidth="5"
            animationDuration=".5s"
            aria-label="Loading"
          />
        </div>
        <InfiniteLoader
          id="notification-scroll"
          target="#notification-view"
          relative
          @loadMore="$emit('load-more-notification')"
        />
      </template>
    </DataView>
  </div>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, reactive } from "vue";
export default defineComponent({
  name: "UserNotification",
  emits: ['load-more-notification', 'marked-all', 'update-notification-count'],
  props: {
    notifications: {
      type: Array,
    } as any,
    pagination: {
      type: Object,
    } as any,
    loading: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const appData: any = getCurrentInstance();
    const self = reactive({
      properties: appData.appContext.config.globalProperties,
    });
    function viewUrl(notification: any) {
      if (!notification.read) {
        readNotification(notification);
      } else {
        if (notification.url) {
          if (
            notification.type == 'downloads' || notification.type == 'report'
          ) {
            self.properties.$downloadItem(notification.url);
          } else if (notification.url.includes("?")) {
            const params = notification.url.slice(
              notification.url.indexOf('=') + 1
            );
            self.properties.$router.push({
              path: notification.url,
              query: { ref: params },
            });
          } else {
            self.properties.$router.push({ path: notification.url });
          }
        }
      }
    }
    function readNotification(notification: any) {
      self.properties.app_service.updateData(
        'notifications',
        {
          read: true,
        },
        notification.id,
        () => {
          notification.read = true;
          emit('update-notification-count', true);
          if (notification.url) {
            if (
              notification.type == 'downloads' || notification.type == 'report'
            ) {
              self.properties.$downloadItem(notification.url);
            } else if (notification.url.includes('?')) {
              const params = notification.url.slice(
                notification.url.indexOf('=') + 1
              );
              self.properties.$router.push({
                path: notification.url,
                query: { 
                  ref: params
                },
              });
            } else {
              self.properties.$router.push({ path: notification.url });
            }
          }
        },
        null,
        true
      );
    }

    function readAllNotifications() {
      self.properties.app_service.getData(
        'notifications/read-all',
        null,
        () => {
          emit('marked-all', true);
        },
        false
      );
    }
    return {
      appData,
      self,
      viewUrl,
      readNotification,
      readAllNotifications,
    };
  },
});
</script>