<template>
  <TableView
    id="document-table"
    ref="docTable"
    v-model:selected_items="componentData.selected_documents"
    v-model:selected_item="componentData.selected_document"
    :class="{ 'clickable-table': clickable }"
    :permission_type="permission"
    :highlight_doc="highlight_doc"
    :contextMenu="true"
    :stripedRows="false"
    :context_items="context_items"
    :items="items"
    :fields="fields"
    :pagination="pagination"
    :moving_doc="moving_doc"
    :drag_selector="drag_selector"
    :disable_select="disable_select"
    :dragged_over_doc="componentData.draggedOverDoc"
    :allow_drag_drop="true"
    :target="target"
    @load-more-data="$emit('load-more-docs')"
    @update-menu="$emit('update-menu')"
    @drag-started="dragStarted"
    @dragged-over="draggedOver"
    @drag-leave="dragLeaved"
    @drag-stoped="dragStopped"
    @dropped-item="droppedItem"
  >
    <template #footer>
      <div
        class="card flex justify-content-center"
        v-if="loading" 
      >
        <ProgressSpinner
          style="width: 30px; height: 30px"
          strokeWidth="5"
          animationDuration=".5s"
          aria-label="Loading"
        />
      </div>
    </template>
    <template #col-data="{ data }">
      <div v-if="data.key == 'name'">
        <div
          :id="data.data.id"
          :class="{
            'opacity-60':
              (moving_doc.type == 'cut' &&
                moving_doc.detail.id == data.data.id) ||
              data.data.text_extraction_pending ||
              permission == 'trash',
          }"
          class="selectable-item flex justify-content-start flex-wrap"
          @click="onItemClick($event, data.data)"
          @mouseover="
            data.data.renaming || data.data.new
              ? null
              : togglePopover($event, data.data)
          "
          @mouseleave="
            data.data.renaming || data.data.new
              ? null
              : togglePopover($event, data.data, false)
          "
        >
          <div
            :class="properties.$getContentType(data.data)"
            class="flex align-items-center justify-content-center document-icon mr-1"
          >
            <span v-if="properties.$getContentType(data.data) == 'image'">
              <Image
                width="32"
                :src="properties.$getFullPath(data.data.path)"
                class="flex align-content-center flex-wrap"
                :alt="data.value"
              />
            </span>
            <span v-else v-html="properties.$getIcon(data.data)"></span>
            <span v-if="data.data.shared">
              <i class="overlap-icon shared-icon fas fa-share-alt" />
            </span>
            <span v-if="data.data.starred">
              <i class="overlap-icon starred-icon fas fa-star" />
            </span>
            <span v-if="data.data.pinned">
              <i class="overlap-icon pinned-icon fa-solid fa-thumbtack" />
            </span>
          </div>
          <div
            :class="[activities_view ? 'w-10' : 'w-10']"
            class="flex align-items-center justify-content-start"
          >
            <input
              v-if="data.data.renaming || data.data.new"
              class="p-inputtext p-component p-filled"
              v-model="data.data.newName"
              :ref="`docName${data.data.id}`"
              @focus="$emit('update:disabled_selection', true)"
              @blur="$emit('update:disabled_selection', false)"
              @keypress.enter="
                data.data.new
                  ? $emit('create-document', data.data.newName)
                  : $emit('update-document', data.data.newName)
              "
            />
            <span
              v-else
              class="white-space-nowrap overflow-hidden text-overflow-ellipsis"
              :class="[activities_view ? 'max-w-20rem' : 'max-w-20rem']"
            >
              {{ data.value }}
            </span>
          </div>
        </div>
        <OverlayPanel :ref="`tablePopOver${data.data.id}`" appendTo="body">
          <ul>
            <li
              v-if="
                view_parent &&
                data.data.parent_project &&
                data.data.type != 'project'
              "
              class="max-w-26rem"
            >
              Parent Project: {{ data.data.parent_project.name }}
            </li>
            <li
              class="max-w-26rem"
            >
              Name: {{ data.data.name }}
            </li>
            <li v-if="!disable_select">
              Created at:
              {{ properties.$dayMonthDateYear(data.data.created_at) }}
            </li>
            <li v-if="data.data.metadata && !disable_select">
              Size:
              {{
                properties.$getFileSize(
                  properties.app_service.parseJSON(data.data.metadata).size
                )
              }}
            </li>
            <li v-if="!disable_select">
              Owner:
              {{
                data.data.owner.name ? data.data.owner.name : data.data.owner
              }}
            </li>
          </ul>
        </OverlayPanel>
      </div>
      
      <span
        @click="$emit('load-doc-detail', data)"
        v-else
        v-html="data.value"
        :class="{
          'opacity-60':
            (moving_doc.type == 'cut' &&
              moving_doc.detail.id == data.data.id) ||
            data.data.text_extraction_pending,
        }"
      ></span>
    </template>
  </TableView>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  reactive,
  watch,
  ref,
} from "vue";
import DragSelect from "dragselect";
export default defineComponent({
  name: "DocTable",
  props: {
    target: {
      type: String,
      default: null,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    selected_documents: {
      type: Array,
      default: () => [] as any,
    },
    selected_document: {
      type: Object,
      default: () => {
        return {};
      },
    },
    context_items: {
      type: Array,
      default: () => [] as any,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activities_view: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [] as any,
    },
    fields: {
      type: Array,
      default: () => [] as any,
    },
    pagination: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    moving_doc: {
      type: Object,
      default: () => {
        return {
          detail: {},
          type: "",
        };
      },
    } as any,
    permission: {
      type: String,
    },
    drag_selector: {
      type: Boolean,
      default: true,
    },
    highlight_doc: {
      type: Object,
    },
    disabled_selection: {
      type: Boolean,
      default: false,
    },
    view_parent: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "load-more-docs",
    "load-doc-detail",
    "update:selected_documents",
    "update:selected_document",
    "update:disabled_selection",
    "update-document",
    "create-document",
    "update-menu",
    "dragged-doc",
    "stop-dragged",
    "drag-over-sub",
    "drag-leave-sub",
    "upload-on-document",
    "update-name",
  ],
  setup(props: any, { emit }) {
    const docTable = ref();
    const self: any = getCurrentInstance();
    const properties: any = self.appContext.config.globalProperties;
    const debounce = properties.$debounce();
    const componentData = reactive({
      selected_documents: [] as any,
      selected_doc_ids: [] as any,
      selected_document: {} as any,
      draggedDoc: {} as any,
      draggedOverDoc: {} as any,
      drag_selector: {} as any,
      loading_data: false,
    });

    onMounted(() => {
      getDocData();
      if (props.disable_select) {
        properties.$removeDragSelection();
        componentData.drag_selector = {};
      }
    });

    function getDocData() {
      componentData.selected_documents = props.selected_documents;
      componentData.selected_document = props.selected_document;
      getSelectionData();
    }

    function updateDocument(data: any) {
      debounce(() => {
        if (data.new) {
          emit("create-document", data.newName);
        } else if (data.renaming) {
          emit("update-document", data.newName);
        }
      });
    }

    function getSelectionData() {
      setTimeout(() => {
        if (
          document.querySelector("#document-table table") &&
          document.querySelectorAll(".p-datatable tbody tr td .selectable-item")
            .length >= 1 &&
          props.items.length &&
          !props.loading &&
          props.drag_selector
        ) {
          if (!Object.keys(componentData.drag_selector).length) {
            if (!props.disable_select) {
              componentData.drag_selector = new DragSelect({
                selectables: document.querySelectorAll(
                  ".p-datatable tbody tr td .selectable-item"
                ) as any,
                area: document.querySelector("#document-table table") as any,
                draggability: false,
                multiSelectToggling: true,
              });
            }
          }
          componentData.drag_selector.subscribe("DS:end", (select: any) => {
            if (select.items) {
              getSelectedItems(select.items);
            }
          });
        }
      }, 1500);
    }

    function getSelectedItems(items: any) {
      if (props.drag_selector) {
        items.forEach((item: any) => {
          props.items.forEach((doc: any) => {
            if (doc.id == item.getAttribute("id")) {
              updateSelectedDocs(doc);
            }
          });
        });
        upDateSelectedItems();
      }
    }

    function updateSelectedDocs(doc: any) {
      if (componentData.selected_documents.length) {
        if (!componentData.selected_doc_ids.includes(doc.id)) {
          componentData.selected_documents.push(doc);
          componentData.selected_doc_ids.push(doc.id);
        }
      } else {
        componentData.selected_documents.push(doc);
        componentData.selected_doc_ids.push(doc.id);
      }
    }

    function upDateSelectedItems() {
      if (Object.keys(componentData.drag_selector).length) {
        const selection: any = {
          selectables: componentData.drag_selector.getSelectables(),
          selected_items: componentData.drag_selector.getSelection(),
          filtered_selected_items: [],
        };
        componentData.drag_selector.clearSelection();
        selection.filtered_selected_items = selection.selectables.filter(
          (item: any) =>
            componentData.selected_doc_ids.includes(Number(item.id))
        );
        componentData.drag_selector.addSelection(
          selection.filtered_selected_items
        );
      }
    }

    function dragStarted(e: any, data: any) {
      componentData.draggedDoc = data;
      emit("dragged-doc", e, componentData.draggedDoc);
    }

    function draggedOver(e: any, data: any) {
      componentData.draggedOverDoc = data;
      emit("drag-over-sub", e, componentData.draggedOverDoc);
    }

    function dragLeaved(e: any) {
      componentData.draggedOverDoc = {};
      emit("drag-leave-sub", e);
    }

    function dragStopped(e: any) {
      componentData.draggedOverDoc = {};
      componentData.draggedDoc = {};
      emit("drag-leave-sub", e);
    }

    function droppedItem(e: any) {
      emit("upload-on-document", e, componentData.draggedOverDoc);
    }

    function onItemClick(e: any, doc: any) {
      if (e.ctrlKey || e.shiftKey || e.metaKey || doc.renaming || doc.new) {
        e.preventDefault();
        return;
      }
      emit("load-doc-detail", { data: doc });
    }

    function togglePopover(e: any, doc: any, view = true) {
      const popOverTableData: any = self.refs[`tablePopOver${doc.id}`];
      if (view) {
        popOverTableData.show(e);
      } else {
        popOverTableData.hide();
      }
    }

    watch(
      () => props.selected_documents,
      (data: any) => {
        componentData.selected_doc_ids = data.map((doc: any) => {
          return doc.id;
        });
        componentData.selected_documents = data;
        upDateSelectedItems();
      }
    );

    watch(
      () => props.selected_document,
      (data: any) => {
        componentData.selected_document = data;
      }
    );

    watch(
      () => componentData.selected_documents,
      (data: any) => {
        emit("update:selected_documents", data);
      }
    );

    watch(
      () => componentData.selected_document,
      (data: any) => {
        emit("update:selected_document", data);
      }
    );

    watch(
      () => props.drag_selector,
      (val: any) => {
        if (!val) {
          properties.$removeDragSelection();
          componentData.drag_selector = {};
        } else {
          getSelectionData();
        }
      }
    );

    watch(
      () => props.disable_select,
      (val: any) => {
        if (val) {
          properties.$removeDragSelection();
          componentData.drag_selector = {};
        }
      }
    );

    return {
      docTable,
      self,
      properties,
      componentData,
      debounce,
      togglePopover,
      getDocData,
      dragStarted,
      draggedOver,
      dragLeaved,
      dragStopped,
      droppedItem,
      onItemClick,
      updateDocument,
    };
  },
});
</script>
