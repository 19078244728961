<template>
  <div>
    <AppToolbar
      v-model:current_view="self.current_view"
      v-model:current_sort="self.current_sort"
      v-model:view_activities="self.activities.view"
      v-model:selected_items="self.selectedDocuments"
      permission="trash"
      search_placeholder="Search Trash"
      :breadcrumb_home="self.breadcrumbHome"
      :options="self.toolbarOptions"
      :type_options="self.filterTypeOptions"
      :filter="self.filterOptions"
      :loading="self.loading"
      @reload-data="reloadAllData"
      @empty-trash="emptyTrash"
      @submit-filter-data="searchDocuments"
    />
    <div class="the-content" @contextmenu.prevent>
      <div class="grid">
        <div
          :class="[self.activities.view ? 'col-8' : 'col-12 width-transaction']"
        >
          <div class="vault-docs" v-if="self.documents.length">
            <DocTableView
              v-show="self.current_view == 'list' && !self.loading_docs"
              ref="docTable"
              v-model:selected_documents="self.selectedDocuments"
              v-model:selected_document="self.selected_document"
              permission="trash"
              :context_items="self.menu_items"
              :loading="self.loading"
              :items="self.documents"
              :fields="self.table_fields"
              :pagination="self.pagination"
              :activities_view="self.activities.view"
              clickable
              :drag_selector="false"
              disable_select
              @load-more-docs="getDocument"
              @update-menu="getMenuItems"
            />
            <DocGrid
              v-show="self.current_view == 'grid' && !self.loading_docs"
              ref="docGrid"
              v-model:selected_documents="self.selectedDocuments"
              v-model:selected_document="self.selected_document"
              permission="trash"
              :context_items="self.menu_items"
              :loading="self.loading"
              :items="self.documents"
              disable_select
              :drag_selector="false"
              :create="{}"
              :activities_view="self.activities.view"
              @load-more-docs="getDocument"
              @update-menu="getMenuItems"
            />
          </div>
          <div v-if="self.loading_docs" class="text-center my-4">
            <ProgressSpinner
              style="width: 20px; height: 20px"
              strokeWidth="5"
              animationDuration=".5s"
              aria-label="Loading"
            />
          </div>
          <AppErrorMessage
            v-if="!self.loading_docs && !self.documents.length"
            page_type="trashed items"
            page_icon="fas fa-trash"
            hide_button
          />
        </div>
        <div class="col-4" @contextmenu.stop v-if="self.activities.view">
          <Activities
            v-model:active_tab="self.activities.active_tab"
            :doc="self.selected_document"
            :activities="self.activities.data"
            :loading="self.activities.loading"
            :tabs="['activities']"
            @load-more-activities="getActivities"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  capitalize,
  watch,
  ref,
} from "vue";
import DocTableView from "../my-vault/components/tableView.vue";
import DocGrid from "../my-vault/components/gridView.vue";
import Activities from "@/components/common/activities/index.vue";

export default defineComponent({
  name: "TrashData",
  components: {
    DocTableView,
    DocGrid,
    Activities,
  },
  setup() {
    const docTable = ref();
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_urls: {
        trash: "trash",
        project: "projects",
        media: "media",
        activity: "activities",
      },
      breadcrumbHome: {
        icon: "fas fa-trash mr-1",
        label: "Trash",
        to: { name: "trashDocs" },
      } as any,
      current_view: "list",
      current_sort:"A_Z",
      sort_by_query:false,
      toolbarOptions: {
        filter: true,
        activities: true,
        refresh: true,
        empty: true,
        view_data: true,
        view_sort:true
      },
      activities: {
        view: false,
        active_tab: 0,
        data: [] as any,
        page: 1,
        pagination: {
          per_page: 15,
          total_records: 30,
          current_page: 1,
          total_page: 1,
        },
        loading: false,
      },
      loading: false,
      search_view: false,
      search_values: {} as any,
      filterOptions: {
        status: false,
        date: true,
        type: true,
        owner: false,
      },
      filterTypeOptions: ["project", "folder", "file"],
      selected_document: {} as any,
      selectedDocuments: [] as any,
      loading_docs: true,
      documents: [] as any,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
        breadcrumbs: [] as any,
      },
      menu_items: [] as any,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:70%",
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          format: (val: any) => {
            return capitalize(val);
          },
        },
      ],
    });
    onMounted(() => {
      getDocument();
      getMenuItems();
      getActivities();
      self.current_view = self.properties.$store.getters[
        "system/getUserPreference"
      ]
        ? self.properties.$store.getters["system/getUserPreference"].view
        : "list";

        self.current_sort = self.properties.$store.getters[
        "system/getUserPreference"
      ]
        ? self.properties.$store.getters["system/getUserPreference"].sorting
          ? self.properties.$store.getters["system/getUserPreference"].sorting
          : "A_Z"
        : "A_Z";
    });

    watch(
      () => self.current_sort,
      () => {
        if (!self.loading_docs) {
          self.sort_by_query = true;
          reloadAllData();
        }
      }
    );

    function getActivities(reset = false) {
      if (reset) {
        self.activities.page = 1;
        self.activities.data = [];
      }
      if (
        (self.activities.page <= self.activities.pagination.total_page &&
          !self.activities.loading) ||
        (self.activities.page == 1 && !self.activities.loading)
      ) {
        self.activities.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_urls.activity,
          self.activities.page,
          (response: any, error: any) => {
            if (response) {
              self.activities.pagination = response.metadata;
              self.activities.data.push(...response.data);
              self.activities.page += 1;
            }
            if (error) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(error)
              );
            }
            self.activities.loading = false;
          }
        );
      }
    }
    function getMenuItems() {
      self.menu_items = [
        {
          label: "Restore",
          icon: "fa-solid fa-trash-can-arrow-up",
          command: () => {
            restoreDocument();
          },
        },
        {
          label: "Delete Forever",
          icon: "fa-solid fa-trash",
          command: () => {
            deleteDocument();
          },
        },
      ];
    }

    function reloadAllData() {
      getDocument(true);
    }

    function getDocument(reset = false) {
      if (reset) {
        self.page = 1;
        self.documents = [];
        self.loading_docs = true;
      }
      if (self.search_view) {
        return searchDocuments(self.search_values, reset);
      }

      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_urls.trash,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          },true,
          self.sort_by_query ? { sorting: self.current_sort } : null
        );
      }
    }

    function searchDocuments(filter_value: any, reset = true) {
      if (reset) {
        self.page = 1;
        self.documents = [];
        self.loading_docs = true;
      }

      self.search_values = filter_value;
      self.search_view = true;
      Object.assign(self.search_values, {
        status: ["trash"],
        owner: "me",
      });
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.searchData(
          null,
          self.page,
          self.search_values,
          (response: any, error: any) => {
            processListing(response, error);
          },false,
          self.sort_by_query ? { sorting: self.current_sort } : null
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.documents.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
      self.loading_docs = false;
    }

    function viewActivities(val: boolean, tab = 0) {
      self.activities.view = val;
      self.activities.active_tab = tab;
    }

    function restoreDocument() {
      self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to restore trashed document ?",
        header: "Restore Document",
        position: "top",
        rejectLabel: "No",
        acceptLabel: "Yes",
        accept: () => {
          self.properties.app_service.deleteData(
            `${
              self.selected_document.type == "project"
                ? self.api_urls.project
                : self.api_urls.media
            }/${self.selected_document.id}/_restore`,
            null,
            (response: any) => {
              if (response) {
                self.properties.$toastMessage(response);
                reloadAllData();
              }
            }
          );
        },
      });
    }

    function deleteDocument() {
      self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to permanently delete document ?",
        header: "Delete Document",
        position: "top",
        rejectLabel: "No",
        acceptLabel: "Yes",
        accept: () => {
          self.properties.app_service.deleteData(
            `${
              self.selected_document.type == "project"
                ? self.api_urls.project
                : self.api_urls.media
            }`,
            self.selected_document,
            (response: any, error: any) => {
              if (response) {
                self.properties.$toastMessage(response);
                self.documents = self.documents.filter(
                  (doc: any) => doc.id != self.selected_document.id
                );
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            },
            { permanent: true }
          );
        },
      });
    }

    function emptyTrash() {
      self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to Empty Trash?",
        header: "Empty trash",
        position: "top",
        rejectLabel: "No",
        acceptLabel: "Yes",
        accept: () => {
          self.properties.app_service.deleteData(
            "trash/_empty",
            null,
            (response: any) => {
              if (response) {
                self.properties.$toastMessage(response);
                self.documents = [];
                self.page = 1;
                self.pagination = {
                  per_page: 15,
                  total_records: 30,
                  current_page: 1,
                  total_page: 1,
                  breadcrumbs: [] as any,
                };
              }
            },
            { permanent: true }
          );
        },
      });
    }

    return {
      docTable,
      appContext,
      self,
      getActivities,
      reloadAllData,
      getDocument,
      searchDocuments,
      processListing,
      getMenuItems,
      viewActivities,
      deleteDocument,
      emptyTrash,
      restoreDocument,
    };
  },
});
</script>