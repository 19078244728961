import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-9" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = { class: "col-12 md:col-9" }
const _hoisted_8 = {
  id: "share-projects",
  class: "w-full"
}
const _hoisted_9 = { class: "field grid" }
const _hoisted_10 = { class: "col-12 md:col-9" }
const _hoisted_11 = ["for"]
const _hoisted_12 = { class: "flex align-items-center justify-content-end mt-2" }

import { getCurrentInstance, reactive, ref, capitalize } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'createGroup',
  emits: ["created-group"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();
const emit = __emit;
const self = reactive({
  api_url: "user-groups",
  group: getGroupDetail(),
  creating_group: false,
});
const addGroup = ref();

function getGroupDetail() {
  return {
    name: null,
    description: null,
    share_projects: false,
    default_permission: {
      read: false,
      write: false,
      edit: false,
      delete: false,
      manage: false,
    },
  } as any;
}

function updateAll(key: any) {
  appContext.config.globalProperties.$checkAll(
    self.group.default_permission,
    key
  );
}

async function createNewGroup() {
  const validate = await addGroup.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_group = true;
  const form_data = {
    name: self.group.name,
    description: self.group.description,
    share_projects: self.group.share_projects,
    default_permission: {
      read: self.group.default_permission.read ? 1 : 0,
      write: self.group.default_permission.write ? 1 : 0,
      edit: self.group.default_permission.edit ? 1 : 0,
      delete: self.group.default_permission.delete ? 1 : 0,
      manage: self.group.default_permission.manage ? 1 : 0,
    },
  };
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    form_data,
    (response: any, error: any) => {
      self.creating_group = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);

        emit("created-group", response.data.data, true);
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "addGroup",
      ref: addGroup,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "name",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Name "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "name",
              name: "Name",
              rules: "required",
              autocomplete: "off",
              modelValue: self.group.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.group.name) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.group.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.group.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Name"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "description",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Description "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "description",
              name: "Description",
              rules: "required",
              autocomplete: "off",
              modelValue: self.group.description,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((self.group.description) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Textarea, {
                  modelValue: self.group.description,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((self.group.description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Description"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "share-projects",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, " Share Projects ", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Checkbox, {
                modelValue: self.group.share_projects,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((self.group.share_projects) = $event)),
                inputId: "share-projects",
                name: "Share Projects",
                binary: true
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Share Projects"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "permission",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Permission", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Field, {
              class: "w-full formgroup-inline checkbox-wrap justify-content-between",
              as: "div",
              type: "text",
              id: "permission",
              name: "Permission",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(self.group.default_permission, (permission, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "field-checkbox",
                    key: key
                  }, [
                    _createVNode(_component_Checkbox, {
                      modelValue: self.group.default_permission[key],
                      "onUpdate:modelValue": ($event: any) => ((self.group.default_permission[key]) = $event),
                      inputId: key.toString(),
                      name: key.toString(),
                      binary: "",
                      onChange: ($event: any) => (updateAll(key))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "inputId", "name", "onChange"]),
                    _createElementVNode("label", {
                      for: key.toString(),
                      class: "ml-0"
                    }, _toDisplayString(capitalize(key.toString())), 9, _hoisted_11)
                  ]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Permission"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Create Group",
            loading: self.creating_group,
            onClick: _withModifiers(createNewGroup, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})