import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { onMounted, onBeforeUnmount } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'infiniteLoader',
  props: {
  id: {
    type: String,
    default: "scroll-more",
  },
  target: {
    type: String,
    default: null,
  },
  relative: {
    type: Boolean,
    default: false,
  },
},
  emits: ["loadMore"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const props: any = __props;

onMounted(() => {
  if (props.target) {
    const element = document.querySelector(props.target);
    element.addEventListener("scroll", infiniteHandler);
  } else {
    document.addEventListener("scroll", infiniteHandler);
  }
});

onBeforeUnmount(() => {
  if (props.target) {
    const element = document.querySelector(props.target);
    element.removeEventListener("scroll", infiniteHandler);
  } else {
    document.removeEventListener("scroll", infiniteHandler);
  }
});

function infiniteHandler() {
  const topEle = document.getElementById(props.id);
  if (isElementInViewPort(topEle)) {
    emit("loadMore");
  }
}

function isElementInViewPort(element: any) {
  const rect = element.getBoundingClientRect();
  const viewPortBottom = window.innerHeight || document.documentElement.clientHeight;
  const viewPortRight = window.innerWidth || document.documentElement.clientWidth;
  const isTopInViewPort = rect.top >= 0,
    isLeftInViewPort = rect.left >= 0,
    isBottomInViewPort = rect.bottom <= viewPortBottom,
    isRightInViewPort = rect.right <= viewPortRight;
  return isTopInViewPort && isLeftInViewPort && isBottomInViewPort && isRightInViewPort;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: __props.id,
    class: "infinite-scroller",
    style: _normalizeStyle([__props.relative ? 'position:relative;' : ''])
  }, null, 12, _hoisted_1))
}
}

})