<template>
  <div id="form-content" class="page-content">
    <Form ref="addGroup" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0"
          >Name <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="Name"
            rules="required"
            autocomplete="off"
            v-model="self.group.name"
          >
            <InputText type="text" v-model="self.group.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Name" />
        </div>
      </div>

      <div class="field grid">
        <label for="description" class="col-12 mb-2 md:col-3 md:mb-0"
          >Description <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="description"
            name="Description"
            rules="required"
            autocomplete="off"
            v-model="self.group.description"
          >
            <Textarea v-model="self.group.description" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Description" />
        </div>
      </div>
      <div class="field grid">
        <label for="share-projects" class="col-12 mb-2 md:col-3 md:mb-0">
          Share Projects
        </label>
        <div class="col-12 md:col-9">
          <div id="share-projects" class="w-full">
            <Checkbox
              v-model="self.group.share_projects"
              inputId="share-projects"
              name="Share Projects"
              :binary="true"
            />
          </div>
          <ErrorMessage as="small" class="p-error" name="Share Projects" />
        </div>
      </div>
      <div class="field grid">
        <label for="permission" class="col-12 mb-2 md:col-3 md:mb-0"
          >Permission</label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full formgroup-inline checkbox-wrap justify-content-between"
            as="div"
            type="text"
            id="permission"
            name="Permission"
            autocomplete="off"
          >
            <div
              class="field-checkbox"
              v-for="(permission, key) in self.group.default_permission"
              :key="key"
            >
              <Checkbox
                v-model="self.group.default_permission[key]"
                :inputId="key.toString()"
                :name="key.toString()"
                binary
                @change="updateAll(key)"
              />
              <label :for="key.toString()" class="ml-0">
                {{ capitalize(key.toString()) }}
              </label>
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" name="Permission" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Create Group"
          :loading="self.creating_group"
          @click.prevent="createNewGroup"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive, ref, capitalize } from "vue";
const { appContext }: any = getCurrentInstance();
const emit = defineEmits(["created-group"]);
const self = reactive({
  api_url: "user-groups",
  group: getGroupDetail(),
  creating_group: false,
});
const addGroup = ref();

function getGroupDetail() {
  return {
    name: null,
    description: null,
    share_projects: false,
    default_permission: {
      read: false,
      write: false,
      edit: false,
      delete: false,
      manage: false,
    },
  } as any;
}

function updateAll(key: any) {
  appContext.config.globalProperties.$checkAll(
    self.group.default_permission,
    key
  );
}

async function createNewGroup() {
  const validate = await addGroup.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_group = true;
  const form_data = {
    name: self.group.name,
    description: self.group.description,
    share_projects: self.group.share_projects,
    default_permission: {
      read: self.group.default_permission.read ? 1 : 0,
      write: self.group.default_permission.write ? 1 : 0,
      edit: self.group.default_permission.edit ? 1 : 0,
      delete: self.group.default_permission.delete ? 1 : 0,
      manage: self.group.default_permission.manage ? 1 : 0,
    },
  };
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    form_data,
    (response: any, error: any) => {
      self.creating_group = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);

        emit("created-group", response.data.data, true);
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>