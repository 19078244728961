import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref } from "vue"

const _hoisted_1 = { id: "main-header" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href", "target"]
const _hoisted_4 = {
  key: 0,
  class: "navbar-message text-white"
}

import { detect } from "detect-browser";
import { useStore } from "vuex";
import { getCurrentInstance, onMounted, reactive } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'publicHeader',
  emits: ['user-loaded'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { appContext }: any = getCurrentInstance();  
const self = reactive({
  main_logo: appContext.config.globalProperties.$getImage(process.env.VUE_APP_LOGO),
  view_error_msg: false, 
  browser: detect() as any,
  user: {} as any,
  store: useStore() as any,
});

onMounted(() => {
  detectBrowser(); 
  emit('user-loaded',true)
});

function detectBrowser() {
  if (self.browser) {
    if (self.browser.name == 'Chrome' || self.browser.name == 'chrome') {
      self.view_error_msg = false;
    } else {
      self.view_error_msg = true;
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_Image = _resolveComponent("Image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menubar = _resolveComponent("Menubar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menubar, null, {
      start: _withCtx(() => [
        _createVNode(_component_Image, {
          class: "logo",
          src: self.main_logo
        }, null, 8, ["src"])
      ]),
      item: _withCtx(({ label, item, props, root, hasSubmenu }) => [
        (item.to)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item.to,
              custom: ""
            }, {
              default: _withCtx((routerProps) => [
                _createElementVNode("a", _mergeProps({
                  href: routerProps.href
                }, props.action), [
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                  _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                ], 16, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("a", _mergeProps({
              key: 1,
              href: item.url,
              target: item.target
            }, props.action), [
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17),
              _createElementVNode("span", _mergeProps({
                class: [
              hasSubmenu &&
                (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
            ]
              }, props.submenuicon), null, 16)
            ], 16, _hoisted_3))
      ]),
      end: _withCtx(() => [
        (self.view_error_msg)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
              _createElementVNode("p", null, " This browser is not supported. Use the latest version of Chrome to get the best experience. ", -1)
            ])))
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createElementVNode("span", null, null, -1)),
        _createElementVNode("span", null, [
          _createVNode(_component_Button, { type: "button" }, {
            default: _withCtx(() => [
              _createVNode(_component_Avatar, {
                label: "G",
                shape: "circle",
                class: "mr-2 text-white"
              }),
              _cache[2] || (_cache[2] = _createTextVNode(" Guest "))
            ]),
            _: 1
          }),
          _createVNode(_component_Button, {
            label: "Login",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(appContext).config.globalProperties.$keycloak.login())),
            class: "button-primary"
          })
        ])
      ]),
      _: 1
    })
  ]))
}
}

})