import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-9" }
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "flex align-items-center justify-content-end mt-2" }

import { getCurrentInstance, reactive, ref, capitalize } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'createGroupUser',
  props: {
  group: {
    type: Object,
  } as any,
  users: {
    type: Object,
  },
},
  emits: ["created-user","search-users"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();
const emit = __emit;
const props:any = __props;
const self = reactive({
  group: getGroupDetail(),
  creating_group: false,
});
const addGroupUser = ref();

function getGroupDetail() {
  return {
    user_id: null,
    permission: {
      read: false,
      write: false,
      edit: false,
      delete: false,
      manage: false,
    },
  } as any;
}
function updateAll(key: any) {
  appContext.config.globalProperties.$checkAll(self.group.permission, key);
}
async function addUser() {
  const validate = await addGroupUser.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_group = true;
  const form_data = {
    user_id: self.group.user_id,
    permission: {
      read: self.group.permission.read ? 1 : 0,
      write: self.group.permission.write ? 1 : 0,
      edit: self.group.permission.edit ? 1 : 0,
      delete: self.group.permission.delete ? 1 : 0,
      manage: self.group.permission.manage ? 1 : 0,
    },
  };
  await appContext.config.globalProperties.app_service.createData(
    `user-groups/${props.group.id}/users`,
    form_data,
    (response: any, error: any) => { 
      if (response) { 
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-user", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
      self.creating_group = false;
    }
  );
}

return (_ctx: any,_cache: any) => {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "addGroupUser",
      ref: addGroupUser,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("label", {
            for: "user",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("User "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "name",
              name: "User",
              rules: "required",
              autocomplete: "off",
              modelValue: self.group.user_id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((self.group.user_id) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: self.group.user_id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.group.user_id) = $event)),
                  options: __props.users.data,
                  optionLabel: "name",
                  optionValue: "id",
                  placeholder: "Search & Select a User",
                  class: "w-full",
                  filter: "",
                  loading: __props.users.loading,
                  emptyMessage: __props.users.emptyFilterMessage,
                  emptyFilterMessage: __props.users.emptyFilterMessage,
                  onFilter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search-users', { originalEvent: $event, user_id: self.group.user_id })))
                }, null, 8, ["modelValue", "options", "loading", "emptyMessage", "emptyFilterMessage"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "User"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("label", {
            for: "permission",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Permission", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full formgroup-inline checkbox-wrap justify-content-between",
              as: "div",
              type: "text",
              id: "permission",
              name: "Permission",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(self.group.permission, (permission, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "field-checkbox",
                    key: key
                  }, [
                    _createVNode(_component_Checkbox, {
                      modelValue: self.group.permission[key],
                      "onUpdate:modelValue": ($event: any) => ((self.group.permission[key]) = $event),
                      inputId: key.toString(),
                      name: key.toString(),
                      binary: "",
                      onChange: ($event: any) => (updateAll(key))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "inputId", "name", "onChange"]),
                    _createElementVNode("label", {
                      for: key.toString(),
                      class: "ml-0"
                    }, _toDisplayString(capitalize(key.toString())), 9, _hoisted_6)
                  ]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Permission"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Add User",
            loading: self.creating_group,
            onClick: _withModifiers(addUser, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})