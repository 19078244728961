<template>
  <Dialog
    modal
    class="owner-dialog"
    v-model:visible="self.view_modal"
    @hide="$emit('update:view_modal', false)"
    :style="{ width: '70vw' }"
    position="top"
  >
    <template #header>
      <Button
        label="Change Owner"
        icon="fas fa-user"
        text
        class="primary-color p-0 font-bold opacity-100"
        disabled
      />
    </template>
    <div class="modal-body">
      <AutoComplete
        v-model="self.new_owner_id"
        placeholder="Enter Name / Email Address"
        :suggestions="self.users"
        optionLabel="email"
        optionValue="id"
        @complete="searchUsers"
        class="w-full owner-change my-2"
      >
        <template #option="slotProps">
          <div class="flex align-content-center flex-wrap">
            <span class="flex align-items-center justify-content-center">
              <Avatar
                v-if="slotProps.option.image"
                :image="self.properties.$getFullPath(slotProps.option.image)"
                shape="circle"
                class="mr-1"
              />
              <Avatar
                v-else
                :label="slotProps.option.name.charAt(0)"
                shape="circle"
                class="mr-1 text-white"
                :class="`bg-${
                  self.bg_colors[
                    slotProps.index > 8
                      ? Math.floor(Math.random() * 8)
                      : slotProps.index
                  ]
                }-300`"
              />
            </span>
            <span class="flex align-items-center justify-content-center">
              {{ slotProps.option.name }} {{ slotProps.option.email }}
            </span>
          </div>
        </template>
      </AutoComplete>
      <Button
        @click="changeOwnerShip"
        label="Change Owner"
        class="button-primary"
        icon="fas fa-user"
      />
    </div>
  </Dialog>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  capitalize,
} from "vue";

export default defineComponent({
  name: "OwnerModal",
  props: {
    view_modal: {
      type: Boolean,
      default: false,
    },
    doc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:view_modal"],
  setup(props: any, { emit }) {
    const appData: any = getCurrentInstance();
    const self = reactive({
      view_modal: false,
      api_url: `${props.doc.type == "project" ? "projects" : "media"}/${props.doc.id}/change-ownership`,
      properties: appData.appContext.config.globalProperties,
      users: [] as any,
      new_owner_id: null,
      page: 1,
      pagination: {
        current_page: 1,
        items_per_page: 30,
        total_page: 3,
        total_records: 73,
      },
      bg_colors: [
        "blue",
        "green",
        "yellow",
        "cyan",
        "indigo",
        "teal",
        "orange",
        "red",
        "primary",
      ],
      emptyFilterMessage: "Enter a text to view Users",
    });

    const debounce = self.properties.$debounce();

    onMounted(() => {
      self.view_modal = props.view_modal;
    });

    watch(
      () => props.view_modal,
      (val: any) => {
        self.view_modal = val;
      }
    );

    function searchUsers(e: any) {
      debounce(() => {
        if (!e.query) {
          self.emptyFilterMessage = "Enter a text to view Users";
          return;
        }
        self.properties.app_service.searchData(
          "users",
          null,
          {
            query: e.query,
            exclude_me: true,
          },
          (response: any, error: any) => {
            if (response) {
              self.users = response.data;
              if (!self.users) {
                self.emptyFilterMessage = "No User Found";
              }
            }
            if (error) {
              self.properties.$toastMessage(
                self.properties.$getErrorResponse(error)
              );
            }
          }
        );
      });
    }

    function changeOwnerShip() {
      self.properties.app_service.createData(
        self.api_url,
        {
          new_owner_id: self.new_owner_id.id,
        },
        (response: any, error: any) => {
          if (response) {
            self.properties.$toastMessage(response);
            emit("update:view_modal", false);
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    return {
      self,
      appData,
      debounce,
      capitalize,
      changeOwnerShip,
      searchUsers,
    };
  },
});
</script>