<template>
  <div>
    <AppToolbar
      v-model:current_view="self.current_view"
      v-model:selected_items="self.selectedDocuments"
      search_placeholder="Search Shared"
      :breadcrumb_home="self.breadcrumbHome"
      :breadcrumb_items="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      :is_public="true"
      @reload-data="reloadAllData"
      @download-item="downloadSelectedDocuments"
    />
    <div class="the-content">
      <div class="grid">
        <div
          class="p-0 relative top-0 col-12 width-transaction"
          @contextmenu.prevent
        >
          <div class="vault-docs" v-if="self.documents.length && !self.loading">
            <DocTableView
              v-show="self.current_view == 'list' && !self.loading_docs"
              ref="docTable"
              is_public
              v-model:selected_documents="self.selectedDocuments"
              v-model:selected_document="self.selected_document"
              :context_items="self.menu_items"
              :loading="self.loading"
              :items="self.documents"
              :fields="self.table_fields"
              :pagination="self.pagination"
              :clickable="true"
              :drag_selector="false"
              @load-more-docs="getDocument"
              @load-doc-detail="getDocumentDetail"
              @update-menu="getMenuItems"
            />
            <DocGrid
              v-show="self.current_view == 'grid' && !self.loading_docs"
              ref="docGrid"
              :drag_selector="false"
              v-model:selected_documents="self.selectedDocuments"
              v-model:selected_document="self.selected_document"
              :create="self.create"
              :context_items="self.menu_items"
              :loading="self.loading"
              :items="self.documents"
              @load-more-docs="getDocument"
              @load-doc-detail="getDocumentDetail"
              @update-menu="getMenuItems"
            />
          </div>
          <AppErrorMessage
            v-if="!self.documents.length && !self.loading"
            :message="self.emptyMessage"
            page_icon="fa fa-share"
            hide_button
            hide_page_type
          />
          <div v-if="self.loading_docs" class="text-center my-4">
            <ProgressSpinner
              style="width: 20px; height: 20px"
              strokeWidth="5"
              animationDuration=".5s"
              aria-label="Loading"
            />
          </div>
        </div>
      </div>
      <Dialog
        v-model:visible="self.preview_img"
        modal
        :style="{ width: '90em', height: '90em' }"
        maximizable
      >
        <template #header>
          {{ self.selected_document.name }}
        </template>
        <div
          class="flex justify-content-center align-items-center flex-wrap text-center"
        >
          <div class="dialog-image">
            <Image
              :src="self.properties.$getFullPath(self.selected_document.path)"
              :alt="self.selected_document.name"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        v-model:visible="self.view_progress"
        id="doc-upload-progress"
        position="bottomright"
        @hide="self.view_progress = false"
        :style="{ width: '30rem' }"
        :closeOnEscape="false"
        :draggable="false"
      >
        <template #header>
          <Button
            label="Progressing Download"
            icon="fa-solid fa-spinner"
            text
            class="primary-color p-0 font-bold opacity-100"
            disabled
          />
        </template>
        <div class="modal-body">{{ self.download.message }}</div>
      </Dialog>
      <PDFViewer
        v-if="self.preview_pdf"
        :preview="self.preview_pdf"
        :pdf_page="self.pdf_page"
        :is_public="true"
        @close-pdf="
          self.preview_pdf = false;
          self.pdf_page.view = false;
        "
        :pdf_url="
          self.selected_document.proxy_path
            ? self.selected_document.proxy_path
            : self.selected_document.path
        "
        :doc="self.selected_document"
        :user_session="self.user_session"
      />
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  capitalize,
  watch,
  ref,
} from "vue";
import DocTableView from "@/modules/my-vault/views/my-vault/components/tableView.vue";
import DocGrid from "@/modules/my-vault/views/my-vault/components/gridView.vue";
import PDFViewer from "@/components/common/pdf/pdfViewer.vue";
import { v4 as uuidv4 } from "uuid";
export default defineComponent({
  name: "PublicShare",
  components: {
    DocTableView,
    DocGrid,
    PDFViewer,
  },
  emits: ["remove-header"],
  setup(props, { emit }) {
    const docTable = ref();
    const docGrid = ref();
    const moveDoc = ref();
    const docUploader = ref();
    const { appContext }: any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_urls: {
        my_vault: "public/share",
        project: "public/projects",
        media: "public/media",
        analytics: "public/document/analytics",
      },
      breadcrumbHome: {
        icon: "fa fa-share mr-1",
        label: "Shared Content",
        url: `/guest/share.html?token=${appContext.config.globalProperties.$route.query.token}`,
      } as any,
      breadcrumbItems: [] as any,
      current_view: "list",
      user_session: uuidv4(),
      preview_pdf: false,
      download_keys: [],
      download_interval:{},
      pdf_page: {
        view: false,
        page: 1,
      },
      view_progress: false,
      preview_img: false,
      toolbarOptions: {
        refresh: true,
        view_data: true,
      },
      create: {
        id: "new",
        new: false,
        type: "",
        name: "New",
        newName: "New",
      },
      emptyMessage: "" as string,
      loading: false,
      selected_document: {} as any,
      parent_doc: {} as any,
      nested_path: "",
      selectedDocuments: [] as any,
      loading_docs: true,
      documents: [] as any,
      download: {
        modal: false,
        message: "",
      },
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
        breadcrumbs: [],
      },
      menu_items: [] as any,
      default_context_menu: [] as any,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:30%",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          format: (val: any) => {
            return appContext.config.globalProperties.$dayMonthDateYear(val);
          },
        },
        {
          key: "metadata",
          label: "Size",
          sortable: false,
          format: (val: any) => {
            const data = val
              ? appContext.config.globalProperties.app_service.parseJSON(val)
              : null;
            return data
              ? appContext.config.globalProperties.$getFileSize(data.size)
              : "-";
          },
        },
        {
          key: "owner",
          label: "Owner",
          sortable: false,
          format: (val: any) => {
            return val.name ? val.name : val;
          },
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          format: (val: any) => {
            return capitalize(val);
          },
        },
      ],
      is_root: true,
    });
    onMounted(() => {
      getDocument();
      getMenuItems();
      emit("remove-header", false);
    });
    watch(
      () => self.selected_document,
      () => {
        getMenuItems();
      }
    );
    function getMenuItems() {
      self.menu_items = [
        {
          label: "Open",
          icon: "fas fa-folder-open",
          command: () => {
            getDocumentDetail({ data: self.selected_document });
          },
        },
        {
          label: "Download",
          icon: "fas fa-download",
          command: () => {
            downloadDocument();
          },
        },
      ];
    }

    function reloadAllData() {
      getDocument(true);
    }

    function getDocument(reset = false) {
      if (reset) {
        self.page = 1;
        self.documents = [];
        self.loading_docs = true;
      }
      if (!self.is_root) {
        return getItemChildren(reset);
      }

      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.public_service.getData(
          self.api_urls.my_vault,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          },
          true,
          {
            token: appContext.config.globalProperties.$route.query.token,
          }
        );
      }
    }

    function getItemChildren(reset = false) {
      if (reset) {
        self.page = 1;
        self.documents = [];
        self.loading_docs = true;
      }
      self.is_root = false;
      if (self.parent_doc.id) {
        if (
          (self.page <= self.pagination.total_page && !self.loading) ||
          (self.page == 1 && !self.loading)
        ) {
          self.loading = true;

          appContext.config.globalProperties.public_service.getData(
            `${
              self.parent_doc.type == "project"
                ? self.api_urls.project
                : self.api_urls.media
            }/${self.parent_doc.id}/childs`,
            self.page,
            (response: any, error: any) => {
              processListing(response, error);
              setTimeout(() => {
                if (reset) {
                  setDocBreadCrumb();
                }
              }, 500);
            },
            true,
            {
              token: appContext.config.globalProperties.$route.query.token,
            }
          );
        }
      }
    }

    function setDocBreadCrumb() {
      if (self.pagination.breadcrumbs.length) {
        const breadcrumbs = self.pagination.breadcrumbs.map(
          (breadcrumb: any) => {
            return {
              label: breadcrumb.name,
              command: () => {
                getDocData(breadcrumb);
              },
              ...breadcrumb,
            };
          }
        );
        self.breadcrumbItems = breadcrumbs;
      }
    }

    function getDocData(doc: any) {
      self.parent_doc = doc;
      getItemChildren(true);
    }

    function processListing(res: any, err: any) {
      if (res) {
        if (res.metadata) {
          self.pagination = res.metadata;
        }

        if (res.data) {
          self.documents.push(...res.data);
        } else {
          self.documents.push(res);
        }
        if (!self.documents.length) {
          self.emptyMessage = "No Documents Found";
        }

        self.page += 1;
      }
      if (err) {
        self.emptyMessage = "Invalid Token";
      }

      self.loading = false;
      self.loading_docs = false;
    }

    function previewImage() {
      self.preview_img = true;
      updateAnalytics();
    }

    function updateAnalytics() {
      self.user_session = uuidv4();
      self.properties.public_service.createData(
        self.api_urls.analytics,
        {
          user_session: self.user_session,
          document_id: self.selected_document.id,
          current_timestamp: Date.now(),
        },
        () => {
          return;
        }
      );
    }

    function previewDocument(page = 1) {
      if (self.selected_document.text_extraction_pending)
        return appContext.config.globalProperties.$toastMessage({
          status: "info",
          data: "Text Extraction Pending Please Wait",
        });
      if (
        appContext.config.globalProperties.$getContentType(
          self.selected_document
        ) != "pdf"
      ) {
        if (!self.selected_document.proxy_path)
          return appContext.config.globalProperties.$toastMessage({
            status: "warn",
            data: "File is not supported for the preview.",
          });
      }
      self.pdf_page = {
        view: true,
        page: page,
      };
      self.preview_pdf = true;
    }

    function getDocumentDetail(e: any) {
      self.selected_document = e.data;
      if (
        appContext.config.globalProperties.$getContentType(e.data) ==
          "project" ||
        appContext.config.globalProperties.$getContentType(e.data) == "folder"
      ) {
        self.parent_doc = self.selected_document;
        getItemChildren(true);
      } else if (
        appContext.config.globalProperties.$getContentType(e.data) == "image"
      ) {
        previewImage();
      } else {
        previewDocument();
      }
      self.selectedDocuments = [];
    }

    function downloadDocument() {
      const download_key = uuidv4();
      self.download_keys.push(download_key);
      const download_data = {
        projects:
          self.selected_document.type == "project"
            ? [self.selected_document.id]
            : null,
        media:
          self.selected_document.type != "project"
            ? [self.selected_document.id]
            : null,
      };
      appContext.config.globalProperties.public_service.createData(
        "public/downloads",
        download_data,
        (response: any, error: any) => {
          if (response) {
            if (response.data.data.zip_download) {
              self.download = {
                modal: true,
                message: response.data.message,
              };
              self.view_progress = true;
              checkDownloadGeneration(download_key);
            } else {
              appContext.config.globalProperties.$downloadItem(
                response.data.data.url,
                false
              );
            }
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        {
          token: appContext.config.globalProperties.$route.query.token,
          downloadKey: download_key,
        }
      );
    }
    function downloadSelectedDocuments() {
      const download_key = uuidv4();
      self.download_keys.push(download_key);
      const download_list = {
        projects: [] as any,
        media: [] as any,
      };
      self.selectedDocuments.forEach((doc: any) => {
        if (doc.type == "project" || doc.type == "projects") {
          download_list.projects.push(doc.id);
        } else {
          download_list.media.push(doc.id);
        }
      });
      appContext.config.globalProperties.public_service.createData(
        "public/downloads",
        download_list,
        (response: any, error: any) => {
          if (response) {
            if (response.data.data.zip_download) {
              self.download = {
                modal: true,
                message: response.data.message,
              };
              self.view_progress = true;
              checkDownloadGeneration(download_key);
            } else {
              appContext.config.globalProperties.$downloadItem(
                response.data.data.url,
                false
              );
            }
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        },
        {
          token: appContext.config.globalProperties.$route.query.token,
          downloadKey: download_key,
        }
      );
    }

    function checkDownloadGeneration(key: any) {
      const download_key_index = self.download_keys.indexOf(key);
      self.download_interval[key] = setInterval(() => {
        if (self.download_keys[download_key_index]) {
          appContext.config.globalProperties.public_service.getData(
            "public/downloads",
            null,
            (response: any) => {
              if (response) {
                if (response.status == "COMPLETED") {
                  clearInterval(self.download_interval[key]);
                  appContext.config.globalProperties.$downloadItem(
                    response.url,
                    false
                  );
                  self.download_keys = self.download_keys.filter(
                    (dKey: any) => dKey != key
                  );
                  if (!self.download_keys.length) {
                    self.view_progress = false;
                  }
                }
              }
            },
            true,
            {
              token: appContext.config.globalProperties.$route.query.token,
              downloadKey: self.download_keys[download_key_index],
            }
          );
        } else {
          clearInterval(self.download_interval[key]);
        }
      }, 5000);
    }

    return {
      moveDoc,
      docTable,
      docGrid,
      docUploader,
      appContext,
      self,
      reloadAllData,
      getDocument,
      processListing,
      getMenuItems,
      getItemChildren,
      getDocumentDetail,
      previewImage,
      previewDocument,
      checkDownloadGeneration,
      downloadSelectedDocuments,
    };
  },
});
</script>
