// function getIcon(data: any) {
//   let icon = "";
//   if (data.type == "project") {
//     icon = `<div class="fa-stack fa-2x">
//       <i class="fas fa-folder fa-stack-2x" ></i>
//       <i
//         class="fas fa-layer-group fa-stack-1x text-white fa-inverse"
//       ></i>
//     </div>`;
//   } else if (data.type == "folder") {
//     icon = `<i class="fas fa-folder" ></i>`;
//   } else if (data.type == "projects") {
//     icon = `<i
//       class="fas fa-layer-group"
//     ></i>`;
//   } else if (getContentType(data) == "pdf") {
//     icon = `<i class="fa-solid fa-file-pdf" ></i>`;
//   } else {
//     icon = `<i class="fas fa-file"></i>`;
//   }
//   return icon;
// }


function getIcon(data: any) {
  let icon = "";
  if (data.type == "project") {
    icon = `<div class="stack-icon">
      <i class="icofont icofont-folder stack-2x" ></i>
      <i
        class="icofont icofont-layers stack-1x text-white"
      ></i>
    </div>`;
  } else if (data.type == "folder") {
    icon = `<i class="icofont icofont-folder" ></i>`;
  } else if (data.type == "projects") {
    icon = `<i
      class="icofont icofont-layers"
    ></i>`;
  } else if (getContentType(data) == "pdf") {
    icon = `<i class="icofont icofont-file-pdf" ></i>`;
  } else {
    icon = `<i class="icofont icofont-file-fill"></i>`;
  }
  return icon;
}

function getFileSize(val: number) {
  let bytes = "";
  if (val >= 1073741824) {
    bytes = (val / 1073741824).toFixed(2) + " GB";
  } else if (val >= 1048576) {
    bytes = (val / 1048576).toFixed(2) + " MB";
  } else if (val >= 1024) {
    bytes = (val / 1024).toFixed(2) + " KB";
  } else if (val > 1) {
    bytes = val + " bytes";
  } else if (val == 1) {
    bytes = val + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}

function parseJSON(value: any) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

function getContentType(listing: any) {
  if (listing.type == "collection" || listing.type == "folder") return "folder";
  if (listing.type == "project" || listing.type == "projects") return "project";
  const metadata = listing.metadata ? parseJSON(listing.metadata) : null;
  if (metadata && metadata.content_type) {
    switch (metadata.content_type) {
      case "application/pdf":
        return "pdf";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "document";
      case "application/vnd.ms-excel":
      case "application/vnd.ms-excel.sheet.macroEnabled.12":
      case "application/vnd.ms-excel.sheet.binary.macroEnabled.12":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "sheet";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "presentation";
      case "application/zip":
        return "zip";
      case "application/json":
        return "json";
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
        return "image";
      case "text/plain":
        return "txt";
      case "application/sql":
      case "application/x-sql":
        return "sql";
    }
  }
  return "file";
}

export default {
  $getContentType: getContentType,
  $getIcon: getIcon,
  $getFileSize: getFileSize,
};