<template>
  <div>
    <div class="py-2 text-right">
      <Button
        class="button-primary"
        :class="{'cursor-ban':!self.selected_projects.length}"
        :disabled="!self.selected_projects.length"
        @click="revokeAllShare"
        label="Revoke"
      />
    </div>
    <TableView
      permission_type="user"
      v-model:selected_items="self.selected_projects"
      v-model:selected_item="self.selected_project"
      :loading="self.loading"
      :items="self.projects"
      :fields="self.table_fields"
      :actions="self.action_buttons"
      :pagination="self.pagination"
      @load-more-data="getUserProjects"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, reactive, onMounted, capitalize } from "vue";
export default defineComponent({
  name: "UserProjects",
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props:any) {
    const { appContext }:any = getCurrentInstance();
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_url: `users/${props.user.id}/shared-projects`,
      selected_projects: [],
      selected_project: [],
      loading: false,
      projects: [] as any,
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          format:(val:string)=>{
            return val ? capitalize(val) : null
          }
        },
        {
          key: "read",
          label: "Read",
          sortable: false,
          style: "width:90px",
          format: (val: any) => {
            return val
              ? "<i class='fas fa-check success'></i>"
              : "<i class='fas fa-times danger'></i>";
          },
        },
        {
          key: "write",
          label: "Write",
          sortable: false,
          style: "width:90px",
          format: (val: any) => {
            return val
              ? "<i class='fas fa-check success'></i>"
              : "<i class='fas fa-times danger'></i>";
          },
        },
        {
          key: "edit",
          label: "Edit",
          sortable: false,
          style: "width:90px",
          format: (val: any) => {
            return val
              ? "<i class='fas fa-check success'></i>"
              : "<i class='fas fa-times danger'></i>";
          },
        },
        {
          key: "delete",
          label: "Delete",
          sortable: false,
          style: "width:90px",
          format: (val: any) => {
            return val
              ? "<i class='fas fa-check success'></i>"
              : "<i class='fas fa-times danger'></i>";
          },
        },
        {
          key: "manage",
          label: "Manage",
          sortable: false,
          style: "width:90px",
          format: (val: any) => {
            return val
              ? "<i class='fas fa-check success'></i>"
              : "<i class='fas fa-times danger'></i>";
          },
        },
      ],
      action_buttons: [
        {
          type: "Revoke",
          icon: "icofont icofont-ban",
          command: (val: any) => {
            confirmRevoke(val);
          },
          permission:'edit'
        },
      ],
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
    });
    onMounted(() => {
      getUserProjects();
    });
    function getUserProjects(reset = false) {
      if (reset) {
        self.page = 1;
        self.projects = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) || (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        self.properties.app_service.getData(
          self.api_url,
          self.page,
          (res: any, err: any) => {
            if (res) {
              if (res.metadata) {
                self.pagination = res.metadata;
              }
              if (res.data) {
                const data = res.data.map((doc: any) => {
                  const permission = JSON.parse(doc.permission);
                    return {
                      id: doc.id,
                      name: doc.name, 
                      type: doc.type,
                      read: permission.read == 1,
                      write: permission.write == 1,
                      edit: permission.edit == 1,
                      delete: permission.delete == 1,
                      manage: permission.manage == 1,
                    };
                });
                self.projects.push(...data);
              }

              self.page += 1;
            }
            if (err) {
              appContext.config.globalProperties.$toastMessage(
                appContext.config.globalProperties.$getErrorResponse(err)
              );
            }
            self.loading = false;
          }
        );
      }
    }

    function confirmRevoke(val:any){
        self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to revoke share ?",
        header: "Revoke Share",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          revokeShare(val)
        },
      });
    }

    function revokeShare(val: any) {
      self.properties.app_service.deleteData(
        `${val.type == "project" ? "projects" : "media"}/${val.id}/share/${
          props.user.email
        }`,
        null,
        (response: any, error: any) => {
          if (response) {
            self.properties.$toastMessage(response);
            getUserProjects(true);
          }
          if (error) {
            self.properties.$toastMessage(
              self.properties.$getErrorResponse(error)
            );
          }
        }
      );
    }

    function revokeAllShare() {
      self.properties.$confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to revoke all share ?",
        header: "Revoke Share",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          self.selected_projects.forEach((project: any) => {
            revokeShare(project)
          });
        },
      });
    }
    return {
      appContext,
      self,
      getUserProjects,
      confirmRevoke,
      revokeShare,
      revokeAllShare,
    };
  },
});
</script>