<template>
  <Dialog
    v-model:visible="self.upload.process.view"
    id="doc-upload-progress"
    position="bottomright"
    :style="{ width: '30rem' }"
    :closeOnEscape="false"
    :draggable="false"
  >
    <template #container="{ closeCallback }">
      <div class="dialog-header flex justify-content-between flex-wrap p-3">
        <h2>Uploading Documents</h2>
        <div>
          <Button
            text
            @click="toggleUploadMinimize"
            rounded
            aria-label="Minimize"
          >
            <i v-if="self.is_minimized" class="pi pi-angle-up"></i>
            <i v-else class="pi pi-angle-down"></i>
          </Button>
          <Button
            v-if="!self.upload.progressing"
            @click="closeCallback"
            text
            rounded
            aria-label="Close"
          >
            <i class="pi pi-times"></i>
          </Button>
        </div>
      </div>
      <div v-if="!self.is_minimized" class="p-3">
        <div
          v-if="self.upload.process.total_folders.uploading > 0"
          class="folder_upload_status mb-4"
        >
          Folder uploaded : {{ self.upload.process.total_folders.uploaded }} /
          {{ self.upload.process.total_folders.uploading }}
          <ProgressBar :value="total_folders_uploaded" style="height: 10px" />
        </div>
        <div
          v-if="self.upload.process.total_files.uploading"
          class="file_upload_status mb-4"
        >
          <Accordion v-model:activeIndex="active">
            <AccordionTab>
              <template #header>
                <div class="w-11">
                  File uploaded :
                  {{ self.upload.process.total_files.uploaded }} /
                  {{ self.upload.process.total_files.uploading }}
                  <ProgressBar
                    :value="total_files_uploaded"
                    style="height: 10px"
                  />
                </div>
              </template>
              <div class="main-app" v-if="self.upload.activity.length">
                <ScrollPanel
                  :style="`width: 100%; height: ${
                    self.upload.activity.length > 0
                      ? self.upload.activity.length < 2
                        ? '150'
                        : '300'
                      : '50'
                  }px`"
                  :pt="{
                    wrapper: {
                      style: {
                        'border-right': '10px solid var(--surface-ground)',
                      },
                    },
                    bary: 'hover:bg-primary bg-primary opacity-100',
                  }"
                >
                  <Card
                    v-for="(item, index) in self.upload.activity"
                    :key="index"
                    class="p-0 border-bottom-1 cursor-pointer border-noround"
                  >
                    <template #content>
                      <div class="flex">
                        <div
                          class="flex-initial flex align-items-top justify-content-center w-1"
                        >
                          <span
                            class="bg-primary border-circle pt-1 h-2rem w-2rem text-center"
                          >
                            <i class="fa fa-upload"></i>
                          </span>
                        </div>
                        <div
                          class="flex-initial flex align-items-top justify-content-start min-w-full grid pl-2"
                        >
                          <span
                            class="col-11"
                            :class="[
                              item.uploadErr || !userOnline
                                ? 'col-10'
                                : 'col-11',
                            ]"
                          >
                            <span class="block font-medium text-900 mb-2">
                              Uploading {{ item.file_name }}
                            </span>
                            <ProgressBar
                              :value="
                                item.progress
                                  ? Number(item.progress.toFixed(0))
                                  : item.progress
                              "
                              style="height: 10px"
                            />
                            <span class="text-red-500">
                              {{ item.message ? item.message : "" }}
                            </span>
                            <span v-if="!userOnline" class="text-red-500">
                              Please check your internet connection.
                            </span>
                          </span>
                          <template
                            v-if="Number(item.progress.toFixed(0)) < 100"
                          >
                            <div
                              @click="activityCanceled(item)"
                              class="col-1 cursor-pointer text-right flex align-items-center"
                            >
                              <i class="fa fa-times"></i>
                            </div>
                            <div
                              v-if="item.uploadErr || !userOnline"
                              @click="
                                item.done = true;
                                $emit('reupload-file', item);
                              "
                              class="col-1 cursor-pointer text-right flex align-items-center"
                            >
                              <i class="fas fa-redo"></i>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </Card>
                </ScrollPanel>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { v4 as uuidv4 } from "uuid";
export default defineComponent({
  name: "UploadProgress",
  setup() {
    const appData: any = getCurrentInstance();
    const properties: any = appData.appContext.config.globalProperties;
    const fileInput = ref();
    const userOnline = ref(true);
    const total_files_uploaded = computed(() => {
      const total = (
        (self.upload.process.total_files.uploaded /
          self.upload.process.total_files.uploading) *
        100
      ).toFixed(0);
      return Number(total);
    });
    const total_folders_uploaded = computed(() => {
      const total = (
        (self.upload.process.total_folders.uploaded /
          self.upload.process.total_folders.uploading) *
        100
      ).toFixed(0);
      return Number(total);
    });
    const active = ref(0);
    const self = reactive({
      api_url: {
        presigned: "presigned-url",
        media: "media",
        upload: `${process.env.VUE_APP_URL}/upload`,
      },
      is_minimized: false,
      upload: {
        queue: {} as any,
        batch_key: "",
        file_size: 0,
        max_upload_size: process.env.VUE_APP_MAX_UPLOAD_SIZE,
        max_chunk_size: process.env.VUE_APP_MAX_CHUNK_SIZE,
        max_file_chunk: process.env.VUE_APP_MAX_FILE_UPLOAD,
        total_upload_at_once: 0,
        chunkFileFolderDropQueue: {} as any,
        uploading_files: [] as any,
        signed_data: {
          url: "",
          fields: {} as any,
        },
        activity: [] as any,
        update_activity: false,
        progressing: false,
        process: {
          view: false,
          total_folders: {
            uploading: 0,
            uploaded: 0,
          },
          total_files: {
            uploading: 0,
            uploaded: 0,
          },
        },
        progress: {
          process: null,
          key: null,
        },
        media_uploaded: {
          uploaded: false,
          uploaded_all: false,
        },
        uploading_docs: false,
      } as any,
      selected_parent: {} as any,
    });

    watch(
      () => self.upload.progressing,
      (val: any) => {
        if (val) {
          self.upload.uploading_docs = true;
          window.addEventListener("beforeunload", beforeUnloadLocation, true);
        } else {
          self.upload.uploading_docs = false;
          window.removeEventListener(
            "beforeunload",
            beforeUnloadLocation,
            true
          );
        }
      }
    );

    onMounted(() => {
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    });

    function beforeUnloadLocation(e: any) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }

    function resetUploadData(cb: any) {
      self.is_minimized = false;
      self.upload = {
        queue: {} as any,
        batch_key: "",
        file_size: 0,
        max_upload_size: process.env.VUE_APP_MAX_UPLOAD_SIZE,
        max_chunk_size: process.env.VUE_APP_MAX_CHUNK_SIZE,
        max_file_chunk: process.env.VUE_APP_MAX_FILE_UPLOAD,
        total_upload_at_once: 0,
        chunkFileFolderDropQueue: {} as any,
        uploading_files: [] as any,
        signed_data: {
          url: "",
          fields: {} as any,
        },
        activity: [] as any,
        update_activity: false,
        progressing: false,
        process: {
          view: false,
          total_folders: {
            uploading: 0,
            uploaded: 0,
          },
          total_files: {
            uploading: 0,
            uploaded: 0,
          },
        },
        progress: {
          process: null,
          key: null,
        },
        media_uploaded: {
          uploaded: false,
          uploaded_all: false,
        },
        uploading_docs: false,
      } as any;
      cb();
    }

    function uploadDocument(datas: any) {
      resetUploadData(async () => {
        self.upload.progressing = true;
        self.upload.batch_key = uuidv4();
        const items = await getAllFileEntries(self.selected_parent, datas);
        if (!items) return;
        self.upload.chunkFileFolderDropQueue = {} as any;
        createDragDropFolders(
          items,
          self.selected_parent,
          self.selected_parent.slug,
          self.upload.batch_key
        );
      });
    }

    function createDragDropFolders(
      items: any,
      parent: any,
      parent_slug: any,
      batch_key: any,
      i = 0
    ) {
      if (Object.keys(items).length && i < Object.keys(items).length) {
        const item = Object.keys(items)[i];
        self.upload.process.view = true;
        const directory = {
          name: items[item].directory_name,
          parent_slug: parent_slug,
          type: "folder",
        };
        self.upload.progressing = true;
        self.upload.process.total_folders.uploading += 1;
        properties.app_service.createData(
          `${self.api_url.media}`,
          directory,
          (response: any) => {
            if (response) {
              items[item].parent_slug = parent_slug;
              items[item].directory_slug = response
                ? response.data.data.slug
                : null;
              for (const file of items[item].files) {
                prepareFolderFileToUpload(file, items[item].directory_slug);
              }
              i += 1;
              self.upload.process.total_folders.uploaded += 1;
              let new_item: any = Object.keys(items)[i];
              if (new_item) {
                new_item = new_item.split("/");
                new_item.pop();
                new_item = new_item.join("/");
                let new_parent_slug = null;
                if (item == new_item) {
                  new_parent_slug = items[item].directory_slug;
                } else {
                  if (new_item) {
                    new_parent_slug = items[new_item].directory_slug;
                  } else {
                    new_parent_slug = parent_slug;
                  }
                }
                createDragDropFolders(
                  items,
                  parent,
                  new_parent_slug,
                  batch_key,
                  i
                );
              } else {
                prepareChunk(true);
              }
            }
          },
          { batch_key: batch_key }
        );
      }
    }

    function prepareChunk(directoryUpload = false, cb: any = null) {
      const maxFileSize = parseInt(self.upload.max_chunk_size);
      const maxFile = parseInt(self.upload.max_file_chunk);
      const uploadQueue: any = {};
      const signing_keys = {} as any;
      for (const key in self.upload.chunkFileFolderDropQueue) {
        signing_keys[key] = [] as any;
        let i = 0;
        for (const file of self.upload.chunkFileFolderDropQueue[key]) {
          const file_name = uuidv4();
          const file_extension = generateFileDetail(file_name, file);
          const file_information = {
            key: file_extension,
            file_name: file.name,
            file_size: file.size,
            content_type: file.type,
            file: file,
          };
          self.upload.process.total_files.uploading += 1;
          if (key in uploadQueue == false) {
            uploadQueue[key] = [[file_information]];
          } else {
            const arrayOfFileSize = uploadQueue[key][i].map(
              (uq: any) => uq.size
            );
            const sizeOfFiles = arrayOfFileSize.reduce(
              (s1: any, s2: any) => s1 + s2
            );
            if (
              sizeOfFiles + file.size > maxFileSize ||
              uploadQueue[key][i].length >= maxFile ||
              file.size > maxFileSize
            ) {
              i += 1;
              uploadQueue[key][i] = [file_information];
            } else {
              uploadQueue[key][i].push(file_information);
            }
          }
        }
      }
      if (directoryUpload) {
        uploadDragDropChunk(uploadQueue);
      } else {
        cb(uploadQueue);
      }
    }

    function generateFileDetail(file_name: any, file: any) {
      let file_extension = null;
      if (file.name.split(".").length > 1) {
        file_extension = file.name.split(".").pop();
      }
      return file_extension ? `${file_name}.${file_extension}` : file_name;
    }

    function uploadDragDropChunk(queue: any, i = 0) {
      const queue_keys = Object.keys(queue);
      const queue_key_index = queue[queue_keys[i]];
      generateQueueData(queue, queue_key_index, queue_keys, i);
    }

    function generateQueueData(
      queue: any,
      queue_key_index: any,
      queue_keys: any,
      i: any,
      key_index = 0 as any
    ) {
      const signed_keys: any = [];
      const files: any = [];
      let new_index = i;
      new_index += 1;
      if (queue_key_index[key_index]) {
        queue_key_index[key_index].forEach((file: any, file_index: any) => {
          signed_keys.push(file.key);
          files.push(file);
          generateActivities(file.file, file.key, queue_keys[i]);
          if (queue_key_index[key_index].length == file_index + 1) {
            getPresignedDetail(
              signed_keys,
              { slug: queue_keys[i] },
              (data: any) => {
                if (data.res) {
                  if (data.res.data) {
                    data.res.data.forEach(
                      (signed_data: any, signed_index: any) => {
                        processFileUpload(
                          signed_data,
                          files[signed_index],
                          queue_keys[i],
                          () => {
                            let new_key_index = key_index;
                            new_key_index += 1;
                            if (signed_index == file_index) {
                              if (queue_key_index.length != new_key_index) {
                                generateQueueData(
                                  queue,
                                  queue_key_index,
                                  queue_keys,
                                  i,
                                  new_key_index
                                );
                                return;
                              } else if (queue_keys.length != new_index) {
                                uploadDragDropChunk(queue, new_index);
                                return;
                              }
                            }
                          }
                        );
                      }
                    );
                  }
                }
              }
            );
          }
        });
      } else if (queue_keys.length != new_index) {
        uploadDragDropChunk(queue, i + 1);
      }
    }

    async function processFileUpload(
      signed_data: any,
      file_information: any,
      parent: any,
      cb = null as any
    ) {
      let matching_key: any = signed_data["fields"]["key"];
      matching_key = matching_key.split("/").pop();
      const data: any = Object.assign({}, signed_data);
      data.file = file_information.file;
      if (data.file.size <= parseInt(self.upload.max_upload_size)) {
        uploadToStorage(signed_data, matching_key, data, async () => {
          const file_info = {
            file_name: file_information.file.name,
            file_size: file_information.file_size,
            key: matching_key,
            content_type: file_information.content_type,
            parent_slug: parent,
            type: "file",
          };
          self.upload.process.total_files.uploaded += 1;
          await properties.app_service.createData(
            self.api_url.media,
            file_info,
            (response: any, error: any) => {
              if (response) {
                self.upload.activity.forEach((upa: any) => {
                  if (upa.matching_key == matching_key) {
                    upa.progress = 100;
                    upa.done = true;
                  }
                });
              }
              if (error) {
                if (error.message == "Network Failed") {
                  processFileUpload(signed_data, file_information, parent, cb);
                }
                properties.$toastMessage(properties.$getErrorResponse(error));
              }
              self.upload.media_uploaded = {
                uploaded: true,
                uploaded_all:
                  self.upload.process.total_files.uploading ==
                  self.upload.process.total_files.uploaded,
              };
              if (
                self.upload.process.total_files.uploading ==
                self.upload.process.total_files.uploaded
              ) {
                self.upload.progressing = false;
                self.upload.update_activity = true;
              }
              cb();
            }
          );
        });
      }
    }

    function generateActivities(file: any, key: any, parent: any) {
      const activity = {
        matching_key: key,
        file: file,
        file_name: file.name,
        progress: 0,
        done: false,
        source: getSignedSource(),
        parentListingSlug: parent,
        upload_data: true,
      } as any;
      self.upload.file_size = file.size;
      if (self.upload.file_size >= parseInt(self.upload.max_upload_size)) {
        let max_size = parseInt(self.upload.max_upload_size) / 1024 / 1024;
        let limit_size = "MB";
        if (max_size / 1024 >= 1) {
          max_size = max_size / 1024;
          limit_size = "GB";
        }
        activity.message = `File size exceed. Max upload size is ${max_size} ${limit_size}`;
      }
      self.upload.activity.unshift(activity);
    }

    function uploadToStorage(
      signed_data: any,
      key: any,
      data: any,
      cb: any,
      queue: any = null,
      failed_jobs: any = {}
    ) {
      let source: any = null;
      self.upload.activity.forEach((upa: any) => {
        if (upa.matching_key == key) {
          source = upa.source;
        }
      });
      const formData = new FormData();
      Object.entries(data.fields).forEach(([key, value]: any) => {
        formData.append(key, value);
      });
      formData.append("file", data.file);
      properties.signed_url.axios
        .post(self.api_url.upload, formData, {
          cancelToken: source.token,
          headers: { "x-amz-acl": signed_data.fields.acl },
          onUploadProgress: (process: any) => {
            updateUploadProgress(process, key);
          },
        })
        .then(() => {
          if (key in failed_jobs) {
            delete failed_jobs[key];
          }
          cb();
        })
        .catch((error: any) => {
         
          if (properties.signed_url.axios.isCancel(error)) {
            self.upload.total_upload_at_once =
              self.upload.total_upload_at_once - 1;
            if (self.upload.total_upload_at_once == 0 && queue != null) {
              queue();
            }
          } else {
            if (key in failed_jobs) {
              clearTimeout(failed_jobs[key]);
            }
            if (data.file.size >= parseInt(self.upload.max_upload_size)) {
              delete failed_jobs[key];
            } else {
              failed_jobs[key] = setTimeout(() => {
                uploadToStorage(signed_data, key, data, cb, queue, failed_jobs);
              }, 3000);
            }
          }
        });
    }

    function prepareFolderFileToUpload(file: any, dir_slug: any) {
      if (
        self.upload.chunkFileFolderDropQueue != null &&
        dir_slug in self.upload.chunkFileFolderDropQueue
      ) {
        self.upload.chunkFileFolderDropQueue[dir_slug].push(file);
      } else {
        self.upload.chunkFileFolderDropQueue[dir_slug] = [file];
      }
    }

    async function getAllFileEntries(parent: any, items: any) {
      const file = {
        entries: {} as any,
        queue: [] as any,
        file_queue: [] as any,
      };

      for (let i = 0; i < items.length; i++) {
        file.queue.push(items[i].webkitGetAsEntry());
        file.file_queue.push(items[i].webkitGetAsEntry());
      }
      self.upload.queue = [] as any;
      while (file.queue.length > 0) {
        const entry = file.queue.shift();
        if (!entry) return;
        if (entry.isFile) {
          const full_path = entry.fullPath.split("/");
          full_path.pop();
          const directory_path: any = full_path.join("/");
          if (directory_path in file.entries) {
            entry.file((ent: any) => {
              file.entries[directory_path].files.push(ent);
            });
          } else {
            entry.file((ent: any) => {
              progressUploadFile(ent, parent, file.file_queue);
            });
          }
        } else if (entry.isDirectory) {
          file.entries[entry.fullPath] = {
            directory_name: entry.name,
            directory_slug: null,
            parent_slug: null,
            files: [],
          };
          const reader = entry.createReader();
          file.queue.push(...(await readAllDirectoryEntries(reader)));
        }
      }
      return file.entries;
    }

    async function progressUploadFile(file: any, parent: any, entries: any) {
      const file_key = uuidv4();
      const file_extension = generateFileDetail(file_key, file);
      const file_information = {
        key: file_extension,
        file_name: file.name,
        file_size: file.size,
        content_type: file.type,
        file: file,
      } as any;
      self.upload.queue.push(file_information);
      if (self.upload.queue.length == entries.length) {
        uploadFile(self.upload.queue, parent);
      }
    }

    function uploadFile(queue: any, parent: any) {
      resetUploadData(() => {
        self.upload.progressing = true;
        const upload_doc = new Promise((resolve: any) => {
          for (const file of queue) {
            prepareFolderFileToUpload(
              file.file ? file.file : file,
              parent.slug
            );
          }
          prepareChunk(false, (response: any) => {
            resolve(response);
          });
        });
        upload_doc.then((response: any) => {
          uploadDragDropChunk(response);
        });
      });
    }

    async function getPresignedDetail(keys_data: any, parent: any, cb: any) {
      self.upload.process.view = true;
      const keys = {
        keys: keys_data,
      };
      await properties.app_service.createData(
        self.api_url.presigned,
        keys,
        (response: any, error: any) => {
          cb({ res: response, err: error });
        },
        { parent_slug: parent.slug }
      );
    }

    function getSignedSource() {
      return properties.signed_url.axios.CancelToken.source();
    }

    async function readAllDirectoryEntries(reader: any) {
      const entries = [] as any;
      let readEntries: any = await readEntriesPromise(reader);
      while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = await readEntriesPromise(reader);
      }
      return entries;
    }
    async function readEntriesPromise(reader: any) {
      try {
        return await new Promise((resolve, reject) => {
          reader.readEntries(resolve, reject);
        });
      } catch {
        return;
      }
    }

    function updateOnlineStatus(e: any) {
      const { type } = e;
      userOnline.value = type === "online";
    }

    function activityCanceled(activity: any) {
      if (activity.err) {
        return (activity.done = false);
      } else if (activity.canceled) {
        self.upload.activity = self.upload.activity.filter(
          (act: any) => act.file_name != activity.file_name
        );
      } else {
        activity.source.cancel("Operation Cancelled By User");
        if (activity.message) {
          activity.done = true;
        }
        activity.progress = 0;
        activity.message = "Cancelled by user.";
        activity.canceled = true;
      }
      if (!self.upload.activity.length) {
        self.upload.progressing = false;
      }
    }

    function updateUploadProgress(process: any, key: any) {
      self.upload.activity.forEach((upa: any) => {
        if (upa.matching_key == key) {
          const upload_progress = (process.loaded * 100) / process.total;
          upa.progress = upload_progress;
          if (upload_progress == 100) {
            upa.done = true;
          }
        }
      });
    }

    function toggleUploadMinimize() {
      self.is_minimized = !self.is_minimized;
    }

    return {
      appData,
      properties,
      fileInput,
      total_files_uploaded,
      total_folders_uploaded,
      self,
      active,
      userOnline,
      uploadDocument,
      getAllFileEntries,
      uploadFile,
      generateActivities,
      getSignedSource,
      readAllDirectoryEntries,
      readEntriesPromise,
      createDragDropFolders,
      prepareChunk,
      uploadDragDropChunk,
      prepareFolderFileToUpload,
      processFileUpload,
      uploadToStorage,
      activityCanceled,
      updateUploadProgress,
      toggleUploadMinimize,
    };
  },
});
</script>
