/* eslint-disable */
import "@fortawesome/fontawesome-free/js/all.min";
import "@icon/icofont/icofont.css";
import "primeflex/primeflex.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primevue/resources/themes/lara-light-green/theme.css";
import "../assets/scss/main.scss";
import UIComponents from "./ui";
import modules from "./modules";
import directives from "./directives";
import globalProperties from "./globalProperties";
import { keycloak } from "./keycloak";
import "./validation";
declare global {
  interface Document {
    docvault: any;
    uploading_docs: Boolean;
  }
}

export default {
  install: (app: any) => {
    app.config.globalProperties.$keycloak = keycloak;
    Object.entries(UIComponents).forEach(([name, component]) => {
      app.component(name, component);
    });
    // Load all registered modules
    Object.entries(modules).forEach(([key, value]) => {
      app.use(value);
    });
    Object.entries(globalProperties).forEach(([key, value]) => {
      app.config.globalProperties[key] = value;
    });
    // Load all regestered directives
    Object.entries(directives).forEach(([name, directive]) => {
      app.directive(name, directive);
    });
    /* Theme and Favicon */
    const fav_icon = app.config.globalProperties.$getImage(
      process.env.VUE_APP_FAV
    );
    const link = document.createElement("link");
    link.type = "image/png";
    link.rel = "shortcut icon";
    link.href = fav_icon;
    document.getElementsByTagName("head")[0].appendChild(link);
    const theme_ = process.env.VUE_APP_THEME as any;
    document.body.classList.add(theme_);
    app.config.warnHandler = () => {};
    document.docvault = app;
    /* EOF Theme and Favicon */
  },
};