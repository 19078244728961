<template>
  <div class="error-content bg-transparent mt-6 relative">
    <div class="error-message">
      <span class="icon-error p-mb-2">
        <i :class="page_icon"></i>
      </span>
      <p class="p-m-0">
        {{ message }} <span v-if="!hide_page_type" class="text-capitalize">{{ page_type }}</span>
      </p>
      <Button
        v-if="!hide_button"
        type="button"
        class="button-primary p-mt-3"
        :icon="button_icon"
        :label="button_text"
        @click.prevent="$emit('add-new', true)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "EmptyErrorMessage",
  props: {
    page_icon: {
      type: String,
      default: "fa fa-file",
    },
    page_type: {
      type: String,
      default: "Items",
    },
    hide_button: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "There are no",
    },
    hide_page_type:{
      type: Boolean,
      default: false,
    },
    button_text:{
      type: String,
      default: "Add",
    },
    button_icon:{
      type: String,
      default: "fas fa-plus",
    },
    
  }, 
};
</script>