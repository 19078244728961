<template>
  <div id="form-content" class="page-content">
    <Form ref="editResource" class="form-wrap">
      <div class="field grid">
        <label for="name" class="col-12 mb-2 md:col-3 md:mb-0">
          Name <small class="ml-2 p-error">*</small>
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="name"
            rules="required"
            autocomplete="off"
            v-model="self.resource.name"
          >
            <InputText type="text" v-model="self.resource.name" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="name" />
        </div>
      </div>
      <div class="field grid">
        <label for="description" class="col-12 mb-2 md:col-3 md:mb-0">
          Description <small class="ml-2 p-error">*</small>
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="description"
            name="description"
            rules="required"
            autocomplete="off"
            v-model="self.resource.description"
          >
            <Textarea v-model="self.resource.description" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="description" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update Resource"
          :loading="self.updating_resource"
          @click.prevent="updateResource"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { appContext }: any = getCurrentInstance();
const props = defineProps({
  resource: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const editResource = ref();
const emit = defineEmits(["updated-resource"]);
const self = reactive({
  store: useStore(),
  resource: getFormDetail(),
  updating_resource: false,
});
function getFormDetail() {
  return {
    name: props.resource.name,
    description: props.resource.description,
  };
}

async function updateResource() {
  const validate = await editResource.value.validate();
  if (!validate.valid) return;
  self.updating_resource = true;
  await appContext.config.globalProperties.app_service.updateData(
    "resources",
    self.resource,
    props.resource.id,
    (response: any, error: any) => {
      if (response) {
        self.updating_resource = false;
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("updated-resource", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>