import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex grid justify-content-between flex-wrap" }
const _hoisted_2 = { class: "col-10 flex justify-content-start align-items-center" }
const _hoisted_3 = { class: "ext-large w-12rem h-12rem" }
const _hoisted_4 = { class: "profile-info w-9 ml-3" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "flex justify-content-between flex-wrap w-20rem" }
const _hoisted_7 = { class: "col-2 flex justify-content-start align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createElementVNode("input", {
          type: "file",
          ref: "fileInputImg",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageSelected && _ctx.onImageSelected(...args))),
          style: {"display":"none"}
        }, null, 544),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.user_detail.image)
                ? (_openBlock(), _createBlock(_component_Avatar, {
                    key: 0,
                    image: _ctx.self.properties.$getFullPath(_ctx.user_detail.image),
                    shape: "circle",
                    class: "mr-2",
                    size: "xlarge"
                  }, null, 8, ["image"]))
                : (_openBlock(), _createBlock(_component_Avatar, {
                    key: 1,
                    label: _ctx.user_detail.name.charAt(0),
                    shape: "circle",
                    class: "mr-2 text-white",
                    size: "xlarge"
                  }, null, 8, ["label"]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, [
                _createElementVNode("b", null, _toDisplayString(_ctx.user_detail.name), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                  class: "text-primary",
                  to: { name: 'myVault' }
                }, {
                  default: _withCtx(() => [
                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-layer-group mr-2" }, null, -1)),
                    _createTextVNode(_toDisplayString(_ctx.summary_detail.project_count), 1)
                  ]),
                  _: 1
                })), [
                  [
                    _directive_tooltip,
                    'Projects',
                    void 0,
                    { bottom: true }
                  ]
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                  class: "text-primary",
                  to: { name: 'myVault' }
                }, {
                  default: _withCtx(() => [
                    _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa fa-folder mr-2" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.summary_detail.folder_count), 1)
                  ]),
                  _: 1
                })), [
                  [
                    _directive_tooltip,
                    'Folders',
                    void 0,
                    { bottom: true }
                  ]
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                  class: "text-primary",
                  to: { name: 'myVault' }
                }, {
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa fa-file mr-2" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.summary_detail.file_count), 1)
                  ]),
                  _: 1
                })), [
                  [
                    _directive_tooltip,
                    'Files',
                    void 0,
                    { bottom: true }
                  ]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              icon: "icofont icofont-pencil-alt-2",
              class: "button-primary",
              label: "Update Image",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.fileInputImg.click()), ["prevent"])),
              raised: ""
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}