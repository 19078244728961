<template>
  <div id="form-content" class="page-content">
    <Form ref="changeUserPassword" class="form-wrap">
      <div class="field grid">
        <label for="password" class="col-12 mb-2 md:col-4 md:mb-0"
          >Password <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="password"
            name="password"
            rules="required|password_requirement"
            autocomplete="off"
            v-model="self.user.new_password"
          >
            <Password class="w-full" v-model="self.user.new_password">
              <template #header>
                <h6>Enter Password</h6>
              </template>
              <template #footer>
                <Divider />
                <p class="mt-2">Suggestions</p>
                <ul class="pl-2 ml-2 mt-0">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>Minimum 8 characters</li>
                </ul>
              </template>
            </Password>
          </Field>
          <ErrorMessage as="small" class="p-error" name="password" />
        </div>
      </div>
      <div class="field grid">
        <label for="confirm-password" class="col-12 mb-2 md:col-4 md:mb-0"
          >Re-type Password <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-8">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="confirm-password"
            name="confirm-password"
            rules="required|confirmed:password"
            autocomplete="off"
            v-model="self.user.re_password"
          >
            <Password class="w-full" v-model="self.user.re_password" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="confirm-password" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update Password"
          :loading="self.updating_password"
          @click.prevent="updatePassword"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, reactive, ref } from "vue";
const { appContext }: any = getCurrentInstance();
const props = defineProps({
  user: {
    type: Object,
  },
});
const emit = defineEmits(["updated-password"]);
const self = reactive({
  user: {
    new_password: "",
    re_password: "",
  },
  updating_password: false,
  api_url: "users",
});
const changeUserPassword = ref();

async function updatePassword() {
  const validate = await changeUserPassword.value.validate();
  if (!validate.valid) {
    return;
  }
  self.updating_password = true;
  await appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    self.user,
    props.user.id,
    (response: any, error: any) => {
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("updated-password", response.data.data);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
      self.updating_password = false;
    },
    "update-password",
    true
  );
}
</script>