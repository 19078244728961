<template>
  <div class="app-layout">
    <div class="page">
      <div class="body-content">
        <div
          class="flex flex-wrap align-items-center justify-content-center h-screen"
        >
          <Card class="border-round w-3 mx-auto shadow-5 text-center p-6">
            <template #header>
              <Image :src="self.logo" />
            </template>
            <template #title>{{ self.data.title }}</template>
            <template #content>
              <Message class="text-left" :closable="false" severity="error">
                {{ self.data.message }}
              </Message>
            </template>
            <template #footer>
              <Button
                @click="self.$keycloak.login()"
                label="Login"
                class="button-primary block w-full uppercase p-3 text-lg"
              />
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, getCurrentInstance, onMounted } from "vue";
export default defineComponent({
  name: "MaintananceLayout",
  setup() {
    const { appContext }: any = getCurrentInstance();
    const self: any = reactive({
      ...appContext.config.globalProperties,
      logo: appContext.config.globalProperties.$getImage(
        process.env.VUE_APP_THEME == "green"
          ? process.env.VUE_APP_MAIN_LOGO
          : process.env.VUE_APP_LOGO
      ),
      data: {
        title: "Maintanance",
        message: "Under Maintanance",
      },
    });
    onMounted(() => {
      const type = self.$route.params.type;
      self.data = {
        title:
          type == "no-role"
            ? "No Role Assigned"
            : type == "auth-failed"
            ? "Authorization Failed"
            : "Under Maintanance",
        message:
          type == "no-role"
            ? "Oops!! looks like you have not been assigned any role. Please contact system administrator to get a role."
            : type == "auth-failed"
            ? "Your session has expired please login again."
            : "This site is under maintanance. Please try logging in again after few minutes.",
      };
      checkUserLogin();
    });

    function checkUserLogin() {
      setTimeout(() => {
        const userData = self.$store.getters["setting/getUserDetail"];
        if (userData) {
          self.$router.push({
            name: "dashboard",
          });
        }
      }, 500);
    }

    return {
      self,
      checkUserLogin,
    };
  },
});
</script>
