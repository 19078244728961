<template>
  <div id="form-content" class="page-content">
    <Form ref="editGroupUser" class="form-wrap">
      <div class="field grid">
        <label for="user" class="col-12 mb-2 md:col-3 md:mb-0"
          >User <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="name"
            name="User"
            rules="required"
            autocomplete="off"
            v-model="self.group.user_id"
          >
            <Dropdown
              inputId="type"
              v-model="self.group.user_id"
              :options="users.data"
              optionLabel="name"
              optionValue="id"
              placeholder="Search & Select a User"
              filter
              :loading="users.loading"
              :emptyMessage="users.emptyFilterMessage"
              :emptyFilterMessage="users.emptyFilterMessage"
              @filter="$emit('search-users',{originalEvent: $event, user_id:self.group.user_id})"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="User" />
        </div>
      </div>
      <div class="field grid">
        <label for="permission" class="col-12 mb-2 md:col-3 md:mb-0"
          >Permission</label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full formgroup-inline checkbox-wrap justify-content-between"
            as="div"
            type="text"
            id="permission"
            name="Permission"
            autocomplete="off"
          >
            <div
              class="field-checkbox"
              v-for="(permission, key) in self.group.permission"
              :key="key"
            >
              <Checkbox
                v-model="self.group.permission[key]"
                :inputId="key.toString"
                :name="key.toString"
                binary
                @change="updateAll(key)"
              />
              <label :for="key.toString" class="ml-0">
                {{ capitalize(key.toString()) }}</label
              >
            </div>
          </Field>
          <ErrorMessage as="small" class="p-error" name="Permission" />
        </div>
      </div>
      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Update User"
          :loading="self.creating_group"
          @click.prevent="updateUser"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import {
  getCurrentInstance,
  reactive,
  ref,
  capitalize,
  
} from "vue";
const { appContext }: any = getCurrentInstance();
const emit = defineEmits(["updated-group-user", "search-users"]);
const props:any = defineProps({
  group: {
    type: Object,
  } as any,
  user: {
    type: Object,
  } as any,
  users: {
    type: Object,
  },
});
const self = reactive({
  api_url: `user-groups/${props.group.id}/users/${props.user.user.id}`,
  group: getGroupDetail(),
  creating_group: false, 
});
const editGroupUser = ref();
 
function getGroupDetail() {
  return {
    user_id: props.user.user.id,
    permission: {
      read: props.user.permission.read == 1,
      write: props.user.permission.write == 1,
      edit: props.user.permission.edit == 1,
      delete: props.user.permission.delete == 1,
      manage: props.user.permission.manage == 1,
    },
  } as any;
}

function updateAll(key: any) {
  appContext.config.globalProperties.$checkAll(self.group.permission, key);
}
async function updateUser() {
  const validate = await editGroupUser.value.validate();
  if (!validate.valid) {
    return;
  }
  self.creating_group = true;
  const form_data = {
    permission: {
      read: self.group.permission.read ? 1 : 0,
      write: self.group.permission.write ? 1 : 0,
      edit: self.group.permission.edit ? 1 : 0,
      delete: self.group.permission.delete ? 1 : 0,
      manage: self.group.permission.manage ? 1 : 0,
    },
  };
  appContext.config.globalProperties.app_service.updateData(
    self.api_url,
    form_data,
    null,
    (response: any, error: any) => {
      self.creating_group = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        emit("updated-group-user", response.data.data);
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}
</script>
