import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: [],
}) as any;
router.beforeEach((to: any, from: any, next: any) => {
  document.title = to.meta.title ? `${to.meta.title}` : process.env.VUE_APP_NAME || "";
  document.docvault.config.globalProperties.$removeDragSelection();
  goToRoute(to, next);
});

function goToRoute(to: any, next: any) {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    next();
  } else {
    next(true);
  }
  if (to.hash) {
    router.replace({ name: to.name, hash: null, query: to.query });
  }
}
export default router;