<template>
  <div>
    <AppToolbar permission="reports" :breadcrumb_home="self.breadcrumbItems" :options="self.toolbarOptions"
      :loading="self.loading" @reload-data="reloadAllData" @create-new="self.create_new = true" />
    <div class="the-content">
      <TabView v-model:activeIndex="self.active_tab">
        <TabPanel header="Active Reports">
          <TableView v-if="self.active_tab == 0" class="mt-2" permission_type="reports" :loading="self.loading"
            :items="self.reports" :fields="self.table_fields" :actions="self.action_buttons"
            :pagination="self.pagination" disable_select @load-more-data="getReportListing">
            <template #col-data="{ data }">
              <ProgressBar v-if="data.key == 'progress'" :value="data.value" style="height: 20px" />
              <span v-else v-html="data.value"></span>
            </template>
          </TableView>
        </TabPanel>
        <TabPanel header="Future Reports">
          <TableView v-if="self.active_tab == 1" class="mt-2" permission_type="reports" :loading="self.upcoming.loading"
            :items="self.upcoming.reports" :fields="self.upcoming.table_fields" :actions="self.upcoming.action_buttons"
            :pagination="self.upcoming.pagination" disable_select @load-more-data="getUpcomingReportListing">
            <template #col-data="{ data }">
              <ProgressBar v-if="data.key == 'progress'" :value="data.value" style="height: 20px" />
              <span v-else v-html="data.value"></span>
            </template>
          </TableView>
        </TabPanel>
      </TabView>
      <Sidebar id="sidebar-form" v-model:visible="self.create_new" position="right">
        <template #header>Generate Report </template>
        <GenerateReport :options="self.report_options" @created-report="reloadAllData" @search-users="searchUsers" @search-projects="searchProjects"/>
      </Sidebar>
      <Sidebar id="sidebar-form" v-model:visible="self.regenerate_report" position="right">
        <template #header>Re-Generate Report</template>
        <ReGenerateReport :report="self.selected_report" :options="self.report_options" @updated-report="reloadAllData"
          @search-users="searchUsers" @search-projects="searchProjects"/>
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
const ReGenerateReport = defineAsyncComponent(() => import("./components/reGenerateReport.vue"));
const GenerateReport = defineAsyncComponent(() => import("./components/generateReport.vue"));
export default defineComponent({
  name: "ReportIndexData",
  components: {
    GenerateReport,
    ReGenerateReport,
  },
  setup() {
    const { appContext }: any = getCurrentInstance();
    const debounce = appContext.config.globalProperties.$debounce();
    const self = reactive({
      properties: appContext.config.globalProperties,
      api_url: "reports",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-file-alt mr-1",
        label: "Reports",
        to: { name: "reportsIndex" },
      },
      permissions: {} as any,
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        refresh: true,
        add: true,
      },
      reports: [] as any,
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "title",
          label: "Name",
          sortable: true,
          style: "width:25%",
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: false,
          format: (val: any) => {
            return val ? appContext.config.globalProperties.$dateTimeToLocal(val) : null;
          },
        },
        {
          key: "filters",
          label: "Start Date",
          sortable: false,
          format: (val: any) => {
            return val.start_date
              ? appContext.config.globalProperties.$monthDateYear(val.start_date)
              : null;
          },
        },
        {
          key: "filters",
          label: "End Date",
          sortable: false,
          format: (val: any) => {
            return val.end_date
              ? appContext.config.globalProperties.$monthDateYear(val.end_date)
              : null;
          },
        },
        {
          key: "progress",
          label: "Status",
          sortable: false,
          style: "width:15%",
        },
      ],
      selected_report: {},
      create_new: false,
      regenerate_report: false,
      action_buttons: [
        {
          type: "Re-generate Report",
          icon: "icofont icofont-refresh",
          command: (val: any) => {
            reGenerateReport(val);
          },
          permission: "edit",
        },
        {
          type: "Download",
          icon: "icofont icofont-download",
          command: (val: any) => {
            downloadReport(val);
          },
          view_on: ["file_path"],
          permission: "read",
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteReport(val);
          },
          permission: "delete",
        },
      ],
      upcoming: {
        api_url: '_future/reports',
        loading: false,
        reports: [],
        page: 1,
        pagination: {
          per_page: 15,
          total_records: 30,
          current_page: 1,
          total_page: 1,
        },
        table_fields: [
          {
            key: "title",
            label: "Name",
            sortable: true,
            style: "width:25%",
          },
          {
            key: "created_by",
            label: "Created By",
            sortable: false,
          },
          {
            key: "job_execution_date",
            label: "Recurring Date",
            sortable: false,
            format: (val: any) => {
              return val ? appContext.config.globalProperties.$dateTimeToLocal(val) : null;
            },
          },
          {
            key: "progress",
            label: "Status",
            sortable: false,
            style: "width:15%",
          },
        ],
        action_buttons: [
          {
            type: "Delete",
            icon: "icofont icofont-trash",
            command: (val: any) => {
              deleteReport(val);
            },
            permission: "delete",
          },
        ]
      },
      report_options: {
        tags: [] as any,
        types: [] as any,
        outputs: [{ name: "CSV", slug: "csv" }],
        users: {
          data: [] as any,
          loading: false,
          emptyFilterMessage: "Enter a text to view Users",
        },
        projects: {
          data: [] as any,
          loading: false,
          emptyFilterMessage: "Enter a text to view Projects",
        },
        frequencies: [
          { name: "Once", slug: "once" },
          { name: "Every 5 Min", slug: "five_min" },
          { name: "Daily", slug: "daily" },
          { name: "Weekly", slug: "weekly" },
          { name: "Monthly", slug: "monthly" },
          { name: "Quarterly", slug: "quarterly" },
          { name: "Semi Annually", slug: "semi_annually" },
          { name: "Annually", slug: "annually" },
        ],
      },
    });
    watch(
      () => self.active_tab,
      () => {
        updatedReportListing()
      }
    );
    onMounted(() => {
      getReportListing();
      getUserPermission();
      getReportTypes();
      openSocketConnection();
    });
    function getUserPermission() {
      self.permissions = self.properties.$appPermissions();
      if (
        self.properties.$appPermissions("tags").read == 1 ||
        self.properties.$appPermissions("tags").manage == 1
      ) {
        getTags();
      }
    }

    function getTags() {
      appContext.config.globalProperties.app_service.getData(
        "tags",
        1,
        (response: any, error: any) => {
          if (response) {
            self.report_options.tags = response.data;
          }
          if (error) {
            appContext.config.globalProperties.$toastMessage(
              appContext.config.globalProperties.$getErrorResponse(error)
            );
          }
        }
      );
    }
    function getReportTypes() {
      self.report_options.types = [
        { label: "List all projects", value: "project" },
        { label: "List all folders", value: "folder" },
        { label: "List all files", value: "file" },
        { label: "List all user activities", value: "user-activity" },
        { label: "List all quarantine files", value: "quarantine-file" },
        { label: "User Login Activity", value: "user-login-activity" },
        { label: "List untagged folders", value: "list-untagged-folders" },
      ];
    }
    // function reloadAllData(data: object = {}, created_new: boolean = false) {
    function reloadAllData() {
      self.regenerate_report = false;
      self.create_new = false;
      self.report_options.users.data = [];
      if (self.active_tab == 0) {
        getReportListing(true);
      } else {
        getUpcomingReportListing(true)
      }
      // if (created_new) {
      //   self.reports.unshift(data);
      // } else {
      //   if(self.active_tab == 0){
      //    getReportListing(true); 
      // } else {
      //   getUpcomingReportListing(true)
      // }
      // }
    }

    function reGenerateReport(val: any) {
      self.selected_report = val;
      if (val.attached_users) {
        self.report_options.users.data = val.attached_users;
      }
      if (val.projects) {
        self.report_options.projects.data[0] = val.projects;
      }
      self.regenerate_report = true;
    }

    function downloadReport(val: any) {
      self.selected_report = val;
      appContext.config.globalProperties.$downloadItem(val.file_path);
    }

    function deleteReport(val: any) {
      self.selected_report = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to delete?",
        header: "Delete Report",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_report,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedReportListing();
              }

              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedReportListing() {
      self.regenerate_report = false;
      if (self.active_tab == 0) {
        getReportListing(true);
      } else {
        getUpcomingReportListing(true)
      }

    }

    function getUpcomingReportListing(reset = false) {
      if (reset) {
        self.upcoming.page = 1;
        self.upcoming.reports = [];
      }

      if (
        (self.upcoming.page <= self.upcoming.pagination.total_page && !self.upcoming.loading) ||
        (self.upcoming.page == 1 && !self.upcoming.loading)
      ) {
        self.upcoming.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.upcoming.api_url,
          self.upcoming.page,
          (response: any, error: any) => {
            processupcomingListing(response, error);
          }
        );
      }
    }

    function getReportListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.reports = [];
      }

      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }
    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.reports.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    function processupcomingListing(res: any, err: any) {
      if (res) {
        self.upcoming.pagination = res.metadata;
        self.upcoming.reports.push(...res.data);
        self.upcoming.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.upcoming.loading = false;
    }

    function openSocketConnection() {
      appContext.config.globalProperties.app_service.getSocketConnection(
        appContext.config.globalProperties,
        "reports",
        (response: any) => {
          if (response.data) {
            self.reports.forEach((report: any) => {
              if (report.id == response.data.report_id) {
                report.progress = response.data.progress;
                report.file_path = response.data.file_path;
              }
            });
            setTimeout(() => {
              if (response.reload) {
                return openSocketConnection();
              }
            }, 300);
          }
        }
      );
    }

    function searchUsers(e: any) {
      const old_data = self.report_options.users.data;
      debounce(() => {
        if (!e.originalEvent.value)
          return (self.report_options.users.emptyFilterMessage =
            "Enter a text to view Users");
        self.report_options.users.loading = true;
        self.properties.app_service.searchData(
          "users",
          null,
          {
            query: e.originalEvent.value,
          },
          (response: any, error: any) => {
            if (response) {
              self.report_options.users.data = response.data;
              updateUserListing(old_data);
              if (!self.report_options.users.data.length) {
                self.report_options.users.emptyFilterMessage = "No User Found";
              }
            }
            if (error) {
              self.properties.$toastMessage(self.properties.$getErrorResponse(error));
            }
            self.report_options.users.loading = false;
          }
        );
      });
    }

    function searchProjects(e: any) {
      const old_data = self.report_options.projects.data;
      debounce(() => {
        if (!e.originalEvent.value)
          return (self.report_options.projects.emptyFilterMessage =
            "Enter a text to view Users");
        self.report_options.projects.loading = true;
        self.properties.app_service.searchData(
          "projects",
          null,
          {
            query: e.originalEvent.value,
          },
          (response: any, error: any) => {
            if (response) {
              self.report_options.projects.data = response.data;
              updateProjectListing(old_data);
              if (!self.report_options.projects.data.length) {
                self.report_options.projects.emptyFilterMessage = "No User Found";
              }
            }
            if (error) {
              self.properties.$toastMessage(self.properties.$getErrorResponse(error));
            }
            self.report_options.projects.loading = false;
          }
        );
      });
    }

    function updateUserListing(old_data: any) {
      self.report_options.users.data = self.properties.$removeDuplicateFromArray(
        self.report_options.users.data,
        old_data
      );
    }

    function updateProjectListing(old_data: any) {
      self.report_options.projects.data = self.properties.$removeDuplicateFromArray(
        self.report_options.projects.data,
        old_data
      );
    }

    return {
      self,
      getTags,
      getReportTypes,
      debounce,
      searchUsers,
      searchProjects,
      updatedReportListing,
      getReportListing,
      getUpcomingReportListing,
      downloadReport,
      reGenerateReport,
      deleteReport,
      reloadAllData,
      openSocketConnection,
    };
  },
});
</script>