<template>
  <div>
    <AppToolbar
      permission="user_groups"
      :breadcrumb_home="self.breadcrumbItems"
      :options="self.toolbarOptions"
      :loading="self.loading"
      @reload-data="reloadAllData"
      @create-new="self.create_new = true"
    />
    <div class="the-content">
      <TableView
        permission_type="user_groups"
        :loading="self.loading"
        :items="self.groups"
        :fields="self.table_fields"
        :actions="self.action_buttons"
        :pagination="self.pagination"
        disable_select
        @load-more-data="getGroupListing"
      />
      <Sidebar
        id="sidebar-form"
        position="right"
        v-model:visible="self.edit_group"
      >
        <template #header> Edit User Group</template>
        <EditGroup
          :group="self.selected_group"
          @updated-group="updatedUserListing"
        />
      </Sidebar>

      <Sidebar
        id="sidebar-form"
        position="right"
        v-model:visible="self.create_new"
      >
        <template #header> Add User Group </template>
        <CreateGroup @created-group="updatedUserListing" />
      </Sidebar>
      <Sidebar
        id="sidebar-form"
        position="right"
        class="w-6"
        v-model:visible="self.edit_group_users"
      >
        <template #header> Update Users In Group </template>
        <GroupUserList
          :users="self.users"
          :group="self.selected_group"
          :loading="self.loading"
          @search-users="searchUsers"
          @update-user-data="updateUserData"
        />
      </Sidebar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
const EditGroup = defineAsyncComponent(() => import("./components/editGroup.vue"));
const CreateGroup = defineAsyncComponent(() => import("./components/createGroup.vue"));
const GroupUserList = defineAsyncComponent(() => import("./components/groupUsers.vue"));
export default defineComponent({
  name: "UserGroupIndexData",
  components: {
    EditGroup,
    CreateGroup,
    GroupUserList,
  },
  setup() {
    const { appContext }:any = getCurrentInstance();
    const properties = appContext.config.globalProperties;
    const debounce = properties.$debounce();
    const self = reactive({
      api_url: "user-groups",
      confirm: useConfirm(),
      breadcrumbItems: {
        icon: "fas fa-users-cog mr-1",
        label: "User Groups",
        to: { name: "userGroups" },
      },
      store: useStore(),
      active_tab: 0,
      toolbarOptions: {
        filter: false,
        refresh: true,
        add: true,
      },
      exclude_keys: ["role"],
      groups: [] as any,
      users: {
        data: [] as any,
        loading: false,
        emptyFilterMessage: "Enter a text to view Users",
      },
      loading: false,
      page: 1,
      pagination: {
        per_page: 15,
        total_records: 30,
        current_page: 1,
        total_page: 1,
      },
      table_fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          style: "width:190px",
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
          style: "width:190px",
        },
        {
          key: "share_projects",
          label: "Share Projects",
          sortable: true,
          format: (val: any) => {
            return val ? "Yes" : "No";
          },
        },
      ],
      selected_group: {},
      create_new: false,
      edit_group: false,
      edit_group_users: false,
      action_buttons: [
        {
          type: "Edit",
          icon: "icofont icofont-edit",
          command: (val: any) => {
            editGroup(val);
          },
          permission: "edit",
          status: ["active"],
        },
        {
          type: "Delete",
          icon: "icofont icofont-trash",
          command: (val: any) => {
            deleteGroup(val);
          },
          permission: "delete",
          status: ["active"],
        },
        {
          type: "Update User(s) in Group",
          icon: "icofont icofont-ui-user",
          command: (val: any) => {
            updateUsersList(val);
          },
          permission: "edit",
          status: ["active"],
        },
      ],
    });

    onMounted(() => {
      getGroupListing();
    });
    
    function reloadAllData() {
      getGroupListing(true);
    }

    function editGroup(val: any) {
      self.selected_group = val;
      self.edit_group = true;
    }

    function updateUsersList(val: any) {
      self.selected_group = val;
      self.edit_group_users = true;
    }

    function deleteGroup(val: any) {
      self.selected_group = val;
      self.confirm.require({
        group: "confirmDialog",
        message: "Are you sure you want to delete?",
        header: "Delete Group",
        position: "top",
        rejectLabel: "Cancel",
        acceptLabel: "Ok",
        accept: () => {
          appContext.config.globalProperties.app_service.deleteData(
            self.api_url,
            self.selected_group,
            (response: any, error: any) => {
              if (response) {
                appContext.config.globalProperties.$toastMessage(response);
                updatedUserListing();
              }
              if (error) {
                appContext.config.globalProperties.$toastMessage(
                  appContext.config.globalProperties.$getErrorResponse(error)
                );
              }
            }
          );
        },
      });
    }

    function updatedUserListing(
      data: object = {},
      created_new: boolean = false
    ) {
      self.create_new = false;
      self.edit_group = false;
      self.users.data = [];
      if (created_new) {
        self.groups.unshift(data);
      } else {
        getGroupListing(true);
      }
    }

    function updateUserData(data: any) {
      self.users.data = [data];
    }

    function searchUsers(e: any) {
      const old_data = self.users.data;
      debounce(() => {
        if (!e.originalEvent.value)
          return (self.users.emptyFilterMessage = "Enter a text to view Users");
        properties.app_service.searchData(
          "users",
          null,
          {
            query: e.originalEvent.value,
          },
          (response: any, error: any) => {
            if (response) {
              self.users.data = response.data.map((user: any) => {
                return {
                  id: user.id,
                  name: user.name,
                };
              });
              updateUserListing(old_data);
              if (!self.users.data.length) {
                self.users.emptyFilterMessage = "No User Found";
              }
            }
            if (error) {
              properties.$toastMessage(properties.$getErrorResponse(error));
            }
          }
        );
      });
    }
    function updateUserListing(old_data: any) {
      self.users.data = properties.$removeDuplicateFromArray(
        self.users.data,
        old_data
      );
    }

    function getGroupListing(reset = false) {
      if (reset) {
        self.page = 1;
        self.groups = [];
      }
      if (
        (self.page <= self.pagination.total_page && !self.loading) ||
        (self.page == 1 && !self.loading)
      ) {
        self.loading = true;
        appContext.config.globalProperties.app_service.getData(
          self.api_url,
          self.page,
          (response: any, error: any) => {
            processListing(response, error);
          }
        );
      }
    }

    function processListing(res: any, err: any) {
      if (res) {
        self.pagination = res.metadata;
        self.groups.push(...res.data);
        self.page += 1;
      }
      if (err) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(err)
        );
      }
      self.loading = false;
    }

    return {
      self,
      editGroup,
      debounce,
      updateUserData,
      searchUsers,
      updatedUserListing,
      deleteGroup,
      getGroupListing,
      reloadAllData,
    };
  },
});
</script>
