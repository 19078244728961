import appConfig from "@/configs/app";
import Service from "@/library/system/service";
export default  class ShareService extends Service {
  shareURL: string;
  myShareURL: string;
  constructor() {
    super();
    this.setBaseUrl(appConfig.ApiBaseUrl);
    this.setHttpClient();
    this.shareURL = "share";
    this.myShareURL = "my/share";
  }

  getPrivateShareList(id: string, type: string, cb: any) {
    const url = `${type}/${id}/${this.shareURL}`
    this.get(url, {}, {})
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error: any) => {
        cb(null, error);
      });
  }
}