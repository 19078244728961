import axios from "axios";
import HttpClientInterface from "./httpClientInterface";
import { detect } from "detect-browser";
function userDevice() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}
const user_device = userDevice();
const browser_information = detect();
class AxiosHttpClient implements HttpClientInterface {
  private _httpClient: any;
  private _baseUrl: string;
  constructor() {
    this._httpClient = axios;
    this._baseUrl = "";
  }

  setBaseUrl(baseUrl: string): this {
    this._baseUrl = baseUrl;
    return this;
  }

  getBaseUrl(): string {
    return this._baseUrl;
  }

  setHttpClient(): this {
    this._httpClient = axios.create({
      baseURL: this.getBaseUrl(),
    });
    this.setUserToken();
    return this;
  }

  setUserToken() {
    this._httpClient.interceptors.request.use(
      (config: any) => {
        const token =
          document.docvault.config.globalProperties.$store.getters["setting/getUserToken"];
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        config.headers["User-Device"] = user_device;
        config.headers["User-Browser-Name"] = browser_information ? browser_information.name : null;
        config.headers["User-Browser-Version"] = browser_information ? browser_information.version : null;
        config.headers["User-OS"] = browser_information ? browser_information.os : null;
        config.headers["User-Browser-Type"] = browser_information ? browser_information.type : null;
        return config;
      },
      (err: any) => {
        return Promise.reject(err);
      }
    );
  }

  getHttpClient(): any {
    return this._httpClient;
  }

  get(url: string, params = {}, config: any): Promise<unknown> {
    if (config) config.params = params;
    return new Promise((resolve, reject) => {
      this.getHttpClient()
        .get(url, config)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err: any) => {
          this.onReject(reject, err);
        });
    });
  }

  post(
    url: string,
    data: string | object,
    params: any = {},
    config: any
  ): Promise<unknown> {
    if (config) config.params = params;
    return new Promise((resolve, reject) => {
      this.getHttpClient()
        .post(url, data, config)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err: any) => {
          this.onReject(reject, err);
        });
    });
  }

  patch(url: string, data: string | object, config: any): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.getHttpClient()
        .patch(url, data, config)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err: any) => {
          this.onReject(reject, err);
        });
    });
  }

  put(url: string, data: string | object, config: any): Promise<unknown> {
    return new Promise((resolve, reject) => {
      this.getHttpClient()
        .put(url, data, config)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err: any) => {
          this.onReject(reject, err);
        });
    });
  }

  delete(url: string, params: string | object, config: any): Promise<unknown> {
    if (config) config.params = params;
    return new Promise((resolve, reject) => {
      this.getHttpClient()
        .delete(url, config)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err: any) => {
          this.onReject(reject, err);
        });
    });
  }

  onReject(reject: any, err: any) {
    if (err.response) {
      if (
        err.response.status == 502 ||
        err.response.status == 503 ||
        err.response.status == 401 ||
        err.response.status == 426
      ) {
        const msg =
          err.response.status == 502 || err.response.status == 503
            ? "maintanance"
            : err.response.status == 426
            ? "no-role"
            : "auth-failed";
        this.loadMaintainencePage(msg);
        return reject(err);
      } else {
        return reject(err);
      }
    } else {
      this.loadMaintainencePage(err.code);
      return reject(err);
    }
  }

  loadMaintainencePage(msg: any) {
    setTimeout(() => {
      document.docvault.config.globalProperties.$router.push({
        name: "onMaintanance",
        params: {
          type: msg,
        },
      });
    }, 500);
  }
}

export default AxiosHttpClient;
