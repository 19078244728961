<template>
  <div v-if="progress>=1" class="custom-progress-bar">
    <ProgressBar :value="progress" :showValue="false" style="height: 0.1em" />
  </div>
</template>
<script>
export default {
  name: "ChuchuroProgressBar",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  mounted() {
    this.getloadingProgress(true);
  },
  methods: {
    getloadingProgress(start_progress = false) {
      this.progress = 0;
      if (start_progress) {
        const loading_interval = setInterval(() => {
          if (this.progress < 85) {
            this.progress += 5;
          } else {
            clearInterval(loading_interval);
          }
        }, 50);
      }
      if (!start_progress) {
        this.progress = 100;
      }
    },
  },
  watch: {
    loading(val) {
      this.getloadingProgress(val);
    },
  },
};
</script>